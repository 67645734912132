import { UserFacade } from '@act/features/user/data-access';
import {
  MessageTemplateCategoryDto,
  MessageTemplateDto,
  MessageTemplateVariablesDto
} from '@act/shared/data-transfer-objects';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TextTemplateFacade } from '../+state/text-template.facade';
import { compile as compileHandleBars } from 'handlebars';
import { Subscription } from 'rxjs';

@Component({
  selector: 'act-text-template',
  templateUrl: './text-template.component.html',
  styleUrls: ['./text-template.component.scss']
})
export class TextTemplateComponent implements OnInit, OnDestroy {
  textTemplates: MessageTemplateCategoryDto[];
  textTemplateVariables: MessageTemplateVariablesDto;
  selectedTemplate: MessageTemplateCategoryDto;
  subscriptions: Subscription[] = [];
  @Output() selectTemplate = new EventEmitter<{
    template: MessageTemplateDto;
    compiledTemplate: string;
  }>();

  @Input() userId: string;

  constructor(
    private textTemplateFacade: TextTemplateFacade,
    private userFacade: UserFacade
  ) {}

  async ngOnInit() {
    const userSubscription = this.userFacade.user$.subscribe(async user => {
      const canSeeMessageTemplates = user.featureFlags.MESSAGE_TEMPLATES;

      if (canSeeMessageTemplates) {
        const {
          templates,
          variables
        } = await this.textTemplateFacade.getTextTemplatesForUser(this.userId);
        if (templates && templates.length) {
          this.textTemplates = templates;
          this.textTemplateVariables = variables;
        }
      }
    });
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onItemClick(item) {
    this.selectedTemplate = item;
  }

  handleTemplateClick(template: MessageTemplateDto): void {
    const hbTemplate = compileHandleBars<MessageTemplateVariablesDto>(
      template.template
    );
    const compiledTemplate = hbTemplate({ ...this.textTemplateVariables });

    this.selectTemplate.emit({ template, compiledTemplate });
  }
  goBack() {
    this.selectedTemplate = null;
  }
}
