import { createAction, props } from '@ngrx/store';
import { TodoRequests } from './todo.requests';
import {
  TodoEventTypes,
  TodoEventPayload,
  TodoDeletedEventPayload
} from '@act/features/todos/events';

const requests = new TodoRequests(null, null);

export const {
  initiator: loadTodos,
  success: todosLoaded,
  error: todosLoadError
} = requests.getTodos.getRequestActions();

export const {
  initiator: snoozeTodo,
  success: todosSnoozed,
  error: todosSnoozeError
} = requests.snoozeTodos.getRequestActions();

export const refreshTodos = createAction(`$[Todo] Refresh Todos`);

export const todoCreated = createAction(
  TodoEventTypes.TODO_CREATED,
  props<{ payload: TodoEventPayload }>()
);

export const todoUpdated = createAction(
  TodoEventTypes.TODO_UPDATED,
  props<{ payload: TodoEventPayload }>()
);

export const todoDeleted = createAction(
  TodoEventTypes.TODO_DELETED,
  props<{ payload: TodoDeletedEventPayload }>()
);
