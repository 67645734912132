import { createAction, props } from '@ngrx/store';
import {
  MessageDto,
  MessageWithFeedItemDto
} from '@act/shared/data-transfer-objects';

export const createMessage = createAction(
  '[Messages] Create Message',
  props<{ message: MessageDto; creationId: number }>()
);

export const messageCreated = createAction(
  '[Messages] Message Created',
  props<{
    message: MessageWithFeedItemDto;
    creationId: number;
  }>()
);

export const messageCreationError = createAction(
  '[Messages] Message Creation Error',
  props<{ creationId: number }>()
);

export const resendFailedMessage = createAction(
  '[Messages] Resend Failed Message',
  props<{ message: MessageDto; creationId: number }>()
);
