import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  userAuthenticated,
  userNotAuthenticated,
  userLoaded
} from './user.actions';

import { userQueries } from './user.selectors';
import { ActingUserDto } from '@act/shared/data-transfer-objects';

@Injectable()
export class UserFacade {
  user$: Observable<ActingUserDto> = this.store.pipe(
    select(userQueries.selectUser)
  );
  authenticated$: Observable<boolean> = this.store.pipe(
    select(userQueries.selectAuthenticated)
  );

  constructor(private store: Store<any>) {}

  userLoaded(user: ActingUserDto): void {
    this.store.dispatch(userLoaded({ user }));
  }

  userAuthenticated() {
    this.store.dispatch(userAuthenticated());
  }

  userNotAuthenticated() {
    this.store.dispatch(userNotAuthenticated());
  }
}
