import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  loadMemberStages,
  memberStagesLoaded,
  memberStageLoadError,
  memberStatusLoadError,
  memberStatusesLoaded,
  loadMemberStatuses
} from './member-stage.actions';
import {
  MemberStageDto,
  MemberStatusDto
} from '@act/shared/data-transfer-objects';

export const memberStagesFeatureKey = 'member-stages';

interface StatusSubState extends EntityState<MemberStatusDto> {
  loading: boolean;
}

export interface State extends EntityState<MemberStageDto> {
  loading: boolean;
  statuses: StatusSubState;
}

export const adapter: EntityAdapter<MemberStageDto> = createEntityAdapter<
  MemberStageDto
>();

export const statusAdapter: EntityAdapter<
  MemberStatusDto
> = createEntityAdapter<MemberStatusDto>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  statuses: statusAdapter.getInitialState({ loading: false })
});

export const memberStagesReducer = createReducer(
  initialState,

  on(loadMemberStages, (state, action) => ({
    ...adapter.removeAll(state),
    loading: true
  })),
  on(memberStagesLoaded, (state, action) => ({
    ...adapter.addAll(action.responseBody, state),
    loading: false
  })),
  on(memberStageLoadError, (state, action) => ({
    ...state,
    loading: false
  })),

  on(loadMemberStatuses, (state, action) => ({
    ...state,
    statuses: {
      ...statusAdapter.removeAll(state.statuses),
      loading: true
    }
  })),
  on(memberStatusesLoaded, (state, action) => ({
    ...state,
    statuses: {
      ...statusAdapter.addAll(action.responseBody, state.statuses),
      loading: false
    }
  })),
  on(memberStatusLoadError, (state, action) => ({
    ...state,
    statuses: {
      ...state.statuses,
      loading: false
    }
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return memberStagesReducer(state, action);
}
