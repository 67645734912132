import { ErrorActionPayload } from '@act/shared/models';
import { Action } from '@ngrx/store';
import { UserDto } from '@act/shared/data-transfer-objects';

export enum LoadPatientsTypes {
  LOAD_PATIENTS = '[Patients] Load Patients',
  PATIENTS_LOADED = '[Patients] Patients Loaded',
  LOAD_PATIENTS_FAILED = '[Patients] Load Patients Failed'
}

export class LoadPatientsEvent implements Action {
  readonly type = LoadPatientsTypes.LOAD_PATIENTS;
}

export interface PatientsLoadedPayload {
  patients: UserDto[];
}

export class PatientsLoaded implements Action {
  readonly type = LoadPatientsTypes.PATIENTS_LOADED;

  constructor(readonly payload: PatientsLoadedPayload) {}
}

export class LoadPatientsFailed implements Action {
  readonly type = LoadPatientsTypes.LOAD_PATIENTS_FAILED;

  constructor(public payload: ErrorActionPayload) {}
}
