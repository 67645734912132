import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usDateFormat'
})
export class USDateFormatPipe implements PipeTransform {
  transform(value: Date, ...args: any[]): any {
    const year = value.getFullYear();
    const month = value.getMonth() + 1;
    const date = value.getDate();
    return `${month}/${date}/${year}`;
  }
}
