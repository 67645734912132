import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { MatIconModule } from '@angular/material/icon';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [CardComponent],
  exports: [CardComponent]
})
export class SharedUiCardModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'btn-edit',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/btn_Edit.svg')
    ),
    iconRegistry.addSvgIcon(
      'plus-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Icon_Plus.svg')
    );
  }
}
