import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromUser from './+state/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './+state/user.effects';
import { UserFacade } from './+state/user.facade';

@NgModule({
  imports: [
    StoreModule.forFeature(fromUser.userFeatureKey, fromUser.reducer),
    EffectsModule.forFeature([UserEffects])
  ],
  providers: [UserFacade]
})
export class FeaturesUserDataAccessModule {}
