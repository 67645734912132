import { NoteFacade } from '../+state/note/note.facade';
import { FeedItem, IconMenuItem } from '../models';
import { FeedDto, NoteDto, UserDto } from '@act/shared/data-transfer-objects';
import { PatientsFacade } from '@act/features/patients/data-access';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { VisitFacade } from '../+state/visit/visit.facade';
import { Observable, Subject } from 'rxjs';
import { UsersFacade } from '@act/features/users/data-access';
import { UserFacade } from '@act/features/user/data-access';
import { CallFacade } from '../+state/call/call.facade';

@Component({
  selector: 'act-single-feed',
  templateUrl: './single-feed.component.html',
  styleUrls: ['./single-feed.component.scss']
})
export class SingleFeedComponent implements OnInit, OnChanges {
  @Input() item: FeedItem;
  @Input() previousItem: FeedItem;
  @Input() nextItem: FeedItem;
  @Input() userMap: Dictionary<UserDto>;
  @Input() user: UserDto;
  @Input() member: UserDto;
  @Input() itemNotes: NoteDto[];
  @Input() displayOnly: boolean;
  @Input() feed: FeedDto;
  @Input() isLastEventItem: boolean;
  @HostBinding('class.found-item') @Input() isNew: boolean;

  constructor(
    private noteFacade: NoteFacade,
    public visitFacade: VisitFacade,
    public patientsFacade: PatientsFacade,
    public usersFacade: UsersFacade,
    public userFacade: UserFacade,
    public callFacade: CallFacade
  ) {}

  itemNotes$: Observable<NoteDto[]>;
  menuItems: IconMenuItem[] = [];

  ngOnInit() {
    // this.setItemNotes();
    this.menuItems = this.getMenuItems();
  }

  ngOnChanges() {
    this.setItemNotes();
  }

  setItemNotes() {
    if (this.item) {
      this.itemNotes$ = this.noteFacade.getNotesForFeedItem$(this.item.id);
    } else {
      this.itemNotes$ = new Subject();
    }
  }

  private getMenuItems(): IconMenuItem[] {
    if (this.displayOnly) {
      return null;
    }

    const menuItems: IconMenuItem[] = [];

    // Add Edit Options
    if (this.item.createdBy === this.user.id) {
      if (this.item.note) {
        menuItems.push({
          icon: 'edit',
          tooltip: 'Edit Note',
          action: () => {
            this.noteFacade.toggleNoteEditing(this.item.note);
          }
        });
      }

      if (this.item.visit) {
        menuItems.push({
          icon: 'edit',
          tooltip: 'Edit Visit',
          action: () => {
            this.visitFacade.editVisit(this.item.visit);
          }
        });
      }
    }

    menuItems.push({
      tooltip: 'Add Note',
      iconImage: '/assets/icons/note.svg',
      action: () => {
        this.viewFeedItemNotes(this.item.id);
      }
    });

    return menuItems;
  }

  viewFeedItemNotes(feedItemId: number) {
    this.noteFacade.viewFeedItemNotes(feedItemId);
  }
}
