import { NoteDto, UserDto } from '@act/shared/data-transfer-objects';
import { Dictionary } from '@ngrx/entity';
import { FeedCardData } from '@act/shared/ui/feed-card';
import {
  getCreatedByString,
  getTimeString
} from '../helpers/format-data.helper';

export const getCardDataFromNote = (
  note: NoteDto,
  actingUser: UserDto,
  userMap: Dictionary<UserDto>
): FeedCardData => {
  return {
    icon: 'note',
    iconColor: 'outgoing',

    titleParts: [
      {
        text: 'Note',
        bold: true
      },
      {
        text: getCreatedByString(note.createdBy, actingUser.id, userMap)
      }
    ],
    metadataParts: note.createdAt
      ? [{ text: getTimeString(note.createdAt) }]
      : [],
    details: null,
    audio: null,
    note: note.text,
    badges: []
  };
};
