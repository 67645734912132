import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  noteCreated,
  noteCreationError,
  noteUpdated,
  noteUpdateError,
  createNote,
  editNote,
  stopEditingNote,
  viewFeedItemNotes,
  stopViewingFeedItemNotes,
  noteDeleted,
  updateNote,
  deleteNote,
  noteDeleteError,
  createComment,
  commentCreated,
  commentCreationError
} from './note.actions';
import { NoteDto } from '@act/shared/data-transfer-objects';
import {
  addToInflightList,
  InflightIds,
  removeFromInflightList
} from '@act/shared/api';

export const noteFeatureKey = 'notes';

export interface State extends EntityState<NoteDto> {
  editedNoteId: number;
  viewFeedItemNotes: number;
  inflightCreationIds: number[];
  inflightUpdateIds: InflightIds;
  loading: boolean;
}

export const adapter: EntityAdapter<NoteDto> = createEntityAdapter<NoteDto>({
  sortComparer: (a: NoteDto, b: NoteDto) => (a.createdAt > b.createdAt ? 1 : -1)
});

export const initialState: State = adapter.getInitialState({
  editedNoteId: null,
  viewFeedItemNotes: null,
  inflightCreationIds: [],
  inflightUpdateIds: {},
  loading: false
});

const noteReducer = createReducer(
  initialState,
  on(createNote, (state, action) => ({
    ...state,
    inflightCreationIds: [
      ...state.inflightCreationIds,
      action.actionParams.creationId
    ],
    loading: true
  })),
  on(noteCreated, (state, action) => {
    // Add note if the new note isn't associated with a feedItem
    const newState = !action.responseBody.feedItem
      ? adapter.upsertOne(action.responseBody, state)
      : state;
    return {
      ...newState,
      editedNoteId: action.responseBody.id,
      viewFeedItemNotes: action.responseBody.feedItemId,
      inflightCreationIds: state.inflightCreationIds.filter(
        id => id !== action.actionParams.creationId
      ),
      loading: false
    };
  }),
  on(noteCreationError, (state, action) => ({
    ...state,
    inflightCreationIds: state.inflightCreationIds.filter(
      id => id !== action.actionParams.creationId
    ),
    loading: false
  })),
  on(updateNote, (state, action) => ({
    ...state,
    inflightUpdateIds: addToInflightList(
      action.params.noteId,
      state.inflightUpdateIds
    ),
    loading: !!action.requestBody.delta
  })),
  on(createComment, (state, action) => ({
    ...state,
    inflightUpdateIds: addToInflightList(
      action.params.noteId,
      state.inflightUpdateIds
    ),
    loading: true
  })),
  on(noteUpdated, (state, action) => {
    return {
      ...adapter.upsertOne(action.responseBody, state),
      inflightUpdateIds: removeFromInflightList(
        action.params.noteId,
        state.inflightUpdateIds
      ),
      loading: false
    };
  }),
  on(
    noteUpdateError,
    commentCreated,
    commentCreationError,
    (state, action) => ({
      ...state,
      inflightUpdateIds: removeFromInflightList(
        action.params.noteId,
        state.inflightUpdateIds
      ),
      loading: false
    })
  ),
  on(deleteNote, (state, action) => ({ ...state, loading: true })),
  on(noteDeleted, (state, action) => ({
    ...adapter.removeOne(action.params.noteId, state),
    loading: false
  })),
  on(noteDeleteError, (state, action) => ({ ...state, loading: false })),
  on(editNote, (state, action) => {
    const feedItemId = action.note.feedItemId || null;
    return {
      ...state,
      editedNoteId: action.note.id,
      viewFeedItemNotes: feedItemId
    };
  }),
  on(stopEditingNote, (state, action) => {
    if (state.editedNoteId === action.noteId) {
      return { ...state, editedNoteId: null };
    }
    return state;
  }),
  on(viewFeedItemNotes, (state, action) => {
    return {
      ...state,
      editedNoteId: null,
      viewFeedItemNotes: action.feedItemId
    };
  }),
  on(stopViewingFeedItemNotes, (state, action) => {
    if (state.viewFeedItemNotes === action.feedItemId) {
      return {
        ...state,
        viewFeedItemNotes: null
      };
    }
    return state;
  })
);

export const noteAdapter = adapter;

export function reducer(state: State | undefined, action: Action) {
  return noteReducer(state, action);
}
