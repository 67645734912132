import { createAction, props } from '@ngrx/store';
import { FeedItemDto } from '@act/shared/data-transfer-objects';
import {
  FeedItemUpdatedEventPayload,
  FeedItemUpdatedTypes
} from '@act/feed/events';

export const loadFeedItemFrame = createAction(
  '[Feed-Item] Load Feed Item Frame',
  props<{ earlier: boolean }>()
);

export const feedItemsFrameLoaded = createAction(
  '[Feed-Item] Feed Item Frame Loaded',
  props<{ items: FeedItemDto[]; earlier: boolean; isMore: boolean }>()
);

export const feedItemsFrameLoadError = createAction(
  '[Feed-Item] Feed Item Frame Load Error',
  props<{ earlier: boolean }>()
);

export const newFeedItem = createAction(
  '[Feed-Item] New Feed Item Added',
  props<{ item: FeedItemDto }>()
);

export const clearFeed = createAction('[Feed-Item] Clear Feed');

export const deleteFeedItem = createAction(
  '[Feed-Item] DeleteFeed Item',
  props<{ feedItemId: number }>()
);

export const setFeedItemFilters = createAction(
  '[Feed-Item] Set Feed Item Filters',
  props<{ typeFilters: string[] }>()
);

export const resetFeedItemFilters = createAction(
  '[Feed-Item] Reset Feed Item Filters'
);

// This is an ActionCreator version of the FeedTypingListUpdated event
export const updateFeedItem = createAction(
  FeedItemUpdatedTypes.FEED_ITEM_UPDATED,
  props<{ payload: FeedItemUpdatedEventPayload }>()
);
