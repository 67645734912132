import {
  ScheduledEventActionEventArgsDto,
  ScheduledEventDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ComponentRef,
  TemplateRef,
  OnDestroy
} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScheduledEventFacade } from '../+state/scheduled-event/scheduled-event.facade';
import * as moment from 'moment';
import { eventTypes } from '@act/shared/ui/feed-card';
import { Dictionary } from '@ngrx/entity';
import { upcomingEventsObservable$ } from '../feed-header/feed-header.component';
import { AnchoredDialogService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog.service';
import { AnchoredDialogComponent } from 'libs/shared/ui/dialog/src/lib/components/anchored-dialog/anchored-dialog.component';
import { Subscription } from 'rxjs';
import { UserFacade } from '@act/features/user/data-access';

@Component({
  selector: 'act-scheduled-events',
  templateUrl: './scheduled-events.component.html',
  styleUrls: ['./scheduled-events.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(90%)' }),
        animate('300ms ease-in')
      ]),
      transition(':leave', [
        style({ transform: 'translateY(10%)' }),
        animate('100ms ease-out')
      ])
    ])
  ]
})
export class ScheduledEventsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() member: UserDto;
  @Input() pastEvents: ScheduledEventDto[];
  @Input() upcomingEvents: ScheduledEventDto[];
  @Input() userMap: Dictionary<UserDto>;
  @Input() showPastEvents: boolean;
  @Input() loadingFeed: boolean;
  @Output() scheduledEventActionEvent = new EventEmitter<
    ScheduledEventActionEventArgsDto
  >();
  @ViewChild('upcomingEventsRef', { static: true })
  upcomingEventsRef: TemplateRef<any>;
  anchoredDialogRef: ComponentRef<AnchoredDialogComponent>;
  upcomingEventsSubscription: Subscription;

  dialogRef: MatDialogRef<any>;
  scheduledEventId: number;
  currentTimeStampInSeconds = new Date().getTime();
  eventTypes = eventTypes;

  actingUser$ = this.userFacade.user$;

  constructor(
    public scheduledEventFacade: ScheduledEventFacade,
    public userFacade: UserFacade,
    private anchoredDialogService: AnchoredDialogService
  ) {}

  ngOnInit() {
    this.upcomingEventsSubscription = upcomingEventsObservable$.subscribe(
      show => {
        if (show && !this.anchoredDialogRef) {
          this.anchoredDialogRef = this.anchoredDialogService.create({
            icon: 'notifications_none',
            title: 'Upcoming Events',
            ref: this.upcomingEventsRef,
            onClose: () => {
              this.anchoredDialogRef = null;
            },
            iconStyleClass: 'pink-icon',
            actionType: 'view',
            eventType: 'reminder',
            eventId: this.member.id
          });
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.upcomingEvents &&
      changes.upcomingEvents.currentValue.length
    ) {
      this.upcomingEvents = this.upcomingEvents.sort(this.sortingAccessor);
    }

    if (
      changes &&
      changes.pastEvents &&
      changes.pastEvents.currentValue.length
    ) {
      this.pastEvents = this.pastEvents.sort(this.sortingAccessor);
    }
  }

  sortingAccessor(a: ScheduledEventDto, b: ScheduledEventDto) {
    // Sort events on the basis of date
    if (new Date(a.date) > new Date(b.date)) {
      return 1;
    } else if (new Date(a.date) < new Date(b.date)) {
      return -1;
    } else {
      // If dates are same, sort on time
      // Show the events with a specified time,
      // above the events with no specified time,
      if (!a.time && b.time) {
        return 1;
      }
      if (!b.time && a.time) {
        return -1;
      }
      if (a.time && b.time) {
        return a.time > b.time ? 1 : -1;
      }
      return -1;
    }
  }

  trackItem(index: number, item: ScheduledEventDto): number {
    return item.id;
  }

  scheduledEventActionEventHandler($event: ScheduledEventActionEventArgsDto) {
    this.scheduledEventActionEvent.emit($event);
  }

  checkEventDate(date: string) {
    let response = false;
    const today = moment().format('YYYY-MM-DD');

    if (date === today) {
      response = true;
    }
    return response;
  }

  ngOnDestroy() {
    this.upcomingEventsSubscription.unsubscribe();
  }
}
