import { Pipe, PipeTransform } from '@angular/core';
import { convertMsToHoursMinutesSeconds } from '@act/common/utilities';

@Pipe({
  name: 'hoursMinutes'
})
export class HoursMinutesPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    const conversion = convertMsToHoursMinutesSeconds(value);
    const days = conversion.day ? `${conversion.day} days ` : '';
    const hours = conversion.hour ? `${conversion.hour} hours ` : '';
    const minutes =
      (!conversion.day && !conversion.hour) || conversion.minute
        ? `${conversion.minute} minutes `
        : '';

    return `${days}${hours}${minutes}`;
  }
}
