import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MomentModule } from 'ngx-moment';

import { ListTeammateComponent } from './list-teammate/list-teammate.component';
import { TodayItemsComponent } from './today-items/today-items.component';
import { DefaultCheckboxComponent } from './today-items/default-checkbox.component';
import { CheckboxGroupComponent } from './today-items/checkbox-group-component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatCheckboxModule,
    MomentModule,
    MatTooltipModule,
    MatChipsModule
  ],
  declarations: [
    ListTeammateComponent,
    TodayItemsComponent,
    CheckboxGroupComponent,
    DefaultCheckboxComponent
  ],
  exports: [ListTeammateComponent, TodayItemsComponent]
})
export class SharedTeammateModule {}
