import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullscreenComponent } from './fullscreen/fullscreen.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FullscreenComponent],
  exports: [FullscreenComponent]
})
export class CommonComponentsFullscreenModule {}
