import { DebounceAutoSaver } from '@act/common/utilities/autosave';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'act-rich-text-input',
  templateUrl: './rich-text-input.component.html',
  styleUrls: ['./rich-text-input.component.scss']
})
export class RichTextInputComponent implements OnInit {
  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() autoSaver: DebounceAutoSaver<any>;
  @Input() placeholder: string;
  @ViewChild(QuillEditorComponent, null) editor: QuillEditorComponent;

  quilModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }]
    ],
    clipboard: {
      matchVisual: false
    }
  };

  constructor() {}

  ngOnInit() {}
}
