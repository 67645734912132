import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

const selectUserFeatureState = createFeatureSelector(fromUser.userFeatureKey);
const selectUser = createSelector(
  selectUserFeatureState,
  (state: fromUser.State) => state.user
);
const selectAuthenticated = createSelector(
  selectUserFeatureState,
  (state: fromUser.State) => state.authenticated
);

export const userQueries = {
  selectUser,
  selectAuthenticated
};
