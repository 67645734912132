import { FileRequests } from './file.requests';

const requests = new FileRequests(null, null);

export const {
  initiator: getFileInfo,
  success: fileInfoReceived,
  error: fileInfoLoadError
} = requests.getFileInfo.getRequestActions();

export const {
  initiator: uploadFiles,
  success: filesUploaded,
  error: filesUploadError
} = requests.uploadFiles.getRequestActions();
