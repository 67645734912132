import { ApiRequestDefinition } from '@act/shared/api';
import {
  MemberStageDto,
  MemberStatusDto
} from '@act/shared/data-transfer-objects';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class MemberStageRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getMemberStages = ApiRequestDefinition.createRequestDefinition<
    MemberStageDto[],
    null,
    null,
    null
  >(
    '/users/member-stages',
    'GET',
    '[Member Stage] Get Member Stages',
    this.store,
    this.http
  );

  getMemberStatuses = ApiRequestDefinition.createRequestDefinition<
    MemberStatusDto[],
    null,
    null,
    null
  >(
    '/users/member-statuses',
    'GET',
    '[Member Status] Get Member Statuses',
    this.store,
    this.http
  );
}
