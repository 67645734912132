import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as TodoActions from './todo.actions';
import { TodoDto } from '@act/shared/data-transfer-objects';

export const todoFeatureKey = 'todos';

export interface TodoState extends EntityState<TodoDto> {
  userId: string;
  loading: boolean;
}

export const adapter: EntityAdapter<TodoDto> = createEntityAdapter<TodoDto>({
  sortComparer: (a: TodoDto, b: TodoDto) => (a.createdAt < b.createdAt ? 1 : -1)
});

export const initialState: TodoState = adapter.getInitialState({
  userId: null,
  loading: false
});

export const todoReducer = createReducer(
  initialState,

  /**
   * Load Todos
   */
  on(TodoActions.loadTodos, (state, action) => ({
    ...adapter.removeAll(state),
    userId: action.params.guideId,
    loading: true
  })),
  on(TodoActions.todosLoaded, (state, action) => ({
    ...adapter.upsertMany(action.responseBody, state),
    loading: false
  })),
  on(TodoActions.todosLoadError, (state, action) => ({
    ...state,
    loading: false
  })),
  on(TodoActions.todoCreated, (state, action) => {
    const todo = action.payload.todo;
    if (todo.userId === state.userId) {
      return upsertTodoFromToday(todo, state);
    }
    return state;
  }),
  on(TodoActions.todoUpdated, (state, action) => {
    const todo = action.payload.todo;
    if (todo.userId === state.userId) {
      return upsertTodoFromToday(todo, state);
    }
    return state;
  }),
  on(TodoActions.todoDeleted, (state, action) => {
    const { todoId, userId } = action.payload;
    if (userId === state.userId) {
      return adapter.removeOne(todoId, state);
    }
    return state;
  })
);

const upsertTodoFromToday = (todo: TodoDto, state: TodoState): TodoState => {
  const date = new Date();
  const todayDateString =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    date
      .getDate()
      .toString()
      .padStart(2, '0');

  if (todo.date <= todayDateString) {
    // Upsert todo because it is in the right date range
    return adapter.upsertOne(todo, state);
  } else {
    // Remove todo if it exits because it is out of the date range
    return adapter.removeOne(todo.id, state);
  }
};

export function reducer(state: TodoState | undefined, action: Action) {
  return todoReducer(state, action);
}
