import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromScheduledEvent from './scheduled-event.reducer';
import { Observable } from 'rxjs';
import {
  ScheduledEventDto,
  CreateScheduledEventRequestDto,
  UpdateScheduledEventRequestDto
} from '@act/shared/data-transfer-objects';
import {
  selectLoading,
  selectScheduledEvent,
  selectScheduledEventEntities,
  selectScheduledEventMap
} from './scheduled-event.selectors';
import {
  deleteScheduledEvent,
  loadScheduledEvents,
  snoozeScheduledEvent
} from './scheduled-event.actions';
import { ScheduledEventRequests } from './scheduled-event.requests';
import { Dictionary } from '@ngrx/entity';

@Injectable()
export class ScheduledEventFacade {
  scheduledEventCreationIdTicker = 1;

  constructor(
    private store: Store<fromScheduledEvent.State>,
    private scheduledEventRequests: ScheduledEventRequests
  ) {}

  scheduledEvents$: Observable<ScheduledEventDto[]> = this.store.pipe(
    select(selectScheduledEvent)
  );

  scheduledEventsLoading$: Observable<boolean> = this.store.select(
    selectLoading
  );

  scheduledEventsMap$: Observable<
    Dictionary<ScheduledEventDto>
  > = this.store.select(selectScheduledEventMap);

  createScheduledEvent(
    request: CreateScheduledEventRequestDto,
    memberId: string
  ): Promise<ScheduledEventDto> {
    return this.scheduledEventRequests.postScheduledEvent.makeRequest({
      params: { userId: memberId },
      body: request,
      actionParams: { creationId: this.scheduledEventCreationIdTicker++ }
    });
  }

  updateScheduledEvent(
    request: UpdateScheduledEventRequestDto,
    memberId: string,
    scheduledEventId: number
  ): Promise<ScheduledEventDto> {
    return this.scheduledEventRequests.patchScheduledEvent.makeRequest({
      params: { userId: memberId, scheduledEventId },
      body: request
    });
  }

  getScheduledEventOfMember(memberId: string) {
    this.store.dispatch(
      loadScheduledEvents({ params: { userId: memberId }, requestBody: null })
    );
  }

  clearScheduledEvent(scheduledEventId: number, memberId: string) {
    this.store.dispatch(
      deleteScheduledEvent({
        params: { scheduledEventId, userId: memberId },
        requestBody: null
      })
    );
  }

  snoozeScheduledEvent(scheduledEventId: number, memberId: string) {
    this.store.dispatch(
      snoozeScheduledEvent({
        params: { scheduledEventId, userId: memberId },
        requestBody: {}
      })
    );
  }
}
