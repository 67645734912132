import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  ViewContainerRef
} from '@angular/core';
import { AnchoredDialogComponent } from '../components/anchored-dialog/anchored-dialog.component';

import {
  AnchoredDialogData,
  AnchoredDialogOptions,
  maximumVisibleDialogObservable$,
  shouldOpenDialogSubject$
} from '../models/dialog-options.model';

const MAX_ALLOWED_DIALOGS = 7;

@Injectable()
export class AnchoredDialogService {
  containerRef: ViewContainerRef;
  maximumVisibleDialogs: number;
  dialogs: AnchoredDialogData[] = [];
  previousMaximumVisibleDialogs = 0;

  constructor(private resolver: ComponentFactoryResolver) {
    this.previousMaximumVisibleDialogs = this.maximumVisibleDialogs;
    maximumVisibleDialogObservable$.subscribe(maxCount => {
      this.maximumVisibleDialogs = maxCount;
      this.onMaximumVisibleDialogChange();
    });
  }

  create(dialogOptions: AnchoredDialogOptions) {
    const { actionType, eventType, eventId } = dialogOptions;
    const id = `${actionType}_${eventType}_${eventId}`;
    const dialogData = {
      id,
      options: dialogOptions,
      visible: true
    };
    if (dialogData && this.dialogs.length < MAX_ALLOWED_DIALOGS) {
      // Create a new Dialog and store the ref
      const componentRef = this.createComponent(dialogData);
      this.dialogs.push({ ...dialogData, dialogRef: componentRef });
      shouldOpenDialogSubject$.next(this.dialogs.length < MAX_ALLOWED_DIALOGS);

      // Hide the last dialog from the right side
      if (this.dialogs.length > this.maximumVisibleDialogs) {
        this.dialogs[
          this.dialogs.length - 1 - this.maximumVisibleDialogs
        ].dialogRef.instance.dialog.visible = false;
      }

      this.setDialogsPosition();

      // Subscribe to the close event of the dialog
      componentRef.instance.closeEvent.subscribe((id: string) => {
        this.destroyComponent(id);
      });

      return componentRef;
    }
    return null;
  }

  onMaximumVisibleDialogChange() {
    if (this.dialogs.length) {
      if (this.maximumVisibleDialogs > this.previousMaximumVisibleDialogs) {
        this.showDialogs();
      } else if (
        this.maximumVisibleDialogs < this.previousMaximumVisibleDialogs
      ) {
        this.hideDialogs();
      }
    }
    this.previousMaximumVisibleDialogs = this.maximumVisibleDialogs;
  }

  private showDialogs() {
    let maxVisibleCount = this.maximumVisibleDialogs;
    let i = this.dialogs.length - 1;
    while (maxVisibleCount > 0 && i >= 0) {
      this.dialogs[i].dialogRef.instance.dialog.visible = true;
      if (this.maximumVisibleDialogs > 1) {
        this.dialogs[i].dialogRef.instance.showMinimize = true;
      }
      i--;
      maxVisibleCount--;
    }

    while (i >= 0) {
      this.dialogs[i].dialogRef.instance.dialog.visible = false;
      if (this.maximumVisibleDialogs > 1) {
        this.dialogs[i].dialogRef.instance.showMinimize = true;
      }
      i--;
    }

    this.setDialogsPosition();
  }

  private hideDialogs() {
    const visibleDialogs = this.dialogs.filter(
      dialog => dialog.dialogRef.instance.dialog.visible
    );
    let dialogsToHide = visibleDialogs.length - this.maximumVisibleDialogs;
    visibleDialogs.forEach(o => {
      if (dialogsToHide > 0) {
        o.dialogRef.instance.dialog.visible = false;
        dialogsToHide--;
      }
    });

    if (this.maximumVisibleDialogs === 1) {
      this.dialogs.forEach(o => {
        o.dialogRef.instance.showMinimize = false;
      });
    }

    this.setDialogsPosition();
  }

  private createComponent(dialogData: AnchoredDialogData) {
    const factory = this.resolver.resolveComponentFactory(
      AnchoredDialogComponent
    );

    const componentRef: ComponentRef<
      AnchoredDialogComponent
    > = this.containerRef.createComponent(factory);

    // Pass the input data to the dialog
    if (this.maximumVisibleDialogs === 1) {
      componentRef.instance.showMinimize = false;
    } else {
      componentRef.instance.showMinimize = true;
    }

    componentRef.instance.dialog = dialogData;

    return componentRef;
  }

  private destroyComponent(id: string) {
    const dialog = this.dialogs.find(dialog => dialog.id === id);

    // Remove the dialog from the array
    this.dialogs = this.dialogs.filter(dialog => dialog.id !== id);
    shouldOpenDialogSubject$.next(this.dialogs.length < MAX_ALLOWED_DIALOGS);

    // Show the last dialog from the right side
    if (this.dialogs.length >= this.maximumVisibleDialogs) {
      this.dialogs[
        this.dialogs.length - this.maximumVisibleDialogs
      ].dialogRef.instance.dialog.visible = true;
    }

    this.setDialogsPosition();

    // Unsubscribe and destroy the dialog
    dialog.dialogRef.destroy();
  }

  private setDialogsPosition() {
    const visibleDialogs = this.dialogs.filter(
      d => d.dialogRef.instance.dialog.visible
    );
    visibleDialogs.forEach((dialog, index) => {
      const margin = index * 15 + 20;
      const containerWidth = 525;
      dialog.dialogRef.instance.leftValue = index * containerWidth + margin;
    });
  }

  setContainerRef(containerRef: ViewContainerRef) {
    this.containerRef = containerRef;
  }

  destroyAllComponents() {
    this.dialogs.forEach(dialog => dialog.dialogRef.destroy());
    this.dialogs = [];
  }

  isOpen(id: string) {
    return !!this.dialogs.find(o => o.id === id);
  }

  showDialog(id: string) {
    let dialog: AnchoredDialogData;
    const filteredDialogs = this.dialogs.filter(o => {
      if (o.id !== id) {
        return true;
      } else {
        dialog = o;
        return false;
      }
    });
    if (dialog) {
      // Set the position of the dialog to the left
      this.dialogs = [...filteredDialogs, dialog];
      this.showDialogs();

      // Maximize the dialog
      dialog.dialogRef.instance.minimized = false;
    }
  }
}
