import { MessageDto, UserDto } from '@act/shared/data-transfer-objects';
import { Dictionary } from '@ngrx/entity';
import { FeedCardData } from '@act/shared/ui/feed-card';
import {
  getPhoneNumberInfo,
  getTimeString
} from '../helpers/format-data.helper';

export const getCardDataFromMessage = (
  message: MessageDto,
  userMap: Dictionary<UserDto>
): FeedCardData => {
  const originator = message.incoming
    ? getPhoneNumberInfo(
        {
          userId: message.userId,
          phoneNumber: message.smsPhoneNumber
        },
        userMap
      )
    : getPhoneNumberInfo(
        { userId: message.userId, phoneNumber: null },
        userMap
      );
  const receiver = message.incoming
    ? null
    : getPhoneNumberInfo(
        {
          userId: message.subjectId,
          phoneNumber: message.smsPhoneNumber
        },
        userMap
      );

  return {
    icon: message.incoming ? 'text_incoming' : 'text_outgoing',
    iconColor: message.incoming ? 'incoming' : 'outgoing',
    titleParts: [
      {
        text: message.incoming
          ? 'Incoming Text Message'
          : 'Outbound Text Message',
        bold: true
      },
      ...(originator
        ? [
            {
              text: 'from'
            },
            {
              text: originator.title,
              bold: true,
              details: originator.details
            }
          ]
        : []),

      ...(receiver
        ? [
            {
              text: 'to'
            },
            {
              text: receiver.title,
              bold: true,
              details: receiver.details
            }
          ]
        : [])
    ],
    metadataParts: [{ text: getTimeString(message.createdAt) }],

    details: null,
    audio: null,
    note: message.text,
    badges: []
  };
};
