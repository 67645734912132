import { MemberStageFacade } from '@act/features/member-stage/data-access';
import { UsersFacade } from '@act/features/users/data-access';
import {
  CohortDto,
  MemberFiltersDto,
  MemberStatusDto
} from '@act/shared/data-transfer-objects';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ISetFilter } from './default-checkbox.component';

@Component({
  selector: 'act-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit, OnDestroy {
  eventFilters = [
    {
      key: 'message',
      text: 'Text'
    },
    {
      key: 'call',
      text: 'Phone Call'
    },
    {
      key: 'visit',
      text: 'Visit'
    },
    {
      key: 'reminder',
      text: 'Reminder'
    },
    {
      key: 'tbd',
      text: 'Auto-Reminder'
    }
  ];
  memberStatuses$ = this.memberStageFacade.memberStatuses$;
  statuses: MemberStatusDto[];
  formFilters: FormGroup = new FormGroup({
    cohorts: new FormControl(null),
    events: new FormControl(null),
    statuses: new FormControl(null)
  });
  totalCohorts = 0;

  @Output() hideFilters: EventEmitter<boolean> = new EventEmitter();
  @Output() updateFilter = new EventEmitter<ISetFilter>();
  @Input() idOfSelectedGuide: string;
  @Input() memberFilters: MemberFiltersDto;
  @Input() cohorts$: Observable<CohortDto[]>;
  memberStatusSubscription: Subscription;

  constructor(
    public memberStageFacade: MemberStageFacade,
    public usersFacade: UsersFacade
  ) {}

  ngOnInit() {
    this.memberStatusSubscription = this.memberStatuses$.subscribe(statuses => {
      this.statuses = statuses;
    });
  }

  ngOnDestroy() {
    this.memberStatusSubscription.unsubscribe();
  }

  onSetFilter(filter: ISetFilter) {
    this.updateFilter.emit(filter);
  }

  trackById(index: number, object: { id: number }) {
    return object.id;
  }
}
