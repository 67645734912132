export const uniqueCache: {[label: string]: boolean} = {};

export function unique<T>(label: T | ''): T {
    // todo | Matt Blum | fix for use within multiple services
    // if (uniqueCache[<string>label]) {
    //     throw new Error(`Action type "${label}" is not unique"`);
    // }
    
    uniqueCache[<string>label] = true;
    
    return <T>label;
}