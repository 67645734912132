import { ElementRef, Injectable } from '@angular/core';
import { AddressDto, UserDto } from '@act/shared/data-transfer-objects';
import {
  baseUrl,
  DEFAULT_COHORT_COLOR,
  MarkerData,
  markerIconTemplate,
  MarkerIconTemplateVariablesDto
} from './map.dto';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { MapRenderer } from './map-renderer';
import { MapEventsService } from './map-events.service';
import { compile as compileHandleBars } from 'handlebars';

@Injectable()
export class MapService {
  private mapView$: Subject<boolean> = new BehaviorSubject(false);
  private templateRef: ElementRef;
  private markers: google.maps.Marker[] = [];
  private map: google.maps.Map;
  private markerClusterer: MarkerClusterer;

  constructor(private mapEventsService: MapEventsService) {}

  /**
   * Creates markers according to member locations
   */
  setMarkers(members: UserDto[]) {
    this.clearMarkers();
    if (this.markerClusterer) {
      this.markerClusterer.setMap(null);
    }
    this.markers = members.reduce(
      (acc: google.maps.Marker[], user: UserDto) => {
        const primaryAddress = user.addresses.find(({ primary }) => primary);
        if (primaryAddress) {
          const marker = this.createMarker(primaryAddress, user);
          return [...acc, marker];
        }
        return acc;
      },
      []
    );

    this.fitBoundsToVisibleMarkers();
    this.setMarkerClusterer();
  }

  /**
   * Clear all the markers on the map
   */
  private clearMarkers() {
    this.markers.forEach(marker => {
      marker.setMap(null);
    });
    this.markers = [];
  }

  /**
   * Creates a new Map
   */
  async createMap(ref: ElementRef) {
    this.templateRef = ref;
    const loader = new Loader({
      apiKey: 'AIzaSyCxxGQ0tU_ogdDzpoPmQlt3K4Un4mOxLA4'
    });

    await loader.load();
    this.map = new google.maps.Map(this.templateRef.nativeElement, {
      center: new google.maps.LatLng(45.4555729, 9.169236),
      zoom: 15,
      streetViewControl: false,
      fullscreenControl: false
    });
    this.mapEventsService.setMap(this.map);
    this.map.addListener('click', () => {
      this.mapEventsService.mapClickHandler();
    });
  }

  /**
   * Checks if map view is on
   */
  isMapView$: Observable<boolean> = this.mapView$.asObservable();

  /**
   * Toggles the map view
   */
  toggleMapView(value: boolean) {
    this.mapView$.next(value);
  }

  /**
   * Creates and new marker clusterer
   */
  private setMarkerClusterer() {
    const markers = [];
    this.markers.forEach(marker => {
      markers.push(marker);
    });
    this.markerClusterer = new MarkerClusterer({
      map: this.map,
      markers,
      renderer: new MapRenderer(this.mapEventsService),
      onClusterClick: () => {}
    });
  }

  /**
   * Fit bounds of the map
   */
  private fitBoundsToVisibleMarkers() {
    const bounds = new google.maps.LatLngBounds();

    this.markers.forEach(marker => {
      if (marker.getVisible()) {
        bounds.extend(marker.getPosition());
      }
    });

    this.map.fitBounds(bounds);
  }

  /**
   * Creates a new google maps marker
   * @returns New google maps marker
   */
  private createMarker(
    primaryAddress: AddressDto,
    user: UserDto
  ): google.maps.Marker {
    const { cohort, memberStatus } = user;

    // Create svg for marker icon
    const template = compileHandleBars<MarkerIconTemplateVariablesDto>(
      markerIconTemplate
    );
    const templateVariables = {
      color: cohort ? cohort.colorHex : DEFAULT_COHORT_COLOR
    };
    const svg = template({ ...templateVariables });

    const svgUrl = baseUrl + btoa(svg);
    const marker = new google.maps.Marker({
      position: primaryAddress,
      map: this.map,
      icon: {
        url: svgUrl,
        scaledSize: new google.maps.Size(30, 30)
      }
    });

    const data: MarkerData = {
      cohortColor: cohort ? cohort.colorHex : DEFAULT_COHORT_COLOR,
      memberName: user.name,
      memberStatus: memberStatus.name,
      memberId: user.id
    };

    marker.set('data', JSON.stringify(data));

    marker.addListener('click', () => {
      this.mapEventsService.markerClickHandler(data, marker);
    });

    marker.addListener('mouseover', () => {
      this.mapEventsService.mouseOverHandler(data, marker);
    });

    marker.addListener('mouseout', () => {
      this.mapEventsService.mouseOutHandler();
    });

    return marker;
  }
}
