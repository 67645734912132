import { userNeedAdapter, userNeedFeatureKey, State } from './need.reducer';
import * as fromChat from '../chat.selectors';
import { createSelector } from '@ngrx/store';
import { usersQueries } from '@act/features/users/data-access';

const userNeedSelectors = userNeedAdapter.getSelectors();

const selectUserNeedState = createSelector(
  fromChat.selectChatState,
  (state): State => state[userNeedFeatureKey]
);

export const selectUserNeedEntities = createSelector(
  selectUserNeedState,
  userNeedSelectors.selectEntities
);

export const selectIds = createSelector(
  selectUserNeedState,
  userNeedSelectors.selectIds
);

export const selectUserNeed = createSelector(
  usersQueries.selectSelectedMemberId,
  selectUserNeedEntities,
  selectIds,
  (selectedMemberId, state, ids) => {
    let entities = [];
    ids.forEach(id => entities.push(state[id]));
    return entities.filter(item => item.userId === selectedMemberId);
  }
);

export const selectLoadingUserNeeds = createSelector(
  selectUserNeedState,
  state => state.loadingUserNeeds
);

export const selectNeedCategories = createSelector(
  selectUserNeedState,
  state => state.needCategories
);

export const selectNeedCategoriesMap = {};
