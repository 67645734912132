import {
  UserDto,
  VisitDto,
  VisitOutcomeDto
} from '@act/shared/data-transfer-objects';
import { Component, Input, OnChanges } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { VisitFacade } from '../+state/visit/visit.facade';
import { FeedActionsService } from '../feed-actions/feed-actions.service';
import { FeedCardAction, FeedCardData } from '@act/shared/ui/feed-card';
import { getCardDataFromVisit } from './visits.card-helper';
@Component({
  selector: 'act-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss']
})
export class VisitsComponent implements OnChanges {
  @Input() visit: VisitDto;
  @Input() member: UserDto;
  @Input() userMap: Dictionary<UserDto>;
  @Input() actingUser: UserDto;
  @Input() visitOutcomesMap: Dictionary<VisitOutcomeDto>;

  cardData: FeedCardData;
  actions: FeedCardAction[];

  constructor(
    public visitFacade: VisitFacade,
    private feedActionsService: FeedActionsService
  ) {}

  ngOnChanges() {
    this.setCardData();
  }

  setCardData() {
    this.cardData = getCardDataFromVisit(
      this.visit,
      this.actingUser,
      this.userMap,
      this.visitOutcomesMap
    );

    this.actions = [
      {
        icon: 'edit',
        title: 'Edit',
        details: 'Edit Visit',
        handler: () => this.editVisit()
      }
    ];
  }
  /**
   * Edit a visit in the modal
   */
  editVisit() {
    this.feedActionsService.editVisit(this.visit);
  }
}
