import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'act-feed-loading',
  templateUrl: './feed-loading.component.html',
  styleUrls: ['./feed-loading.component.scss']
})
export class FeedLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
