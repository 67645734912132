import {
  FeedLastSeenUpdatedEventPayload,
  FeedLastSeenUpdatedTypes,
  FeedTypingListUpdatedPayload,
  FeedTypingListUpdatedTypes
} from '@act/feed/events';
import { FeedDto } from '@act/shared/data-transfer-objects';
import { createAction, props } from '@ngrx/store';
import { FeedRequests } from './feed.requests';

const requests = new FeedRequests(null, null);

export const selectFeed = createAction(
  '[Feed] Select Feed',
  props<{ feedId: number }>()
);

export const updateFeeds = createAction(
  '[Feed] UpdateFeeds',
  props<{ feeds: FeedDto[] }>()
);

export const {
  initiator: loadFeed,
  success: feedLoaded,
  error: feedLoadError
} = requests.getFeed.getRequestActions();

export const {
  initiator: updateLastSeen,
  success: lastSeenUpdated,
  error: lastSeenUpdateError
} = requests.updateLastSeen.getRequestActions();

export const feedsUpdated = createAction('[Feed] Feeds Updated');

// This is an ActionCreator version of the FeedTypingListUpdated event
export const feedTypingListUpdated = createAction(
  FeedTypingListUpdatedTypes.FEED_TYPING_LIST_UPDATED,
  props<{ payload: FeedTypingListUpdatedPayload }>()
);

// This is an ActionCreator version of the FeedLastSeenUpdated event
export const feedLastSeenUpdated = createAction(
  FeedLastSeenUpdatedTypes.FEED_LAST_SEEN_UPDATED,
  props<{ payload: FeedLastSeenUpdatedEventPayload }>()
);
