import { Injectable } from '@angular/core';
import { SocketFacade } from '@act/common/sockets';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Patient } from '@act/features/patients/models';
import { patientsQueries } from './patients/patients.selectors';
import { upsertPatient } from './patients/patients.actions';
import { ClientBootstrap, SOCKET_NAME, UserRole } from '@act/shared/models';
import { getClientConfig } from '@act/shared/environment';
import { Platform } from '@act/core/platform';
import {
  UserUpdatedPayload,
  UserUpdatedType
} from '@act/features/users/events';
import { filter } from 'rxjs/operators';
import { userWasUpdatedAction } from '@act/features/users/data-access';
import { Dictionary } from '@ngrx/entity';

const url = () => {
  const config: ClientBootstrap = getClientConfig();
  return `${config.reema.serviceUris.websocketService}/patients`;
};

const socketConfig = () => ({
  url: url(),
  options: {
    path: '/socket.io',
    autoConnect: false,
    transports: ['websocket'],
    query: {
      token: Platform.getAuthToken(),
      deviceId: Platform.deviceId
    }
  }
});

@Injectable()
export class PatientsFacade extends SocketFacade {
  patientIds$: Observable<string[] | number[]> = this.store.pipe(
    select(patientsQueries.selectPatientIds)
  );
  patients$: Observable<Patient[]> = this.store.pipe(
    select(patientsQueries.selectAllPatients)
  );
  patientMap$: Observable<Dictionary<Patient>> = this.store.pipe(
    select(patientsQueries.selectPatients)
  );
  constructor(protected store: Store<any>) {
    super(socketConfig, SOCKET_NAME.PATIENTS, store);
  }

  protected registerEventListeners() {
    this.socket
      .fromEvent(UserUpdatedType)
      .pipe(
        filter(
          (payload: UserUpdatedPayload) =>
            payload.user.role === UserRole.PATIENT
        )
      )
      .subscribe((payload: UserUpdatedPayload) => {
        this.store.dispatch(upsertPatient({ patient: payload.user }));
        this.store.dispatch(userWasUpdatedAction({ user: payload.user }));
      });
  }
}
