import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallsPageComponent } from './calls-page/calls-page.component';
import * as fromCallState from './+state/call-list.reducer';
import { StoreModule } from '@ngrx/store';
import { CallListFacade } from './+state/call-list.facade';
import { CallListEffects } from './+state/call-list.effects';
import { EffectsModule } from '@ngrx/effects';
import { CallFiltersComponent } from './call-filters/call-filters.component';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRadioModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
  MatPaginatorModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { CommonPipesHoursMinutesSecondsModule } from '@act/common/pipes/hours-minutes-seconds';
import { CommonPipesPhoneNumberModule } from '@act/common/pipes/phone-number';
import { SharedUiAudioPlayerModule } from '@act/shared/ui/audio-player';
import { SharedUiSpinnerModule } from '@act/shared/ui/spinner';
import { CallListComponent } from './call-list/call-list.component';
import { CallInitiatorComponent } from './call-initiator/call-initiator.component';
import { NgxMaskModule } from 'ngx-mask';
import { CallListRequests } from './+state/call-list.requests';
import { SharedUiFormsModule } from '@act/shared/ui/forms';
import { SharedUiDialogModule } from '@act/shared/ui/dialog';
import { FeedModule } from '@act/feed';
import { FeaturesPatientsFeatureV1Module } from '@act/features/patients/feature-v1';
import { CallsDashboardComponent } from './calls-dashboard/calls-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCallState.callListFeatureKey,
      fromCallState.CALL_LIST_FEATURE_REDUCER_TOKEN
    ),
    EffectsModule.forFeature([CallListEffects]),
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    SharedUiSpinnerModule,
    CommonPipesHoursMinutesSecondsModule,
    CommonPipesPhoneNumberModule,
    SharedUiAudioPlayerModule,
    NgxMaskModule.forRoot(),
    SharedUiFormsModule,
    SharedUiDialogModule,
    FeedModule,
    FeaturesPatientsFeatureV1Module
  ],
  providers: [
    CallListFacade,
    CallListRequests,
    {
      provide: fromCallState.CALL_LIST_FEATURE_REDUCER_TOKEN,
      useValue: fromCallState.reducer
    }
  ],
  declarations: [
    CallsPageComponent,
    CallFiltersComponent,
    CallListComponent,
    CallInitiatorComponent,
    CallsDashboardComponent
  ],
  exports: [CallsPageComponent]
})
export class CallsModule {}
