import { Inject, Injectable } from '@angular/core';
import * as createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { PersistenceService } from '@act/shared/persistence';
import { Auth0SharedClientsConfig, TOKEN } from '@act/shared/auth/models';
import { AUTH0_CONFIG } from '../shared-auth-config';
import { Store } from '@ngrx/store';
import { AuthSuccessful } from '../+state/auth.actions';
import { REDIRECT_URI } from 'libs/shared/auth/models/src/lib/persistence-keys';

@Injectable()
export class Auth0Service {
  isAuthenticated = new BehaviorSubject(false);
  profile = new BehaviorSubject<any>(null);

  private auth0Client: Auth0Client;
  private createAuth0Client = createAuth0Client as any;

  constructor(
    @Inject(AUTH0_CONFIG) private config: Auth0SharedClientsConfig,
    private persistenceService: PersistenceService,
    private store: Store<any>
  ) {
    this.init();
  }

  /**
   * Gets the Auth0Client instance.
   */
  async getAuth0Client(): Promise<Auth0Client> {
    if (!this.auth0Client)
      this.auth0Client = await this.createAuth0Client(this.config);

    return this.auth0Client;
  }

  public async login() {
    const client = await this.getAuth0Client();
    await client.loginWithRedirect({
      redirect_uri: this.config.redirect_uri
    });
  }

  public async logout() {
    const client = await this.getAuth0Client();
    await client.logout({
      client_id: this.config.client_id,
      returnTo: window.location.origin
    });
    this.persistenceService.remove(TOKEN);
    this.isAuthenticated.next(false);
  }

  public async handleRedirectCallback() {
    const client = await this.getAuth0Client();
    const result = (await client.handleRedirectCallback().catch(err => {
      console.log(err);
      throw new Error(err);
    })) as any;

    const token = await client.getTokenSilently();
    this.persistenceService.set(TOKEN, token);
    // dispatch the auth successful action
    this.store.dispatch(
      new AuthSuccessful({
        defaultRedirectRoute:
          this.persistenceService.get(REDIRECT_URI) ||
          this.config.postLoginRedirect
      })
    );
    this.persistenceService.remove(REDIRECT_URI);
  }

  private async init() {
    await this.getAuth0Client();
    const token = this.persistenceService.get<string>(TOKEN);
    const valid: boolean = token && token.length > 0;
    this.isAuthenticated.next(valid);
  }
}
