import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { initialLoadingState, LoadingItems } from '../models/dot-spinner.model';

@Injectable()
export class DotSpinnerService {
  private loadingItems: LoadingItems;
  private spinnerSubject$: Subject<boolean>;

  constructor() {
    this.spinnerSubject$ = new Subject();
    this.loadingItems = initialLoadingState;
  }

  getLoadingObservable(): Observable<boolean> {
    return this.spinnerSubject$.asObservable();
  }

  isLoading(): boolean {
    return Object.values(this.loadingItems).some(item => item === true);
  }

  showSpinner(type: string) {
    this.loadingItems[type] = true;
    this.spinnerSubject$.next(this.isLoading());
  }

  hideSpinner(type: string) {
    this.loadingItems[type] = false;
    this.spinnerSubject$.next(this.isLoading());
  }
}
