import { getCardDataFromCall } from './lib/calls/calls.card-helper';
import { getCardDataFromMessage } from './lib/messages/messages.card-helper';
import { getCardDataFromNote } from './lib/notes/notes.card-helper';
import { getCardDataFromScheduledEvent } from './lib/scheduled-events/scheduled-events.card-helper';
import { getCardDataFromVisit } from './lib/visits/visits.card-helper';

export * from './lib/feed.module';
export * from './lib/+state/chat.facade';
export * from './lib/+state/note/note.facade';
export * from './lib/+state/feed/feed.facade';
export * from './lib/+state/visit/visit.facade';
export {
  feedsUpdated,
  updateFeeds,
  selectFeed
} from './lib/+state/feed/feed.actions';
export { CallFacade } from './lib/+state/call/call.facade';
export { FeedActionsService } from './lib/feed-actions/feed-actions.service';

export const getCardData = {
  fromCall: getCardDataFromCall,
  fromMessage: getCardDataFromMessage,
  fromNote: getCardDataFromNote,
  fromScheduledEvent: getCardDataFromScheduledEvent,
  fromVisit: getCardDataFromVisit
};
