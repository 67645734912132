import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TodayTypes, UsersFacade } from '@act/features/users/data-access';
import { Dictionary } from '@ngrx/entity';
import { map } from 'rxjs/operators';
import moment = require('moment');
import { UserDto } from '@act/shared/data-transfer-objects';

@Component({
  selector: 'act-today-items',
  templateUrl: './today-items.component.html',
  styleUrls: ['./today-items.component.scss']
})
export class TodayItemsComponent implements OnInit, OnDestroy {
  @Input() userMap: Dictionary<UserDto>;
  @Input() user: UserDto;
  @Input() idOfSelectedMember: string;
  @Output() memberSelect: EventEmitter<UserDto> = new EventEmitter();

  todayResults: TodayTypes;
  isEmpty: boolean;
  selectedMemberSubscription: Subscription;
  todayResultSubscription: Subscription;
  currentTime = new Date().getTime();
  currentDate = moment().format('YYYY-MM-DD');
  selectedMemberId = '';

  constructor(public usersFacade: UsersFacade) {}

  loadingToday$ = this.usersFacade.loadingToday$;

  isEmpty$ = this.usersFacade.todayResults$.pipe(
    map(
      (result: TodayTypes) =>
        !!(
          !this.listExists(result.call) &&
          !this.listExists(result.message) &&
          !this.listExists(result.visit) &&
          !this.listExists(result.recent) &&
          !this.listExists(result.reminder)
        )
    )
  );

  listExists(thing: any[]): boolean {
    return thing && !!thing.length;
  }

  ngOnInit() {
    this.selectedMemberSubscription = this.usersFacade.selectedTeamMemberId$.subscribe(
      id => {
        if (id) {
          if (id) {
            if (id === 'all') {
              console.log('here');
              this.usersFacade.getAllTodayItems();
            } else {
              this.usersFacade.getTodayItems();
            }
          }
        }
      }
    );
    this.todayResultSubscription = this.usersFacade.todayResults$.subscribe(
      result => {
        this.todayResults = result;
      }
    );
  }

  ngOnDestroy() {
    this.selectedMemberSubscription.unsubscribe();
    this.todayResultSubscription.unsubscribe();
  }
}
