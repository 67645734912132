import { ThrottledChangeWatcher } from '@act/common/utilities';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersFacade } from '@act/features/users/data-access';
import { Subscription } from 'rxjs';
import { UserDto } from '@act/shared/data-transfer-objects';

@Component({
  selector: 'act-summary-editor',
  templateUrl: './summary-editor.component.html',
  styleUrls: ['./summary-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() user: UserDto;

  lastIdentifier: string;

  public throttleWatcher: ThrottledChangeWatcher<string>;

  formControl: FormControl;

  throttledChangesSubscription: Subscription;

  constructor(public usersFacade: UsersFacade) {}

  ngOnInit() {
    this.setupEditor();
  }

  ngOnChanges() {
    if (this.lastIdentifier !== this.getUpdateComparison()) {
      this.setupEditor();
    }
  }

  ngOnDestroy() {
    if (this.throttledChangesSubscription) {
      this.throttledChangesSubscription.unsubscribe();
    }
  }

  setupEditor() {
    this.lastIdentifier = this.getUpdateComparison();

    if (this.throttleWatcher) {
      this.throttleWatcher.stopWatching();
    }

    this.formControl = new FormControl(
      this.user &&
        this.user.summary &&
        (this.user.summary.draftText || this.user.summary.text)
    );

    this.throttleWatcher = new ThrottledChangeWatcher(s => s, this.formControl);

    this.throttledChangesSubscription = this.throttleWatcher.throttledChanges$.subscribe(
      text => {
        this.autoSave(text);
      }
    );

    this.getUpdateComparison();
  }

  save() {
    this.usersFacade.updateUserSummary(this.user.id, {
      isAutoUpdate: false,
      text: this.formControl.value
    });
  }

  discardDraft() {
    this.lastIdentifier = null;
    this.autoSave(null);
  }

  autoSave(text: string) {
    this.usersFacade.updateUserSummary(this.user.id, {
      isAutoUpdate: true,
      text: text
    });
  }

  getUpdateComparison(): string {
    return (
      this.user &&
      this.user.summary &&
      `${this.user.summary.lastSavedAt}_${this.user.id}`
    );
  }
}
