import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'act-todo-list-page',
  templateUrl: './todo-list-page.component.html',
  styleUrls: ['./todo-list-page.component.scss']
})
export class TodoListPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
