import { CohortRequests } from './cohort.requests';

const requests = new CohortRequests(null, null);

export const {
  initiator: loadCohorts,
  success: cohortsLoaded,
  error: cohortsLoadError
} = requests.getCohorts.getRequestActions();

/**
 * Creating cohorts
 */
export const {
  initiator: createCohort,
  success: cohortCreated,
  error: cohortCreationError
} = requests.createCohort.getRequestActions();

/**
 * Updating cohorts
 */
export const {
  initiator: updateCohort,
  success: cohortUpdated,
  error: cohortUpdateError
} = requests.patchCohort.getRequestActions();
