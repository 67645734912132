import { ApiRequestDefinition } from '@act/shared/api';
import {
  CreateScheduledEventRequestDto,
  ScheduledEventDto,
  UpdateScheduledEventRequestDto
} from '@act/shared/data-transfer-objects';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class ScheduledEventRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getScheduledEvents = ApiRequestDefinition.createRequestDefinition<
    ScheduledEventDto[],
    null,
    { userId: string },
    null
  >(
    '/users/:userId/scheduled-events',
    'GET',
    '[Scheduled-Event] Get Scheduled Events',
    this.store,
    this.http
  );

  deleteScheduledEvent = ApiRequestDefinition.createRequestDefinition<
    null,
    null,
    { userId: string; scheduledEventId: number },
    null
  >(
    '/users/:userId/scheduled-events/:scheduledEventId',
    'DELETE',
    '[Scheduled-Event] Delete Scheduled Event',
    this.store,
    this.http
  );

  snoozeScheduledEvent = ApiRequestDefinition.createRequestDefinition<
    ScheduledEventDto,
    {},
    { userId: string; scheduledEventId: number },
    null
  >(
    '/users/:userId/scheduled-events/:scheduledEventId/snooze',
    'POST',
    '[Scheduled-Event] Snooze Scheduled Event',
    this.store,
    this.http
  );

  patchScheduledEvent = ApiRequestDefinition.createRequestDefinition<
    ScheduledEventDto,
    UpdateScheduledEventRequestDto,
    { userId: string; scheduledEventId: number },
    null
  >(
    '/users/:userId/scheduled-events/:scheduledEventId',
    'PATCH',
    '[Scheduled-Event] Update Scheduled Event',
    this.store,
    this.http
  );

  postScheduledEvent = ApiRequestDefinition.createRequestDefinition<
    ScheduledEventDto,
    CreateScheduledEventRequestDto,
    { userId: string },
    { creationId: number }
  >(
    '/users/:userId/scheduled-events',
    'POST',
    '[Scheduled-Event] Create Scheduled Event',
    this.store,
    this.http
  );
}
