import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { DisplayEnvironment } from '@act/shared/models';
import { Client } from '@act/server/models/clients';
import { Dictionary } from '@ngrx/entity';
import * as faker from 'faker';
// import Stream = OT.Stream;
// import Session = OT.Session;
import { PlatformFilter } from '../models/platform-filter.interface';
import {
  PlatformSpeedbump,
  PlatformSpeedbumpRef
} from '../models/platform-speedbump';
import { ActingUserDto, UserDto } from '@act/shared/data-transfer-objects';

function notProvided(): never {
  throw new Error(
    'Function not provided, please check the configuration of your application'
  );
}

export type ProvidedUser = Subject<UserDto>;

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  /**
   * Observable used by the core app bootstrap code to indicate to the rest of the
   * app initializers that the platform is ready.
   */
  static _ready$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // subscribe to this one
  static ready$: Observable<boolean> = PlatformService._ready$.asObservable();

  /**
   * Display environment
   * @description observable which updates to provide the current window dimensions
   * as well as the media query break points.
   */
  static displayEnvironment: Observable<DisplayEnvironment> = throwError(
    'Display environment is not defined'
  );

  /**
   * Device ID
   * @description unique id given to each device that's connected to an app. This
   * is used for websockets and distinguishing, for example, from which device a
   * user answered a call
   */
  private static _deviceId: string;
  static get deviceId() {
    return PlatformService._deviceId;
  }
  static set deviceId(value: string) {
    throw new Error(
      'You may not set the device ID directly. Please use the generateDeviceId function'
    );
  }

  /**
   * Auth
   * @description function to get the current auth token.
   */
  static getAuthToken: () => string = notProvided;

  /**
   * Clients
   */
  loadTenants: () => void = notProvided;
  tenants$: Observable<Client[]>;
  tenantsMap$: Observable<Dictionary<Client>>;

  /**
   * Users
   */
  user$: Observable<ActingUserDto> = throwError('User info not provided');
  userAuthenticated$: Observable<boolean> = throwError(
    'User authenticated status not provided'
  );
  usersMap$: Observable<Dictionary<UserDto>> = throwError(
    'usersMap$ not provided'
  );

  /**
   * WhenReady
   * @description takes a callback which is run right away if the platform
   * is ready, otherwise as soon as it becomes ready.
   */
  static whenReady = (callback: () => void) => {
    if (PlatformService._ready$.getValue()) {
      callback();
    } else {
      PlatformService.ready$.subscribe(ready => ready && callback());
    }
  };

  /**
   * Each frontend app should call this function so that each device that's
   * connected to the platform has its own unique device ID.
   */
  static generateDeviceId = () => {
    if (PlatformService.deviceId) {
      throw new Error('Device ID should only be generated once');
    }
    PlatformService._deviceId = faker.random.uuid();
  };

  addSpeedbump<T>(
    speedbump: PlatformSpeedbump<T>,
    speedbumpArray: PlatformSpeedbumpRef<T>[]
  ): PlatformSpeedbumpRef<T> {
    const id = faker.random.uuid();
    function remove() {
      speedbumpArray.splice(
        speedbumpArray.findIndex(
          (findIndexRef: PlatformSpeedbumpRef<T>) => findIndexRef.id === id
        ),
        1
      );
    }
    const ref = new PlatformSpeedbumpRef<T>(id, speedbump, remove);
    speedbumpArray.push(ref);

    return ref;
  }

  async canProceed<T>(
    speedbumps: PlatformSpeedbumpRef<T>[],
    data: T
  ): Promise<boolean> {
    let canProceed = true;
    for (const speedbump of speedbumps) {
      const res = speedbump.fn(data);
      if (res instanceof Observable) {
        canProceed = await new Promise((resolve, reject) => {
          res.pipe(take(1)).subscribe((obsRes: boolean) => resolve(obsRes));
        });
      } else if (res instanceof Promise) {
        canProceed = await res;
      } else {
        canProceed = res;
      }

      if (!canProceed) break;
    }

    return canProceed;
  }
}
