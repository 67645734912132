import { Component, Input } from '@angular/core';
import { AutoSaver } from '../debounce-saver';

@Component({
  selector: 'act-save-status',
  templateUrl: './save-status.component.html',
  styleUrls: ['./save-status.component.scss']
})
export class SaveStatusComponent {
  constructor() {}
  @Input() autoSaver: AutoSaver;
  @Input() savingMessage: string;
  @Input() savedMessage: string;
  @Input() iconLocation: 'right' | 'left' = 'right';
}
