export interface InflightIds {
  [key: string]: boolean;
}

export const addToInflightList = (
  id: string | number,
  inflightIds: InflightIds
): InflightIds => {
  if (id === null || id === undefined) {
    return inflightIds;
  }
  return {
    ...inflightIds,
    [id.toString()]: true
  };
};

export const removeFromInflightList = (
  id: string | number,
  inflightIds: InflightIds
): InflightIds => {
  if (id === null || id === undefined) {
    return inflightIds;
  }
  const key = id.toString();

  return Object.keys(inflightIds).reduce(
    (newIds, id) => {
      if (id !== key) {
        newIds[key] = true;
      }
      return newIds;
    },
    <InflightIds>{}
  );
};
