import { Pipe, PipeTransform } from '@angular/core';
import {convertMsToHoursMinutesSeconds} from '@act/common/utilities';

@Pipe({
  name: 'hoursMinutesSeconds'
})
export class HoursMinutesSecondsPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    const conversion = convertMsToHoursMinutesSeconds(value);
    const days = conversion.day ? `${conversion.day} days ` : '';
    const hours = conversion.hour ? `${conversion.hour} hours ` : '';
    const minutes = conversion.minute ? `${conversion.minute} minutes ` : '';
    const seconds = `${conversion.seconds} seconds`;

    return `${days}${hours}${minutes}${seconds}`;
  }
}
