export enum SOCKET_NAME {
  // public
  APP = 'app',

  // private
  CALLS = 'calls',
  CHAT = 'chat',
  CLIENTS = 'clients',
  ENROLLMENTS = 'enrollments',
  NOTES = 'notes',
  PATIENTS = 'patients',
  SURVEY = 'survey',
  USER = 'user',
  TODO = 'todo'
}
