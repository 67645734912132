import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { PhoneNumberDisplayComponent } from './phone-numbers/phone-number-display/phone-number-display.component';
import { AddressesDisplayComponent } from './addresses/addresses-display/addresses-display.component';
import { CommonPipesPhoneNumberModule } from '@act/common/pipes/phone-number';
import { SharedUiIconModule } from '@act/shared/ui/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { SharedUiFadeExpanderModule } from '@act/shared/ui/fade-expander';
import { SharedUiHtmlModule } from '@act/shared/ui/html';
import { SummaryEditorComponent } from './summary/summary-editor/summary-editor.component';
import { ContactInfoEditorComponent } from './phone-numbers/contact-info-editor/contact-info-editor.component';
import { PhoneNumbersDisplayComponent } from './phone-numbers/phone-numbers-display/phone-numbers-display.component';
import { AddressDisplayComponent } from './addresses/address-display/address-display.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedUiFormsModule } from '@act/shared/ui/forms';
import { FeaturesConsentDataAccessModule } from '@act/features/consent/data-access';
import { ConsentGroupComponent } from './consent/consent-group/consent-group.component';
import { ConsentDialogComponent } from './consent/consent-dialog/consent-dialog.component';
import { ConsentLinkDialogComponent } from './consent/consent-link-dialog/consent-link-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { CommonPipesTextFormatModule } from '@act/common/pipes/text-format';
import { CommonPipesHoursMinutesSecondsModule } from '@act/common/pipes/hours-minutes-seconds';
import { MatIconModule } from '@angular/material/icon';
import { NeedDialogComponent } from './needs/need-dialog/need-dialog.component';
import { ActDialogService, SharedUiDialogModule } from '@act/shared/ui/dialog';
import { ProfileViewComponent } from './profile-view/profile-view.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedUiCardModule } from '@act/shared/ui/card';
import { MemberInfoDisplayComponent } from './member-info-display/member-info-display.component';
import { GroupInfoDisplayComponent } from './group-info-display/group-info-display.component';
import { MatChipsModule } from '@angular/material/chips';
import { TooltipComponent } from './profile-view/tooltip/tooltip.component';
import { TooltipDirective } from './profile-view/tooltip/tooltip.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NeedDisplayComponent } from './needs/need-display/need-display.component';
import { SharedUiSpinnerModule } from '@act/shared/ui/spinner';
import { MatCheckboxModule } from '@angular/material';
import { CommonSnackbarModule, SnackbarService } from '@act/common/snackbar';
import { CommonUtilitiesAutosaveModule } from '@act/common/utilities/autosave';
import { MemberInfoEditComponent } from './member-info-edit/member-info-edit.component';
import { ProfileActionsService } from './profile-actions/profile-actions.service';

@NgModule({
  imports: [
    CommonModule,
    CommonPipesPhoneNumberModule,
    SharedUiIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    SharedUiFadeExpanderModule,
    SharedUiHtmlModule,
    FormsModule,
    ReactiveFormsModule,
    SharedUiFormsModule,
    CommonPipesHoursMinutesSecondsModule,
    SharedUiDialogModule,
    SharedUiCardModule,
    MatChipsModule,
    DragDropModule,
    SharedUiSpinnerModule,
    MatCheckboxModule,

    CommonSnackbarModule,
    FeaturesConsentDataAccessModule,
    CommonPipesTextFormatModule,
    CommonUtilitiesAutosaveModule,
    QuillModule.forRoot({
      modules: {
        mentions: true,
        toolbar: false
      }
    })
  ],
  declarations: [
    PhoneNumberDisplayComponent,
    AddressesDisplayComponent,
    SummaryEditorComponent,
    ContactInfoEditorComponent,
    PhoneNumbersDisplayComponent,
    AddressDisplayComponent,
    ConsentGroupComponent,
    ConsentDialogComponent,
    ConsentLinkDialogComponent,
    NeedDialogComponent,
    ProfileViewComponent,
    MemberInfoDisplayComponent,
    GroupInfoDisplayComponent,
    TooltipComponent,
    TooltipDirective,
    NeedDisplayComponent,
    MemberInfoEditComponent
  ],
  providers: [ActDialogService, SnackbarService, ProfileActionsService],
  exports: [PhoneNumberDisplayComponent, ProfileViewComponent],
  entryComponents: [NeedDialogComponent, TooltipComponent]
})
export class FeaturesProfileUiModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'add-note',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/drag-add-note.svg'
      )
    ),
      iconRegistry.addSvgIcon(
        'gripper-icon',
        sanitizer.bypassSecurityTrustResourceUrl(
          '/assets/icons/Icon_Gripper.svg'
        )
      );
  }
}
