import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthFacade } from '../services/auth.facade';
import { AUTH_HEADER, SKIP_AUTH_HEADER } from '@act/shared/auth/models';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authFacade: AuthFacade) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authFacade.getToken();
    const skipHeader = req.headers.get(SKIP_AUTH_HEADER);

    let clonedRequest;
    if(skipHeader) {
      clonedRequest =  req.clone({
        headers: req.headers.delete(SKIP_AUTH_HEADER)
      });
    } else if(token) {
      clonedRequest = req.clone({
        headers: req.headers.set(AUTH_HEADER, `Bearer ${token}`)
      });
    } else {
      clonedRequest = req.clone();
    }

    return next.handle(clonedRequest);
  }
}
