import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { DialogBaseComponent } from '../components/dialog-base/dialog-base.component';
import { ConfirmationDialogData } from '../models/confirmation-dialog-data.model';
import { BaseDialogData } from '../models/base-dialog-data.model';
import { ActDialogOptions } from '../models/dialog-options.model';

@Injectable()
export class ActDialogService {
  constructor(private matDialog: MatDialog) {}

  confirm(
    message: string,
    textOptions: { yes?: string; no?: string; messageIsHtml?: boolean } = {}
  ): Promise<boolean> {
    return new Promise(resolve => {
      let dialog: ActDialog;

      const componentData: ConfirmationDialogData = {
        text: message,
        textIsHtml: textOptions.messageIsHtml,
        yesText: textOptions.yes,
        noText: textOptions.no,
        confirm: (confirmation: boolean) => {
          if (dialog) {
            dialog.close();
          }
          resolve(confirmation);
        }
      };

      dialog = new ActDialog(this.matDialog, {
        component: ConfirmDialogComponent,
        componentData: componentData,
        disableClose: true,
        class: 'confirm-event',
        doNotUseDefaultClass: true
      });
      dialog.open();
    });
  }

  alert(
    message: string,
    textOptions: { ok?: string } = {},
    onClose: Function = null
  ): Promise<void> {
    return this.confirm(message, { yes: textOptions.ok }).then(b =>
      onClose ? onClose() : null
    );
  }

  createDialog(options: ActDialogOptions, addedClass?: string): ActDialog {
    return this.createBaseDialog(options, addedClass);
  }

  createFullScreenDialog(options: ActDialogOptions): ActDialog {
    return this.createBaseDialog(options, 'act-dialog-full-width');
  }

  private createBaseDialog(
    options: ActDialogOptions,
    addedClass?: string
  ): ActDialog {
    const componentData: BaseDialogData = { template: options.ref };

    return new ActDialog(this.matDialog, {
      ...options,
      class: addedClass,
      component: DialogBaseComponent,
      componentData: componentData,
      disableClose: false
    });
  }
}

interface ActDialogConfig {
  onOpen?: () => any;
  onClose?: () => any;
  class?: string;
  component: ComponentType<any>;
  componentData: any;
  disableClose: boolean;
  doNotUseDefaultClass?: boolean;
  width?: string;
}

export class ActDialog {
  private _isOpen = false;
  private matDialogRef: MatDialogRef<any, any>;

  constructor(private matDialog: MatDialog, private config: ActDialogConfig) {}

  isOpen() {
    return this._isOpen;
  }

  open() {
    if (this.isOpen()) {
      return;
    }

    this.matDialogRef = this.matDialog.open(this.config.component, {
      panelClass: this.config.doNotUseDefaultClass
        ? [this.config.class]
        : ['act-dialog', this.config.class],
      width: this.config.width,
      backdropClass: 'act-dialog-backdrop',
      data: this.config.componentData,
      disableClose: this.config.disableClose
    });

    this.matDialogRef.afterOpened().subscribe(() => {
      this._isOpen = true;
      this.config.onOpen && this.config.onOpen();
    });
    this.matDialogRef.afterClosed().subscribe(() => {
      this._isOpen = false;
      this.config.onClose && this.config.onClose();
    });

    this.matDialogRef;
  }

  close() {
    if (!this.matDialogRef) {
      return;
    }
    this.matDialogRef.close();
    this.matDialogRef = null;
  }
}
