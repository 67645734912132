import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

const regex = /(\\r\\n)|([\r\n])/gim;

@Pipe({
  name: 'lineBreak'
})
export class NewLineToHtmlBreakPipe implements PipeTransform {
  transform(text: string, ...args: any[]): any {
    return text.replace(regex, '<br/>');
  }
}
