import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromCall from './call.reducer';
import { Observable } from 'rxjs';
import {
  CallDto,
  CallCreationRequestDto,
  CallUpdateRequestDto,
  CommentDto
} from '@act/shared/data-transfer-objects';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ClientBootstrap } from '@act/shared/models';
import { getClientConfig } from '@act/shared/environment';
import {
  createCall,
  triggerCallOutcomeLoad,
  updateNoFollowUp
} from './call.actions';
import {
  selectCallOutcomesList,
  selectCallOutcomesMap,
  selectCallsBeingCreated,
  selectInflightUpdateIds,
  selectCallLoading
} from './call.selectors';
import { CallRequests } from './call.requests';

@Injectable()
export class CallFacade {
  callCreationIdTicker = 1;

  constructor(
    private store: Store<fromCall.State>,
    private http: HttpClient,
    private callRequests: CallRequests
  ) {}

  private config: ClientBootstrap = getClientConfig();

  callOutcomes$ = this.store.select(selectCallOutcomesList);
  callOutcomesMap$ = this.store.select(selectCallOutcomesMap);
  inflightUpdateIds$ = this.store.select(selectInflightUpdateIds);
  inflightCreationIds$ = this.store.select(selectCallsBeingCreated);
  callLoading$ = this.store.select(selectCallLoading);

  startCall(request: CallCreationRequestDto) {
    this.store.dispatch(
      createCall({
        params: null,
        requestBody: request,
        actionParams: { creationId: this.callCreationIdTicker++ }
      })
    );
  }

  getCalls(callIds: number[]): Observable<CallDto[]> {
    const url = `${
      this.config.reema.serviceUris.websocketService
    }/calls?ids=${callIds.join(',')}`;
    return this.http
      .get<CallDto[]>(url, { observe: 'response' })
      .pipe(map(response => response.body));
  }

  updateCall(callId: number, request: CallUpdateRequestDto): Promise<CallDto> {
    return this.callRequests.patchCall.makeRequest({
      params: { callId },
      body: { ...request }
    });
  }

  autoSaveCallNote(callId: number, note: string): Promise<CallDto> {
    return this.callRequests.patchCall.makeRequest({
      params: { callId },
      body: { note }
    });
  }

  updateCallNoFollowUpNeeded(callId: number, noFollowUpNeeded: boolean): void {
    this.store.dispatch(
      updateNoFollowUp({
        params: { callId },
        requestBody: { noFollowUpNeeded }
      })
    );
  }

  createComment(callId: number, text: string): Promise<CommentDto> {
    return this.callRequests.createComment.makeRequest({
      params: { callId },
      body: { text }
    });
  }

  loadCallOutcomes() {
    this.store.dispatch(triggerCallOutcomeLoad());
  }
}
