import {APP_INITIALIZER, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FeaturesUsersDataAccessModule} from '@act/features/users/data-access';
import {BootstrapService} from './services/bootstrap.service';

const initFactory = (bootstrapService: BootstrapService) => () => bootstrapService.bootstrap();

@NgModule({
  imports: [
      CommonModule,
      
      // feature sub modules
      FeaturesUsersDataAccessModule
  ],
    providers: [
        BootstrapService,
        {
            provide: APP_INITIALIZER,
            useFactory: initFactory,
            deps: [
                BootstrapService
            ],
            multi: true
        }
    ]
})
export class FeaturesUsersFeatureV1Module {}
