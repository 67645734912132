import { ApiRequestDefinition } from '@act/shared/api';
import { FeedDto } from '@act/shared/data-transfer-objects';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class FeedRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getFeed = ApiRequestDefinition.createRequestDefinition<
    FeedDto,
    null,
    { feedId: number },
    null
  >('/feeds/:feedId', 'GET', '[Feed] Get Feed', this.store, this.http);

  updateLastSeen = ApiRequestDefinition.createRequestDefinition<
    null,
    { lastSeenTime: number },
    { feedId: number },
    null
  >(
    '/feeds/:feedId/last-seen',
    'POST',
    '[Feed] Update Last Seen',
    this.store,
    this.http
  );
}
