import {
  visitAdapter,
  visitFeatureKey,
  State,
  UNSAVED_VISIT_ID,
  visitOutcomeAdapter
} from './visit.reducer';
import * as fromChat from '../chat.selectors';
import { createSelector } from '@ngrx/store';
import { VisitDto } from '@act/shared/data-transfer-objects';
import { InflightIds } from '@act/shared/api';
import { selectEntities } from '../feed-item/feed-item.reducer';

const visitSelectors = visitAdapter.getSelectors();
const visitOutcomeSelectors = visitOutcomeAdapter.getSelectors();

const selectVisitState = createSelector(
  fromChat.selectChatState,
  (state): State => state[visitFeatureKey]
);

export const selectCallsBeingCreated = createSelector(
  selectVisitState,
  (state: State): InflightIds => {
    return state.inflightCreationIds;
  }
);

export const selectInflightUpdateIds = createSelector(
  selectVisitState,
  (state: State): InflightIds => {
    return state.inflightUpdateIds;
  }
);

export const selectVisitEntities = createSelector(
  selectVisitState,
  visitSelectors.selectEntities
);

export const selectVisitLoading = createSelector(
  selectVisitState,
  state => state.loading
);

export const selectEditedVisitId = createSelector(
  selectVisitState,
  state => state.editedVisitId
);

export const selectEditedSubjectUserId = createSelector(
  selectVisitState,
  state => state.editedSubjectUserId
);

const selectVisitOutcomeState = createSelector(
  selectVisitState,
  state => state.outcomes
);

export const selectVisitOutcomesList = createSelector(
  selectVisitOutcomeState,
  visitOutcomeSelectors.selectAll
);

export const selectVisitOutcomesMap = createSelector(
  selectVisitOutcomeState,
  visitOutcomeSelectors.selectEntities
);

export const selectVisitOutcomesLoading = createSelector(
  selectVisitOutcomeState,
  state => state.loading
);

export const selectVisitsBeingCreated = createSelector(
  selectVisitState,
  (state: State): boolean => {
    return !!state.inflightCreationIds.length;
  }
);

export const selectEditedVisit = createSelector(
  selectVisitEntities,
  selectEditedVisitId,
  selectEditedSubjectUserId,
  (entities, visitId, subjectUserId): VisitDto => {
    if (visitId === UNSAVED_VISIT_ID) {
      return {
        id: UNSAVED_VISIT_ID,
        addressId: null,
        location: '',
        description: '',
        startTime: new Date().getTime(),
        endTime: new Date().getTime() + 1000 * 60 * 15, // default endtime to 15 mins from now
        createdBy: null,
        subjectUserId: subjectUserId,
        note: null,
        outcomeId: null,
        outcomeDetails: null,
        comments: []
      };
    }
    return visitId ? entities[visitId] : null;
  }
);
