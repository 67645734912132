import { ComponentRef, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AnchoredDialogComponent } from '../components/anchored-dialog/anchored-dialog.component';

export const maximumVisibleDialogSubject$ = new Subject<number>();
export const maximumVisibleDialogObservable$ = maximumVisibleDialogSubject$.asObservable();

export const shouldOpenDialogSubject$ = new BehaviorSubject<boolean>(true);
export const shouldOpenDialogObservable$ = shouldOpenDialogSubject$.asObservable();

export interface ActDialogOptions {
  ref: TemplateRef<any>;
  width?: string;
  onOpen: () => any;
  onClose: () => any;
}

export interface AnchoredDialogOptions {
  title: string;
  icon: string;
  ref: TemplateRef<any>;
  onOpen?: () => any;
  onClose?: () => any;
  subTitle?: string;
  duration?: number;
  date?: number;
  iconStyleClass?: string;
  eventType: EventType;
  actionType: ActionType;
  eventId: string | number;
  isLoading$?: Observable<boolean>;
}

export type EventType =
  | 'call'
  | 'reminder'
  | 'note'
  | 'visit'
  | 'text'
  | 'need';
export type ActionType = 'create' | 'edit' | 'view';

export interface AnchoredDialogData {
  id: string;
  visible: boolean;
  options: AnchoredDialogOptions;
  dialogRef?: ComponentRef<AnchoredDialogComponent>;
}
