import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatSliderModule,
  MatIconModule,
  MatTooltipModule
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSliderModule,
    MatTooltipModule
  ],
  declarations: [AudioPlayerComponent],
  exports: [AudioPlayerComponent]
})
export class SharedUiAudioPlayerModule {}
