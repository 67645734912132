import {WorkSheet} from 'xlsx';

export interface HeaderColumnValue {
    column: string;
    value: string;
}

export function parseHeaderColumnNames(worksheet: WorkSheet): string[] {
    return Object.keys(worksheet).filter((key: string) => parseInt(key.replace(/\D/g,''), 10) === 1);
}

export function parseHeaderColumnValues(worksheet: WorkSheet): HeaderColumnValue[] {
    const columns: string[] = parseHeaderColumnNames(worksheet);
    
    return columns.map((column: string): HeaderColumnValue => ({
        column,
        value: worksheet[column].v
    }));
}