import {Action} from '@ngrx/store';
import {type} from '@act/common/utilities';

export const AuthActionTypes = {
    AUTH_SUCCESSFUL: type('[AUTH] Auth Successful')
};

export class AuthSuccessful implements Action {
    readonly type = AuthActionTypes.AUTH_SUCCESSFUL;
    
    constructor(public payload: {
        defaultRedirectRoute: string;
    }) {
    }
}