import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCall from './consent.reducer';
import { selectUserTermGroups } from './consent.selectors';
import { Observable } from 'rxjs';
import {
  TermGroupDto,
  ConsentLinkDto
} from '@act/shared/data-transfer-objects';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ClientBootstrap } from '@act/shared/models';
import { getClientConfig } from '@act/shared/environment';
import { submitConsentResponse } from './consent.actions';

@Injectable()
export class ConsentFacade {
  constructor(private store: Store<fromCall.State>, private http: HttpClient) {}

  private config: ClientBootstrap = getClientConfig();

  selectConsentForUser$(userId: string): Observable<TermGroupDto[]> {
    return this.store.pipe(select(selectUserTermGroups, userId));
  }

  submitConsentForUser(userId: string, termId: number, consented: boolean) {
    this.store.dispatch(
      submitConsentResponse({
        params: { userId },
        requestBody: [{ id: termId, consented }]
      })
    );
  }

  createConsentLink(
    userId: string,
    termGroup: TermGroupDto
  ): Observable<ConsentLinkDto> {
    const url = `${this.config.reema.serviceUris.websocketService}/consent/links`;
    return this.http
      .post<any>(
        url,
        {
          userId,
          termGroupKey: termGroup.key
        },
        { observe: 'response' }
      )
      .pipe(map(response => response.body));
  }
}
