import { TodoDto } from '@act/shared/data-transfer-objects';
import { ApiRequestDefinition } from '@act/shared/api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class TodoRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getTodos = ApiRequestDefinition.createRequestDefinition<
    TodoDto[],
    null,
    { guideId: string },
    null
  >('/users/:guideId/todos', 'GET', '[Todo] Get Todos', this.store, this.http);

  snoozeTodos = ApiRequestDefinition.createRequestDefinition<
    TodoDto,
    null,
    { guideId: string; todoId: number },
    null
  >(
    '/users/:guideId/todos/:todoId/snooze',
    'POST',
    '[Todo] Snooze Todos',
    this.store,
    this.http
  );
}

export const requests = {};
