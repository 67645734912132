import {
  MemberStageDto,
  NeedCategoryDto,
  TermGroupDto,
  UserDto,
  UserNeedDto
} from '@act/shared/data-transfer-objects';
import { ActDialog, ActDialogService } from '@act/shared/ui/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { actions } from 'libs/shared/ui/card/src/lib/card/card.dto';
import { ProfileActionsService } from '../profile-actions/profile-actions.service';
@Component({
  selector: 'act-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileViewComponent implements OnChanges {
  @Input() user: UserDto;
  @Input() userNeeds: UserNeedDto[];
  @Input() needCategories: NeedCategoryDto[];
  @Input() selectedMemberId: string;
  @Input() consentGroups: TermGroupDto[];
  @Input() loadingUserNeeds: boolean;
  @Input() userDetailsLoading: boolean;
  @Input() memberStages: MemberStageDto[];

  @ViewChild('editContactInfoRef', { static: false })
  editContactsDialogRef: TemplateRef<any>;
  @ViewChild('editMemberInfoRef', { static: false })
  editMemberInfoDialogRef: TemplateRef<any>;

  actions = actions;
  selectedIndex = 0;

  private editContactsDialog: ActDialog;
  private editMemberInfoDialog: ActDialog;

  openNeeds: UserNeedDto[];
  fullFilledNeeds: UserNeedDto[];

  constructor(
    private dialogService: ActDialogService,
    private profileActionsService: ProfileActionsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.userNeeds) {
      this.openNeeds = changes.userNeeds.currentValue.filter(
        (need: UserNeedDto) => need.status !== 'Need Fulfilled'
      );
      this.fullFilledNeeds = changes.userNeeds.currentValue.filter(
        (need: UserNeedDto) => need.status === 'Need Fulfilled'
      );
    }

    if (changes && changes.selectedMemberId) {
      this.selectedIndex = 0;
    }
  }

  editContactInfo(action: boolean) {
    if (action) {
      if (this.editContactsDialogRef) {
        this.editContactsDialog = this.dialogService.createDialog({
          ref: this.editContactsDialogRef,
          onOpen: null,
          onClose: () => {},
          width: '710px'
        });
      }
      this.editContactsDialog.open();
    }
  }

  editMemberInfo(action: boolean) {
    if (action) {
      if (this.editMemberInfoDialogRef) {
        this.editMemberInfoDialog = this.dialogService.createDialog({
          width: '710px',
          ref: this.editMemberInfoDialogRef,
          onOpen: null,
          onClose: () => {}
        });
      }
      this.editMemberInfoDialog.open();
    }
  }

  closeEditMemberInfo() {
    this.editMemberInfoDialog.close();
  }

  addNeed(action: boolean) {
    if (action) {
      this.addEditNeed(null);
    }
  }

  addEditNeed(need: UserNeedDto) {
    if (need) {
      this.profileActionsService.editNeed(
        this.user.id,
        this.needCategories,
        need
      );
    } else {
      this.profileActionsService.createNeed(this.user.id, this.needCategories);
    }
  }
}
