import { userQueries } from '@act/features/user/data-access';
import {
  selectMemberAction,
  usersQueries
} from '@act/features/users/data-access';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import {
  loadTextTemplates,
  loadTextTemplateVariables
} from './text-template.actions';
import { TextTemplateRequests } from './text-template.requests';

@Injectable()
export class TextTemplateEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private textTemplateRequests: TextTemplateRequests
  ) {}

  triggerTextTemplatesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectMemberAction),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedMemberId)),
        this.store.pipe(select(userQueries.selectUser))
      ),
      filter(
        ([action, memberId, loggedInUser]) =>
          memberId && loggedInUser.featureFlags.MESSAGE_TEMPLATES
      ),
      map(([action, memberId]) => {
        return loadTextTemplates({
          params: { userId: memberId },
          requestBody: null
        });
      })
    )
  );

  triggerTextTemplateVariablesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectMemberAction),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedMemberId)),
        this.store.pipe(select(userQueries.selectUser))
      ),
      filter(
        ([action, memberId, loggedInUser]) =>
          memberId && loggedInUser.featureFlags.MESSAGE_TEMPLATES
      ),
      map(([action, memberId]) => {
        return loadTextTemplateVariables({
          params: { userId: memberId },
          requestBody: null
        });
      })
    )
  );

  getTextTemplates$ = this.textTemplateRequests.getTextTemplates.createEffect(
    this.actions$
  );

  getTextTemplateVariables$ = this.textTemplateRequests.getTextTemplateVariables.createEffect(
    this.actions$
  );
}
