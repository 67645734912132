import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  visitCreated,
  visitCreationError,
  visitUpdated,
  visitUpdateError,
  createVisit,
  editVisit,
  stopEditingVisit,
  startCreatingVisit,
  loadVisitOutcomes,
  visitOutcomesLoaded,
  visitOutcomesLoadError,
  visitDeleted,
  updateVisit,
  deleteVisit,
  visitDeleteError,
  createComment,
  commentCreated,
  commentCreationError
} from './visit.actions';
import { VisitDto, VisitOutcomeDto } from '@act/shared/data-transfer-objects';
import {
  addToInflightList,
  InflightIds,
  removeFromInflightList
} from '@act/shared/api';

export const visitFeatureKey = 'visits';

export const UNSAVED_VISIT_ID = -1;

export interface State extends EntityState<VisitDto> {
  editedVisitId: number;
  editedSubjectUserId: string;
  outcomes: OutcomeState;
  inflightCreationIds: InflightIds;
  inflightUpdateIds: InflightIds;
  loading: boolean;
}

export const adapter: EntityAdapter<VisitDto> = createEntityAdapter<VisitDto>({
  sortComparer: (a: VisitDto, b: VisitDto) =>
    a.startTime > b.startTime ? 1 : -1
});

interface OutcomeState extends EntityState<VisitOutcomeDto> {
  loading: boolean;
}

export const visitOutcomeAdapter: EntityAdapter<
  VisitOutcomeDto
> = createEntityAdapter<VisitOutcomeDto>();

export const initialState: State = adapter.getInitialState({
  editedVisitId: null,
  viewFeedItemVisits: null,
  editedSubjectUserId: null,
  outcomes: visitOutcomeAdapter.getInitialState({ loading: false }),
  inflightCreationIds: {},
  inflightUpdateIds: {},
  loading: false
});

const visitReducer = createReducer(
  initialState,
  on(createVisit, (state, action) => ({
    ...state,
    loading: true,
    inflightCreationIds: addToInflightList(
      action.actionParams.creationId,
      state.inflightCreationIds
    )
  })),
  on(visitCreated, (state, action) => {
    return {
      ...adapter.upsertOne(action.responseBody, state),
      loading: false,
      inflightCreationIds: removeFromInflightList(
        action.actionParams.creationId,
        state.inflightCreationIds
      )
    };
  }),
  on(visitCreationError, (state, action) => ({
    ...state,
    loading: false,
    inflightCreationIds: removeFromInflightList(
      action.actionParams.creationId,
      state.inflightCreationIds
    )
  })),
  on(updateVisit, (state, action) => ({
    ...state,
    loading: !!action.requestBody.visitId,
    inflightUpdateIds: addToInflightList(
      action.params.visitId,
      state.inflightUpdateIds
    )
  })),
  on(createComment, (state, action) => ({
    ...state,
    loading: true,
    inflightUpdateIds: addToInflightList(
      action.params.visitId,
      state.inflightUpdateIds
    )
  })),
  on(visitUpdated, (state, action) => ({
    ...visitAdapter.upsertOne(action.responseBody, state),
    loading: false,
    inflightUpdateIds: removeFromInflightList(
      action.params.visitId,
      state.inflightUpdateIds
    )
  })),
  on(commentCreated, (state, action) => ({
    ...state,
    loading: false,
    inflightUpdateIds: removeFromInflightList(
      action.params.visitId,
      state.inflightUpdateIds
    )
  })),
  on(visitUpdateError, commentCreationError, (state, action) => ({
    ...state,
    loading: false,
    inflightUpdateIds: removeFromInflightList(
      action.params.visitId,
      state.inflightUpdateIds
    )
  })),
  on(deleteVisit, (state, action) => ({ ...state, loading: true })),
  on(visitDeleted, (state, action) => ({
    ...adapter.removeOne(action.params.visitId, state),
    loading: false
  })),
  on(visitDeleteError, (state, action) => ({ ...state, loading: false })),
  on(editVisit, (state, action) => {
    return {
      ...state,
      editedVisitId: action.visit.id,
      editedSubjectUserId: action.visit.subjectUserId
    };
  }),
  on(stopEditingVisit, (state, action) => {
    if (state.editedVisitId === action.visitId) {
      return { ...state, editedVisitId: null, editedSubjectUserId: null };
    }
    return state;
  }),
  on(startCreatingVisit, (state, action) => {
    return {
      ...state,
      editedVisitId: UNSAVED_VISIT_ID,
      editedSubjectUserId: action.subjectUserId
    };
  }),
  on(loadVisitOutcomes, (state, action) => ({
    ...state,
    outcomes: { ...state.outcomes, loading: true }
  })),
  on(visitOutcomesLoaded, (state, action) => ({
    ...state,
    outcomes: {
      ...visitOutcomeAdapter.upsertMany(action.responseBody, state.outcomes),
      loading: false
    }
  })),
  on(visitOutcomesLoadError, (state, action) => ({
    ...state,
    outcomes: {
      ...visitOutcomeAdapter.removeAll(state.outcomes),
      loading: true
    }
  }))
);

export const visitAdapter = adapter;

export function reducer(state: State | undefined, action: Action) {
  return visitReducer(state, action);
}
