import { ApiRequestDefinition } from '@act/shared/api';
import {
  MessageTemplateCategoryDto,
  MessageTemplateVariablesDto
} from '@act/shared/data-transfer-objects';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class TextTemplateRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getTextTemplates = ApiRequestDefinition.createRequestDefinition<
    MessageTemplateCategoryDto[],
    null,
    { userId: string },
    null
  >(
    '/chat/messages/templates',
    'GET',
    '[Text-Template] Get Text Templates',
    this.store,
    this.http
  );

  getTextTemplateVariables = ApiRequestDefinition.createRequestDefinition<
    MessageTemplateVariablesDto,
    null,
    { userId: string },
    null
  >(
    '/chat/messages/template-variables',
    'GET',
    '[Text-Template] Get Text Template Variables',
    this.store,
    this.http
  );
}

export const requests = {};
