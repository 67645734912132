import { ChatFacade } from '../+state/chat.facade';
import { MessageType } from '@act/shared/models';
import {
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AnchoredDialogComponent } from 'libs/shared/ui/dialog/src/lib/components/anchored-dialog/anchored-dialog.component';
import {
  MessageTemplateDto,
  MessageTemplateVariablesDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { combineLatest, Subscription } from 'rxjs';
import { TextTemplateFacade } from 'libs/shared/text-template/src/lib/text-template/+state/text-template.facade';
import { AnchoredDialogService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog.service';
import { UserFacade } from '@act/features/user/data-access';
import { FileFacade } from '@act/features/files/data-access';
import {
  SUPPORTED_FILE_SIZE,
  SUPPORTED_FILE_TYPES
} from './message-dialog.data';
import { map } from 'rxjs/operators';

@Component({
  selector: 'act-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit, OnDestroy {
  @Input() scheduledEventId: number;
  @Input() user: UserDto;

  textTemplateVariablesSubscription: Subscription;
  textTemplateVariables: MessageTemplateVariablesDto;

  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;
  private anchoredDialogRef: ComponentRef<AnchoredDialogComponent>;

  textMessage = new FormControl();
  constructor(
    private chatFacade: ChatFacade,
    private textTemplateFacade: TextTemplateFacade,
    private anchoredDialogService: AnchoredDialogService,
    private userFacade: UserFacade,
    private fileFacade: FileFacade
  ) {}

  files: File[] = [];

  actingUser$ = this.userFacade.user$;
  maxFileSize = SUPPORTED_FILE_SIZE;
  allowedFileTypes = SUPPORTED_FILE_TYPES;

  open(): ComponentRef<AnchoredDialogComponent> {
    const name = this.user
      ? this.user.firstName + ' ' + this.user.lastName
      : null;
    this.anchoredDialogRef = this.anchoredDialogService.create({
      icon: 'textsms',
      title: 'New Text Message' + (name ? ' for ' + name : ''),
      ref: this.dialogRef,
      actionType: 'create',
      eventType: 'text',
      eventId: this.user.id,
      isLoading$: combineLatest([
        this.chatFacade.messageLoading$,
        this.fileFacade.filesLoading$
      ]).pipe(
        map(([messageLoading, filesLoading]) => messageLoading || filesLoading)
      )
    });

    return this.anchoredDialogRef;
  }

  ngOnInit() {
    this.textTemplateVariablesSubscription = this.textTemplateFacade.textTemplateVariables$.subscribe(
      textTemplateVariables => {
        this.textTemplateVariables = textTemplateVariables;
      }
    );
  }

  cancel() {
    this.anchoredDialogRef.instance.closeModal();
  }

  async sendMessage() {
    let fileIds = [];
    if (this.files) {
      const files = this.files;
      this.files = [];
      const uploadedFiles = await this.fileFacade.uploadFiles(files);
      fileIds = uploadedFiles.map(f => f.id);
    }

    const message = this.textMessage.value;

    if (message) {
      this.textMessage.setValue('');
      this.chatFacade.sendMessage(
        MessageType.TEXT,
        this.user.id,
        message,
        '',
        fileIds,
        this.scheduledEventId
      );
    }
    this.anchoredDialogRef.instance.closeModal();
  }

  selectTemplateEventHandler(value: {
    template: MessageTemplateDto;
    compiledTemplate: string;
  }) {
    this.textMessage.setValue(value.compiledTemplate);
  }

  ngOnDestroy() {
    this.textTemplateVariablesSubscription.unsubscribe();
  }
}
