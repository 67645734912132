import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, tap, timeout } from 'rxjs/operators';

export interface AutoSaver {
  saving$: Observable<boolean>;
  saved$: Observable<boolean>;
}

export class DebounceAutoSaver<T> implements AutoSaver {
  constructor(
    private obs: Observable<T>,
    private handler: (value: T) => Promise<any> | Observable<any>,
    private shouldSave: (value: T) => boolean = v => true
  ) {
    // Do not save the very first change. This gets around issues with the initial value being saved. This could cause issues because it wont save an initial character if that's all the user wants to autosave (but this is a low risk for a convenient  implementation)
    let changedBefore = false;
    const subscription = this.obs.subscribe(t => {
      if (changedBefore) {
        subscription.unsubscribe();
        this.savable = true;
      }
      changedBefore = true;
    });
  }

  saving$ = new Subject<boolean>();
  saved$ = new Subject<boolean>();
  private savingPromises: Promise<any>[] = [];
  private changing = false;

  savable = false;

  typingSubscription = this.obs
    .pipe(
      filter(v => this.shouldSave(v) && this.savable),
      tap(() => {
        this.changing = true;
        this.updateSaving(this.shouldShowSaving());
        this.updateSaved(false);
      }),
      debounceTime(1000)
    )
    .subscribe(async value => {
      this.changing = false;
      const result = this.handler(value);

      // If no result is returned than set saving status and return
      if (!result) {
        this.updateSaving(this.shouldShowSaving());
        return;
      }

      // Add new Saving promise to list of savingPromises
      if (result.constructor === Promise) {
        result;
      } else {
        <Observable<any>>result;
      }
      const promise = <Promise<any>>(
        (result.constructor === Promise
          ? result
          : (<Observable<any>>result).toPromise())
      );
      this.savingPromises.push(promise);

      this.updateSaving(this.shouldShowSaving());

      await promise;

      // Remove new Saving promise from list of savingPromises
      this.savingPromises = this.savingPromises.filter(p => p !== promise);

      const saving = this.shouldShowSaving();
      this.updateSaving(saving);

      if (!saving) {
        this.updateSaved(true);

        setTimeout(() => {
          this.updateSaved(false);
        }, 1000);
      }
    });

  private shouldShowSaving(): boolean {
    return this.changing || !!this.savingPromises.length;
  }

  destroy() {
    this.typingSubscription.unsubscribe();
  }

  private lastSavingValue: boolean;
  private updateSaving(value: boolean) {
    if (this.lastSavingValue !== value) {
      this.saving$.next(value);
    }
  }

  private lastSavedValue: boolean;
  private updateSaved(value: boolean) {
    if (this.lastSavedValue !== value) {
      this.saved$.next(value);
    }
  }
}
