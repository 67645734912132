import {Component, OnInit} from '@angular/core';
import {Auth0Service} from '../../services/auth0.service';

@Component({
  selector: 'act-auth0-callback',
  templateUrl: './auth0-callback.component.html',
  styleUrls: ['./auth0-callback.component.scss']
})
export class Auth0CallbackComponent implements OnInit {
  
  constructor(private auth0Service: Auth0Service) {
  }
  
  async ngOnInit() {
    await this.auth0Service.handleRedirectCallback().catch((err) => {
      console.log('something failed', err);
      // todo | inform the user something went wrong
      this.auth0Service.logout();
    });
  }
}
