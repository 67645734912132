import { getValueOfObservable } from '@act/common/utilities';
import { TodoFacade } from '@act/features/todos/data-access';
import { UserFacade } from '@act/features/user/data-access';
import { UsersFacade } from '@act/features/users/data-access';
import { TodoDto } from '@act/shared/data-transfer-objects';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'act-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.scss'],
  animations: [
    trigger('newTodo', [
      state('true', style({ height: '0px', visibility: 'hidden' })),
      state('false, void', style({ height: '*', visibility: 'visible' })),
      transition('* <=> *', animate('2000ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class TodoListComponent implements OnInit, OnDestroy {
  constructor(
    private todoFacade: TodoFacade,
    private usersFacade: UsersFacade,
    private userFacade: UserFacade
  ) {}

  todoItems$: Observable<TodoDto[]> = this.todoFacade.todos$;
  todoItemsLoading$: Observable<boolean> = this.todoFacade.todosLoading$;

  userMap$ = this.usersFacade.userMap$;
  actingUser$ = this.userFacade.user$;

  subscriptions: Subscription[] = [];

  incompleteItems: TodoDto[] = [];
  completeItems: TodoDto[] = [];

  private existingTodosBefore: { [key: string]: boolean } = {};
  newTodos: { [key: string]: boolean } = {};

  ngOnInit() {
    let initialLoad = true;
    this.subscriptions.push(
      this.todoItems$.subscribe(items => {
        this.incompleteItems = [];
        this.completeItems = [];
        this.newTodos = {};

        // If loading then stop
        if (getValueOfObservable(this.todoItemsLoading$)) {
          return;
        }

        // Split todo items into complete and incomplete groups
        items.forEach(item => {
          if (item.completedAt) {
            this.completeItems.push(item);
          } else {
            this.incompleteItems.push(item);
          }
        });

        if (!initialLoad) {
          // Mark new todo items as new
          items.forEach(item => {
            if (!this.existingTodosBefore[item.id]) {
              this.newTodos[item.id] = true;
            }
          });
        }

        // Update Existing Todos
        this.existingTodosBefore = {};
        items.forEach(item => {
          this.existingTodosBefore[item.id] = true;
        });

        initialLoad = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  trackById(index: number, object: { id: number }) {
    return object.id;
  }

  isNewTest = false;
  updateNewTest() {
    this.isNewTest = !this.isNewTest;
  }
}
