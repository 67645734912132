import { Action } from '@ngrx/store';
import { type } from '@act/common/utilities';
import { UserDto } from '@act/shared/data-transfer-objects';

export const UserUpdatedType = type('[users] user updated');

export interface UserUpdatedPayload {
  user: UserDto;
}

export class UserUpdated implements Action {
  readonly type = UserUpdatedType;

  constructor(public payload: UserUpdatedPayload) {}
}
