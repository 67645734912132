import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { pickFirstCharWithDot } from '@act/common/utilities';
import { UsersFacade } from '@act/features/users/data-access';
import { UserDto } from '@act/shared/data-transfer-objects';
import { UserFacade } from '@act/features/user/data-access';

@Component({
  selector: 'act-list-teammate',
  templateUrl: './list-teammate.component.html',
  styleUrls: ['./list-teammate.component.scss']
})
export class ListTeammateComponent implements OnInit, OnDestroy {
  selectedMember: UserDto;
  idOfSelectedGuide: string;
  selectedMemberSubscription: Subscription;

  constructor(public usersFacade: UsersFacade, public userFacade: UserFacade) {}

  ngOnInit() {
    this.selectedMemberSubscription = this.usersFacade.selectedTeamMemberId$.subscribe(
      id => {
        this.idOfSelectedGuide = id;
      }
    );
  }

  selectGuide(member: UserDto) {
    this.usersFacade.selectTeamMember(member.id);
    this.selectedMember = member;
  }

  trackById(index: number, object: { id: number }) {
    return object.id;
  }

  getFirstChar(name: string) {
    let shortName = '';

    if (name) {
      shortName = pickFirstCharWithDot(name, false);
    }
    return shortName;
  }

  viewAll() {
    this.usersFacade.selectTeamMember('all');
  }

  ngOnDestroy(): void {
    this.selectedMemberSubscription.unsubscribe();
  }
}
