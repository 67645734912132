import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import * as fromFeedItem from './feed-item/feed-item.reducer';
import * as fromFeed from './feed/feed.reducer';
import * as fromMessage from './message/message.reducer';
import * as fromCall from './call/call.reducer';
import * as fromNote from './note/note.reducer';
import * as fromVisit from './visit/visit.reducer';
import * as fromScheduledEvent from './scheduled-event/scheduled-event.reducer';
import * as fromUserNeed from './need/need.reducer';

export const chatFeatureKey = 'chat';

export const CHAT_FEATURE_REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<ChatFeatureState>
>('Chat');

export interface ChatFeatureState {
  [fromFeedItem.feedFeatureKey]: fromFeedItem.State;
  [fromFeed.feedFeatureKey]: fromFeed.State;
  [fromMessage.messageFeatureKey]: fromMessage.State;
  [fromCall.callFeatureKey]: fromCall.State;
  [fromNote.noteFeatureKey]: fromNote.State;
  [fromVisit.visitFeatureKey]: fromVisit.State;
  [fromScheduledEvent.scheduledEventFeatureKey]: fromScheduledEvent.State;
  [fromUserNeed.userNeedFeatureKey]: fromUserNeed.State;
}

export function getReducers(): ActionReducerMap<ChatFeatureState> {
  return {
    [fromFeedItem.feedFeatureKey]: fromFeedItem.reducer,
    [fromFeed.feedFeatureKey]: fromFeed.reducer,
    [fromMessage.messageFeatureKey]: fromMessage.reducer,
    [fromCall.callFeatureKey]: fromCall.reducer,
    [fromNote.noteFeatureKey]: fromNote.reducer,
    [fromVisit.visitFeatureKey]: fromVisit.reducer,
    [fromScheduledEvent.scheduledEventFeatureKey]: fromScheduledEvent.reducer,
    [fromUserNeed.userNeedFeatureKey]: fromUserNeed.reducer
  };
}
