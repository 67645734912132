import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { pickFirstCharWithDot } from '@act/common/utilities';
import { MatDialogRef } from '@angular/material/dialog';
import { NoteFacade } from '../+state/note/note.facade';
import { FeedFacade } from '../+state/feed/feed.facade';
import { MemberStageDto, UserDto } from '@act/shared/data-transfer-objects';
import { feedItemTypes } from './feed-item-types.data';
import { FormControl, Validators } from '@angular/forms';
import { UsersFacade } from '@act/features/users/data-access';
import { Subject } from 'rxjs';
import { ActDialogService } from '@act/shared/ui/dialog';

const upcomingEventsSubject$ = new Subject<boolean>();
export const upcomingEventsObservable$ = upcomingEventsSubject$.asObservable();

@Component({
  selector: 'act-feed-header',
  templateUrl: './feed-header.component.html',
  styleUrls: ['./feed-header.component.scss']
})
export class FeedHeaderComponent {
  @Input() member: UserDto;
  @Output() feedItemTypeEvent = new EventEmitter<string>();
  @Input() canToggleProfile: boolean;
  @Input() profileOpen: boolean;
  @Output() toggleProfile: EventEmitter<void> = new EventEmitter();
  @Input() canCloseFeed: boolean;
  @Output() closeFeed: EventEmitter<void> = new EventEmitter();
  @Input() user: UserDto;
  @Input() memberStages: MemberStageDto[];

  feedItemType: FormControl = new FormControl('');
  feedItemOptions = feedItemTypes;
  dialogRef: MatDialogRef<any>;
  cadenceControl = new FormControl('', [Validators.required]);
  dialogRefMat: MatDialogRef<any>;
  cadenceOptions = ['Weekly', 'Bi-Weekly', 'Monthly', 'Unknown'].map(o => {
    return { label: o, value: o };
  });

  constructor(
    public feedFacade: FeedFacade,
    public noteFacade: NoteFacade,
    public usersFacade: UsersFacade,
    private dialogService: ActDialogService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.user && this.user) {
      this.cadenceControl.setValue(this.user.cadence || 'Unknown');
    }
  }

  getFirstChar(name: string) {
    return pickFirstCharWithDot(name, false);
  }

  async changeCadenceOpt(selectedCadence: string) {
    const savedCadence = this.member.cadence || 'Unknown';
    const confirm = await this.dialogService.confirm(
      `Are you sure you want to update the cadence for ${this.member.name} from ${savedCadence} to ${selectedCadence}?`
    );
    if (confirm) {
      this.user.cadence = selectedCadence;
      this.usersFacade.updateUser(this.member.id, {
        cadence: selectedCadence
      });
    } else {
      this.cadenceControl.setValue(savedCadence);
    }
  }

  async setInProgram() {
    const newStatus = this.memberStages.find(i => i.key === 'in-program');
    if (newStatus) {
      const confirm = await this.dialogService.confirm(
        `<p>Are you sure ${this.member.name} meets all of our requirements for them to be considered "Engaged"?</p>
        <ul>
          <li>Member has a positive disposition about Reema’s services</li>
          <li>Member does not express hesitancy around legitimacy after being introduced to Guide, given business card, and being directed to the website</li>
          <li>Guide has an established next step with member</li>
          <li>Member understands expectation around at least monthly contact</li>         
        </ul>`,
        { messageIsHtml: true }
      );
      if (confirm) {
        this.usersFacade.updateUser(this.member.id, {
          memberStageId: newStatus.id
        });
      }
    }
  }
  showUpcomingEvents() {
    upcomingEventsSubject$.next(true);
  }
}
