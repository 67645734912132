import { HostBinding } from '@angular/core';
import { AbstractControl, ControlValueAccessor } from '@angular/forms';

export class BaseInputComponent<T> implements ControlValueAccessor {
  @HostBinding('class.act-form-item') someField: boolean = true;

  public control: AbstractControl;

  constructor() {}

  onChange = (_: any) => {};
  onTouched = () => {};
  val: T = null;
  disabled: boolean;

  writeValue(value: T): void {
    this.val = this.transformToDisplay(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private setValue(val: T) {
    this.val = val;
    this.onChange(this.transformFromDisplay(this.val));
    this.onTouched();
  }

  private getValue(): T {
    return this.val;
  }

  set value(val: T) {
    this.setValue(val);
  }

  get value() {
    return this.getValue();
  }

  transformToDisplay(value: T): T {
    return value;
  }

  transformFromDisplay(displayValue: T): T {
    return displayValue;
  }
}
