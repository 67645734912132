import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromFeed from './feed.reducer';
import { feedQueries } from './feed.selectors';
import { Observable } from 'rxjs';

import { getValueOfObservable } from '@act/common/utilities';
import { FeedTypingMap } from '../../models';
import { combineLatest } from 'rxjs';
import { Platform } from '@act/core/platform';
import { FeedDto } from '@act/shared/data-transfer-objects';
import { HttpClient } from '@angular/common/http';
import { ClientBootstrap } from '@act/shared/models';
import { getClientConfig } from '@act/shared/environment';
import { selectFirstPageLoading } from '../feed-item/feed-item.selectors';
import { updateLastSeen } from './feed.actions';

@Injectable()
export class FeedFacade {
  private config: ClientBootstrap = getClientConfig();

  feeds$: Observable<FeedDto[]> = this.store.pipe(
    select(feedQueries.selectAllFeeds)
  );
  userFeedMap$: Observable<{ [key: string]: FeedDto }> = this.store.pipe(
    select(feedQueries.selectSubjectFeedMap)
  );
  idOfSelectedFeed$: Observable<number> = this.store.pipe(
    select(feedQueries.selectSelectedFeedId)
  );
  feedTypingMap$: Observable<FeedTypingMap> = this.store.pipe(
    select(feedQueries.selectFeedTypingMap)
  );
  selectedFeed$: Observable<FeedDto> = this.store.pipe(
    select(feedQueries.selectSelectedFeed)
  );
  selectFirstPageLoading$: Observable<boolean> = this.store.pipe(
    select(selectFirstPageLoading)
  );

  activeFeedTypingMap$: Observable<string[]> = combineLatest(
    this.idOfSelectedFeed$,
    this.feedTypingMap$,
    (id, map) =>
      !map[id]
        ? []
        : map[id].filter(
            (id: string) => id !== getValueOfObservable(this.platform.user$).id
          )
  );

  constructor(
    private store: Store<fromFeed.State>,
    private platform: Platform,
    private http: HttpClient
  ) {}

  getSelectedFeedId(): number {
    return getValueOfObservable(this.idOfSelectedFeed$);
  }

  getFeedIdForMember(memberId: string): number {
    const userFeedMap = getValueOfObservable(this.userFeedMap$);
    const feed = userFeedMap[memberId];
    return feed ? feed.id : null;
  }

  updateLastSeen(feedId: number, lastSeenTime: number): void {
    this.store.dispatch(
      updateLastSeen({ params: { feedId }, requestBody: { lastSeenTime } })
    );
  }
}
