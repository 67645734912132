import { NoteDto } from '@act/shared/data-transfer-objects';
import { createAction, props } from '@ngrx/store';
import { NoteRequests } from './note.requests';

const requests = new NoteRequests(null, null);

/**
 * Creating Notes
 */
export const {
  initiator: createNote,
  success: noteCreated,
  error: noteCreationError
} = requests.postNote.getRequestActions();

/**
 * Updating Notes
 */
export const {
  initiator: updateNote,
  success: noteUpdated,
  error: noteUpdateError
} = requests.patchNote.getRequestActions();

/**
 * Deleting Notes
 */
export const {
  initiator: deleteNote,
  success: noteDeleted,
  error: noteDeleteError
} = requests.deleteNote.getRequestActions();

/**
 *  Edit Notes
 */
export const editNote = createAction(
  '[Notes] Edit Note',
  props<{ note: NoteDto }>()
);

export const stopEditingNote = createAction(
  '[Notes] Stop Editing Note',
  props<{ noteId: number }>()
);

export const toggleNoteEditing = createAction(
  '[Notes] Toggle Note Editing',
  props<{ note: NoteDto }>()
);

export const viewFeedItemNotes = createAction(
  '[Notes] View Feed Item Notes',
  props<{ feedItemId: number }>()
);

export const stopViewingFeedItemNotes = createAction(
  '[Notes] Stop Viewing Feed Item Notes',
  props<{ feedItemId: number }>()
);

/**
 * Create Comment
 */
export const {
  initiator: createComment,
  success: commentCreated,
  error: commentCreationError
} = requests.createComment.getRequestActions();
