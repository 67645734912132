import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadUserConsent,
  submitConsentResponseSuccess
} from './consent.actions';
import { map, filter } from 'rxjs/operators';
import { selectMemberAction } from '@act/features/users/data-access';
import { HttpClient } from '@angular/common/http';
import { ConsentRequests } from './consent.requests';

@Injectable()
export class ConsentEffects {
  constructor(
    private actions$: Actions,
    private consentRequests: ConsentRequests
  ) {}

  getUserConsents$ = this.consentRequests.getConsentsForUser.createEffect(
    this.actions$
  );

  submitUserConsentResponse$ = this.consentRequests.postConsentResponse.createEffect(
    this.actions$
  );

  getConsentsWhenPatientIsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectMemberAction),
      filter(action => !!action.id),
      map(action => {
        return loadUserConsent({
          params: { userId: action.id },
          requestBody: null
        });
      })
    )
  );

  getConsentAfterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitConsentResponseSuccess),
      map(action => {
        return loadUserConsent({
          params: { userId: action.params.userId },
          requestBody: null
        });
      })
    )
  );
}
