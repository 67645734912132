import { TermDto, UserDto } from '@act/shared/data-transfer-objects';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ConsentFacade } from '@act/features/consent/data-access';
import { ActDialogService } from '@act/shared/ui/dialog';

@Component({
  selector: 'act-consent-dialog',
  templateUrl: './consent-dialog.component.html',
  styleUrls: ['./consent-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsentDialogComponent implements OnInit {
  @Input() term: TermDto;
  @Input() user: UserDto;
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(
    private consentFacade: ConsentFacade,
    private dialogService: ActDialogService
  ) {}

  consentValue: boolean;

  ngOnInit() {
    this.consentValue = this.term.consented;
  }

  valueChange(consented: boolean) {
    this.consentValue = consented;
  }

  cancel() {
    this.close.emit();
  }

  async submit() {
    const confirmed = await this.dialogService.confirm(
      `Did ${this.user.name} give verbal consent for ${this.term.name}?`,
      { yes: 'Yes', no: 'No' }
    );
    if (confirmed) {
      this.consentFacade.submitConsentForUser(
        this.user.id,
        this.term.id,
        this.consentValue
      );
      this.close.emit();
    } else {
      this.dialogService.alert(`Verbal consent is required.`);
    }
  }
}
