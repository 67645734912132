import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {InlineSVGModule} from 'ng-inline-svg';
import {CommonDirectivesModule} from '@act/common/directives';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    CommonDirectivesModule,
    InlineSVGModule.forRoot(),
    RouterModule.forChild([])
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class SharedUiHeaderModule {
}
