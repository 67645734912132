import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { HeaderConfig } from '../models/header-config.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'act-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Input() headerConfig: HeaderConfig;
  @Input() page: string;
  @Input() mobile: boolean;
  @Output() navigate: EventEmitter<string> = new EventEmitter();

  private _mobileMenuActive$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  mobileMenuActive$: Observable<
    boolean
  > = this._mobileMenuActive$.asObservable();

  toggleMobileMenu() {
    this._mobileMenuActive$.next(!this._mobileMenuActive$.getValue());
  }

  openMobileMenu() {
    this._mobileMenuActive$.next(true);
  }

  closeMobileMenu() {
    this._mobileMenuActive$.next(false);
  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }
}
