import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { MomentModule } from 'ngx-moment';
import { CommonPipesRoleMapperModule } from '@act/common/pipes/role-mapper';
import { CommonPipesHoursMinutesSecondsModule } from '@act/common/pipes/hours-minutes-seconds';
import { CommonPipesPhoneNumberModule } from '@act/common/pipes/phone-number';
import { StoreModule } from '@ngrx/store';
import * as fromChat from './+state/chat-reducers';
import { EffectsModule } from '@ngrx/effects';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { ChatFacade } from './+state/chat.facade';
import { FeedEffects } from './+state/feed/feed.effects';
import { CommonDispatcherModule } from '@act/common/dispatcher';
import { VendorOpentokModule } from '@act/vendor/opentok';
import { CommonSystemModule } from '@act/common/system';
import { FeedItemEffects } from './+state/feed-item/feed-item.effects';
import { MessageEffects } from './+state/message/message.effects';
import { FeedFacade } from './+state/feed/feed.facade';
import { CallEffects } from './+state/call/call.effects';
import { CallFacade } from './+state/call/call.facade';
import { NoteEffects } from './+state/note/note.effects';
import { NoteFacade } from './+state/note/note.facade';
import { VisitEffects } from './+state/visit/visit.effects';
import { VisitFacade } from './+state/visit/visit.facade';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { FeedHeaderComponent } from './feed-header/feed-header.component';
import { MessagesComponent } from './messages/messages.component';
import { CallsComponent } from './calls/calls.component';
import { NotesComponent } from './notes/notes.component';
import { VisitsComponent } from './visits/visits.component';
import { ScheduledEventsComponent } from './scheduled-events/scheduled-events.component';
import { ActionPanelComponent } from './action-panel/action-panel.component';
import { SingleFeedComponent } from './single-feed/single-feed.component';
import { DeliveredComponent } from './messages/delivered/delivered.component';
import { ScheduleComponent } from './messages/schedule/schedule.component';
import { UndeliveredComponent } from './messages/undelivered/undelivered.component';
import { DayBreakerComponent } from './day-breaker/day-breaker.component';
import { FeedLoadingComponent } from './feed-loading/feed-loading.component';
import { NotePreviewComponent } from './dialog/note-preview/note-preview.component';
import { NoteDialogComponent } from './notes/note-dialog/note-dialog.component';

import {
  MatDialogModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatGridListModule
} from '@angular/material';
import { CommonUtilitiesAutosaveModule } from '@act/common/utilities/autosave';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneDialogComponent } from './dialog/phone-dialog/phone-dialog.component';
import { EditCallNotesDialogComponent } from './calls/edit-call-notes-dialog/edit-call-notes-dialog.component';
import { VisitDialogComponent } from './visits/visit-dialog/visit-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { SharedUiAudioPlayerModule } from '@act/shared/ui/audio-player';
import { ScheduledEventFacade } from './+state/scheduled-event/scheduled-event.facade';
import { ScheduledEventEffects } from './+state/scheduled-event/scheduled-event.effects';
import { UserNeedFacade } from './+state/need/need.facade';
import { UserNeedEffects } from './+state/need/need.effects';
import { ScheduledDialogComponent } from './dialog/scheduled-dialog/scheduled-dialog.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { SingleScheduledEventComponent } from './scheduled-events/single-scheduled-event/single-scheduled-event.component';
import { FileFacade } from '@act/features/files/data-access';
import { SharedTextTemplateModule } from '@act/shared/text-template';
import { NeedRequests } from './+state/need/need.requests';
import { CallRequests } from './+state/call/call.requests';
import { FeedRequests } from './+state/feed/feed.requests';
import { NoteRequests } from './+state/note/note.requests';
import { ScheduledEventRequests } from './+state/scheduled-event/scheduled-event.requests';
import { VisitRequests } from './+state/visit/visit.requests';
import { SharedUiFormsModule } from '@act/shared/ui/forms';
import { SharedUiFeedCardModule } from '@act/shared/ui/feed-card';
import { FeedFiltersComponent } from './feed-filters/feed-filters.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { SharedUiDialogModule } from '@act/shared/ui/dialog';
import { FeedActionsService } from './feed-actions/feed-actions.service';
import { SharedUiSpinnerModule } from '@act/shared/ui/spinner';
import { CommentsComponent } from './comments/comments.component';
import { CommonPipesTextFormatModule } from '@act/common/pipes/text-format';
import { FeaturesFilesUiModule } from '@act/features/files/ui';

@NgModule({
  declarations: [
    LayoutComponent,
    FeedHeaderComponent,
    DayBreakerComponent,
    MessagesComponent,
    CallsComponent,
    NotesComponent,
    VisitsComponent,
    ScheduledEventsComponent,
    ActionPanelComponent,
    SingleFeedComponent,
    DeliveredComponent,
    ScheduleComponent,
    UndeliveredComponent,
    FeedLoadingComponent,
    NotePreviewComponent,
    NoteDialogComponent,
    PhoneDialogComponent,
    EditCallNotesDialogComponent,
    VisitDialogComponent,
    ScheduledDialogComponent,
    ConfirmDialogComponent,
    SingleScheduledEventComponent,
    FeedFiltersComponent,
    MessageDialogComponent,
    CommentsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    CommonModule,
    CommonPipesHoursMinutesSecondsModule,
    CommonPipesPhoneNumberModule,
    CommonDispatcherModule,
    CommonSystemModule,
    MomentModule,
    CommonPipesRoleMapperModule,
    QuillModule.forRoot({
      modules: {
        mentions: true,
        toolbar: false
      }
    }),
    StoreModule.forFeature(
      fromChat.chatFeatureKey,
      fromChat.CHAT_FEATURE_REDUCER_TOKEN
    ),
    EffectsModule.forFeature([
      FeedEffects,
      FeedItemEffects,
      MessageEffects,
      CallEffects,
      NoteEffects,
      VisitEffects,
      ScheduledEventEffects,
      UserNeedEffects
    ]),
    SocketIoModule.forRoot(({} as any) as SocketIoConfig),
    VendorOpentokModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    SharedUiAudioPlayerModule,
    CommonUtilitiesAutosaveModule,
    SharedTextTemplateModule,
    SharedUiFormsModule,
    SharedUiFeedCardModule,
    SharedUiDialogModule,
    SharedUiSpinnerModule,
    CommonPipesTextFormatModule,
    FeaturesFilesUiModule
  ],
  exports: [LayoutComponent, FeedLoadingComponent, FeedHeaderComponent],
  providers: [
    // facades
    ChatFacade,
    FeedFacade,
    CallFacade,
    NoteFacade,
    VisitFacade,
    FileFacade,
    ScheduledEventFacade,
    UserNeedFacade,
    NeedRequests,
    CallRequests,
    FeedRequests,
    NoteRequests,
    ScheduledEventRequests,
    VisitRequests,
    FeedActionsService,

    // +state
    {
      provide: fromChat.CHAT_FEATURE_REDUCER_TOKEN,
      useFactory: fromChat.getReducers
    }
  ],
  entryComponents: [
    NotePreviewComponent,
    NoteDialogComponent,
    PhoneDialogComponent,
    EditCallNotesDialogComponent,
    VisitDialogComponent,
    ScheduledDialogComponent,
    ConfirmDialogComponent,
    EditCallNotesDialogComponent,
    MessageDialogComponent
  ]
})
export class FeedModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'create-note',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/btn_AddNote.svg')
    );
    iconRegistry.addSvgIcon(
      'btn-edit',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/btn_Edit.svg')
    );
    iconRegistry.addSvgIcon(
      'btn-send',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/btn_send_arrow.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'btn-close',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/btn_close.svg')
    );
  }
}
