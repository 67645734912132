import {ClientBootstrap, ClientBootstrapResponse} from '@act/shared/models';
import {ENVS} from '@act/shared/environment';

export const CLIENT_CONFIG_KEY = '__r';

declare var window: {
    [CLIENT_CONFIG_KEY]: ClientBootstrapResponse;
};

export function getClientData(): ClientBootstrapResponse {
    return window[CLIENT_CONFIG_KEY];
}

export function getClientConfig(): ClientBootstrap {
    return window[CLIENT_CONFIG_KEY].config;
}

export function getClientEnvironment(): ENVS {
    return window[CLIENT_CONFIG_KEY].environment;
}