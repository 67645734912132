import { UsersFacade } from '@act/features/users/data-access';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'act-router',
  templateUrl: './router.component.html',
  styleUrls: ['./router.component.scss']
})
export class RouterComponent implements OnInit, OnDestroy {
  page$: Observable<string>;
  changesSubscription: Subscription;

  page: string;
  routeValues: RouteValues;
  stateValues: StateValues;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usersFacade: UsersFacade
  ) {
    const routeValues$ = this.getRouteValuesObservable();
    const stateValues$ = this.getStateValuesObservable();

    this.page$ = routeValues$.pipe(map(({ routeValues }) => routeValues.page));

    let lastRouteValues: RouteValues;
    let lastStateValues: StateValues;

    const changes$ = combineLatest([routeValues$, stateValues$]).pipe(
      map(([{ routeValues }, stateValues]) => {
        const routeValuesChanged = lastRouteValues !== routeValues;
        const stateValuesChanged = lastStateValues !== stateValues;
        lastRouteValues = routeValues;
        lastStateValues = stateValues;

        return {
          routeValues,
          stateValues,
          routeValuesChanged: routeValuesChanged,
          stateValuesChanged: stateValuesChanged && !routeValuesChanged
        };
      })
    );

    this.changesSubscription = changes$.subscribe(
      ({
        routeValues,
        stateValues,
        routeValuesChanged,
        stateValuesChanged
      }) => {
        this.stateValues = stateValues;
        this.routeValues = routeValues;
        this.page = routeValues.page;

        if (routeValuesChanged) {
          this.handleRouteUpdate(routeValues, stateValues);
        }
        if (stateValuesChanged) {
          this.handleStateUpdate(stateValues, routeValues);
        }
      }
    );
  }

  goToPage(page: Page | string) {
    const { url, queryParams } = buildRoute({
      page: <Page>page,
      guideId: this.routeValues.guideId,
      memberId: null
    });

    this.router.navigate([url], { queryParams });
  }

  handleRouteUpdate(routeValues: RouteValues, stateValues: StateValues) {
    this.routeValues = routeValues;
    if (routeValues.guideId !== stateValues.guideId) {
      // Update guideId
      this.usersFacade.selectTeamMember(routeValues.guideId);
    }

    if (routeValues.memberId !== stateValues.memberId) {
      // Update memberId
      this.usersFacade.selectMember(routeValues.memberId);
    }
  }

  handleStateUpdate(stateValues: StateValues, routeValues: RouteValues) {
    const queryValues = getQueryValues(this.route.snapshot.queryParamMap);
    const { url, queryParams } = buildRoute(
      {
        page: routeValues.page,
        guideId: stateValues.guideId,
        memberId: stateValues.memberId
      },
      stateValues.memberId ? { ...queryValues } : null
    );

    this.router.navigate([url], { queryParams });
  }

  getRouteValuesObservable() {
    let lastRouteValues: RouteValues;

    return combineLatest([this.route.params, this.route.queryParams]).pipe(
      map(([params, queryParams]) => buildRouteValues(params, queryParams)),
      filter(
        ({ routeValues }) => !routeValuesEquals(routeValues, lastRouteValues)
      ),
      tap(({ routeValues }) => (lastRouteValues = routeValues))
    );
  }

  getStateValuesObservable() {
    let lastStateValues: StateValues;

    return combineLatest([
      this.usersFacade.selectedTeamMemberId$,
      this.usersFacade.selectedMemberId$
    ]).pipe(
      map(([guideId, memberId]) => buildStateValues(guideId, memberId)),
      filter(value => !stateValuesEquals(value, lastStateValues)),
      tap(value => (lastStateValues = value))
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.changesSubscription) {
      this.changesSubscription.unsubscribe();
    }
  }
}

interface StateValues {
  guideId: string;
  memberId: string;
}

const buildStateValues = (
  selectedTeamMemberId: string,
  selectedMemberId: string
): StateValues => ({
  guideId: selectedTeamMemberId,
  memberId: selectedMemberId
});

const routeValuesEquals = (s1: RouteValues, s2: RouteValues): boolean => {
  if (s1 === s2) return true;
  if (!s1 || !s2) return false;

  return (
    s1.guideId === s2.guideId &&
    s1.memberId === s2.memberId &&
    s1.page === s2.page
  );
};

type Page = 'calls' | 'member-list' | 'today';
interface RouteValues {
  page: Page;
  guideId: string;
  memberId: string;
}

export interface QueryValues {
  feedItem: string;
  feedItemExternalType: string;
  feedItemExternalId: string;
}

const stateValuesEquals = (s1: StateValues, s2: StateValues): boolean => {
  if (s1 === s2) return true;
  if (!s1 || !s2) return false;

  return s1.guideId === s2.guideId && s1.memberId === s2.memberId;
};

const buildRouteValues = (
  params: Params,
  queryParams: Params
): { routeValues: RouteValues; queryValues: QueryValues } => {
  const { param1, param2, param3, param4, param5, param6 } = params;

  let guideId,
    page,
    memberId,
    feedItem,
    feedItemExternalType,
    feedItemExternalId;

  switch (param1) {
    case 'calls':
      page = 'calls';
      break;
    case 'member-list':
      // member-list/guide/<guideId>/member/<memberId>
      // or member-list/<memberId>
      page = 'member-list';
      if (param2 === 'guide') {
        guideId = param3;
        if (param4 === 'member') {
          memberId = param5;
        }
      } else if (param2) {
        memberId = param2;
      }

      if (memberId) {
        ({
          feedItem,
          feedItemExternalType,
          feedItemExternalId
        } = buildQueryValues(queryParams));
      }

      break;
    case 'today':
      // today/guide/<guideId>/member/<memberId>
      // or today/<memberId>
      page = 'today';

      if (param2 === 'guide') {
        guideId = param3;
        if (param4 === 'member') {
          memberId = param5;
        }
      } else if (param2) {
        memberId = param2;
      }

      if (memberId) {
        ({
          feedItem,
          feedItemExternalType,
          feedItemExternalId
        } = buildQueryValues(queryParams));
      }

      break;

    case 'members':
      // members/<memberId>
      page = 'member-list';
      if (param2) {
        memberId = param2;
      }
      break;
    default:
      page = 'today';
      break;
  }
  return {
    routeValues: {
      page,
      guideId,
      memberId
    },
    queryValues: {
      feedItem,
      feedItemExternalType,
      feedItemExternalId
    }
  };
};

const buildRoute = (
  routeValues: RouteValues,
  queryValues: QueryValues = null
): { url: string; queryParams?: Params } => {
  let url = '';
  switch (routeValues.page) {
    case 'calls':
      return { url: 'calls' };
    case 'member-list':
      url = 'member-list';
      break;
    case 'today':
      url = 'today';
      break;
    default:
      return { url: 'today' };
  }

  if (routeValues.guideId) {
    url += `/guide/${routeValues.guideId}`;

    if (routeValues.memberId) {
      url += `/member/${routeValues.memberId}`;
    }
  } else if (routeValues.memberId) {
    url += `/${routeValues.memberId}`;
  }

  const queryParamsMap = new Map<string, string>();

  if (queryValues) {
    if (queryValues.feedItem) {
      queryParamsMap.set('feedItem', queryValues.feedItem);
    } else if (
      queryValues.feedItemExternalId &&
      queryValues.feedItemExternalType
    ) {
      queryParamsMap.set(
        'feedItemExternalType',
        queryValues.feedItemExternalType
      );
      queryParamsMap.set('feedItemExternalId', queryValues.feedItemExternalId);
    }
  }

  return {
    url: url,
    queryParams: [...queryParamsMap.entries()].reduce(
      (obj, [key, value]) => ((obj[key] = value), obj),
      {}
    )
  };
};

function buildQueryValues(queryParams: Params): QueryValues {
  let feedItem, feedItemExternalType, feedItemExternalId;
  if (queryParams.feedItem) {
    feedItem = queryParams.feedItem;
  } else if (
    queryParams.feedItemExternalType &&
    queryParams.feedItemExternalId
  ) {
    feedItemExternalType = queryParams.feedItemExternalType;
    feedItemExternalId = queryParams.feedItemExternalId;
  }
  return { feedItem, feedItemExternalType, feedItemExternalId };
}

export function getQueryValues(queryParamMap: ParamMap): QueryValues {
  return {
    feedItem: queryParamMap.get('feedItem'),
    feedItemExternalType: queryParamMap.get('feedItemExternalType'),
    feedItemExternalId: queryParamMap.get('feedItemExternalId')
  };
}
