import {
  CohortDto,
  MemberStageDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  TemplateRef,
  DoCheck
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CohortFacade } from 'libs/features/users/data-access/src/lib/+state/cohort/cohort.facade';
import { UsersFacade } from '@act/features/users/data-access';
import { UserFacade } from '@act/features/user/data-access';
import { Subscription } from 'rxjs';
import { ActDialog, ActDialogService } from '@act/shared/ui/dialog';
import { MatButtonToggleChange } from '@angular/material';
import { MapService } from '../../services/map.service';
import { getValueOfObservable } from '@act/common/utilities';
@Component({
  selector: 'act-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss']
})
export class ListHeaderComponent implements OnInit, OnDestroy {
  @Input() selectedMember: UserDto;
  @Input() userId: string;
  @Input() cohorts: CohortDto[];
  @Input() stages: MemberStageDto[];
  dialogRef: ActDialog;
  @ViewChild('editCohortRef', { static: true }) editCohortRef: TemplateRef<any>;

  @Input() team: UserDto[];
  @Output() assignGuideEvent: EventEmitter<UserDto> = new EventEmitter();
  @Output() assignCohortEvent: EventEmitter<CohortDto> = new EventEmitter();
  @Output() assignStageEvent: EventEmitter<MemberStageDto> = new EventEmitter();
  @Input() membersSelectedCount: number | undefined;
  updateCohortSubscription: Subscription;
  addCohortSubscription: Subscription;
  mapViewSubscription: Subscription;
  mapView: boolean;

  constructor(
    public usersFacade: UsersFacade,
    public cohortFacade: CohortFacade,
    public userFacade: UserFacade,
    private dialogService: ActDialogService,
    public mapService: MapService
  ) {}

  openCohortsModal() {
    this.dialogRef = this.dialogService.createDialog(
      {
        ref: this.editCohortRef,
        onClose: () => {},
        onOpen: () => {},
        width: '400px'
      },
      'cohort-dialog'
    );
    this.dialogRef.open();
  }

  updateCohortHandle(cohort: CohortDto) {
    this.cohortFacade.updateCohort(cohort, cohort.id);
  }

  addCohortHandle({ name, colorHex }: { name: string; colorHex: string }) {
    this.cohortFacade.createCohort({ name, colorHex });
  }

  closeDialogHandle() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.mapView = getValueOfObservable(this.mapService.isMapView$);
    this.mapViewSubscription = this.mapService.isMapView$.subscribe(value => {
      this.mapView = value;
    });
  }

  ngOnDestroy() {
    if (this.updateCohortSubscription) {
      this.updateCohortSubscription.unsubscribe();
    }
    if (this.addCohortSubscription) {
      this.addCohortSubscription.unsubscribe();
    }
    this.mapViewSubscription.unsubscribe();
  }

  onClickGuide(teamMate: UserDto) {
    this.assignGuideEvent.emit(teamMate);
  }

  onClickCohort(cohort: CohortDto) {
    this.assignCohortEvent.emit(cohort);
  }

  unassignCohort() {
    this.assignCohortEvent.emit(null);
  }

  onClickStage(stage: MemberStageDto) {
    this.assignStageEvent.emit(stage);
  }

  toggleView(change: MatButtonToggleChange) {
    this.mapView = change.value;
    this.mapService.toggleMapView(change.value);
  }
}
