import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewLineToHtmlBreakPipe } from './pipes/new-line.pipe';
import { FormatTextTemplatePipe } from './pipes/format-text-template.pipe';
import { GetInitialsPipe } from './pipes/get-initials.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    NewLineToHtmlBreakPipe,
    FormatTextTemplatePipe,
    GetInitialsPipe
  ],
  exports: [NewLineToHtmlBreakPipe, FormatTextTemplatePipe, GetInitialsPipe]
})
export class CommonPipesTextFormatModule {}
