import { ActDialog, ActDialogService } from '@act/shared/ui/dialog';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CallListFacade } from '../+state/call-list.facade';

@Component({
  selector: 'act-call-initiator',
  templateUrl: './call-initiator.component.html',
  styleUrls: ['./call-initiator.component.scss']
})
export class CallInitiatorComponent implements OnInit {
  constructor(
    private readonly callListFacade: CallListFacade,
    private dialogService: ActDialogService
  ) {}

  ngOnInit() {}

  dialogRef: ActDialog;
  @ViewChild('dialogRef', { static: true }) dialogTemplateRef: TemplateRef<any>;
  phoneNumberControl = new FormControl('', [
    Validators.required,
    Validators.pattern('\\d{10}')
  ]);

  openCallDialog() {
    this.phoneNumberControl.setValue('');
    this.dialogRef = this.dialogService.createDialog(
      {
        ref: this.dialogTemplateRef,
        onClose: () => {},
        onOpen: () => {}
      },
      'call-dialog'
    );
    this.dialogRef.open();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  createCall() {
    if (this.phoneNumberControl.valid) {
      const fullNumber = '+1' + this.phoneNumberControl.value;

      // CallCreationRequestDto
      this.callListFacade.callNumber(fullNumber);
    }
  }
}
