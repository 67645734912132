import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadNeedCategories, loadUserNeeds } from './need.actions';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectFeed } from '../feed/feed.actions';
import { feedQueries } from '../feed/feed.selectors';
import { NeedRequests } from './need.requests';

@Injectable()
export class UserNeedEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private needRequests: NeedRequests
  ) {}

  triggerUserNeedsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectFeed),
      withLatestFrom(this.store.pipe(select(feedQueries.selectSelectedFeed))),
      map(([action, feed]) => {
        return loadUserNeeds({
          params: { userId: feed.subjectId },
          requestBody: null
        });
      })
    )
  );

  triggerNeedCategoriesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectFeed),
      map(action => {
        return loadNeedCategories({ params: null, requestBody: null });
      })
    )
  );

  updateUserNeedOrder$ = this.needRequests.updateUserNeedOrder.createEffect(
    this.actions$
  );

  getUserNeeds$ = this.needRequests.getUserNeeds.createEffect(this.actions$);

  getNeedCategories = this.needRequests.getNeedCategories.createEffect(
    this.actions$
  );

  createUserNeed$ = this.needRequests.postUserNeed.createEffect(this.actions$);

  updateUserNeed$ = this.needRequests.patchUserNeed.createEffect(this.actions$);

  deleteUserNeed$ = this.needRequests.deleteUserNeed.createEffect(
    this.actions$
  );
}
