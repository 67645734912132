import { Pipe, PipeTransform } from '@angular/core';
import {UserRoleMappings} from '@act/shared/models';

@Pipe({
  name: 'roleMapper'
})
export class RoleMapperPipe implements PipeTransform {
  
  transform(value: any, ...args: any[]): any {
    return UserRoleMappings[value];
  }
  
}
