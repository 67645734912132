import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';

@Component({
  selector: 'act-list-loading',
  templateUrl: './list-loading.component.html',
  styleUrls: ['./list-loading.component.scss']
})
export class ListLoadingComponent implements OnInit, OnDestroy {
  @Input() delayMilliseconds: number = 0;

  constructor(private cd: ChangeDetectorRef) {}

  show = false;

  private showTimeout: NodeJS.Timeout;

  ngOnInit() {
    // Start showing loading indicator after a second
    this.showTimeout = setTimeout(() => {
      this.show = true;
      this.cd.detectChanges();
    }, this.delayMilliseconds);
  }

  ngOnDestroy() {
    // Cancel timeout on destroy
    clearTimeout(this.showTimeout);
  }
}
