import {
  UserNeedDto,
  UpdateUserNeedSortOrderDto
} from '@act/shared/data-transfer-objects';
import { createAction, props } from '@ngrx/store';
import { NeedRequests } from './need.requests';

const requests = new NeedRequests(null, null);
/**
 * Get User Needs
 */
export const {
  initiator: loadUserNeeds,
  success: userNeedsLoaded,
  error: userNeedsLoadError
} = requests.getUserNeeds.getRequestActions();

/**
 * Get Need Categories
 */
export const {
  initiator: loadNeedCategories,
  success: needCategoriesLoaded,
  error: needCategoriesLoadError
} = requests.getNeedCategories.getRequestActions();

/**
 * Creating User Needs
 */
export const {
  initiator: createUserNeed,
  success: userNeedCreated,
  error: userNeedCreationError
} = requests.postUserNeed.getRequestActions();

/**
 * Updating User Needs
 */
export const {
  initiator: updateUserNeed,
  success: userNeedUpdated,
  error: userNeedUpdateError
} = requests.patchUserNeed.getRequestActions();

/**
 * Deleting User Needs
 */
export const {
  initiator: deleteUserNeed,
  success: userNeedDeleted,
  error: userNeedDeleteError
} = requests.deleteUserNeed.getRequestActions();

/**
 * Update User Need Order
 */
export const {
  initiator: updateUserNeedOrder,
  success: userNeedOrderUpdated,
  error: userNeedOrderUpdateError
} = requests.updateUserNeedOrder.getRequestActions();

/**
 * Socket event action
 */
export const newUserNeed = createAction(
  '[User-Needs] New User Need Added',
  props<{ item: UserNeedDto }>()
);
