import { adapter } from './patients.reducer';
import { createSelector } from '@ngrx/store';

import {
  PatientsFeatureState,
  patientsKey,
  PatientsState
} from '@act/features/patients/models';

import * as fromPatientsFeature from '../patients.selectors';

const selectPatientsState = createSelector(
  fromPatientsFeature.selectPatientsFeatureState,
  (state: PatientsFeatureState) => state[patientsKey]
);

export const {
  selectIds: selectPatientIds,
  selectEntities: selectPatients,
  selectAll: selectAllPatients,
  selectTotal: selectPatientTotal
} = adapter.getSelectors(selectPatientsState);

export const patientsQueries = {
  selectPatientIds,
  selectPatients,
  selectAllPatients,
  selectPatientTotal
};
