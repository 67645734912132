import {
  memberStagesFeatureKey,
  State,
  adapter,
  statusAdapter
} from './member-stage.reducer';
import { createSelector } from '@ngrx/store';

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const {
  selectEntities: selectStatusEntities,
  selectAll: selectAllStatuses
} = statusAdapter.getSelectors();

const selectState = (state: any): State => state[memberStagesFeatureKey];

export const selectAllMemberStages = createSelector(
  selectState,
  selectAll
);

export const selectMemberStageMap = createSelector(
  selectState,
  selectEntities
);

export const selectMemberStagesLoading = createSelector(
  selectState,
  state => state.loading
);

const selectStatusState = (state: any): State =>
  state[memberStagesFeatureKey].statuses;

export const selectAllMemberStatuses = createSelector(
  selectStatusState,
  selectAllStatuses
);

export const selectMemberStatusMap = createSelector(
  selectStatusState,
  selectStatusEntities
);

export const selectMemberStatusesLoading = createSelector(
  selectStatusState,
  state => state.loading
);
