import { type } from '@act/common/utilities';
import { Survey, SurveyRecord } from '@act/server/models/surveys';
import { Action } from '@ngrx/store';

export const WebsocketSurveyResponsesActionTypes = {
  UPDATE_RESPONSE: type('[responses] update response'),
  RESPONSE_UPDATED: type('[responses] response updated')
};

export const WebsocketSurveyActionTypes = {
  LOAD_SURVEY: type('[surveys] load survey'),
  COMPLETE_SURVEY: type('[surveys] complete survey'),
  SURVEY_LOADED: type('[surveys] survey loaded')
};

export class WebsocketSurveyLoadedAction implements Action {
  public readonly type = WebsocketSurveyActionTypes.SURVEY_LOADED;

  constructor(
    public payload: {
      survey: Survey;
      surveyRecord: SurveyRecord;
    }
  ) {}
}
