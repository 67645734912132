import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialog } from './services/confirmation-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedUiFormsModule } from '@act/shared/ui/forms';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    SharedUiFormsModule
  ],
  declarations: [ConfirmationDialogComponent],
  entryComponents: [ConfirmationDialogComponent],
  providers: [ConfirmationDialog]
})
export class CommonDialogsConfirmationModule {}
