import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HoursMinutesSecondsPipe } from './pipes/hours-minutes-seconds.pipe';
import { HoursMinutesPipe } from './pipes/hours-minutes.pipe';
import { USDateFormatPipe } from './pipes/us-date-format.pipe';
import { DateStringToDatePipe } from './pipes/date-string-to-date.pipe';
import { YearsFromDatePipe } from './pipes/years-from-date.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    HoursMinutesSecondsPipe,
    HoursMinutesPipe,
    USDateFormatPipe,
    DateStringToDatePipe,
    YearsFromDatePipe
  ],
  exports: [
    HoursMinutesSecondsPipe,
    HoursMinutesPipe,
    USDateFormatPipe,
    DateStringToDatePipe,
    YearsFromDatePipe
  ]
})
export class CommonPipesHoursMinutesSecondsModule {}
