import {
  AddressDto,
  NoteDto,
  PhoneNumberDto,
  TodoDto,
  TodoTypeDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  CallFacade,
  VisitFacade,
  ChatFacade,
  FeedActionsService,
  getCardData
} from '@act/feed';
import { Dictionary } from '@ngrx/entity';
import { formatPhoneNumber, getValueOfObservable } from '@act/common/utilities';
import moment = require('moment');
import { FileFacade } from '@act/features/files/data-access';
import { UsersFacade } from '@act/features/users/data-access';
import { UserFacade } from '@act/features/user/data-access';
import { FeedCardData } from '@act/shared/ui/feed-card';
import { ScheduledEventFacade } from 'libs/feed/src/lib/+state/scheduled-event/scheduled-event.facade';
import { title } from 'process';
import { TodoFacade } from '@act/features/todos/data-access';

@Component({
  selector: 'act-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.scss']
})
export class TodoComponent implements OnInit, OnChanges {
  @Input() todo: TodoDto;
  @Input() userMap: Dictionary<UserDto>;
  @Input() actingUser: UserDto;
  @Input() isNew: boolean;

  constructor(
    private usersFacade: UsersFacade,
    private userFacade: UserFacade,
    private callFacade: CallFacade,
    private chatFacade: ChatFacade,
    private fileFacade: FileFacade,
    private visitFacade: VisitFacade,
    private scheduledEventFacade: ScheduledEventFacade,
    private feedActionsService: FeedActionsService,
    private todoFacade: TodoFacade
  ) {}

  actingUser$ = this.userFacade.user$;
  callOutcomeMap$ = this.callFacade.callOutcomesMap$;
  visitOutcomeMap$ = this.visitFacade.visitOutcomesMap$;

  ngOnInit() {}
  ngOnChanges() {
    this.cardValues = this.getCardValues(this.todo);
  }

  cardValues: TodoCardData = null;

  getCardValues(todo: TodoDto): TodoCardData {
    switch (todo.externalItemType) {
      case 'call':
        return this.getCardValuesFromCall(todo);
      case 'message':
        return this.getCardValuesFromMessage(todo);
      case 'visit':
        return this.getCardValuesFromVisit(todo);
      case 'note':
        return this.getCardValuesFromNote(todo);
      case 'scheduled-event':
        return this.getCardValuesFromScheduledEvent(todo);
    }
  }

  openUserFeed(userId: string) {
    this.usersFacade.selectMember(userId);
  }

  remindTomorrow(todo: TodoDto) {
    if (todo.scheduledEvent) {
      this.scheduledEventFacade.snoozeScheduledEvent(
        todo.scheduledEvent.id,
        todo.scheduledEvent.userId
      );
    } else {
      this.todoFacade.snoozeTodo(todo);
    }
  }

  callUser(
    userId: string,
    phoneNumber: PhoneNumberDto,
    scheduledEventId: number = null
  ) {
    this.feedActionsService.createCall(userId, scheduledEventId);
  }

  messageUser(
    userId: string,
    phoneNumber: PhoneNumberDto,
    scheduledEventId: number = null
  ) {
    // TODO: Call Specific Phone Number
    this.feedActionsService.createMessage(userId, scheduledEventId);
  }

  editNote(note: NoteDto) {
    this.feedActionsService.editNote(note);
  }

  recordVisit(
    userId: string,
    visitLocation: string | AddressDto,
    scheduledEventId: number = null
  ) {
    // TODO: Pre-populate visit with location
    this.feedActionsService.createVisit(userId, scheduledEventId);
  }

  getCardValuesFromCall(todo: TodoDto): TodoCardData {
    const call = todo.call;
    const canMarkAsNoFollowUpNeeded = call.incoming && !call.noFollowUpNeeded;
    const originator = this.getPhoneNumberInfo(call.originator);
    const outcomeMap = getValueOfObservable(this.callFacade.callOutcomesMap$);

    const user = this.userMap[call.subjectId];
    const directionString = call.incoming ? 'from' : 'to';
    const userName = user ? getShortName(user) : null;

    return {
      expandableTitle:
        todo.customTitle ||
        (() => {
          switch (todo.type) {
            case TodoTypeDto.CALL_BACK:
              return 'Call Back';
            case TodoTypeDto.INCOMPLETE_CALL:
              return 'Incomplete data for call';
            default:
              return 'Call';
          }
        })() + (userName ? ` ${directionString} ${userName}` : ''),

      launchAction: () => {
        this.openUserFeed(call.subjectId);
      },
      launchDescription: 'View in feed',
      additionalActions: [
        canMarkAsNoFollowUpNeeded
          ? {
              title: 'No Action Needed',
              details: 'We do not need to call user back',
              handler: () => {
                this.callFacade.updateCallNoFollowUpNeeded(call.id, true);
              }
            }
          : null,
        {
          title: 'Remind Me Tomorrow',
          handler: () => {
            this.remindTomorrow(todo);
          }
        }
      ].filter(a => a),
      cardData: getCardData.fromCall(call, this.userMap, outcomeMap, fileId =>
        this.fileFacade.getContentUrl(fileId)
      ),
      actions: [
        ...(todo.type === TodoTypeDto.CALL_BACK
          ? [
              {
                icon: 'call_outgoing',
                title: 'Call Now',
                handler: () => {
                  this.callUser(call.subjectId, originator.phoneNumber);
                }
              },
              {
                icon: 'text',
                title: 'Text Now',
                handler: () => {
                  this.messageUser(call.subjectId, originator.phoneNumber);
                }
              }
            ]
          : [
              {
                icon: 'edit',
                title: 'Edit',
                handler: () => {
                  this.feedActionsService.editCall(call);
                }
              }
            ])
      ]
    };
  }

  getCardValuesFromMessage(todo: TodoDto): TodoCardData {
    const message = todo.message;

    const canMarkAsNoFollowUpNeeded =
      message.incoming && !message.noFollowUpNeeded;

    const originator = message.incoming
      ? this.getPhoneNumberInfo({
          userId: message.userId,
          phoneNumber: message.smsPhoneNumber
        })
      : this.getPhoneNumberInfo({ userId: message.userId, phoneNumber: null });

    const user = this.userMap[message.subjectId];
    const userName = user ? getShortName(user) : '';

    return {
      expandableTitle: todo.customTitle || 'Text Back ' + userName,

      launchAction: () => {
        this.openUserFeed(message.subjectId);
      },
      launchDescription: 'View in feed',
      additionalActions: [
        canMarkAsNoFollowUpNeeded
          ? {
              title: 'No Action Needed',
              details: 'We do not need to text user back',
              handler: () => {
                this.chatFacade.updateMessageNoFollowUpNeeded(message.id, true);
              }
            }
          : null,
        {
          title: 'Remind Me Tomorrow',
          handler: () => {
            this.remindTomorrow(todo);
          }
        }
      ].filter(a => a),
      cardData: getCardData.fromMessage(message, this.userMap),
      actions: [
        ...(message.incoming
          ? [
              {
                icon: 'text',
                title: 'Text Now',
                handler: () => {
                  this.messageUser(message.subjectId, originator.phoneNumber);
                }
              },
              {
                icon: 'call_outgoing',
                title: 'Call Now',
                handler: () => {
                  this.callUser(message.subjectId, originator.phoneNumber);
                }
              }
            ]
          : [])
      ]
    };
  }

  getCardValuesFromVisit(todo: TodoDto): TodoCardData {
    const visit = todo.visit;
    const outcomeMap = getValueOfObservable(this.visitFacade.visitOutcomesMap$);

    const user = this.userMap[visit.subjectUserId];
    const title =
      todo.type === TodoTypeDto.INCOMPLETE_VISIT
        ? 'Incomplete data for visit' +
          (user ? ' with ' + getShortName(user) : '')
        : 'Visit Occurred';

    return {
      expandableTitle: todo.customTitle || title,

      launchAction: () => {
        this.openUserFeed(visit.subjectUserId);
      },
      launchDescription: 'View in feed',
      additionalActions: [
        {
          title: 'Remind Me Tomorrow',
          handler: () => {
            this.remindTomorrow(todo);
          }
        }
      ],
      cardData: getCardData.fromVisit(
        visit,
        this.actingUser,
        this.userMap,
        outcomeMap
      ),
      actions: [
        {
          icon: 'edit',
          title: 'Edit',
          handler: () => {
            this.feedActionsService.editVisit(visit);
          }
        }
      ]
    };
  }

  getCardValuesFromNote(todo: TodoDto): TodoCardData {
    const note = todo.note;

    const user = this.userMap[note.userId];
    const username = user ? getShortName(user) : null;
    return {
      expandableTitle:
        todo.customTitle ||
        'Here is a note' + (username ? ' for' + username : ''),
      launchAction: () => {
        this.openUserFeed(note.userId);
      },
      launchDescription: 'View in feed',
      additionalActions: [
        {
          title: 'Remind Me Tomorrow',
          handler: () => {
            this.remindTomorrow(todo);
          }
        }
      ],

      cardData: getCardData.fromNote(note, this.actingUser, this.userMap),
      actions: [
        {
          icon: 'edit',
          title: 'Edit',
          handler: () => {
            this.editNote(note);
          }
        }
      ]
    };
  }

  getCardValuesFromScheduledEvent(todo: TodoDto): TodoCardData {
    const scheduledEvent = todo.scheduledEvent;

    const user = this.userMap[scheduledEvent.userId];

    const title =
      (() => {
        switch (scheduledEvent.type) {
          case 'call':
            return 'Scheduled Call';
          case 'message':
            return 'Scheduled Message';
          case 'visit':
            return 'Scheduled Visit';
          case 'tbd':
            return 'Scheduled Reminder';
          default:
            return 'Reminder';
        }
      })() + (user ? ' for ' + getShortName(user) : '');

    return {
      expandableTitle: todo.customTitle || title,

      launchAction: () => {
        this.openUserFeed(scheduledEvent.userId);
      },
      launchDescription: "View user's feed",
      additionalActions: [
        {
          title: 'Remind Me Tomorrow',
          details: 'Snooze Reminder',
          handler: () => {
            this.remindTomorrow(todo);
          }
        }
      ],

      cardData: getCardData.fromScheduledEvent(
        scheduledEvent,
        this.actingUser,
        this.userMap
      ),
      actions: [
        scheduledEvent.type === 'call'
          ? {
              icon: 'call_outgoing',
              title: 'Call Now',
              handler: () => {
                this.callUser(scheduledEvent.userId, null, scheduledEvent.id);
              }
            }
          : null,

        scheduledEvent.type === 'message'
          ? {
              icon: 'text',
              title: 'Text Now',
              handler: () => {
                this.messageUser(
                  scheduledEvent.userId,
                  null,
                  scheduledEvent.id
                );
              }
            }
          : null,

        scheduledEvent.type === 'visit'
          ? {
              icon: 'visit',
              title: 'Record Visit',
              handler: () => {
                this.recordVisit(
                  scheduledEvent.userId,
                  scheduledEvent.visitLocation,
                  scheduledEvent.id
                );
              }
            }
          : null,
        {
          icon: 'snooze_reminder',
          title: 'Snooze',
          handler: () => {
            this.scheduledEventFacade.snoozeScheduledEvent(
              scheduledEvent.id,
              scheduledEvent.userId
            );
          }
        },
        scheduledEvent.type !== 'reminder'
          ? {
              icon: 'clear_reminder',
              title: 'Clear',
              handler: () => {
                this.scheduledEventFacade.clearScheduledEvent(
                  scheduledEvent.id,
                  scheduledEvent.userId
                );
              }
            }
          : null
      ].filter(a => a)
    };
  }

  getPhoneNumberInfo(info: { userId: string; phoneNumber: string }) {
    if (!info) {
      return null;
    }
    const user = this.userMap[info.userId];
    if (user) {
      if (user.isMember) {
        const phoneNumber = user.phoneNumbers.find(
          p => p.number === info.phoneNumber
        );
        if (phoneNumber) {
          // Member and we could find phone number
          return {
            title:
              phoneNumber.description +
              (phoneNumber.primary ? ' (primary)' : ''),
            details: formatPhoneNumber(info.phoneNumber),
            phoneNumber
          };
        }
        // Member but could not find phone number
        return {
          title: user.firstName + ' ' + user.lastName,
          details: formatPhoneNumber(info.phoneNumber),
          phoneNumber: null
        };
      } else {
        // User is a guide
        return {
          title: user.firstName + ' ' + user.lastName,
          details: info.userId ? null : 'Unknown User',
          phoneNumber: null
        };
      }
    } else {
      // User not found
      return {
        title: formatPhoneNumber(info.phoneNumber),
        details: info.userId ? null : 'Unknown User'
      };
    }
  }
}

type TodoCardData = {
  expandableTitle: string;

  launchAction: () => void;
  launchDescription: string;
  additionalActions: {
    title: string;
    details?: string;
    handler: () => void;
  }[];
  cardData: FeedCardData;
  actions: {
    icon: string;
    title: string;
    details?: string;
    handler: () => void;
  }[];
};

const getShortName = (user: UserDto): string => {
  if (!user) {
    return null;
  }
  return (
    user.firstName + ' ' + (user.lastName ? ' ' + user.lastName[0] + '.' : '')
  );
};
