import { createAction } from '@ngrx/store';
import { CallRequests } from './call.requests';

const requests = new CallRequests(null, null);

export const {
  initiator: createCall,
  success: callCreated,
  error: callCreationError
} = requests.createCall.getRequestActions();

/**
 * Updating Calls
 */
export const {
  initiator: updateCall,
  success: callUpdated,
  error: callUpdateError
} = requests.patchCall.getRequestActions();

export const {
  initiator: loadCallOutcomes,
  success: callOutcomesLoaded,
  error: callOutcomesLoadError
} = requests.getCallOutcomes.getRequestActions();

export const {
  initiator: updateNoFollowUp,
  success: noFollowUpUpdated,
  error: noFollowUpUpdateError
} = requests.updateCallNoFollowUpNeeded.getRequestActions();

/**
 * Create Comment
 */
export const {
  initiator: createComment,
  success: commentCreated,
  error: commentCreationError
} = requests.createComment.getRequestActions();

export const triggerCallOutcomeLoad = createAction(
  '[Call] Trigger Call Outcome Load'
);
