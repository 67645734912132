import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  callListFeatureKey,
  callListAdapter,
  State,
  UserSubState,
  usersSubAdapter
} from './call-list.reducer';

const callSelectors = callListAdapter.getSelectors();

export const selectCallListState = createFeatureSelector<State>(
  callListFeatureKey
);

export const selectCallList = createSelector(
  selectCallListState,
  callSelectors.selectAll
);

export const selectCallListLoading = createSelector(
  selectCallListState,
  (state: State) => state.loading
);

export const selectCallListFilters = createSelector(
  selectCallListState,
  (state: State) => {
    return state.filters;
  }
);

export const selectCallListPagination = createSelector(
  selectCallListState,
  (state: State) => {
    return state.pagination;
  }
);

const userSelectors = usersSubAdapter.getSelectors();

export const selectCallListUserState = createSelector(
  selectCallListState,
  state => state.users
);

export const selectCallListUserMap = createSelector(
  selectCallListUserState,
  (state: UserSubState) => {
    return state.entities;
  }
);

export const selectCallListUsers = createSelector(
  selectCallListUserState,
  userSelectors.selectAll
);

export const selectCallListUserLoading = createSelector(
  selectCallListUserState,
  (state: UserSubState) => {
    return state.loading;
  }
);
