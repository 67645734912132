import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { PlatformFilter } from '@act/core/platform';

export function platformFilter<T>(
  entities$: Observable<T[]>,
  filters: PlatformFilter<T>[],
  destroyed$: Subject<void>
): Observable<T[]> {
  if (!filters || !filters.length) return entities$;

  console.log(filters.filter((filter: PlatformFilter<T>) => !!filter.cd$));

  const triggers = [
    entities$,
    ...filters.filter((filter: PlatformFilter<T>) => {
      console.log(filter);

      return !!filter.cd$;
    }).map((filter: PlatformFilter<T>) => filter.cd$)
  ];
  console.log(triggers);

}
