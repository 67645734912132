import { ApiRequestDefinition } from '@act/shared/api';
import {
  TermGroupDto,
  TermResponseDto
} from '@act/shared/data-transfer-objects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class ConsentRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getConsentsForUser = ApiRequestDefinition.createRequestDefinition<
    TermGroupDto[],
    null,
    { userId: string },
    null
  >('/consent', 'GET', '[Consent] Get User Consent', this.store, this.http);

  postConsentResponse = ApiRequestDefinition.createRequestDefinition<
    null,
    TermResponseDto[],
    { userId: string },
    null
  >('/consent', 'POST', '[Consent] Submit User Consent', this.store, this.http);
}
