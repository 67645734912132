import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  scheduledEventUpdated,
  scheduledEventDeleted,
  loadScheduledEvents,
  newScheduledEvent
} from './scheduled-event.actions';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
  ScheduledEventCreatedEvent,
  ScheduledEventDeletedEvent,
  ScheduledEventEventTypes,
  ScheduledEventUpdatedEvent
} from '@act/feed/events';
import { selectFeed } from '../feed/feed.actions';
import { feedQueries } from '../feed/feed.selectors';
import { ScheduledEventRequests } from './scheduled-event.requests';

@Injectable()
export class ScheduledEventEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private scheduledEventRequests: ScheduledEventRequests
  ) {}

  triggerScheduledEventsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectFeed),
      withLatestFrom(this.store.pipe(select(feedQueries.selectSelectedFeed))),
      map(([action, feed]) => {
        return loadScheduledEvents({
          params: { userId: feed.subjectId },
          requestBody: null
        });
      })
    )
  );

  getScheduledEvents$ = this.scheduledEventRequests.getScheduledEvents.createEffect(
    this.actions$
  );

  createScheduledEvent$ = this.scheduledEventRequests.postScheduledEvent.createEffect(
    this.actions$
  );

  updateScheduledEvent$ = this.scheduledEventRequests.patchScheduledEvent.createEffect(
    this.actions$
  );

  deleteScheduledEvent$ = this.scheduledEventRequests.deleteScheduledEvent.createEffect(
    this.actions$
  );

  snoozeScheduledEvent$ = this.scheduledEventRequests.snoozeScheduledEvent.createEffect(
    this.actions$
  );

  newScheduledEventItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduledEventEventTypes.SCHEDULED_EVENT_CREATED),
      map((action: ScheduledEventCreatedEvent) =>
        newScheduledEvent({ item: action.payload.scheduledEvent })
      )
    )
  );

  deletedScheduledEventItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduledEventEventTypes.SCHEDULED_EVENT_DELETED),
      map((action: ScheduledEventDeletedEvent) =>
        scheduledEventDeleted({
          params: {
            scheduledEventId: action.payload.scheduledEventId,
            userId: action.payload.userId
          },
          requestBody: null,
          responseBody: null
        })
      )
    )
  );

  updatedScheduledEventItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduledEventEventTypes.SCHEDULED_EVENT_UPDATED),
      map((action: ScheduledEventUpdatedEvent) =>
        scheduledEventUpdated({
          params: null,
          responseBody: action.payload.scheduledEvent,
          requestBody: null
        })
      )
    )
  );
}
