import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadVisitOutcomes, triggerVisitOutcomeLoad } from './visit.actions';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectFeed } from '../feed/feed.actions';
import {
  selectVisitOutcomesList,
  selectVisitOutcomesLoading
} from './visit.selectors';
import { VisitRequests } from './visit.requests';

@Injectable()
export class VisitEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private visitRequests: VisitRequests
  ) {}

  createVisit$ = this.visitRequests.postVisit.createEffect(this.actions$);

  updateVisit$ = this.visitRequests.patchVisit.createEffect(this.actions$);

  deleteVisit$ = this.visitRequests.deleteVisit.createEffect(this.actions$);

  getVisitOutcomes$ = this.visitRequests.getVisitOutcomes.createEffect(
    this.actions$
  );

  getVisits$ = this.visitRequests.getVisits.createEffect(this.actions$);

  createComment$ = this.visitRequests.createComment.createEffect(this.actions$);

  triggerVisitOutcomesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectFeed, triggerVisitOutcomeLoad),
      withLatestFrom(
        this.store.pipe(select(selectVisitOutcomesList)),
        this.store.pipe(select(selectVisitOutcomesLoading))
      ),
      mergeMap(([action, visitOutcomes, visitOutcomesLoading]) => {
        if (
          (!visitOutcomes || !visitOutcomes.length) &&
          !visitOutcomesLoading
        ) {
          return of(loadVisitOutcomes({ params: null, requestBody: null }));
        } else {
          return EMPTY; // HACK to return nothing
        }
      })
    )
  );
}
