import { Injectable } from '@angular/core';
import { Auth0Service } from './auth0.service';
import { Observable } from 'rxjs';
import { PersistenceService } from '@act/shared/persistence';
import { TOKEN } from '@act/shared/auth/models';

@Injectable()
export class AuthFacade {
  authenticated$: Observable<
    boolean
  > = this.auth0Service.isAuthenticated.asObservable();

  constructor(
    private auth0Service: Auth0Service,
    private persistenceService: PersistenceService
  ) {}

  login(): void {
    this.auth0Service.login();
  }

  logout(): void {
    this.auth0Service.logout();
  }

  getToken(): string {
    return this.persistenceService.get<string>(TOKEN);
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    if (!token) return false;

    return true;
  }
}
