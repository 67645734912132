export enum PORTS {
  // clients
  CLIENTS_GLOBAL = 5601,
  CLIENTS_CARE_PORTAL = 5670,

  // services
  API_GATEWAY = 5600,
  CALL_SERVICE = 5614,
  CHAT_SERVICE = 5603,
  CLIENT_SERVICE = 5610,
  CONFIG_SERVICE = 5999,
  ENROLLMENT_SERVICE = 5612,
  NOTES_SERVICE = 5613,
  SMS_SERVICE = 5604,
  CONSENT_SERVICE = 5611,
  SURVEY_SERVICE = 5609,
  USER_SERVICE = 5605,
  WEBSOCKET_SERVICE = 5608,
  FILE_SERVICE = 5615,
  NOTIFICATION_SERVICE = 5616,
  FEED_SERVICE = 5617,
  VISIT_SERVICE = 5618,
  CARE_SERVICE = 5619,
  BUNDLE = 5620,

  // infrastructure
  REDIS = 6379,
  MONGO = 27017,
  POSTGRES = 5432
}
