import { Action, createReducer, on, State } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Patient, PatientsState } from '@act/features/patients/models';
import * as PatientActions from './patients.actions';

export const adapter: EntityAdapter<Patient> = createEntityAdapter<Patient>();

export const initialState: PatientsState = adapter.getInitialState({});

const patientReducer = createReducer(
  initialState,

  on(PatientActions.addPatient, (state, action) =>
    adapter.addOne(action.patient, state)
  ),
  on(PatientActions.upsertPatient, (state, action) =>
    adapter.upsertOne(action.patient, state)
  ),
  on(PatientActions.addPatients, (state, action) =>
    adapter.addMany(action.patients, state)
  ),
  on(PatientActions.upsertPatients, (state, action) =>
    adapter.upsertMany(action.patients, state)
  ),
  on(PatientActions.updatePatient, (state, action) =>
    adapter.updateOne(action.patient, state)
  ),
  on(PatientActions.updatePatients, (state, action) =>
    adapter.updateMany(action.patients, state)
  ),
  on(PatientActions.deletePatient, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(PatientActions.deletePatients, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(PatientActions.clearPatients, state => adapter.removeAll(state))
);

export function reducer(state: PatientsState | undefined, action: Action) {
  return patientReducer(state, action);
}
