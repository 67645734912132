import { SelectDto } from 'libs/shared/ui/forms/src/lib/inputs/select/select.component';

export const durations: SelectDto[] = [
  {
    label: '5 m',
    value: 300
  },
  {
    label: '10 m',
    value: 600
  },
  {
    label: '15 m',
    value: 900
  },
  {
    label: '30 m',
    value: 1800
  },
  {
    label: '45 m',
    value: 2700
  },
  {
    label: '1 h',
    value: 3600
  },
  {
    label: '1 h, 15 m',
    value: 4500
  },
  {
    label: '1 h, 30 m',
    value: 5400
  },
  {
    label: '1 h, 45 m',
    value: 6300
  },
  {
    label: '2 h',
    value: 7200
  },
  {
    label: '2 h, 30 m',
    value: 9000
  },
  {
    label: '3 h',
    value: 10800
  },
  {
    label: '4 h',
    value: 14400
  },
  {
    label: '5 h',
    value: 18000
  },
  {
    label: '6 h',
    value: 21600
  }
];
