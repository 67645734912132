import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  PATIENT_FEATURE_REDUCER_TOKEN,
  getReducers
} from './+state/patients.reducers';
import { patientsFeatureKey } from '@act/features/patients/models';
import { EffectsModule } from '@ngrx/effects';
import { PatientsEffects } from './+state/patients/patients.effects';
import { PatientsFacade } from './+state/patients.facade';

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature(patientsFeatureKey, PATIENT_FEATURE_REDUCER_TOKEN),
    EffectsModule.forFeature([PatientsEffects])
  ],
  providers: [
    PatientsFacade,
    { provide: PATIENT_FEATURE_REDUCER_TOKEN, useFactory: getReducers }
  ]
})
export class FeaturesPatientsDataAccessModule {}
