import { Component, Input, OnInit } from '@angular/core';

type Icon =
  | 'call_incoming'
  | 'call_incoming_missed'
  | 'call_outgoing'
  | 'call_outgoing_missed'
  | 'call_failed'
  | 'text_incoming'
  | 'text_outgoing'
  | 'visit'
  | 'reminder'
  | 'note'
  | 'edit'
  | 'text'
  | 'snooze_reminder'
  | 'clear_reminder'
  | 'team_communication';

type IconConfig = {
  icon: string;
  fontSet?: 'material-icons-outlined';
  isSvg?: boolean;
  color?: string;
};

const iconLookup: {
  [key in Icon]: IconConfig;
} = {
  call_incoming: {
    icon: 'phone_inbound',
    isSvg: true,
    color: 'incoming'
  },
  call_incoming_missed: {
    icon: 'phone_missed',
    isSvg: true,
    color: 'incoming-missed'
  },
  call_outgoing: {
    icon: 'phone_outbound',
    isSvg: true,
    color: 'outgoing'
  },
  call_outgoing_missed: {
    icon: 'phone_missed',
    isSvg: true,
    color: 'outgoing-missed'
  },
  call_failed: {
    icon: 'phone_failed',
    isSvg: true,
    color: 'failure'
  },
  text_incoming: {
    icon: 'text_inbound',
    isSvg: true,
    color: 'incoming'
  },
  text_outgoing: {
    icon: 'text_outbound',
    isSvg: true,
    color: 'outgoing'
  },
  visit: {
    icon: 'domain',
    color: 'outgoing'
  },
  reminder: {
    icon: 'notifications',
    fontSet: 'material-icons-outlined',
    color: 'reminder'
  },
  note: {
    icon: 'assignment',
    fontSet: 'material-icons-outlined',
    color: 'outgoing'
  },
  edit: {
    icon: 'edit',
    fontSet: 'material-icons-outlined'
  },
  text: {
    icon: 'textsms',
    fontSet: 'material-icons-outlined'
  },
  snooze_reminder: {
    icon: 'notifications_paused',
    fontSet: 'material-icons-outlined'
  },
  clear_reminder: {
    icon: 'notifications_off',
    fontSet: 'material-icons-outlined'
  },
  team_communication: {
    icon: 'account_circle',
    color: 'team',
    fontSet: 'material-icons-outlined'
  }
};

@Component({
  selector: 'act-feed-card-icons',
  templateUrl: './feed-card-icons.component.html',
  styleUrls: ['./feed-card-icons.component.scss']
})
export class FeedCardIconsComponent implements OnInit {
  constructor() {}

  @Input() icon: Icon;
  @Input() iconColor: string;

  config: IconConfig;

  ngOnInit() {
    this.config = iconLookup[this.icon];
  }

  ngOnChange() {
    this.config = iconLookup[this.icon];
  }
}
