import { messageAdapter, messageFeatureKey, State } from './message.reducer';
import * as fromChat from '../chat.selectors';
import { createSelector } from '@ngrx/store';

const messageSelectors = messageAdapter.getSelectors();

const selectMessageState = createSelector(
  fromChat.selectChatState,
  (state): State => state[messageFeatureKey]
);

export const selectMessageEntities = createSelector(
  selectMessageState,
  messageSelectors.selectEntities
);

export const selectMessagesBeingCreated = createSelector(
  selectMessageState,
  (state: State): boolean => {
    return !!state.inflightCreationIds.length;
  }
);

export const selectMessageLoading = createSelector(
  selectMessageState,
  state => state.loading
);
