import {
  UserDto,
  VisitDto,
  VisitOutcomeDto
} from '@act/shared/data-transfer-objects';
import { Dictionary } from '@ngrx/entity';
import { FeedCardData } from '@act/shared/ui/feed-card';
import {
  getRoughDurationString,
  getShortName,
  getTimeString
} from '../helpers/format-data.helper';
import { outcomeRequirementDateCutoff } from '../calls/calls.card-helper';

export const getCardDataFromVisit = (
  visit: VisitDto,
  actingUser: UserDto,
  userMap: Dictionary<UserDto>,
  visitOutcomesMap: Dictionary<VisitOutcomeDto>
): FeedCardData => {
  if (!visit) {
    return null;
  }
  const subjectUser = userMap[visit.subjectUserId];

  let addressString = 'Unknown Location';
  let addressDescription = null;
  if (visit.addressId) {
    addressString = 'Unknown Address';

    if (subjectUser) {
      const a = subjectUser.addresses.find(
        a => a.id === Number(visit.addressId)
      );
      if (a) {
        addressString =
          a.line1 + (a.line2 ? ', ' + a.line2 : '') + `,  ${a.city} ${a.state}`;
        addressDescription = a.description;
      }
    }
  } else if (visit.location) {
    addressString = visit.location;
  }

  const outcome = visitOutcomesMap[visit.outcomeId];
  let outcomeString = 'Unknown';
  if (outcome) {
    outcomeString =
      outcome.key === 'other'
        ? visit.outcomeDetails || outcome.text
        : outcome.text;
  }

  const subjectName = getShortName(subjectUser) || 'member';
  const visitCreator = userMap[visit.createdBy];
  const visitorName =
    visit.createdBy === actingUser.id
      ? 'You'
      : getShortName(visitCreator) || 'Someone';
  const visitorString = visitorName + ' visited ' + subjectName;

  return {
    icon: 'visit',
    iconColor: 'outgoing',
    titleParts: [
      {
        text: 'Visit',
        bold: true
      },

      {
        text: 'at'
      },
      {
        text: addressString,
        details: addressDescription
      }
    ],

    metadataParts: [
      {
        text: getTimeString(visit.startTime)
      },
      visit.endTime && visit.startTime
        ? { text: getRoughDurationString(visit.endTime - visit.startTime) }
        : null
    ].filter(m => m),

    details: visitorString + ', ' + outcomeString,
    audio: null,
    note: visit.note,
    badges:
      !outcome && visit.startTime > outcomeRequirementDateCutoff
        ? [
            {
              text: 'Incomplete',
              color: 'red',
              details: 'No outcome for call'
            }
          ]
        : []
  };
};
