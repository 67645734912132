import { ApiRequestDefinition } from '@act/shared/api';
import {
  CallCreationRequestDto,
  CallDto,
  CallOutcomeDto,
  CallUpdateRequestDto,
  CallWithFeedItemDto,
  CommentDto
} from '@act/shared/data-transfer-objects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class CallRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  patchCall = ApiRequestDefinition.createRequestDefinition<
    CallDto,
    CallUpdateRequestDto,
    { callId: number },
    null
  >('/calls/:callId', 'PATCH', '[Call] Update Call', this.store, this.http);

  getCallOutcomes = ApiRequestDefinition.createRequestDefinition<
    CallOutcomeDto[],
    null,
    null,
    null
  >(
    '/calls/outcomes',
    'GET',
    '[Call] Get Call Outcomes',
    this.store,
    this.http
  );

  createCall = ApiRequestDefinition.createRequestDefinition<
    CallWithFeedItemDto,
    CallCreationRequestDto,
    null,
    { creationId: number }
  >('/calls', 'POST', '[Call] Create Call', this.store, this.http);

  updateCallNoFollowUpNeeded = ApiRequestDefinition.createRequestDefinition<
    CallDto,
    { noFollowUpNeeded: boolean },
    { callId: number },
    { creationId: number }
  >(
    '/calls/:callId/no_follow_up_needed',
    'POST',
    '[Call] Update Call No Follow Up',
    this.store,
    this.http
  );

  createComment = ApiRequestDefinition.createRequestDefinition<
    CommentDto,
    { text: string },
    { callId: number }
  >(
    '/calls/:callId/comments',
    'POST',
    '[Call] Create Comment',
    this.store,
    this.http
  );
}

export const requests = {};
