import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleMapperPipe } from './pipes/role-mapper.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [RoleMapperPipe],
  exports: [RoleMapperPipe]
})
export class CommonPipesRoleMapperModule {}
