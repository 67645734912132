export const mapObjects = <T>(
  arr: T[],
  idKey: string = 'id'
): { [key: string]: T } => {
  if (!arr) {
    return {};
  }
  return arr.reduce(
    (map, obj) => {
      map[obj[idKey]] = obj;
      return map;
    },
    <{ [key: string]: T }>{}
  );
};
