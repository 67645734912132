import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromUser from './users.reducer';
import { usersQueries } from './users.selectors';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import {
  AddressCreationRequestDto,
  PhoneNumberCreationRequestDto,
  UpdateAddressRequestDto,
  UpdatePhoneNumberRequestDto,
  UpdateUserRequestDto,
  UpdateUserSummaryRequestDto,
  MemberFiltersDto,
  UserDto,
  RaceEthnicityDto,
  UserRaceEthnicityDto,
  UpdateRaceEthnicityRequestDto
} from '@act/shared/data-transfer-objects';
import {
  createUserPhoneNumber,
  deleteUserPhoneNumber,
  updateUserPhoneNumber,
  updateUserSummary,
  createUserAddress,
  updateUserAddress,
  deleteUserAddress,
  loadUserDetails as loadUserDetailsAction,
  selectTeamMember,
  updateUser,
  updateUsers,
  addRemoveFilter,
  clearFilter,
  loadTodayResults,
  selectMember,
  loadTeam,
  loadAllTodayResults,
  loadMembers
} from './users.actions';
import { getValueOfObservable } from '@act/common/utilities';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { UsersRequests } from './users.requests';

const showConfettiSubject$ = new Subject<{ show: boolean }>();

export const showConfettiObservable$ = showConfettiSubject$.asObservable();

@Injectable()
export class UsersFacade {
  constructor(
    private store: Store<fromUser.State>,
    private usersRequests: UsersRequests
  ) {
    let lastSelectedMember: UserDto = null;

    combineLatest([
      this.store.pipe(
        select(usersQueries.selectSelectedMemberId),
        startWith(null)
      ),
      this.store.pipe(
        select(usersQueries.selectUserEntities),
        startWith(null)
      )
    ])
      .pipe(
        map(([selectedMemberId, userMap]) => {
          return selectedMemberId ? userMap[selectedMemberId] || null : null;
        }),
        distinctUntilChanged()
      )
      .subscribe(selectedMember => {
        const sameMember =
          selectedMember &&
          lastSelectedMember &&
          selectedMember.id === lastSelectedMember.id;
        if (sameMember) {
          const memberNotEngaged =
            selectedMember.memberStatus.key !==
              lastSelectedMember.memberStatus.key &&
            ['newly-engaged', 're-engaged'].includes(
              selectedMember.memberStatus.key
            );
          if (memberNotEngaged) {
            showConfettiSubject$.next({ show: true });
          }
        }
        lastSelectedMember = selectedMember;
      });
  }

  users$: Observable<UserDto[]> = this.store.pipe(
    select(usersQueries.selectAllUsers)
  );
  userMap$: Observable<Dictionary<UserDto>> = this.store.pipe(
    select(usersQueries.selectUserEntities)
  );

  team$: Observable<UserDto[]> = this.store.pipe(
    select(usersQueries.selectAllTeam)
  );
  selectedTeamMemberId$: Observable<string> = this.store.pipe(
    select(usersQueries.selectSelectedTeamMemberId)
  );
  members$: Observable<UserDto[]> = this.store.pipe(
    select(usersQueries.selectAllFilteredMembers)
  );
  membersLoading$: Observable<boolean> = this.store.pipe(
    select(usersQueries.selectMembersLoading)
  );
  selectedMemberId$: Observable<string> = this.store.pipe(
    select(usersQueries.selectSelectedMemberId)
  );
  selectedMemberFilters$: Observable<MemberFiltersDto> = this.store.pipe(
    select(usersQueries.selectMemberFilters)
  );

  todayResults$: Observable<fromUser.TodayTypes> = this.store.pipe(
    select(usersQueries.todayResults)
  );

  loadingToday$: Observable<boolean> = this.store.pipe(
    select(usersQueries.loadingToday)
  );

  updateMemberFilter(key: string, value: string | number, flag: string) {
    this.store.dispatch(addRemoveFilter({ key, value, flag }));
  }

  clearMemberFilter() {
    this.store.dispatch(clearFilter());
  }

  updateUserSummary(userId: string, request: UpdateUserSummaryRequestDto) {
    this.store.dispatch(
      updateUserSummary({ params: { userId }, requestBody: { ...request } })
    );
  }

  userDetailsLoading$(userId: string): Observable<boolean> {
    return this.store.pipe(
      select(usersQueries.selectUserDetailsLoading, userId)
    );
  }

  userDetailsLoadError$(userId: string): Observable<boolean> {
    return this.store.pipe(select(usersQueries.selectUserDetailsError, userId));
  }

  selectTeamMember(teamMemberId: string) {
    this.store.dispatch(selectTeamMember({ teamMemberId }));
  }

  selectMember(memberId: string) {
    this.store.dispatch(selectMember({ id: memberId }));
  }

  createPhoneNumber(userId: string, request: PhoneNumberCreationRequestDto) {
    this.store.dispatch(
      createUserPhoneNumber({ params: { userId }, requestBody: { ...request } })
    );
  }

  loadUserDetails(userId: string, request: PhoneNumberCreationRequestDto) {
    this.store.dispatch(
      loadUserDetailsAction({ params: { userId }, requestBody: null })
    );
  }

  updatePhoneNumber(
    userId: string,
    phoneNumberId: number,
    request: UpdatePhoneNumberRequestDto
  ) {
    this.store.dispatch(
      updateUserPhoneNumber({
        params: { userId, phoneNumberId },
        requestBody: { ...request }
      })
    );
  }
  deletePhoneNumber(userId: string, phoneNumberId: number) {
    this.store.dispatch(
      deleteUserPhoneNumber({
        params: { userId, phoneNumberId },
        requestBody: null
      })
    );
  }

  getTeam(): void {
    this.store.dispatch(loadTeam({ params: null, requestBody: null }));
  }

  getMembersForGuide(guideId: string): void {
    this.store.dispatch(
      loadMembers({
        params: { guideId },
        requestBody: null
      })
    );
  }

  createAddress(userId: string, request: AddressCreationRequestDto) {
    this.store.dispatch(
      createUserAddress({ params: { userId }, requestBody: { ...request } })
    );
  }
  updateAddress(
    userId: string,
    addressId: number,
    request: UpdateAddressRequestDto
  ) {
    this.store.dispatch(
      updateUserAddress({
        params: { userId, addressId },
        requestBody: { ...request }
      })
    );
  }
  deleteAddress(userId: string, addressId: number) {
    this.store.dispatch(
      deleteUserAddress({ params: { userId, addressId }, requestBody: null })
    );
  }

  getTodayItems() {
    const guideId = getValueOfObservable(this.selectedTeamMemberId$);
    this.store.dispatch(
      loadTodayResults({
        params: { guideId },
        requestBody: null,
        actionParams: { silent: false }
      })
    );
  }

  getAllTodayItems() {
    this.store.dispatch(
      loadAllTodayResults({
        requestBody: null,
        params: null,
        actionParams: { silent: false }
      })
    );
  }

  updateUser(userId: string, request: UpdateUserRequestDto) {
    this.store.dispatch(
      updateUser({
        params: { userId },
        requestBody: request
      })
    );
  }

  updateUsers(userIds: string[], request: UpdateUserRequestDto) {
    this.store.dispatch(
      updateUsers({
        params: { id: userIds },
        requestBody: request
      })
    );
  }

  loadAvailableRaceEthnicities(): Promise<RaceEthnicityDto[]> {
    return this.usersRequests.getAvailableRaceEthnicities.makeSimpleRequest();
  }

  loadUserRaceEthnicities(userId: string): Promise<UserRaceEthnicityDto[]> {
    return this.usersRequests.getUserRaceEthnicities.makeSimpleRequest({
      params: { userId }
    });
  }

  updateUserRaceEthnicities(
    userId: string,
    updates: UpdateRaceEthnicityRequestDto[]
  ): Promise<UserRaceEthnicityDto[]> {
    return this.usersRequests.updateUserRaceEthnicities.makeSimpleRequest({
      params: { userId },
      body: updates
    });
  }
}
