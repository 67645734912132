import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { take } from 'rxjs/operators';
import { ConfirmationMessages } from '../models/confirmation-messages.enum';

@Injectable()
export class ConfirmationDialog {
  constructor(private matDialog: MatDialog) {}

  open(
    message:
      | ConfirmationMessages
      | string = ConfirmationMessages.UNSAVED_CHANGES
  ): Observable<boolean> {
    const dialogResponse: Subject<boolean> = new Subject();

    this.matDialog
      .open(ConfirmationDialogComponent, {
        data: { message },
        panelClass: 'reema-modal'
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        dialogResponse.next(result);
        dialogResponse.complete();
      });

    return dialogResponse.asObservable();
  }
}
