import { MessageDto, UserDto } from '@act/shared/data-transfer-objects';
import { pickFirstCharWithDot } from '@act/common/utilities';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'act-undelivered',
  templateUrl: './undelivered.component.html',
  styleUrls: ['./undelivered.component.scss']
})
export class UndeliveredComponent implements OnInit {
  @Input() message: MessageDto;
  @Input() time: number;
  @Input() sendToUser: UserDto;
  @Input() fromUser: UserDto;
  textTitle: string;
  smsPhoneNumber: string;
  primaryPhoneText: string;

  constructor() {}

  ngOnInit() {
    this.checkSmsStatus();
  }

  checkSmsStatus() {
    this.textTitle = 'Text not Delivered';

    if (
      this.message.smsStatus &&
      this.message.smsStatus === 'delivery_unknown'
    ) {
      this.textTitle = 'Text Delivery Unknown';
    }

    if (this.message.smsPhoneNumber) {
      this.primaryPhoneText = ', Primary ';
      this.smsPhoneNumber = this.message.smsPhoneNumber;
    }
  }

  getUserName(user: UserDto): string {
    if (!user) {
      return 'Unknown User';
    }
    return user.firstName + ' ' + pickFirstCharWithDot(user.lastName);
  }
}
