import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { filters } from './feed-filter.data';

@Component({
  selector: 'act-feed-filters',
  templateUrl: './feed-filters.component.html',
  styleUrls: ['./feed-filters.component.scss']
})
export class FeedFiltersComponent implements OnInit, OnChanges {
  @Output() feedItemTypeEvent = new EventEmitter<string[]>();
  @Input() feedFilters: { typeFilters: string[] };
  @Input() loadingFeed: boolean;

  status: boolean = false;
  itemFilters = filters;
  feedItemTypes: string[] = [];

  constructor() {}

  ngOnInit() {
    this.feedItemTypes = (this.feedFilters || <any>{}).typeFilters || [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.feedFilters) {
      this.feedItemTypes = (this.feedFilters || <any>{}).typeFilters || [];
    }
  }

  showFilter() {
    this.status = !this.status;
  }

  onFilterChange(itemType: string) {
    // Don't change filters while loading feed
    if (!this.loadingFeed) {
      if (itemType) {
        if (this.feedItemTypes.includes(itemType)) {
          const filteredItems = this.feedItemTypes.filter(o => o !== itemType);
          this.feedItemTypeEvent.emit(filteredItems);
        } else {
          this.feedItemTypeEvent.emit([...this.feedItemTypes, itemType]);
        }
      } else {
        this.feedItemTypeEvent.emit([]);
      }
    }
  }
}
