import { UsersFacade } from '@act/features/users/data-access';
import {
  AddressDto,
  ContactInfoType,
  PhoneNumberDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { ActDialogService } from '@act/shared/ui/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'act-contact-info-editor[phoneNumbers][addresses]',
  templateUrl: './contact-info-editor.component.html',
  styleUrls: ['./contact-info-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactInfoEditorComponent implements OnInit {
  @Input() phoneNumbers: PhoneNumberDto[];
  @Input() addresses: AddressDto[];
  @Input() user: UserDto;

  editingForms: { [key: number]: FormGroup } = {};
  creatingNewPhoneNumber: boolean;
  creatingNewAddress: boolean;
  newPhoneNumberForm: FormGroup;
  newAddressForm: FormGroup;
  contactInfoType: ContactInfoType = {
    address: 'address',
    phoneNumber: 'phoneNumber'
  };

  constructor(
    private usersFacade: UsersFacade,
    private dialogService: ActDialogService
  ) {}

  ngOnInit() {}

  edit(value: AddressDto | PhoneNumberDto) {
    this.editingForms[value.id] = new FormGroup({
      description: new FormControl(value.description)
    });
  }

  cancelEdit(value: PhoneNumberDto | AddressDto) {
    this.editingForms[value.id] = null;
  }

  save(type: string, value: PhoneNumberDto | AddressDto) {
    const form = this.editingForms[value.id];
    const isAddress = type === this.contactInfoType.address;
    if (form) {
      // Update Address
      if (isAddress) {
        this.usersFacade.updateAddress(this.user.id, value.id, {
          primary: value.primary,
          description: form.get('description').value
        });
      } else {
        // Update Phone Number
        if (type === this.contactInfoType.phoneNumber) {
          this.usersFacade.updatePhoneNumber(this.user.id, value.id, {
            primary: value.primary,
            description: form.get('description').value
          });
        }
      }

      this.editingForms[value.id] = null;
    }
  }

  async delete(type: string, value: PhoneNumberDto | AddressDto) {
    const isAddress = type === this.contactInfoType.address;
    if (value.primary) {
      return this.dialogService.alert(
        `You cannot delete the primary ${
          isAddress ? 'address' : 'phone number'
        } .
        Make another phone number primary before removing this phone number.`
      );
    }
    const confirmation = await this.dialogService.confirm(
      `Are you sure you want to delete this ${
        isAddress ? 'address' : 'phone number'
      } for this user?`
    );
    if (confirmation) {
      if (isAddress) {
        this.usersFacade.deleteAddress(this.user.id, value.id);
      } else {
        this.usersFacade.deletePhoneNumber(this.user.id, value.id);
      }
    }
  }

  async makePrimary(type: string, value: PhoneNumberDto | AddressDto) {
    const isAddress = type === this.contactInfoType.address;
    const confirmationMessage = isAddress
      ? `Are you sure you want to make this address the user's primary address?`
      : `Are you sure you want to make this phone number the user's primary phone number?
      If yes, this number will be used for text messages and phone calls.`;

    const confirmation = await this.dialogService.confirm(confirmationMessage);
    if (confirmation) {
      if (isAddress) {
        this.usersFacade.updateAddress(this.user.id, value.id, {
          primary: true,
          description: value.description
        });
      } else {
        this.usersFacade.updatePhoneNumber(this.user.id, value.id, {
          primary: true,
          description: value.description
        });
      }
    }
  }

  startCreatingNewPhoneNumber() {
    this.creatingNewPhoneNumber = true;
    this.newPhoneNumberForm = new FormGroup({
      description: new FormControl(''),
      number: new FormControl('')
    });
  }

  startCreatingNewAddress() {
    this.creatingNewAddress = true;
    this.newAddressForm = new FormGroup({
      line1: new FormControl(''),
      line2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      description: new FormControl('')
    });
  }

  stopCreatingNewPhoneNumber() {
    this.creatingNewPhoneNumber = false;
    this.newPhoneNumberForm = null;
  }

  stopCreatingNewAddress() {
    this.creatingNewAddress = false;
    this.newAddressForm = null;
  }

  saveNewPhoneNumber() {
    if (this.newPhoneNumberForm) {
      this.usersFacade.createPhoneNumber(this.user.id, {
        number: this.newPhoneNumberForm.get('number').value,
        primary: false,
        description: this.newPhoneNumberForm.get('description').value
      });
      this.stopCreatingNewPhoneNumber();
    }
  }

  saveNewAddress() {
    if (this.newAddressForm) {
      this.usersFacade.createAddress(this.user.id, {
        line1: this.newAddressForm.get('line1').value,
        line2: this.newAddressForm.get('line2').value,
        city: this.newAddressForm.get('city').value,
        state: this.newAddressForm.get('state').value,
        zip: this.newAddressForm.get('zip').value,
        description: this.newAddressForm.get('description').value,
        primary: false
      });
      this.stopCreatingNewAddress();
    }
  }

  trackById(index: number, object: { id: number }) {
    return object.id;
  }
}
