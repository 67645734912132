import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  feedItemsFrameLoaded,
  clearFeed,
  deleteFeedItem,
  updateFeedItem,
  newFeedItem,
  loadFeedItemFrame,
  feedItemsFrameLoadError,
  setFeedItemFilters,
  resetFeedItemFilters
} from './feed-item.actions';
import {
  ExternalItemTypeDto,
  FeedItemDto
} from '@act/shared/data-transfer-objects';
import {
  commentCreated,
  noteCreated,
  noteDeleted,
  noteUpdated
} from '../note/note.actions';
import {
  visitCreated,
  visitDeleted,
  visitUpdated
} from '../visit/visit.actions';
import { callCreated, callUpdated } from '../call/call.actions';
import { messageCreated } from '../message/message.actions';

export const feedFeatureKey = 'feed-items';

interface FeedItemWindow {
  earliest: {
    id: number;
    timestamp: number;
    done: boolean;
  };
  latest: {
    id: number;
    timestamp: number;
    done: boolean;
  };
}

export interface State extends EntityState<FeedItemDto> {
  window: FeedItemWindow;
  loadingEarlier: boolean;
  loadingLater: boolean;
  loadingFeedItems: boolean;
  typeFilters: string[];
  firstWindowLoadedOnly: boolean; // is only the initial window loaded (and nothing else)
  lastEventItemId: number;
}

export const adapter: EntityAdapter<FeedItemDto> = createEntityAdapter<
  FeedItemDto
>({
  selectId: (a: FeedItemDto): number => a.id,
  sortComparer: (a: FeedItemDto, b: FeedItemDto) =>
    a.timestamp > b.timestamp ? 1 : -1
});

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

const getClearWindow = (): FeedItemWindow => ({
  earliest: {
    id: null,
    timestamp: null,
    done: false
  },
  latest: {
    id: null,
    timestamp: null,
    done: false
  }
});

export const initialState: State = adapter.getInitialState({
  window: getClearWindow(),
  loadingEarlier: false,
  loadingLater: false,
  loadingFeedItems: true,
  typeFilters: null,
  firstWindowLoadedOnly: false,
  lastEventItemId: null
});

const feedReducer = createReducer(
  initialState,
  on(loadFeedItemFrame, (state, action) => {
    let loadingEarlier = state.loadingEarlier;
    let loadingLater = state.loadingLater;
    let loadingFeedItems = state.loadingFeedItems;
    const isInitialLoad = !state.window.earliest.id;

    if (isInitialLoad) {
      loadingEarlier = true;
      loadingLater = true;
      loadingFeedItems = true;
    } else if (action.earlier) {
      loadingEarlier = true;
    } else {
      loadingFeedItems = true;
    }
    return {
      ...state,
      loadingEarlier,
      loadingLater,
      loadingFeedItems
    };
  }),
  on(feedItemsFrameLoaded, (state, action) => {
    const isInitialLoad = !state.window.earliest.id;
    let newState = adapter.upsertMany(action.items, state);
    let loadingEarlier = state.loadingEarlier;
    let loadingLater = state.loadingLater;
    let loadingFeedItems = state.loadingFeedItems;
    if (isInitialLoad) {
      loadingFeedItems = false;
      loadingEarlier = false;
      loadingLater = false;
    } else if (action.earlier) {
      loadingEarlier = false;
    } else {
      loadingFeedItems = false;
    }

    newState = updateDerivedData(newState);

    return {
      ...newState,
      loadingLater,
      loadingEarlier,
      window: getNewWindow(newState, action.earlier, action.isMore),
      loadingFeedItems,
      firstWindowLoadedOnly: isInitialLoad
    };
  }),
  on(feedItemsFrameLoadError, (state, action) => {
    let loadingEarlier = state.loadingEarlier;
    let loadingLater = state.loadingLater;
    let loadingFeedItems = state.loadingFeedItems;
    if (action.earlier) {
      loadingEarlier = false;
    } else {
      loadingLater = false;
      loadingFeedItems = false;
    }
    loadingFeedItems = false;

    return {
      ...state,
      loadingLater,
      loadingEarlier,
      loadingFeedItems
    };
  }),
  on(newFeedItem, (state, action) => {
    const newState = {
      ...adapter.upsertOne(action.item, state),
      firstWindowLoadedOnly: false
    };
    return updateDerivedData(newState);
  }),
  on(clearFeed, state => ({
    ...adapter.removeAll(state),
    window: getClearWindow(),
    firstWindowLoadedOnly: false
  })),
  on(deleteFeedItem, (state, action) =>
    adapter.removeOne(action.feedItemId, state)
  ),
  on(updateFeedItem, (state, action) => {
    // If item has moved outside of the window then remove the item - otherwise just update the item
    if (
      (!state.window.earliest.done &&
        action.payload.feedItem.timestamp < state.window.earliest.timestamp) ||
      (!state.window.latest.done &&
        action.payload.feedItem.timestamp > state.window.latest.timestamp)
    ) {
      return adapter.removeOne(action.payload.feedItem.id, state);
    }
    return adapter.upsertOne(action.payload.feedItem, state);
  }),
  on(setFeedItemFilters, (state, action) => ({
    ...state,
    typeFilters: action.typeFilters
  })),
  on(resetFeedItemFilters, (state, action) => ({
    ...state,
    typeFilters: []
  })),

  // Note Action Handlers
  on(noteCreated, (state, action) => {
    if (!action.responseBody.feedItem) return state;
    const newState = adapter.upsertOne(action.responseBody.feedItem, state);
    return updateDerivedData(newState);
  }),
  on(commentCreated, (state, action) => {
    const feedItem = getFeedItemByTypeAndId(
      'note',
      action.params.noteId,
      state
    );
    if (!feedItem) return state;
    const newState = adapter.upsertOne(
      {
        ...feedItem,
        note: {
          ...feedItem.note,
          comments: [...feedItem.note.comments, action.responseBody]
        }
      },
      state
    );
    return updateDerivedData(newState);
  }),
  on(noteUpdated, (state, action) => {
    const feedItem = getFeedItemByTypeAndId(
      'note',
      action.responseBody.id,
      state
    );
    if (!feedItem) return state;
    const newState = adapter.upsertOne(
      { ...feedItem, note: action.responseBody },
      state
    );
    return updateDerivedData(newState);
  }),
  on(noteDeleted, (state, action) => {
    const feedItem = getFeedItemByTypeAndId(
      'note',
      action.params.noteId,
      state
    );
    if (!feedItem) return state;
    const newState = adapter.removeOne(feedItem.id, state);
    return updateDerivedData(newState);
  }),

  // Visit Action Handlers
  on(visitCreated, (state, action) => {
    if (!action.responseBody.feedItem) return state;
    const newState = adapter.upsertOne(action.responseBody.feedItem, state);
    return updateDerivedData(newState);
  }),
  on(visitUpdated, (state, action) => {
    const feedItem = getFeedItemByTypeAndId(
      'visit',
      action.responseBody.id,
      state
    );
    if (!feedItem) return state;
    const newState = adapter.upsertOne(
      { ...feedItem, visit: action.responseBody },
      state
    );
    return updateDerivedData(newState);
  }),
  on(visitDeleted, (state, action) => {
    const feedItem = getFeedItemByTypeAndId(
      'visit',
      action.params.visitId,
      state
    );
    if (!feedItem) return state;
    const newState = adapter.removeOne(feedItem.id, state);
    return updateDerivedData(newState);
  }),

  // Call Action Handlers
  on(callCreated, (state, action) => {
    if (!action.responseBody.feedItem) return state;
    const newState = adapter.upsertOne(action.responseBody.feedItem, state);
    return updateDerivedData(newState);
  }),
  on(callUpdated, (state, action) => {
    const feedItem = getFeedItemByTypeAndId(
      'call',
      action.responseBody.id,
      state
    );
    if (!feedItem) return state;
    const newState = adapter.upsertOne(
      { ...feedItem, call: action.responseBody },
      state
    );
    return updateDerivedData(newState);
  }),

  // Message Action Handlers
  on(messageCreated, (state, action) => {
    if (!action.message.feedItem) return state;
    const newState = adapter.upsertOne(action.message.feedItem, state);
    return updateDerivedData(newState);
  })
);

const updateDerivedData = (state: State): State => {
  const allItems = selectAll(state);
  let lastEventItemId: number = null;
  for (var i = allItems.length; i > 0; i--) {
    if (
      ['visit', 'call', 'message'].includes(allItems[i - 1].externalItemType)
    ) {
      lastEventItemId = allItems[i - 1].id;
      break;
    }
  }
  if (state.lastEventItemId === lastEventItemId) {
    return state;
  }
  return {
    ...state,
    lastEventItemId
  };
};

const getFeedItemByTypeAndId = (
  externalType: ExternalItemTypeDto,
  externalItemId: number,
  state: State
): FeedItemDto => {
  for (var i = 0; i < state.ids.length; i++) {
    const entity = state.entities[state.ids[i]];
    if (
      entity.externalItemId === externalItemId &&
      entity.externalItemType === externalType
    ) {
      return entity;
    }
  }
  return null;
};

const getNewWindow = (
  state: State,
  earlier: boolean,
  isMore: boolean
): FeedItemWindow => {
  let window: FeedItemWindow;
  if (!state.ids.length) {
    // Nothing was loaded
    window = getClearWindow();
  } else {
    const firstItem = state.entities[state.ids[0]];
    const lastItem = state.entities[state.ids[state.ids.length - 1]];
    window = {
      earliest: {
        id: firstItem.id,
        timestamp: firstItem.timestamp,
        done: earlier ? !isMore : state.window.earliest.done
      },
      latest: {
        id: lastItem.id,
        timestamp: lastItem.timestamp,
        done: !earlier ? !isMore : state.window.latest.done
      }
    };
  }

  // if earlier == null that means it is the first load that starts at the latest
  if (earlier === null) {
    window.latest.done = true;
    window.earliest.done = !isMore;
  }

  return window;
};

export function reducer(state: State | undefined, action: Action) {
  return feedReducer(state, action);
}

export const feedAdapter = adapter;
