import {
  scheduledEventAdapter,
  scheduledEventFeatureKey,
  State
} from './scheduled-event.reducer';
import * as fromChat from '../chat.selectors';
import { createSelector } from '@ngrx/store';
import { patientsQueries } from 'libs/features/patients/data-access/src/lib/+state/patients/patients.selectors';
import { usersQueries } from '@act/features/users/data-access';

const scheduledEventSelectors = scheduledEventAdapter.getSelectors();

const selectScheduledEventState = createSelector(
  fromChat.selectChatState,
  (state): State => state[scheduledEventFeatureKey]
);

export const selectScheduledEventEntities = createSelector(
  selectScheduledEventState,
  scheduledEventSelectors.selectEntities
);

export const selectScheduledEventMap = createSelector(
  selectScheduledEventState,
  state => state && state.entities
);

export const selectScheduledEvent = createSelector(
  usersQueries.selectSelectedMemberId,
  selectScheduledEventEntities,
  (selectedMemberId, state) => {
    const entities = Object.values(state);
    return entities.filter(item => item.userId === selectedMemberId);
  }
);

export const selectLoading = createSelector(
  selectScheduledEventState,
  state => state.loadingScheduledEvents
);
