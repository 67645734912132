import { ApiRequestDefinition } from '@act/shared/api';
import {
  CreateUserNeedRequestDto,
  NeedCategoryDto,
  UpdateUserNeedRequestDto,
  UpdateUserNeedSortOrderDto,
  UserNeedDto
} from '@act/shared/data-transfer-objects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class NeedRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getUserNeeds = ApiRequestDefinition.createRequestDefinition<
    UserNeedDto[],
    null,
    { userId: string },
    null
  >('/care/user-needs', 'GET', '[Needs] Get User Needs', this.store, this.http);

  deleteUserNeed = ApiRequestDefinition.createRequestDefinition<
    null,
    null,
    { userNeedId: number },
    null
  >(
    '/care/user-needs/:userNeedId',
    'DELETE',
    '[Needs] Delete User Need',
    this.store,
    this.http
  );

  patchUserNeed = ApiRequestDefinition.createRequestDefinition<
    UserNeedDto,
    UpdateUserNeedRequestDto,
    { userNeedId: number },
    null
  >(
    '/care/user-needs/:userNeedId',
    'PATCH',
    '[Needs] Update User Need',
    this.store,
    this.http
  );

  postUserNeed = ApiRequestDefinition.createRequestDefinition<
    UserNeedDto,
    CreateUserNeedRequestDto,
    null,
    { creationId: number }
  >(
    '/care/user-needs',
    'POST',
    '[Needs] Create User Need',
    this.store,
    this.http
  );

  getNeedCategories = ApiRequestDefinition.createRequestDefinition<
    NeedCategoryDto[],
    null,
    null,
    null
  >(
    '/care/need-categories',
    'GET',
    '[Needs] Get Need Categories',
    this.store,
    this.http
  );

  updateUserNeedOrder = ApiRequestDefinition.createRequestDefinition<
    UserNeedDto[],
    UpdateUserNeedSortOrderDto,
    null
  >(
    '/care/user-needs',
    'PATCH',
    '[Needs] Update User Needs Order',
    this.store,
    this.http
  );
}
