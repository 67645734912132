import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) {}

  open(message: string, action: string, config: MatSnackBarConfig = {}) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      panelClass: ['clear-snackbar'],
      ...config
    });
  }
}
