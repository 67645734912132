import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CLIENT_CONFIG } from '@act/shared/environment';
import { ClientBootstrap } from '@act/shared/models';
import { ActingUserDto } from '@act/shared/data-transfer-objects';

@Injectable()
export class AuthService {
  constructor(
    @Inject(CLIENT_CONFIG) private config: ClientBootstrap,
    private http: HttpClient
  ) {}

  verify(): Promise<ActingUserDto> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.config.reema.serviceUris.userService}/user/verify`, {
          observe: 'response'
        })
        .subscribe(
          (res: HttpResponse<any>) => {
            const user: ActingUserDto = res.body.user;
            const currentApp = this.config.reema.app;
            const userCanUseApp = !!user.apps.find(a => a === currentApp);
            if (!userCanUseApp) {
              return reject(
                new Error(
                  `User does not have access to app ${this.config.reema.app}`
                )
              );
            }
            return resolve(user);
          },
          (error: any) => reject(error)
        );
    });
  }
}
