import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextAreaComponent } from './inputs/text-area/text-area.component';
import { DateInputComponent } from './inputs/date-input/date-input.component';
import { TimeInputComponent } from './inputs/time-input/time-input.component';
import { TextInputComponent } from './inputs/text-input/text-input.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormRowComponent } from './structure/form-row/form-row.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FormViewOnlyComponent } from './structure/form-view-only/form-view-only.component';
import { PhoneNumberInputComponent } from './inputs/phone-number-input/phone-number-input.component';
import { NgxMaskModule } from 'ngx-mask';
import { SelectComponent } from './inputs/select/select.component';
import { RichTextInputComponent } from './inputs/rich-text-input/rich-text-input.component';
import { QuillModule } from 'ngx-quill';
import { CommonUtilitiesAutosaveModule } from '@act/common/utilities/autosave';
import { ButtonComponent } from './button/button.component';
import {
  MatButtonModule,
  MatTooltipModule,
  MatAutocompleteModule
} from '@angular/material';
const exportedComponents = [
  TextInputComponent,
  TextAreaComponent,
  SelectComponent,
  DateInputComponent,
  TimeInputComponent,
  FormRowComponent,
  FormViewOnlyComponent,
  PhoneNumberInputComponent,
  RichTextInputComponent,
  ButtonComponent
];

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSelectModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        mentions: true,
        toolbar: false
      }
    }),
    CommonUtilitiesAutosaveModule,
    MatTooltipModule,
    MatButtonModule,
    MatAutocompleteModule
  ],
  declarations: [...exportedComponents],
  exports: [...exportedComponents]
})
export class SharedUiFormsModule {}
