import { Renderer, Cluster } from '@googlemaps/markerclusterer';
import { MapEventsService } from './map-events.service';
import {
  baseUrl,
  circleTemplate,
  CircleTemplateVariablesDto,
  MarkerData
} from './map.dto';
import { compile as compileHandleBars } from 'handlebars';

export class MapRenderer implements Renderer {
  constructor(private mapEventsService: MapEventsService) {}

  render({ count, position, markers }: Cluster) {
    const markersData = [];
    const colors = new Map<string, number>();
    markers.forEach(marker => {
      const data = JSON.parse(marker.get('data')) as MarkerData;
      const count = colors.get(data.cohortColor) || 0;
      colors.set(data.cohortColor, count + 1);
      markersData.push(data);
    });

    const svg = this.createSvg(colors, markers.length);

    const marker = this.createMarker(position, svg, count);

    this.attachEventListeners(marker, markersData);

    return marker;
  }

  /**
   * Create svg for clustered marker icon
   */
  private createSvg(colors: Map<string, number>, totalCount: number): string {
    const circles = [];
    const radius = 75;
    const totalDash = Math.PI * 2 * radius;
    let remainingDash = totalDash;

    colors.forEach((count: number, color: string) => {
      const template = compileHandleBars<CircleTemplateVariablesDto>(
        circleTemplate
      );
      const templateVariables = { color, remainingDash, totalDash, radius };
      const circle = template({ ...templateVariables });
      remainingDash -= (count / totalCount) * totalDash;
      circles.push(circle);
    });

    return btoa(`
      <svg width="300px" height="300px" xmlns="http://www.w3.org/2000/svg" style="border-radius: 50%; fill: none">
        ${circles.join('\n')}
      </svg>`);
  }

  /**
   * Creates clustered marker
   */
  private createMarker(
    position: google.maps.LatLng,
    svg: string,
    count: number
  ): google.maps.Marker {
    return new google.maps.Marker({
      position,
      icon: {
        url: `${baseUrl}${svg}`,
        scaledSize: new google.maps.Size(50, 50)
      },
      label: {
        text: String(count),
        color: '#0a2127',
        fontSize: '12px',
        fontWeight: '600'
      },
      zIndex: 1000 + count
    });
  }

  /**
   * Attach event listeners to clustered marker
   */
  private attachEventListeners(
    marker: google.maps.Marker,
    markersData: MarkerData[]
  ) {
    marker.addListener('click', () => {
      this.mapEventsService.markerClickHandler(markersData, marker);
    });

    marker.addListener('mouseover', () => {
      this.mapEventsService.mouseOverHandler(markersData, marker);
    });

    marker.addListener('mouseout', () => {
      this.mapEventsService.mouseOutHandler();
    });
  }
}
