import {
  AddressDto,
  ScheduledEventDto,
  UserDto,
  VisitDto,
  VisitOutcomeDto
} from '@act/shared/data-transfer-objects';
import { DatePipe } from '@angular/common';
import {
  Component,
  ComponentRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VisitFacade } from '../../+state/visit/visit.facade';
import { getValueOfObservable } from '@act/common/utilities';
import { Platform } from '@act/core/platform';
import { durations } from './durations-data';
import * as moment from 'moment';
import { ActDialogService } from '@act/shared/ui/dialog';
import { Dictionary } from '@ngrx/entity';
import { DebounceAutoSaver } from '@act/common/utilities/autosave';
import { NoteFacade } from '../../+state/note/note.facade';
import { SelectDto } from 'libs/shared/ui/forms/src/lib/inputs/select/select.component';
import { RichTextInputComponent } from 'libs/shared/ui/forms/src/lib/inputs/rich-text-input/rich-text-input.component';
import { AnchoredDialogComponent } from 'libs/shared/ui/dialog/src/lib/components/anchored-dialog/anchored-dialog.component';
import { AnchoredDialogService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog.service';
import { map } from 'rxjs/operators';

const timeFormat = 'hh:mm A';
const dateFormat = 'DD-MM-YYYY';

@Component({
  selector: 'act-visit-dialog',
  templateUrl: './visit-dialog.component.html',
  styleUrls: ['./visit-dialog.component.scss']
})
export class VisitDialogComponent implements OnInit {
  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;
  anchoredDialogRef: ComponentRef<AnchoredDialogComponent>;

  @ViewChild(RichTextInputComponent, null)
  private noteInput: RichTextInputComponent;

  @Input() memberId: string;
  @Input() scheduledEvent: ScheduledEventDto;
  @Input() visit: VisitDto;
  @Input() userMap: Dictionary<UserDto>;

  @Input() addresses: AddressDto[];

  durationOptions = durations;
  dateOptions: SelectDto[];
  timeOptions: SelectDto[];

  locationOptions: SelectDto[] = [];
  editVisit = false;
  selectedDuration = 900;
  pipe = new DatePipe('en-US');
  visitForm = new FormGroup({
    visitDate: new FormControl('', Validators.required),
    visitStartTime: new FormControl('', Validators.required),
    visitDuration: new FormControl('', Validators.required),
    visitLocation: new FormControl('', Validators.required),
    visitOutcome: new FormControl(''),
    visitOtherLocation: new FormControl({ value: '', disabled: true })
  });
  commentControl: FormControl = new FormControl('', Validators.required);
  visitNote = new FormControl();
  saving = false;

  quilModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }]
    ],
    clipboard: {
      matchVisual: false
    }
  };

  constructor(
    public visitFacade: VisitFacade,
    private platform: Platform,
    private dialogService: ActDialogService,
    private noteFacade: NoteFacade,
    private anchoredDialogService: AnchoredDialogService
  ) {}

  isNew: boolean;
  userId: string;
  visitId: number;
  showComments: boolean = false;

  outcomes$ = this.visitFacade.visitOutcomes$;
  outcomeOptions$ = this.outcomes$.pipe(
    map(outcomes => outcomes.map(o => ({ value: o.id, label: o.text })))
  );

  open(): ComponentRef<AnchoredDialogComponent> {
    const user = this.userMap ? this.userMap[this.memberId || ''] : null;
    const name = user ? user.firstName + ' ' + user.lastName : null;
    this.anchoredDialogRef = this.anchoredDialogService.create({
      icon: 'domain',
      title:
        (this.visit ? 'Edit Visit' : 'New Visit') +
        (name ? ' for ' + name : ''),
      ref: this.dialogRef,
      actionType: this.visit ? 'edit' : 'create',
      eventType: 'visit',
      eventId: this.visit ? this.visit.id : this.memberId,
      isLoading$: this.visitFacade.visitLoading$
    });

    return this.anchoredDialogRef;
  }

  async ngOnInit() {
    this.showComments = !!getValueOfObservable(this.platform.user$).featureFlags
      .INTERACTION_COMMENT;
    if (this.addresses) {
      // If addresses are already set then ngOnInit has already ran
      return;
    }
    const member = this.userMap[this.memberId];
    this.addresses = member && member.addresses;

    if (!(getValueOfObservable(this.outcomes$) || []).length) {
      this.visitFacade.loadVisitOutcomes();
    }

    this.userId = getValueOfObservable(this.platform.user$).id;
    this.dateOptions = this.getLastNDates();
    this.timeOptions = this.getTimes();

    this.memberId = this.memberId;
    this.userMap = this.userMap;
    this.setDefaultLocation();
    this.setDefaultDate();
    this.setDefaultTime();

    if (this.visit) {
      this.visitId = this.visit.id;
      this.editVisit = true;
      this.visitNote.setValue(this.visit.note);
      setTimeout(() => {
        const selection = this.noteInput.editor.quillEditor.getSelection();
        if (selection) {
          const text = this.visit.note;
          this.noteInput.editor.quillEditor.setSelection(
            selection.index + text.length,
            0
          );
        }
      }, 300);

      if (this.visit.outcomeId) {
        this.visitForm.controls['visitOutcome'].setValue(this.visit.outcomeId);
      }
    } else {
      const note = await this.noteFacade.autoSave.visit.get(this.memberId);
      this.visitNote.setValue(note);
    }
  }

  autoSaver = new DebounceAutoSaver(this.visitNote.valueChanges, n =>
    this.autoSaveVisitNote(n)
  );

  ngOnDestroy() {
    this.autoSaver.destroy();
  }

  onLocationChange(location: string) {
    this.visitForm.controls['visitOtherLocation'].disable();
    this.visitForm.controls['visitOtherLocation'].clearValidators();

    if (!location) {
      this.visitForm.controls['visitOtherLocation'].enable();
      this.visitForm.controls['visitOtherLocation'].setValidators([
        Validators.required
      ]);
    }
    this.visitForm.controls['visitOtherLocation'].updateValueAndValidity();
  }

  handleCommentChange(commentControl: FormControl) {
    this.commentControl = commentControl;
  }

  trackItem(index: number, item: VisitOutcomeDto): number {
    return item.id;
  }

  async onSubmit() {
    let outcomeId = null;
    let outcomeDetails = null;
    let selectedOutcome: VisitOutcomeDto;

    if (this.visitForm.value.visitOutcome) {
      outcomeId = this.visitForm.value.visitOutcome;
      const outcomes = getValueOfObservable(this.outcomes$);
      selectedOutcome = outcomes.find(i => i.id === outcomeId);
      outcomeDetails = selectedOutcome.text;
    }

    // should we save the visit?
    let save = true;
    const user = this.userMap[this.memberId];
    if (
      user &&
      user.memberStage.key === 'outreach' &&
      selectedOutcome &&
      selectedOutcome.key === 'engaged'
    ) {
      const confirmed = await this.dialogService.confirm(
        `<p>Saving visit with outcome "${selectedOutcome.text}" will move ${user.name} to be In Program.</p>
        <p>Are you sure ${user.name} meets all of our requirements for them to be considered "Engaged"?</p>
        <ul>
          <li>Member has a positive disposition about Reema’s services</li>
          <li>Member does not express hesitancy around legitimacy after being introduced to Guide, given business card, and being directed to the website</li>
          <li>Guide has an established next step with member</li>
          <li>Member understands expectation around at least monthly contact</li>
        </ul>`,
        { yes: 'Yes, save and mark as engaged', no: 'No', messageIsHtml: true }
      );
      save = confirmed;
    }

    if (!save) {
      return;
    }

    const startTime = moment(
      this.visitForm.value.visitDate +
        ' ' +
        this.visitForm.value.visitStartTime,
      dateFormat + ' ' + timeFormat
    ).valueOf();
    const endTime = startTime + this.visitForm.value.visitDuration * 1000;

    let addressId = this.visitForm.value.visitLocation;
    let otherLocation = null;

    if (addressId < 1) {
      otherLocation = this.visitForm.value.visitOtherLocation;
      addressId = null;
    }

    this.saving = true;

    if (!this.editVisit) {
      await this.visitFacade.createVisitForUser({
        subjectUserId: this.memberId,
        location: otherLocation,
        addressId: addressId,
        startTime: startTime,
        endTime: endTime,
        description: null,
        note: this.visitNote.value,
        outcomeId: outcomeId,
        outcomeDetails: outcomeDetails,
        scheduledEventId: this.scheduledEvent ? this.scheduledEvent.id : null
      });

      // To close the modal once the creation is done
      this.saving = false;
      this.anchoredDialogRef.instance.closeModal();

      await this.noteFacade.autoSave.visit.clear(this.memberId);
    } else {
      await this.visitFacade.updateVisit(this.visitId, {
        location: otherLocation,
        addressId: addressId,
        startTime: startTime,
        endTime: endTime,
        description: null,
        note: this.visitNote.value,
        outcomeId: outcomeId,
        outcomeDetails: outcomeDetails,
        visitId: this.visitId
      });

      this.saving = false;
      this.anchoredDialogRef.instance.closeModal();
    }
  }

  async autoSaveVisitNote(note: string) {
    if (this.editVisit) {
      this.visitFacade.autoSaveVisitNote(this.visitId, note);
    } else {
      this.noteFacade.autoSave.visit.update(this.memberId, note);
    }
  }

  getTimes() {
    const minInterval = 15;
    let times = [];
    let startTime = 0;
    const ap = ['AM', 'PM'];

    // loop to increment the time and push results in array
    for (var i = 0; startTime < 24 * 60; i++) {
      let hh = Math.floor(startTime / 60);
      hh = hh % 12 === 0 ? 12 : hh % 12;
      const mm = startTime % 60;
      const el = startTime > 719 ? 1 : 0;
      times[i] =
        ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) + ' ' + ap[el];
      startTime = startTime + minInterval;
    }
    return times.map(o => {
      return { value: o, label: o };
    });
  }

  getLastNDates() {
    let result = [];
    for (var i = 0; i < 10; i++) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - i);
      let dateValue = this.pipe.transform(currentDate, 'dd-MM-yyyy');
      let dateInFormat = this.pipe.transform(currentDate, 'EEEE, MMM dd, yyyy');

      if (i === 0) {
        dateInFormat = `Today (${this.pipe.transform(currentDate, 'M/d')})`;
      } else if (i === 1) {
        dateInFormat = `Yesterday (${this.pipe.transform(currentDate, 'M/d')})`;
      }
      result.push({ value: dateValue, label: dateInFormat });
    }
    return result;
  }

  setDefaultLocation() {
    const userAddresses = this.addresses;

    if (userAddresses.length) {
      this.locationOptions = [];
      for (var k = 0; k < userAddresses.length; k++) {
        this.locationOptions.push({
          value: userAddresses[k].id,
          label: `${userAddresses[k].line1} ${userAddresses[k].line2}, ${userAddresses[k].city} ${userAddresses[k].state}`
        });
      }

      this.locationOptions.push({ value: 0, label: 'Other' });
      const primaryAddress = userAddresses.find(i => i.primary);
      let defaultAddressId = primaryAddress.id;

      if (this.scheduledEvent) {
        const defaultAddress = this.scheduledEvent.visitLocation;
        if (typeof defaultAddress === 'object' && defaultAddress !== null) {
          defaultAddressId = defaultAddress.id;
        } else {
          defaultAddressId = 0;
          this.setOtherLocation(this.scheduledEvent.visitLocation as string);
        }
      }

      if (this.visit) {
        defaultAddressId = this.visit.addressId;

        if (defaultAddressId) {
          defaultAddressId = Number(defaultAddressId);
        } else {
          defaultAddressId = 0;
          this.setOtherLocation(this.visit.location);
        }
      }

      // set default location
      this.visitForm.controls['visitLocation'].setValue(defaultAddressId);
    }
  }

  setOtherLocation(addressValue: string) {
    this.visitForm.controls['visitOtherLocation'].enable();
    this.visitForm.controls['visitOtherLocation'].setValidators([
      Validators.required
    ]);
    this.visitForm.controls['visitOtherLocation'].updateValueAndValidity();
    setTimeout(
      () =>
        this.visitForm.controls['visitOtherLocation'].setValue(addressValue),
      300
    );
  }

  setDefaultDate() {
    let defaultDate = this.pipe.transform(new Date().getTime(), 'dd-MM-yyyy');
    let defaultDuration = this.selectedDuration;

    if (this.visit) {
      defaultDuration = this.visit.endTime - this.visit.startTime;
      defaultDuration = defaultDuration / 1000;
    }

    // set default date
    this.visitForm.controls['visitDate'].setValue(defaultDate);

    // set default duration
    this.visitForm.controls['visitDuration'].setValue(defaultDuration);
  }

  setDefaultTime() {
    let time = new Date().getTime();

    if (this.visit) {
      time = this.visit.startTime;
    }
    const currentTime = this.pipe.transform(time, 'hh:mm aaa');
    const minutes = parseInt(this.pipe.transform(time, 'm'));
    const remain = minutes % 15;
    let defaultTime = currentTime;

    if (remain > 0) {
      let defMinutes: any = minutes - remain;
      defMinutes = defMinutes < 10 ? `0${defMinutes}` : defMinutes;
      const hours = this.pipe.transform(time, 'hh');
      const meridian = this.pipe.transform(time, 'aaa');
      defaultTime = `${hours}:${defMinutes} ${meridian}`;
    }

    // set default start time
    this.visitForm.controls['visitStartTime'].setValue(defaultTime);
  }

  removeWhiteSpace() {
    const visitOtherLocationValue = this.visitForm.controls[
      'visitOtherLocation'
    ].value;
    this.visitForm.controls['visitOtherLocation'].setValue(
      visitOtherLocationValue.replace(/^\s+/g, '')
    );
  }

  async addComment() {
    if (this.visit.id && this.commentControl.value) {
      await this.visitFacade.createComment(
        this.visit.id,
        this.commentControl.value
      );
      this.anchoredDialogRef.instance.closeModal();
    }
  }
}
