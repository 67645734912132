import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { memberStagesFeatureKey, reducer } from './+state/member-stage.reducer';
import { MemberStageEffects } from './+state/member-stage.effects';
import { MemberStageFacade } from './+state/member-stage.facade';
import { MemberStageRequests } from './+state/member-stage.requests';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(memberStagesFeatureKey, reducer),

    EffectsModule.forFeature([MemberStageEffects])
  ],
  providers: [MemberStageFacade, MemberStageRequests]
})
export class FeaturesMemberStageDataAccessModule {}
