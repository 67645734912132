import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JoinRoomTypes, RoomJoined, RoomJoinedPayload } from '@act/feed/events';
import { map, concatMap } from 'rxjs/operators';
import { feedsUpdated, loadFeed, updateFeeds } from './feed.actions';
import { newFeedItem } from '../feed-item/feed-item.actions';
import { FeedRequests } from './feed.requests';

@Injectable()
export class FeedEffects {
  feedJoined$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinRoomTypes.ROOM_JOINED),
      map((action: RoomJoined) => action.payload),
      concatMap((payload: RoomJoinedPayload) => [
        updateFeeds({
          feeds: payload.feeds
        })
      ])
    )
  );

  feedsUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFeeds),
      map(() => feedsUpdated())
    )
  );

  loadFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newFeedItem),
      map(action => {
        return loadFeed({
          params: { feedId: action.item.feedId },
          requestBody: null
        });
      })
    )
  );

  getFeed$ = this.feedRequests.getFeed.createEffect(this.actions$);

  updateLastSeen$ = this.feedRequests.updateLastSeen.createEffect(
    this.actions$
  );

  constructor(private actions$: Actions, private feedRequests: FeedRequests) {}
}
