import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FeedFacade } from '@act/feed';
import { Observable, Subscription } from 'rxjs';
import {
  MemberStageDto,
  TermGroupDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { ConsentFacade } from '@act/features/consent/data-access';
import { Platform } from '@act/core/platform';
import { MatDialogRef } from '@angular/material/dialog';
import { MemberStageFacade } from '@act/features/member-stage/data-access';
import { UsersFacade } from '@act/features/users/data-access';
import { UserNeedFacade } from 'libs/feed/src/lib/+state/need/need.facade';
import { getValueOfObservable } from '@act/common/utilities';
import { maximumVisibleDialogSubject$ } from 'libs/shared/ui/dialog/src/lib/models/dialog-options.model';
import { BreakpointService, ScreenSize } from './breakpoint.service';

@Component({
  selector: 'act-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() hideLeftPanel: boolean;
  userDetailsLoading$: Observable<boolean>;
  userDetailsLoadError$: Observable<boolean>;
  userConsentGroups$: Observable<TermGroupDto[]>;
  selectedMember: UserDto;

  screenSize: ScreenSize;
  sizes = ScreenSize;

  viewMembersList: boolean = false;
  canHideMembersList: boolean = false;
  canToggleProfile: boolean = false;
  dialogRef: MatDialogRef<any>;

  userMap$ = this.usersFacade.userMap$;
  memberStages$ = this.memberStageFacade.memberStages$;
  memberStages: MemberStageDto[];

  breakpointSubscriptions: Subscription[] = [];

  private setSize(size: ScreenSize) {
    this.screenSize = size;
  }

  constructor(
    public usersFacade: UsersFacade,
    public feedFacade: FeedFacade,
    public userNeedFacade: UserNeedFacade,
    public platform: Platform,
    private memberStageFacade: MemberStageFacade,
    private consentFacade: ConsentFacade,
    private breakpointService: BreakpointService
  ) {
    this.breakpointService.getBreakPoints().forEach(breakpoint => {
      const breakpointSubscription = breakpoint.subscribe(({ state, size }) => {
        if (state.matches) {
          this.setSize(size);
          this.setOpenValues();
        }
      });
      this.breakpointSubscriptions.push(breakpointSubscription);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setOpenValues();
  }

  selectedMemberIdSubscription: Subscription;
  ngOnInit() {
    this.setOpenValues();

    this.selectedMemberIdSubscription = this.usersFacade.selectedMemberId$.subscribe(
      memberId => {
        this.setOpenValues();
        this.userDetailsLoading$ = this.usersFacade.userDetailsLoading$(
          memberId
        );
        this.userDetailsLoadError$ = this.usersFacade.userDetailsLoadError$(
          memberId
        );
        this.userConsentGroups$ = this.consentFacade.selectConsentForUser$(
          memberId
        );
      }
    );
  }

  ngOnDestroy() {
    if (this.breakpointSubscriptions && this.breakpointSubscriptions.length) {
      this.breakpointSubscriptions.forEach(e => e.unsubscribe());
    }
    if (this.selectedMemberIdSubscription) {
      this.selectedMemberIdSubscription.unsubscribe();
    }
  }

  viewingProfile: boolean;
  viewProfile(viewingProfile: boolean) {
    this.viewingProfile = viewingProfile;
    this.setOpenValues();
  }

  backToMemberList() {
    if (this.viewingProfile) {
      this.viewProfile(false);
    } else {
      this.usersFacade.selectMember(null);
    }
  }

  profileClosing() {
    // If the profile was closed by clicking out of the profile sidenav than mark profile as no longer opened
    if (this.canToggleProfile && this.profileIsOpen) {
      this.viewProfile(false);
    }
  }

  membersListIsOpen: boolean;
  profileIsOpen: boolean;

  setOpenValues() {
    this.membersListIsOpen = this.shouldMembersListBeOpen();
    this.profileIsOpen = this.shouldProfileBeOpen();
    maximumVisibleDialogSubject$.next(this.getMaximumVisibleDialogCount());
  }

  private getMaximumVisibleDialogCount() {
    if (this.screenSize === ScreenSize.ExtraLarge) {
      return 3;
    } else if (this.screenSize === ScreenSize.Large) {
      return 2;
    }
    return 1;
  }

  private shouldMembersListBeOpen(): boolean {
    let isOpen = !this.canHideMembersList || this.viewMembersList;
    if (
      (this.screenSize === ScreenSize.Small ||
        this.screenSize === ScreenSize.Medium) &&
      this.viewingProfile
    ) {
      return false;
    }

    const selectedMemberId = getValueOfObservable(
      this.usersFacade.selectedMemberId$
    );
    if (!selectedMemberId) {
      return true;
    }

    if (this.screenSize === ScreenSize.ExtraSmall && !this.viewMembersList) {
      return false;
    }

    return isOpen;
  }

  private shouldProfileBeOpen(): boolean {
    this.canToggleProfile =
      this.screenSize !== ScreenSize.Large &&
      this.screenSize !== ScreenSize.ExtraLarge;

    const selectedMemberId = getValueOfObservable(
      this.usersFacade.selectedMemberId$
    );
    if (!selectedMemberId) {
      return false;
    }
    return (
      (this.viewingProfile || !this.canToggleProfile) && !this.viewMembersList
    );
  }

  onSelectMember(memberId: string) {
    this.usersFacade.selectMember(memberId);
  }
}
