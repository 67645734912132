export function removeHtmlTags(input: string): string {
  if (!input) return '';

  const plainText = input.replace(/<[^>]*>/g, '');
  return plainText;
}

const newLine = ['p', 'li', 'div'];
const endOfFirstLineRegex = new RegExp(
  '(' +
    newLine.map(t => '</\\s*' + t + '\\s*>|<\\s*' + t + '\\s*/>').join('|') +
    ').*$'
);

export const firstLineOfHtml = str => {
  const noHtml = removeHtmlTags(str);
  const firstLine = str
    .replace(/\n/g, '') // remove new lines
    .replace(endOfFirstLineRegex, '') // remove everything after the first line (html fist line)
    .replace(/<[^>]*>/g, ''); // remove html tags

  if (noHtml === firstLine) {
    return firstLine;
  }
  return firstLine + '...';
};
