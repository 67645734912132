import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CarePortalAppRootComponent } from './care-portal-app-root/care-portal-app-root.component';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { CarePortalComponent } from './care-portal/care-portal.component';
import { CommonRouterModule } from '@act/common/router';
import { CarePortalRoutingModule } from './care-portal.routing.module';
import {
  AUTH0_CONFIG,
  SharedAuthClientsModule
} from '@act/shared/auth/clients';
import { SharedUiHeaderModule } from '@act/shared/ui/header';
import { MembersComponent } from './members/members.component';
import { ListComponent } from './members/list/list.component';
import { ListHeaderComponent } from './members/list-header/list-header.component';
import { ListFilterComponent } from './members/list-filter/list-filter.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { CheckboxComponent } from './members/list-filter/checkbox.component';
import { DefaultCheckboxComponent } from './members/list-filter/default-checkbox.component';
import { CheckboxGroupComponent } from './members/list-filter/checkbox-group-component';
import { Auth0SharedClientsConfig } from '@act/shared/auth/models';
import { FeaturesUserFeatureV1Module } from '@act/features/user/feature-v1';
import { CallsModule } from '@act/calls';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { FeaturesPatientsFeatureV1Module } from '@act/features/patients/feature-v1';
import { MemberEffects } from './+state/member.effects';
import { getClientConfig } from '@act/shared/environment';
import { ClientBootstrap } from '@act/shared/models';
import { CommonDisplayEnvironmentModule } from '@act/common/display-environment';
import { CommonModule } from '@angular/common';
import { AppEffects } from './+state/app.effects';
import { BootstrapService } from './services/bootstrap.service';
import { FeaturesUsersFeatureV1Module } from '@act/features/users/feature-v1';
import { FeaturesTodosDataAccessModule } from '@act/features/todos/data-access';
import { FeaturesTodosUiModule } from '@act/features/todos/ui';
import { CommonDialogsConfirmationModule } from '@act/common/dialogs/confirmation';
import { FeaturesMemberStageDataAccessModule } from '@act/features/member-stage/data-access';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule, MatRadioModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SharedTeammateModule } from '@act/shared/teammate';
import { EditCohortDialogComponent } from './members/edit-cohort-dialog/edit-cohort-dialog.component';
import { FeedModule } from '@act/feed';
import { FeaturesProfileUiModule } from '@act/features/profile/ui';
import { ListLoadingComponent } from './members/list-loading/list-loading.component';
import { SharedUiSpinnerModule } from '@act/shared/ui/spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterComponent } from './router/router.component';
import { extModules } from './build-specifics';
import { SharedTextTemplateModule } from '@act/shared/text-template';
import { SharedUiFormsModule } from '@act/shared/ui/forms';
import { SharedUiDialogModule } from '@act/shared/ui/dialog';
import { MembersMapComponent } from './members/members-map/members-map.component';
import { MapService } from './services/map.service';
import { MembersInfoWindowComponent } from './members/members-info-window/members-info-window.component';
import { MapEventsService } from './services/map-events.service';

const auth0ConfigFactory = (): Auth0SharedClientsConfig => {
  const config: ClientBootstrap = getClientConfig();
  return {
    domain: config.auth0.issuer,
    client_id: config.auth0.clientId,
    redirect_uri: config.auth0.redirectUri,
    audience: config.auth0.audience,
    postLoginRedirect: '/members',
    postLogoutRedirect: '/'
  };
};

const initFactory = (bootstrapService: BootstrapService) => () =>
  bootstrapService.bootstrap();

@NgModule()
export class EmptyModule {}

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    MatChipsModule,
    MatTableModule,
    MatSortModule,
    FeedModule,
    FeaturesProfileUiModule,

    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    MomentModule,
    StoreModule.forRoot({}),
    extModules,
    EffectsModule.forRoot([AppEffects, MemberEffects]),

    // features
    FeaturesUserFeatureV1Module,
    FeaturesPatientsFeatureV1Module,
    FeaturesUsersFeatureV1Module,
    FeaturesMemberStageDataAccessModule,
    FeaturesTodosDataAccessModule,
    FeaturesTodosUiModule,

    CallsModule,

    // shared deps
    CommonRouterModule,
    CommonDisplayEnvironmentModule,
    CommonDialogsConfirmationModule,
    CarePortalRoutingModule,
    SharedAuthClientsModule,
    SharedUiHeaderModule,
    SharedTeammateModule,
    SharedUiSpinnerModule,
    SharedTextTemplateModule,
    SharedUiFormsModule,
    SharedUiDialogModule
  ],
  declarations: [
    CarePortalAppRootComponent,
    CarePortalComponent,
    AppointmentsComponent,
    MembersComponent,
    ListHeaderComponent,
    ListComponent,
    ListFilterComponent,
    CheckboxGroupComponent,
    CheckboxComponent,
    DefaultCheckboxComponent,
    EditCohortDialogComponent,
    ListLoadingComponent,
    RouterComponent,
    MembersMapComponent,
    MembersInfoWindowComponent
  ],
  exports: [CarePortalComponent],
  providers: [
    BootstrapService,
    MapService,
    MapEventsService,
    { provide: AUTH0_CONFIG, useFactory: auth0ConfigFactory },
    {
      provide: APP_INITIALIZER,
      useFactory: initFactory,
      deps: [BootstrapService],
      multi: true
    }
  ],
  entryComponents: [EditCohortDialogComponent, MembersInfoWindowComponent]
})
export class CarePortalModule {}
