import {
  CallDto,
  CallOutcomeDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { CallStatus } from '@act/shared/models';
import { Component, Input, OnChanges } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { CallFacade } from '../+state/call/call.facade';
import { FileFacade } from '@act/features/files/data-access';
import { FeedActionsService } from '../feed-actions/feed-actions.service';
import { getCardDataFromCall } from './calls.card-helper';
import { FeedCardData, FeedCardAction } from '@act/shared/ui/feed-card';
import { AdditionalFeedCardActions } from 'libs/shared/ui/feed-card/src/lib/feed-card-content/feed-card-content.component';

@Component({
  selector: 'act-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.scss']
})
export class CallsComponent implements OnChanges {
  CallStatus = CallStatus;

  constructor(
    public fileFacade: FileFacade,
    public callFacade: CallFacade,
    private feedActionsService: FeedActionsService
  ) {}

  @Input() call: CallDto;
  @Input() userMap: Dictionary<UserDto>;
  @Input() isLastEventItem: boolean;
  @Input() callOutcomesMap: Dictionary<CallOutcomeDto>;

  cardData: FeedCardData;
  actions: FeedCardAction[];
  additionalActions: AdditionalFeedCardActions[];

  ngOnChanges() {
    this.setCardData();
  }

  setCardData() {
    this.cardData = getCardDataFromCall(
      this.call,
      this.userMap,
      this.callOutcomesMap,
      fileId => this.fileFacade.getContentUrl(fileId)
    );

    this.actions = [
      this.isLastEventItem
        ? {
            icon: 'call_outgoing',
            title: 'Call Now',
            details: 'Call back',
            handler: () => this.viewPhoneNumbers()
          }
        : null,
      {
        icon: 'edit',
        title: 'Edit',
        details: 'Edit Call',
        handler: () => this.openEditCallNote()
      }
    ].filter(a => a);

    this.additionalActions = [];
    if (
      this.isLastEventItem &&
      this.call.incoming &&
      this.call.status === CallStatus.MISSED &&
      !this.call.noFollowUpNeeded
    ) {
      this.additionalActions = [
        {
          title: 'No Follow-up Needed',
          details: 'We do not need to call user back',
          handler: () => this.noFollowUpNeeded()
        }
      ];
    }
  }

  noFollowUpNeeded() {
    this.callFacade.updateCallNoFollowUpNeeded(this.call.id, true);
  }

  /**
   * Manage call notes in the modal
   */
  openEditCallNote() {
    this.feedActionsService.editCall(this.call);
  }

  viewPhoneNumbers() {
    this.feedActionsService.createCall(this.call.subjectId);
  }
}
