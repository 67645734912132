import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { HtmlContentViewerComponent } from './html-content-viewer/html-content-viewer.component';
import { HtmlContentEditorComponent } from './html-content-editor/html-content-editor.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      modules: {
        mentions: true,
        toolbar: false
      }
    })
  ],
  declarations: [HtmlContentViewerComponent, HtmlContentEditorComponent],
  exports: [HtmlContentViewerComponent, HtmlContentEditorComponent]
})
export class SharedUiHtmlModule {}
