import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { HeaderConfig } from '@act/shared/ui/header';
import { RouterFacade } from '@act/common/router';
import { map } from 'rxjs/operators';
import { AuthFacade } from '@act/shared/auth/clients';
import { Observable, Subscription } from 'rxjs';
import { UserFacade } from '@act/features/user/data-access';
import { Platform } from '@act/core/platform';
import { DisplayEnvironment } from '@act/shared/models';
import { ChatFacade } from '@act/feed';
import { PatientsFacade } from '@act/features/patients/data-access';
import { Dispatcher } from '@act/common/dispatcher';
import { LoadPatientsEvent } from '@act/features/patients/events';
import { showConfettiObservable$ } from 'libs/features/users/data-access/src/lib/+state/users/users.facade';
import { apiErrorObservable$ } from '@act/shared/api';
import { SnackbarService } from '@act/common/snackbar';
import { TodoFacade } from '@act/features/todos/data-access';

const mapComponent = (url: string) =>
  map((activeRoute: string) => activeRoute === url);

@Component({
  selector: 'act-care-portal-app-root',
  templateUrl: './care-portal-app-root.component.html',
  styleUrls: ['./care-portal-app-root.component.scss']
})
export class CarePortalAppRootComponent implements OnInit, OnDestroy {
  @Input() page: string;
  @Output() navigate: EventEmitter<string> = new EventEmitter();
  patientsSubscription: Subscription;
  chatSubscription: Subscription;
  confettiSubscription: Subscription;
  errorSubscription: Subscription;

  mobile$: Observable<boolean> = Platform.displayEnvironment.pipe(
    map((de: DisplayEnvironment) => de.window.width < 650)
  );

  headerConfig: HeaderConfig = {
    logo: {
      src: '/assets/identity/reema_logo_white.svg',
      callback: () => this.router.navigate(['/messages'])
    },
    items: [
      {
        label: 'Today',
        routerLink: 'today'
      },
      {
        label: 'My Members',
        routerLink: 'member-list'
      },
      {
        label: 'Calls',
        routerLink: 'calls'
      }
    ],
    profile: {
      user$: this.userFacade.user$,
      logOut: () => this.authFacade.logout()
    }
  };

  constructor(
    private router: RouterFacade,
    private authFacade: AuthFacade,
    private userFacade: UserFacade,
    private chatFacade: ChatFacade,
    private patientsFacade: PatientsFacade,
    private todoFacade: TodoFacade,
    private snackBarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.patientsSubscription = this.patientsFacade.onInitialized$.subscribe(
      (dispatcher: Dispatcher) => {
        dispatcher.send(new LoadPatientsEvent());
      }
    );

    this.confettiSubscription = showConfettiObservable$.subscribe(
      ({ show }) => {
        if (show) {
          this.fire(0.25, {
            spread: 26,
            startVelocity: 55
          });
          this.fire(0.2, {
            spread: 60
          });
          this.fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
          });
          this.fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
          });
          this.fire(0.1, {
            spread: 120,
            startVelocity: 45
          });
        }
      }
    );

    this.chatSubscription = this.chatFacade.onInitialized$.subscribe(() => {
      this.chatFacade.joinRooms();
    });

    this.errorSubscription = apiErrorObservable$.subscribe(action => {
      if (action.responseBody.error.statusCode === 401) {
        this.authFacade.logout();
      } else {
        this.snackBarService.open(`An error has occurred`, 'Close', {
          duration: 5000
        });
      }
    });

    this.chatFacade.connect();
    this.patientsFacade.connect();
    this.todoFacade.connect();
  }

  fire(particleRatio, opts) {
    const count = 200;
    const defaults = {
      origin: { y: 0.7 }
    };
    this.confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      })
    );
  }

  confetti(args: any) {
    return window['confetti'].apply(this, arguments);
  }

  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  ngOnDestroy(): void {
    this.patientsSubscription.unsubscribe();
    this.chatSubscription.unsubscribe();
    this.confettiSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }
}
