import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateDisplayEnvironmentDirective } from './directives/update-display-environment.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [UpdateDisplayEnvironmentDirective],
  exports: [UpdateDisplayEnvironmentDirective]
})
export class CommonDisplayEnvironmentModule {}
