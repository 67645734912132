import { ApiRequestDefinition } from '@act/shared/api';
import {
  CohortDto,
  CreateCohortDto,
  UpdateCohortDto
} from '@act/shared/data-transfer-objects';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class CohortRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getCohorts = ApiRequestDefinition.createRequestDefinition<
    CohortDto[],
    null,
    { ownerUserId: string },
    null
  >('/users/cohorts', 'GET', '[Cohorts] Get Cohorts', this.store, this.http);

  patchCohort = ApiRequestDefinition.createRequestDefinition<
    CohortDto,
    UpdateCohortDto,
    { cohortId: number },
    null
  >(
    '/users/cohorts/:cohortId',
    'PATCH',
    '[Cohorts] Update Cohort',
    this.store,
    this.http
  );

  createCohort = ApiRequestDefinition.createRequestDefinition<
    CohortDto,
    CreateCohortDto,
    null,
    { creationId: number }
  >('/users/cohorts', 'POST', '[Cohorts] Create Cohort', this.store, this.http);
}
export const requests = {};
