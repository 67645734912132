import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TodoEffects } from './+state/todo/todo.effects';
import { reducer } from './+state/todo/todo.reducer';
import { TodoFacade } from './+state/todo/todo.facade';
import { TodoRequests } from './+state/todo/todo.requests';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('todos', reducer),
    EffectsModule.forFeature([TodoEffects])
  ],
  providers: [TodoFacade, TodoRequests]
})
export class FeaturesTodosDataAccessModule {}
