import { getValueOfObservable } from '@act/common/utilities';
import { UsersFacade } from '@act/features/users/data-access';
import {
  CallDto,
  NoteDto,
  ScheduledEventDto,
  VisitDto
} from '@act/shared/data-transfer-objects';
import {
  Injectable,
  Injector,
  ApplicationRef,
  ComponentFactoryResolver
} from '@angular/core';
import { AnchoredDialogActionsService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog-actions.service';
import { AnchoredDialogService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog.service';
import { ScheduledEventFacade } from '../+state/scheduled-event/scheduled-event.facade';
import { EditCallNotesDialogComponent } from '../calls/edit-call-notes-dialog/edit-call-notes-dialog.component';
import { PhoneDialogComponent } from '../dialog/phone-dialog/phone-dialog.component';
import { ScheduledDialogComponent } from '../dialog/scheduled-dialog/scheduled-dialog.component';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { NoteDialogComponent } from '../notes/note-dialog/note-dialog.component';
import { VisitDialogComponent } from '../visits/visit-dialog/visit-dialog.component';

@Injectable()
export class FeedActionsService extends AnchoredDialogActionsService {
  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    appRef: ApplicationRef,
    injector: Injector,
    private usersFacade: UsersFacade,
    private scheduledEventFacade: ScheduledEventFacade,
    private anchoredDialogService: AnchoredDialogService
  ) {
    super(componentFactoryResolver, appRef, injector);
  }

  editCall(call: CallDto) {
    const id = this.getId('edit', 'call', call.id);
    if (!this.anchoredDialogService.isOpen(id)) {
      this.open(EditCallNotesDialogComponent, {
        call,
        userMap: getValueOfObservable(this.usersFacade.userMap$)
      });
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }

  createCall(
    userId: string,
    scheduledEventId: number = null,
    onDone: () => any = null
  ) {
    const id = this.getId('create', 'call', userId);
    if (!this.anchoredDialogService.isOpen(id)) {
      const userMap = getValueOfObservable(this.usersFacade.userMap$);
      this.open(
        PhoneDialogComponent,
        {
          user: userMap[userId],
          scheduledEventId
        },
        onDone
      );
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }

  createMessage(
    userId: string,
    scheduledEventId: number = null,
    onDone: () => any = null
  ) {
    const id = this.getId('create', 'text', userId);
    if (!this.anchoredDialogService.isOpen(id)) {
      const userMap = getValueOfObservable(this.usersFacade.userMap$);
      this.open(
        MessageDialogComponent,
        {
          user: userMap[userId],
          scheduledEventId
        },
        onDone
      );
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }

  editNote(note: NoteDto, onDone: () => any = null) {
    const id = this.getId('edit', 'note', note.id);
    const userMap = getValueOfObservable(this.usersFacade.userMap$);
    if (!this.anchoredDialogService.isOpen(id)) {
      this.open(
        NoteDialogComponent,
        {
          note,
          memberId: note.userId,
          userMap
        },
        onDone
      );
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }

  createNote(userId: string, onDone: () => any = null) {
    const id = this.getId('create', 'note', userId);
    if (!this.anchoredDialogService.isOpen(id)) {
      const userMap = getValueOfObservable(this.usersFacade.userMap$);
      this.open(
        NoteDialogComponent,
        {
          memberId: userId,
          userMap
        },
        onDone
      );
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }

  createVisit(
    userId: string,
    scheduledEventId: number,
    onDone: () => any = null
  ) {
    const id = this.getId('create', 'visit', userId);
    if (!this.anchoredDialogService.isOpen(id)) {
      const userMap = getValueOfObservable(this.usersFacade.userMap$);
      const scheduledEventMap = getValueOfObservable(
        this.scheduledEventFacade.scheduledEventsMap$
      );
      this.open(
        VisitDialogComponent,
        {
          memberId: userId,
          scheduledEvent: scheduledEventMap[scheduledEventId || ''],
          userMap
        },
        onDone
      );
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }

  editVisit(visit: VisitDto, onDone: () => any = null) {
    const id = this.getId('edit', 'visit', visit.id);
    if (!this.anchoredDialogService.isOpen(id)) {
      const userMap = getValueOfObservable(this.usersFacade.userMap$);
      this.open(
        VisitDialogComponent,
        {
          visit,
          memberId: visit.subjectUserId,
          userMap
        },
        onDone
      );
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }

  createScheduledEvent(userId: string, onDone: () => any = null) {
    const id = this.getId('create', 'reminder', userId);
    if (!this.anchoredDialogService.isOpen(id)) {
      const userMap = getValueOfObservable(this.usersFacade.userMap$);
      this.open(
        ScheduledDialogComponent,
        {
          memberId: userId,
          userMap
        },
        onDone
      );
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }

  editScheduledEvent(
    scheduledEvent: ScheduledEventDto,
    onDone: () => any = null
  ) {
    const id = this.getId('edit', 'reminder', scheduledEvent.id);
    if (!this.anchoredDialogService.isOpen(id)) {
      const userMap = getValueOfObservable(this.usersFacade.userMap$);
      this.open(
        ScheduledDialogComponent,
        {
          memberId: scheduledEvent.userId,
          event: scheduledEvent,
          userMap
        },
        onDone
      );
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }
}
