import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { SharedAuthClientsModule } from '@act/shared/auth/clients';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FeaturesFilesDataAccessModule } from '@act/features/files/data-access';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { DragDropDirective } from './components/drop-file-upload/drop-file-upload.directive';
import { FileUploaderTriggerDirective } from './components/file-uploader-trigger/file-uploader-trigger.directive';
import { FileComponent } from './components/file/file.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FileThumbnailService } from './services/file-thumbnail.service';

const EXPORTED_COMPONENTS = [
  FileViewerComponent,
  FileUploaderComponent,
  FileUploaderTriggerDirective
];

@NgModule({
  imports: [
    CommonModule,
    SharedAuthClientsModule,
    FeaturesFilesDataAccessModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule
  ],
  declarations: [...EXPORTED_COMPONENTS, DragDropDirective, FileComponent],
  providers: [FileThumbnailService],
  exports: [...EXPORTED_COMPONENTS]
})
export class FeaturesFilesUiModule {}
