import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { AnchoredDialogService } from '../../services/anchored-dialog.service';

@Component({
  selector: 'act-anchored-modal-layout',
  templateUrl: './anchored-modal-layout.component.html',
  styleUrls: ['./anchored-modal-layout.component.scss']
})
export class AnchoredModalLayoutComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  containerRef: ViewContainerRef;
  constructor(private anchoredDialogService: AnchoredDialogService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.anchoredDialogService.setContainerRef(this.containerRef);
  }

  ngOnDestroy() {
    this.anchoredDialogService.destroyAllComponents();
  }
}
