import { SelectDto } from 'libs/shared/ui/forms/src/lib/inputs/select/select.component';

export const filters: SelectDto[] = [
  { label: 'All', value: '' },
  { label: 'Calls', value: 'call' },
  { label: 'Messages', value: 'message' },
  { label: 'Visits', value: 'visit' },
  { label: 'Notes', value: 'note' },
  { label: 'Reminders', value: 'reminder' }
];
