import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ScreenSize {
  ExtraLarge = 'xlarge',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  ExtraSmall = 'xsmall'
}

export interface BreakpointDto {
  state: BreakpointState;
  size: ScreenSize;
}

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  screenSize: ScreenSize;
  sizes = ScreenSize;
  private breakpoints$: Observable<BreakpointDto>[] = [];

  constructor(breakpointObserver: BreakpointObserver) {
    const sizes = {
      XLarge: ScreenSize.ExtraLarge,
      Large: ScreenSize.Large,
      Medium: ScreenSize.Medium,
      '(min-width: 650px) and (max-width: 959.99px)': ScreenSize.Small,
      '(max-width: 649.99px)': ScreenSize.ExtraSmall
    };

    Object.entries(sizes).forEach(([key, value]) => {
      const breakpoint$ = breakpointObserver
        .observe([Breakpoints[key] || key])
        .pipe(
          map(breakpointState => ({
            state: breakpointState,
            size: value
          }))
        );
      this.breakpoints$.push(breakpoint$);
      // .subscribe(({ state, screenSize }) => {
      //   if (state.matches) {
      //     console.log(screenSize);
      //     // this.setSize(value);
      //     // this.setOpenValues();
      //   }
      // });
      // this.breakpointSubscriptions.push(breakpointSubscription);
    });
  }

  getBreakPoints(): Observable<BreakpointDto>[] {
    return this.breakpoints$;
  }
}
