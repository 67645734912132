import { MessageDto, UserDto } from '@act/shared/data-transfer-objects';
import { Dictionary } from '@ngrx/entity';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ChatFacade } from '../+state/chat.facade';
import { Subscription } from 'rxjs';
import { FeedActionsService } from '../feed-actions/feed-actions.service';
import { FeedCardAction, FeedCardData } from '@act/shared/ui/feed-card';
import { getCardDataFromMessage } from './messages.card-helper';
import { AdditionalFeedCardActions } from 'libs/shared/ui/feed-card/src/lib/feed-card-content/feed-card-content.component';

@Component({
  selector: 'act-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() message: MessageDto;
  @Input() userMap: Dictionary<UserDto>;
  @Input() isLastEventItem: boolean;

  cardData: FeedCardData;
  actions: FeedCardAction[];
  additionalActions: AdditionalFeedCardActions[];
  fileIds: string[] = [];

  updateMessageNoFollowUpNeededSubscription: Subscription;

  ngOnChanges() {
    this.setCardData();
  }

  constructor(
    private readonly chatFacade: ChatFacade,
    private feedActionsService: FeedActionsService
  ) {}

  ngOnInit() {
    this.fileIds = this.message.fileIds || [];
  }

  ngOnDestroy() {
    if (this.updateMessageNoFollowUpNeededSubscription) {
      this.updateMessageNoFollowUpNeededSubscription.unsubscribe();
    }
  }

  setCardData() {
    this.cardData = getCardDataFromMessage(this.message, this.userMap);

    this.actions = [
      this.isLastEventItem
        ? {
            icon: 'text_outgoing',
            title: 'Text Now',
            details: 'Text back',
            handler: () => this.createMessage()
          }
        : null
    ].filter(a => a);

    this.additionalActions = [];
    if (
      this.isLastEventItem &&
      this.message.userId === this.message.subjectId &&
      !this.message.noFollowUpNeeded
    ) {
      this.additionalActions = [
        {
          title: 'No Follow-up Needed',
          details: 'We do not need to text user back',
          handler: () => this.updateNoFollowUp()
        }
      ];
    }
  }

  onFileLoad() {
    this.chatFacade.scrollToBottom();
  }

  resendMessage() {
    this.chatFacade.resendMessage(this.message);
  }

  /**
   * @description Update no follow up needed
   */
  updateNoFollowUp() {
    if (this.message.id) {
      this.updateMessageNoFollowUpNeededSubscription = this.chatFacade
        .updateMessageNoFollowUpNeeded(this.message.id, true)
        .subscribe(message => {
          this.message = message;
        });
    }
  }

  createMessage() {
    this.feedActionsService.createMessage(this.message.subjectId);
  }
}
