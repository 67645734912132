import { UsersFacade } from '@act/features/users/data-access';
import { UserDto } from '@act/shared/data-transfer-objects';
import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MapService } from '../../services/map.service';

@Component({
  selector: 'act-members-map',
  templateUrl: './members-map.component.html',
  styleUrls: ['./members-map.component.scss']
})
export class MembersMapComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public usersFacade: UsersFacade,
    private mapService: MapService
  ) {}

  @ViewChild('map', { static: false }) mapRef: ElementRef;

  membersSubscription: Subscription;
  guideMembers: UserDto[];

  ngOnInit() {}

  async ngAfterViewInit() {
    await this.mapService.createMap(this.mapRef);
    this.membersSubscription = this.usersFacade.members$.subscribe(members => {
      this.guideMembers = members;
      this.mapService.setMarkers(this.guideMembers);
    });
  }

  ngOnDestroy() {
    this.membersSubscription.unsubscribe();
  }
}
