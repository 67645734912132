import { feedAdapter, feedFeatureKey, State } from './feed-item.reducer';
import * as fromChat from '../chat.selectors';
import { createSelector } from '@ngrx/store';
import { feedQueries } from '../feed/feed.selectors';
import { FeedItem } from '../../models';
import {
  CallDto,
  FeedItemDto,
  MessageDto,
  NoteDto,
  VisitDto
} from '@act/shared/data-transfer-objects';
import { selectMessageEntities } from '../message/message.selectors';
import { selectCallEntities } from '../call/call.selectors';
import {
  selectFeedItemIdNotesViewed,
  selectNoteEntities
} from '../note/note.selectors';
import { Dictionary } from '@ngrx/entity';
import { selectVisitEntities } from '../visit/visit.selectors';

const feedSelectors = feedAdapter.getSelectors();

const selectFeedItemState = createSelector(
  fromChat.selectChatState,
  (state): State => state[feedFeatureKey]
);

const selectAllFeedItems = createSelector(
  selectFeedItemState,
  feedSelectors.selectAll
);

export const selectFeedItemEntities = createSelector(
  selectFeedItemState,
  feedSelectors.selectEntities
);

export const selectFeedItemWindow = createSelector(
  selectFeedItemState,
  state => state.window
);

export const selectFeedItemFilters = createSelector(
  selectFeedItemState,
  state => ({
    typeFilters: state.typeFilters
  })
);

export const selectLoadingFeedItems = createSelector(
  selectFeedItemState,
  state => state.loadingFeedItems
);

export const selectLoadingEarlier = createSelector(
  selectFeedItemState,
  state => state.loadingEarlier
);

export const selectCanLoadEarlier = createSelector(
  selectLoadingEarlier,
  selectFeedItemWindow,
  (loading, window) => !loading && !window.earliest.done
);

export const selectLoadingLater = createSelector(
  selectFeedItemState,
  state => state.loadingLater
);

export const selectCanLoadLater = createSelector(
  selectLoadingLater,
  selectFeedItemWindow,
  (loading, window) => !loading && !window.latest.done
);

const selectRawSelectedFeedItems = createSelector(
  feedQueries.selectSelectedFeedId,
  selectAllFeedItems,
  (selectedFeedId, feedItems) =>
    feedItems.filter(item => item.feedId === selectedFeedId)
);

export const selectLastEventItemId = createSelector(
  selectFeedItemState,
  state => state.lastEventItemId
);

const mapFeedItem = (
  item: FeedItemDto,
  messageMap: Dictionary<MessageDto>,
  callMap: Dictionary<CallDto>,
  noteMap: Dictionary<NoteDto>,
  visitMap: Dictionary<VisitDto>
): FeedItem => {
  if (!item) {
    return null;
  }

  switch (item.externalItemType) {
    case 'message':
      const message = item.message;
      return {
        loading: !message,
        createdBy: message && message.userId,
        ...item
      };
    case 'call':
      const call = item.call;
      return {
        loading: !call,
        createdBy: call && call.originator && call.originator.userId,
        ...item
      };

    case 'note':
      const note = item.note;
      return {
        loading: !note,
        createdBy: note && note.createdBy,
        ...item
      };

    case 'visit':
      const visit = item.visit;
      return {
        loading: !visit,
        createdBy: visit && visit.createdBy,
        ...item
      };

    default:
      return {
        loading: false,
        createdBy: null,
        ...item
      };
  }
};

export const selectFeedItem = createSelector(
  selectMessageEntities,
  selectCallEntities,
  selectNoteEntities,
  selectVisitEntities,
  selectFeedItemEntities,
  (
    messageMap,
    callMap,
    noteMap,
    visitMap,
    feedItemMap,
    feedItemId
  ): FeedItem => {
    const feedItem = feedItemMap[feedItemId];
    return mapFeedItem(feedItem, messageMap, callMap, noteMap, visitMap);
  }
);

export const selectSelectedFeedItems = createSelector(
  selectMessageEntities,
  selectCallEntities,
  selectNoteEntities,
  selectVisitEntities,
  selectRawSelectedFeedItems,
  (
    messageMap,
    callMap,
    noteMap,
    visitMap,
    feedItems: FeedItemDto[]
  ): FeedItem[] => {
    return feedItems.map(item =>
      mapFeedItem(item, messageMap, callMap, noteMap, visitMap)
    );
  }
);

export const selectFeedItemForNotesBeingViewed = createSelector(
  state => state,
  selectFeedItemIdNotesViewed,
  (state, feedItemId) => {
    const thing = selectFeedItem(state, feedItemId);
    return thing;
  }
);

export const selectFirstPageLoading = createSelector(
  selectFeedItemState,
  (feedItemState): boolean => {
    const loadingFirstWindow =
      !feedItemState.ids.length &&
      feedItemState.loadingEarlier &&
      feedItemState.loadingLater;

    const loadingFirstWindowExternalItems = feedItemState.firstWindowLoadedOnly;

    return loadingFirstWindow || loadingFirstWindowExternalItems;
  }
);
