import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OpentokService} from './services/opentok.service';

@NgModule({
  imports: [CommonModule],
  providers: [OpentokService]
})
export class VendorOpentokModule {
}
