import { ScheduledEventDto } from '@act/shared/data-transfer-objects';

export enum ScheduledEventEventTypes {
  SCHEDULED_EVENT_CREATED = '[Scheduled Events] Scheduled Event Created',
  SCHEDULED_EVENT_UPDATED = '[Scheduled Events] Scheduled Event Updated',
  SCHEDULED_EVENT_DELETED = '[Scheduled Events] Scheduled Event Deleted'
}

export interface ScheduledEventEventPayload {
  scheduledEvent: ScheduledEventDto;
}

export class ScheduledEventCreatedEvent {
  readonly type = ScheduledEventEventTypes.SCHEDULED_EVENT_CREATED;

  constructor(public payload: ScheduledEventEventPayload) {}
}

export class ScheduledEventUpdatedEvent {
  readonly type = ScheduledEventEventTypes.SCHEDULED_EVENT_UPDATED;

  constructor(public payload: ScheduledEventEventPayload) {}
}

export interface ScheduledEventDeletedEventPayload {
  scheduledEventId: number;
  userId: string;
}

export class ScheduledEventDeletedEvent {
  readonly type = ScheduledEventEventTypes.SCHEDULED_EVENT_DELETED;

  constructor(public payload: ScheduledEventDeletedEventPayload) {}
}
