import { Pipe, PipeTransform } from '@angular/core';
import { UserDto } from '@act/shared/data-transfer-objects';

@Pipe({
  name: 'getInitials'
})
export class GetInitialsPipe implements PipeTransform {
  transform(user: UserDto, ...args: any[]): any {
    return `${user.firstName.charAt(0)}${
      user.lastName ? user.lastName.charAt(0) : ''
    }`;
  }
}
