import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

interface TitlePartObject {
  text: string;
  bold?: boolean;
  details?: string;
}

const outlinedIconRegex = /-outline$/;

type TitlePart = string | TitlePartObject;

type Action = {
  icon?: string;
  title: string;
  details?: string;
  handler: () => any;
};

type IconAction = Action & {
  icon?: string;
};

@Component({
  selector: 'act-expandable-card',
  templateUrl: './expandable-card.component.html',
  styleUrls: ['./expandable-card.component.scss'],
  animations: [
    trigger('bodyExpansion', [
      state('false, void', style({ height: '0px', visibility: 'hidden' })),
      state('true', style({ height: '*', visibility: 'visible' })),
      transition('* <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    trigger('flipIcon', [
      state('false, void', style({ transform: 'rotate(0deg)' })),
      state('true', style({ transform: 'rotate(180deg)' })),
      transition('* <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class ExpandableCardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  state = 'collapsed';
  open = false;
  toggleExpansion(): void {
    this.open = !this.open;
    this.state = this.state === 'collapsed' ? 'expanded' : 'collapsed';
  }

  @Input() icon: string;
  @Input() iconColor: string;
  @Input() expandableTitle: string;
  @Input() checked;

  @Input() launchAction: () => void;
  @Input() launchDescription: string;
  @Input() additionalActions: Action[];

  @HostBinding('class.is-new') @Input() isNew: boolean;
}
