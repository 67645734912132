import { type } from '@act/common/utilities';
import { SOCKET_NAME } from '@act/shared/models';
import { Action } from '@ngrx/store';

export const WebsocketAuthorizationErrorTypes = {
  WEBSOCKET_AUTHORIZATION_ERROR: type(
    `[Websockets] Websocket Authorization Error`
  )
};

export interface WebsocketAuthorizationErrorPayload {
  exception: any;
  socketName?: SOCKET_NAME;
  socketId?: string;
}

export class WebsocketAuthorizationError implements Action {
  readonly type =
    WebsocketAuthorizationErrorTypes.WEBSOCKET_AUTHORIZATION_ERROR;

  constructor(public payload: WebsocketAuthorizationErrorPayload) {}
}
