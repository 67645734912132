import { AddressDto } from '@act/shared/data-transfer-objects';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'act-address-display',
  templateUrl: './address-display.component.html',
  styleUrls: ['./address-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressDisplayComponent implements OnInit {
  @Input() address: AddressDto;

  constructor() {}

  ngOnInit() {}
}
