import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'act-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Output() onClick = new EventEmitter();
  @Input() styleClass: string;
  @Input() disabled: boolean = false;
  @Input() width: string;
  @Input() height: string;
  @Input() tooltip: string;
  @Input() tooltipPosition: string;

  constructor() {}

  ngOnInit() {}
}
