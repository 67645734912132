import { ApiRequestDefinition } from '@act/shared/api';
import {
  CommentDto,
  VisitCreationRequestDto,
  VisitDto,
  VisitOutcomeDto,
  VisitUpdateRequestDto,
  VisitWithFeedItemDto
} from '@act/shared/data-transfer-objects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class VisitRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getVisitOutcomes = ApiRequestDefinition.createRequestDefinition<
    VisitOutcomeDto[],
    null,
    null,
    null
  >(
    '/visits/outcomes',
    'GET',
    '[Visit] Get Visit Outcomes',
    this.store,
    this.http
  );

  patchVisit = ApiRequestDefinition.createRequestDefinition<
    VisitDto,
    VisitUpdateRequestDto,
    { visitId: number },
    null
  >('/visits/:visitId', 'PATCH', '[Visit] Update Visit', this.store, this.http);

  deleteVisit = ApiRequestDefinition.createRequestDefinition<
    null,
    null,
    { visitId: number },
    null
  >(
    '/visits/:visitId',
    'DELETE',
    '[Visit] Delete Visit',
    this.store,
    this.http
  );

  postVisit = ApiRequestDefinition.createRequestDefinition<
    VisitWithFeedItemDto,
    VisitCreationRequestDto,
    null,
    { creationId: number }
  >('/visits', 'POST', '[Visit] Create Visit', this.store, this.http);

  getVisits = ApiRequestDefinition.createRequestDefinition<
    VisitDto[],
    null,
    { ids: number[] },
    null
  >('/visits', 'GET', '[Visit] Get Visits', this.store, this.http);

  createComment = ApiRequestDefinition.createRequestDefinition<
    CommentDto,
    { text: string },
    { visitId: number }
  >(
    '/visits/:visitId/comments',
    'POST',
    '[Visit] Create Comment',
    this.store,
    this.http
  );
}
