import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { File } from '@act/server/models/files';
import { FileFacade } from 'libs/features/files/data-access/src/lib/+state/file/file.facade';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const isVideoMime = type => type.startsWith('video/');
const isImageMime = type => type.startsWith('image/');
const isAudioMime = type => type.startsWith('audio/');

@Component({
  selector: 'act-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {
  fileInfo$: Observable<File>;
  fileInfoLoading$: Observable<boolean>;
  mimeType$: Observable<string>;
  isVideo$: Observable<boolean>;
  isImage$: Observable<boolean>;
  isAudio$: Observable<boolean>;
  isUnknown$: Observable<boolean>;

  constructor(private fileFacade: FileFacade) {}

  @Input() fileId: string;
  @Output() load: EventEmitter<void> = new EventEmitter();
  imageLoading: boolean = true;

  onLoad() {
    this.imageLoading = false;
    this.load.next();
  }

  getLink(): string {
    return this.fileFacade.getContentUrl(this.fileId);
  }

  ngOnInit() {
    this.fileInfo$ = this.fileFacade.file$(this.fileId);
    this.fileInfoLoading$ = this.fileFacade.isFileLoading$(this.fileId);
    this.mimeType$ = this.fileInfo$.pipe(map(f => (f ? f.mimeType : '')));
    this.isImage$ = this.mimeType$.pipe(map(isImageMime));
    this.isVideo$ = this.mimeType$.pipe(map(isVideoMime));
    this.isAudio$ = this.mimeType$.pipe(map(isAudioMime));
    this.isUnknown$ = this.mimeType$.pipe(
      map(t => !isVideoMime(t) && !isImageMime(t) && !isAudioMime(t))
    );

    this.fileFacade.loadFileInfo(this.fileId);
  }
}
