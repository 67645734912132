import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { UsersService } from './services/users.service';
import { USERS_FEATURE_REDUCER_KEY, usersFeatureReducer } from './+state/users';
import { UserEffects } from './+state/users/users.effects';
import { UsersFacade } from './+state/users/users.facade';
import { SharedApiModule } from '@act/shared/api';
import {
  cohortsFeatureReducer,
  COHORTS_FEATURE_REDUCER_KEY
} from './+state/cohort';
import { CohortEffects } from './+state/cohort/cohort.effects';
import { CohortFacade } from './+state/cohort/cohort.facade';
import { CohortRequests } from './+state/cohort/cohort.requests';
import { UsersRequests } from './+state/users/users.requests';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USERS_FEATURE_REDUCER_KEY, usersFeatureReducer),
    StoreModule.forFeature(COHORTS_FEATURE_REDUCER_KEY, cohortsFeatureReducer),
    SharedApiModule,
    EffectsModule.forFeature([UserEffects, CohortEffects])
  ],
  providers: [
    UsersFacade,
    UsersService,
    CohortFacade,
    UsersRequests,
    CohortRequests
  ]
})
export class FeaturesUsersDataAccessModule {}
