import { MemberStageRequests } from './member-stage.requests';

const requests = new MemberStageRequests(null, null);

export const {
  initiator: loadMemberStages,
  success: memberStagesLoaded,
  error: memberStageLoadError
} = requests.getMemberStages.getRequestActions();

export const {
  initiator: loadMemberStatuses,
  success: memberStatusesLoaded,
  error: memberStatusLoadError
} = requests.getMemberStatuses.getRequestActions();
