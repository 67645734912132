import {
  ConsentLinkDto,
  TermGroupDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ConsentFacade } from '@act/features/consent/data-access';
import { Observable } from 'rxjs';

@Component({
  selector: 'act-consent-link-dialog',
  templateUrl: './consent-link-dialog.component.html',
  styleUrls: ['./consent-link-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsentLinkDialogComponent implements OnInit {
  @Input() consentGroup: TermGroupDto;
  @Input() user: UserDto;
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(private consentFacade: ConsentFacade) {}

  consentLink$: Observable<ConsentLinkDto>;
  link: ConsentLinkDto;

  copy(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  async ngOnInit() {
    this.link = await this.consentFacade
      .createConsentLink(this.user.id, this.consentGroup)
      .toPromise();
  }
}
