import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthFacade } from '../services/auth.facade';
import { Platform } from '@act/core/platform';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class IsUnauthenticatedService implements CanActivate {
  constructor(private authFacade: AuthFacade, private platform: Platform) {}

  canActivate(): Observable<boolean> {
    const userUnauthenticated$ = this.platform.userAuthenticated$.pipe(
      map(value => !value)
    );

    // Kind of a hack to log out a user if they have a token and but they are not authenticated
    //   If we don't do this the user is stuck because they can't log back in
    userUnauthenticated$.subscribe(unauthenticated => {
      const tokenExists = !!this.authFacade.getToken();
      if (unauthenticated && tokenExists) {
        this.authFacade.logout();
      }
    });

    return userUnauthenticated$;
  }
}
