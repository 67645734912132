import {
  AddressDto,
  ScheduledEventDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  ComponentRef,
  TemplateRef
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ScheduledEventFacade } from '../../+state/scheduled-event/scheduled-event.facade';
import * as moment from 'moment';
import { SelectDto } from 'libs/shared/ui/forms/src/lib/inputs/select/select.component';
import { AnchoredDialogComponent } from 'libs/shared/ui/dialog/src/lib/components/anchored-dialog/anchored-dialog.component';
import { RichTextInputComponent } from 'libs/shared/ui/forms/src/lib/inputs/rich-text-input/rich-text-input.component';
import { quickDates } from './scheduled-dialog.data';
import { AnchoredDialogService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog.service';
import { Dictionary } from '@ngrx/entity';

@Component({
  selector: 'act-scheduled-dialog',
  templateUrl: './scheduled-dialog.component.html',
  styleUrls: ['./scheduled-dialog.component.scss']
})
export class ScheduledDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(RichTextInputComponent, null)
  private noteInput: RichTextInputComponent;

  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;
  anchoredDialogRef: ComponentRef<AnchoredDialogComponent>;

  @Input() memberId: string;
  @Input() userMap: Dictionary<UserDto>;
  @Input() event: ScheduledEventDto;

  addresses: AddressDto[];
  editEvent = false;
  pipe = new DatePipe('en-US');
  isNew: boolean;
  userId: string;
  eventId: number;
  timeOptions: SelectDto[];
  locationOptions: SelectDto[] = [];
  showDateBox = false;
  showLocationFields = false;
  selectedDate: Date = new Date();
  eventDate: string;
  quickDateOptions = quickDates;
  eventForm = new FormGroup({
    eventType: new FormControl('', Validators.required),
    eventTime: new FormControl(''),
    eventDate: new FormControl('', Validators.required),
    visitLocation: new FormControl('', Validators.required),
    visitOtherLocation: new FormControl({ value: '', disabled: true }),
    quickDate: new FormControl('', Validators.required)
  });
  eventNote = new FormControl();
  currentDate = new Date();
  showOtherLocationInput: boolean = false;

  quilModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }]
    ],
    clipboard: {
      matchVisual: false
    }
  };
  languageOptions: { label: string; value: string }[];
  constructor(
    public scheduledEventFacade: ScheduledEventFacade,
    private anchoredDialogService: AnchoredDialogService
  ) {}

  open(): ComponentRef<AnchoredDialogComponent> {
    if (!this.anchoredDialogRef) {
      const user = this.userMap[this.memberId];
      const name = user ? user.firstName + ' ' + user.lastName : null;
      this.anchoredDialogRef = this.anchoredDialogService.create({
        icon: 'notifications_none',
        title:
          (this.event ? 'Edit' : 'Create') +
          ' Scheduled Event' +
          (name ? ' for ' + name : ''),
        ref: this.dialogRef,
        iconStyleClass: 'pink-icon',
        actionType: this.event ? 'edit' : 'create',
        eventType: 'reminder',
        eventId: this.event ? this.event.id : this.memberId,
        isLoading$: this.scheduledEventFacade.scheduledEventsLoading$
      });
    }
    return this.anchoredDialogRef;
  }

  ngOnInit() {
    const member = this.userMap[this.memberId];
    this.addresses = member && member.addresses;
    this.timeOptions = this.getTimes();
    this.eventDate = this.dateString(new Date());

    if (this.event) {
      this.eventId = this.event.id;
      this.editEvent = true;
      this.eventForm.controls['quickDate'].setValue('custom');
      this.eventForm.controls['eventType'].setValue(this.event.type);
      this.selectedDate = moment(this.event.date + ' 00:00:00').toDate();
      this.eventForm.controls['eventDate'].setValue(this.event.date);

      if (this.event.date) {
        this.eventDate = this.event.date;
      }
      if (!this.event.time) {
        const quickDate = this.getQuickTimeFromDate(this.selectedDate);
        this.eventForm.controls['quickDate'].setValue(quickDate);
      }

      if (!this.event.time) {
        this.eventForm.controls['eventTime'].setValue('any');
      }

      if (
        this.eventForm.controls['quickDate'].value === 'custom' &&
        !this.showDateBox
      ) {
        this.setCustomDateOptions();
      }

      this.eventNote.setValue(this.event.note);
    } else {
      this.eventForm.controls['eventType'].setValue('call');
      this.eventForm.controls['eventTime'].setValue('any');
    }

    this.setDefaultLocation();
    this.setDefaultTime();
  }

  ngAfterViewInit() {
    if (this.event) {
      setTimeout(() => {
        // Put cursor to end
        const selection = this.noteInput.editor.quillEditor.getSelection();
        if (selection) {
          const text = this.event.note;
          this.noteInput.editor.quillEditor.setSelection(
            selection.index + text.length,
            0
          );
        }
      }, 300);
    }
  }

  async onSubmit() {
    let addressId = this.eventForm.value.visitLocation;
    let timeValue = null;
    let otherLocation = null;

    if (addressId < 1) {
      addressId = null;
      otherLocation = this.eventForm.value.visitOtherLocation;
    }

    if (this.eventForm.value.eventType !== 'visit') {
      addressId = null;
      otherLocation = null;
    }

    if (this.eventForm.value.eventTime === 'any') {
      timeValue = new Date(
        this.eventForm.value.eventDate + ' ' + this.eventForm.value.eventTime
      ).getTime();
    }

    if (!this.editEvent) {
      await this.scheduledEventFacade.createScheduledEvent(
        {
          visitLocation: otherLocation,
          visitLocationAddressId: addressId,
          date: this.eventDate,
          time: timeValue,
          type: this.eventForm.value.eventType,
          note: this.eventNote.value
        },
        this.memberId
      );
    } else {
      await this.scheduledEventFacade.updateScheduledEvent(
        {
          visitLocation: otherLocation,
          visitLocationAddressId: addressId,
          date: this.eventDate,
          time: timeValue,
          type: this.eventForm.value.eventType,
          note: this.eventNote.value
        },
        this.memberId,
        this.eventId
      );
    }
    this.anchoredDialogRef.instance.closeModal();
  }

  onDateSelect(event) {
    this.selectedDate = event.toDate();
    this.timeOptions = this.getTimes();
    this.eventDate = this.dateString(event.valueOf());
    this.eventForm.controls['eventDate'].setValue(this.eventDate);
  }

  setCustomDateOptions() {
    this.showDateBox = true;
    this.currentDate = new Date();
    this.eventForm.controls['eventDate'].setValue(this.eventDate);
    this.eventForm.controls['eventTime'].setValidators([Validators.required]);
    this.eventForm.controls['eventTime'].updateValueAndValidity();
  }

  onQuickDateChange() {
    const value = this.eventForm.controls['quickDate'].value;
    this.showDateBox = false;
    let dateTime = new Date().valueOf();

    switch (value) {
      case '1D':
        dateTime = this.addToDate(1);
        break;
      case '2D':
        dateTime = this.addToDate(2);
        break;
      case '1W':
        dateTime = this.addToDate(7);
        break;
      case '2W':
        dateTime = this.addToDate(14);
        break;
      case '1M':
        dateTime = this.addToDate(1, 'month');
        break;
      case 'custom':
        this.setCustomDateOptions();
        break;
    }

    if (!this.showDateBox) {
      this.eventDate = this.dateString(dateTime);
      this.eventForm.controls['eventDate'].setValue(this.eventDate);
      this.eventForm.controls['eventTime'].setValue(null);
      this.eventForm.controls['eventTime'].setValidators([]);
      this.eventForm.controls['eventTime'].updateValueAndValidity();
    }
  }

  addToDate(add: number, type = 'days') {
    let date = new Date();

    if (type === 'days') return date.setDate(date.getDate() + add);
    else {
      let current: Date;
      if (date.getMonth() == 11) {
        current = new Date(date.getFullYear() + 1, 0, date.getDate());
      } else {
        current = new Date(
          date.getFullYear(),
          date.getMonth() + add,
          date.getDate()
        );
      }
      return current.valueOf();
    }
  }

  removeWhiteSpace() {
    const visitOtherLocationValue = this.eventForm.controls[
      'visitOtherLocation'
    ].value;
    this.eventForm.controls['visitOtherLocation'].setValue(
      visitOtherLocationValue.replace(/^\s+/g, '')
    );
  }

  onLocationChange(location: string) {
    if (!location) {
      this.enableOtherLocation();
    } else {
      this.eventForm.controls['visitLocation'].setValue(location);
      this.disableOtherLocation();
    }
  }

  onTypeChange(eventType: string) {
    if (eventType === 'visit') {
      this.showLocationFields = true;
      this.eventForm.controls['visitLocation'].enable();
      this.setDefaultLocation();
    } else {
      this.showLocationFields = false;
      this.clearVisitConstraints();
    }
    this.eventForm.controls['visitLocation'].updateValueAndValidity();
    this.eventForm.controls['visitOtherLocation'].updateValueAndValidity();
  }

  setDefaultLocation() {
    this.clearVisitConstraints();
    const userAddresses = this.addresses;

    if (userAddresses.length) {
      this.setLocationOptions(userAddresses);
      const primaryAddress = userAddresses.find(i => i.primary);
      let defaultAddressId = primaryAddress.id;
      this.eventForm.controls['visitLocation'].enable();

      if (
        this.editEvent &&
        this.event.visitLocation &&
        this.event.type === 'visit'
      ) {
        this.showLocationFields = true;

        const defaultAddress = this.event.visitLocation;

        if (typeof defaultAddress === 'object' && defaultAddress !== null) {
          defaultAddressId = defaultAddress.id;
        } else {
          defaultAddressId = 0;
          if (this.event.type === 'visit') {
            this.eventForm.controls['visitOtherLocation'].setValue(
              defaultAddress
            );
            this.enableOtherLocation();
          }
        }
      }

      // set default location
      this.eventForm.controls['visitLocation'].setValue(defaultAddressId);
    }
  }

  setDefaultTime() {
    let time = new Date().getTime();

    if (this.editEvent && this.event.time) {
      time = this.event.time;
    }
    const currentTime = this.pipe.transform(time, 'hh:mm aaa');
    const minutes = parseInt(this.pipe.transform(time, 'm'));
    const remain = minutes % 30;
    let defaultTime = currentTime;

    if (remain > 0) {
      let defMinutes: any = minutes - remain;
      defMinutes = defMinutes < 10 ? `0${defMinutes}` : defMinutes;
      const hours = this.pipe.transform(time, 'hh');
      const meridian = this.pipe.transform(time, 'aaa');
      defaultTime = `${hours}:${defMinutes} ${meridian}`;
    }

    // set event time
    this.eventForm.controls['eventTime'].setValue(defaultTime);
  }

  getTimes() {
    const minInterval = 30;
    let times = [];
    const currentDateWithoutTime = new Date(this.currentDate).setHours(
      0,
      0,
      0,
      0
    );
    const selectedDateWithoutTime = new Date(this.selectedDate).setHours(
      0,
      0,
      0,
      0
    );
    let startTime =
      currentDateWithoutTime === selectedDateWithoutTime && !this.event
        ? this.currentDate.getHours() * 60 +
          (this.currentDate.getMinutes() < 30 ? 30 : 60)
        : 0;
    const ap = ['AM', 'PM'];

    // loop to increment the time and push results in array
    for (var i = 0; startTime < 24 * 60; i++) {
      let hh = Math.floor(startTime / 60.0);
      hh = hh % 12 === 0 ? 12 : hh % 12;
      const mm = startTime % 60;
      const el = startTime > 719 ? 1 : 0;
      times[i] =
        ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) + ' ' + ap[el];
      startTime = startTime + minInterval;
    }

    // Pivot array around 8:00 am so 8am should be at the beginning and the rest should appear after midnight
    const eightIndex = times.findIndex(t => t.includes('8:00 AM'));
    let timesArray = [];
    if (eightIndex === -1) {
      timesArray = times.map(o => {
        return { label: o, value: o };
      });
    } else {
      timesArray = [
        ...times.slice(eightIndex, times.length),
        ...times.slice(0, eightIndex)
      ].map(o => {
        return { label: o, value: o };
      });
    }

    return [{ label: 'Anytime', value: 'any' }, ...timesArray];
  }

  getQuickTimeFromDate(date: Date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const daysDifference = Math.round(
      (date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
    );

    switch (daysDifference) {
      case 1:
        return '1D';
      case 2:
        return '2D';
      case 7:
        return '1W';
      case 14:
        return '2W';
      default:
        const monthAway = new Date(today.getTime());
        monthAway.setMonth(monthAway.getMonth() + 1);
        if (date.getTime() === monthAway.getTime()) {
          return '1M';
        }
        return 'custom';
    }
  }

  private disableOtherLocation() {
    this.showOtherLocationInput = false;
    this.eventForm.controls['visitOtherLocation'].disable();
    this.eventForm.controls['visitOtherLocation'].clearValidators();
    this.eventForm.controls['visitOtherLocation'].updateValueAndValidity();
  }

  private dateString(time: Date | number): string {
    if (!time) {
      time = new Date();
    }
    if (typeof time === 'number') {
      time = new Date(time);
    }
    return this.pipe.transform(time, 'yyyy-MM-dd');
  }

  private noWhitespaceValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const isWhitespace =
      control.value && (control.value.replace(/^\s+/g, '') || '').length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  private clearVisitConstraints() {
    this.eventForm.controls['visitOtherLocation'].disable();
    this.eventForm.controls['visitOtherLocation'].clearValidators();
    this.eventForm.controls['visitLocation'].disable();
    this.eventForm.controls['visitLocation'].clearValidators();
  }

  private enableOtherLocation() {
    this.showOtherLocationInput = true;
    this.eventForm.controls['visitOtherLocation'].enable();
    this.eventForm.controls['visitOtherLocation'].setValidators([
      Validators.required,
      this.noWhitespaceValidator
    ]);
    this.eventForm.controls['visitOtherLocation'].updateValueAndValidity();
  }

  private setLocationOptions(userAddresses: AddressDto[]) {
    this.locationOptions = [];
    for (var k = 0; k < userAddresses.length; k++) {
      this.locationOptions.push({
        value: userAddresses[k].id,
        label: `${userAddresses[k].line1} ${userAddresses[k].line2}, ${userAddresses[k].city} ${userAddresses[k].state}`
      });
    }

    this.locationOptions.push({ value: 0, label: 'Other' });
  }
}
