import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { SharedTeammateModule } from '@act/shared/teammate';
import { SharedUiAudioPlayerModule } from '@act/shared/ui/audio-player';
import { SharedUiSpinnerModule } from '@act/shared/ui/spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TodoListComponent } from './todo-list/todo-list.component';
import { ExpandableCardComponent } from './expandable-card/expandable-card.component';
import { TodoComponent } from './todo/todo.component';
import { TodoListPageComponent } from './todo-list-page/todo-list-page.component';
import { FeaturesTodosDataAccessModule } from '@act/features/todos/data-access';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material';
import { SharedUiFeedCardModule } from '@act/shared/ui/feed-card';

@NgModule({
  imports: [
    CommonModule,
    FeaturesTodosDataAccessModule,
    SharedTeammateModule,
    SharedUiAudioPlayerModule,
    SharedUiSpinnerModule,
    SharedUiFeedCardModule,

    // Material Modules
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule
  ],
  declarations: [
    TodoListComponent,
    ExpandableCardComponent,
    TodoComponent,
    TodoListPageComponent
  ],
  exports: [TodoListPageComponent]
})
export class FeaturesTodosUiModule {}
