import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  textTemplateAdapter,
  textTemplateFeatureKey,
  State
} from './text-template.reducer';

const textTemplateSelectors = textTemplateAdapter.getSelectors();

export const selectTextTemplateFeatureState = createFeatureSelector<State>(
  textTemplateFeatureKey
);

export const selectTextTemplateEntities = createSelector(
  selectTextTemplateFeatureState,
  textTemplateSelectors.selectEntities
);

export const selectTextTemplates = createSelector(
  selectTextTemplateEntities,
  entities => Object.values(entities)
);

export const selectTextTemplateVariables = createSelector(
  selectTextTemplateFeatureState,
  (state: State) => state.textTemplateVariables
);

export const selectLoadingTextTemplates = createSelector(
  selectTextTemplateFeatureState,
  (state: State) => state.loadingTextTemplates
);

export const selectLoadingTextTemplateVariables = createSelector(
  selectTextTemplateFeatureState,
  (state: State) => state.loadingTextTemplateVariables
);
