import { SOCKET_NAME } from '@act/shared/models';

export enum HealthCheckTypes {
  HEALTH_CHECK = '[Socket] Health Check'
}

export class HealthCheck {
  readonly type = HealthCheckTypes.HEALTH_CHECK;

  constructor(public payload: HealthCheckPayload) {}
}

export interface HealthCheckPayload {
  deviceId: string;
  socketName: SOCKET_NAME;
}

export enum SocketInitializedTypes {
  SOCKET_INITIALIZED = '[Socket] Socket Initialized',
  SOCKET_INITIALIZATION_FAILED = '[Socket] Socket Initialization Failed'
}

export interface SocketInitializedPayload {
  id: string;
  name: SOCKET_NAME;
}

export class SocketInitialized {
  readonly type = SocketInitializedTypes.SOCKET_INITIALIZED;

  constructor(public payload: SocketInitializedPayload) {}
}

export interface SocketInitializedFailedPayload {
  name: SOCKET_NAME;
  error: any;
}

export class SocketInitializationFailed {
  readonly type = SocketInitializedTypes.SOCKET_INITIALIZATION_FAILED;

  constructor(public payload: SocketInitializedFailedPayload) {}
}
