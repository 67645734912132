import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarService } from './services/snackbar.service';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, BrowserAnimationsModule],
  providers: [SnackbarService]
})
export class CommonSnackbarModule {}
