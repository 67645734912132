import { Action } from '@ngrx/store';
import { DispatchEventPayload } from '../models/dispatch-event-payload.interface';

export enum DispatcherActionTypes {
  DISPATCH = '[Dispatcher] Dispatch'
}

export class Dispatch implements Action {
  readonly type = DispatcherActionTypes.DISPATCH;

  constructor(public payload: DispatchEventPayload) {}
}
