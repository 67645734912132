import { CallDto } from './call.dto';
import { MessageDto } from './message.dto';
import { NoteDto } from './note.dto';
import { ScheduledEventDto } from './scheduled-event.dto';
import { VisitDto } from './visit.dto';

export type TodoExternalItemTypeDto =
  | 'call'
  | 'message'
  | 'visit'
  | 'note'
  | 'scheduled-event';

export enum TodoTypeDto {
  TEXT_BACK = 'TEXT_BACK',
  CALL_BACK = 'CALL_BACK',
  INCOMPLETE_CALL = 'INCOMPLETE_CALL',
  INCOMPLETE_VISIT = 'INCOMPLETE_VISIT',
  SCHEDULED_EVENT = 'SCHEDULED_EVENT'
}
export interface TodoDto {
  id: number;
  userId: string;
  type: TodoTypeDto;
  subjectId: string;
  externalItemId: number;
  externalItemType: TodoExternalItemTypeDto;
  customTitle: string;
  date: string;
  time: number;
  completedAt: number;
  call: CallDto;
  message: MessageDto;
  visit: VisitDto;
  note: NoteDto;
  scheduledEvent: ScheduledEventDto;
  createdAt: number;
}
