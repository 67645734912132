import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges
} from '@angular/core';
import { ScheduledEventFacade } from '../../+state/scheduled-event/scheduled-event.facade';
import { removeHtmlTags } from '@act/common/utilities';
import {
  ScheduledEventActionEventArgsDto,
  ScheduledEventDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import moment = require('moment');
import { ActDialogService } from '@act/shared/ui/dialog';
import { FeedActionsService } from '../../feed-actions/feed-actions.service';
import {
  FeedCardAction,
  FeedCardData,
  eventTypes
} from '@act/shared/ui/feed-card';
import { getCardDataFromScheduledEvent } from '../scheduled-events.card-helper';
import { Dictionary } from '@ngrx/entity';

@Component({
  selector: 'act-single-scheduled-event',
  templateUrl: './single-scheduled-event.component.html',
  styleUrls: ['./single-scheduled-event.component.scss']
})
export class SingleScheduledEventComponent implements OnChanges {
  @Input() event: ScheduledEventDto;
  @Input() actingUser: UserDto;
  @Input() userMap: Dictionary<UserDto>;
  @Input() hideIcon: boolean;

  @Output() scheduledEventActionEvent = new EventEmitter<
    ScheduledEventActionEventArgsDto
  >();

  cardData: FeedCardData;
  actions: FeedCardAction[];

  constructor(
    public scheduledEventFacade: ScheduledEventFacade,
    private dialogService: ActDialogService,
    private feedActionsService: FeedActionsService
  ) {}

  ngOnChanges() {
    this.setCardData();
  }

  setCardData() {
    this.cardData = getCardDataFromScheduledEvent(
      this.event,
      this.actingUser,
      this.userMap
    );

    const currentDate = new Date();
    const tomorrow = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1,
      0,
      0,
      0
    );
    const isDue =
      moment(this.event.date + ' 00:00:00')
        .toDate()
        .getTime() < tomorrow.getTime();

    this.actions = [
      this.event.type === eventTypes.call
        ? {
            icon: 'call_outgoing',
            title: 'Call Now',
            handler: () => this.scheduledEventAction(this.event)
          }
        : null,
      this.event.type === eventTypes.message
        ? {
            icon: 'text_outgoing',
            title: 'Text Now',
            handler: () => this.scheduledEventAction(this.event)
          }
        : null,
      this.event.type === eventTypes.visit
        ? {
            icon: 'visit',
            title: 'Record Visit',
            handler: () => this.scheduledEventAction(this.event)
          }
        : null,
      this.event.type !== eventTypes.reminder
        ? {
            icon: 'edit',
            title: 'Edit',
            details: 'Edit Scheduled Event',
            handler: () => this.editScheduledEvent()
          }
        : null,
      isDue
        ? {
            icon: 'snooze_reminder',
            title: 'Snooze',
            details: 'Snooze reminder to tomorrow',
            handler: () => this.snoozeScheduledEvent(this.event)
          }
        : null,
      this.event.type !== eventTypes.reminder
        ? {
            icon: 'clear_reminder',
            title: 'Clear',
            details: 'Clear Scheduled Event',
            handler: () => this.clearScheduledEvent(this.event)
          }
        : null
    ].filter(a => a);
  }

  async clearScheduledEvent(event: ScheduledEventDto) {
    const type = event.type === 'tbd' ? 'reminder' : event.type;
    const confirm = await this.dialogService.confirm(
      `Are you sure you want to clear this scheduled ${type}?`
    );
    if (confirm) {
      this.scheduledEventFacade.clearScheduledEvent(event.id, event.userId);
    }
  }

  snoozeScheduledEvent(event: ScheduledEventDto) {
    this.scheduledEventFacade.snoozeScheduledEvent(event.id, event.userId);
  }

  scheduledEventAction(event: ScheduledEventDto) {
    this.scheduledEventActionEvent.emit({
      scheduledEventId: event.id,
      actionType: event.type
    });
  }

  /**
   * Get timestamp in mil second from date
   * @param value
   */
  getTimeInMilSecFromDate(value: number) {
    return new Date(value).getTime();
  }

  /**
   * Get plain text from Html content
   * @param note
   */
  getPlainText(note: string) {
    return removeHtmlTags(note);
  }

  /**
   * Get icon based on event type
   * @param iconType
   */
  getIconName(iconType: string) {
    let icon = 'text_black.svg';

    if (iconType === 'call') {
      icon = 'Phone_black.svg';
    } else if (iconType === 'visit') {
      icon = 'Icon_Visit_black.svg';
    } else if (iconType === 'tbd') {
      icon = 'Icon_Reminder.svg';
    } else if (iconType === 'reminder') {
      icon = 'Icon_Reminder.svg';
    } else if (iconType === 'message') {
      icon = 'text_black.svg';
    }
    return icon;
  }

  /**
   * Get event title based on event type
   * @param iconType
   */
  getEventTitle(iconType: string) {
    let title = 'Text Scheduled';
    if (iconType === 'call') {
      title = 'Call Scheduled';
    } else if (iconType === 'visit') {
      title = 'Visit Scheduled';
    } else if (iconType === 'reminder') {
      title = 'Automatic Reminder';
    } else if (iconType === 'tbd') {
      title = 'Reminder Scheduled';
    }
    return title;
  }

  currentTime = new Date().getTime();
  currentDate = moment().format('YYYY-MM-DD');

  /**
   * Compare event time with current time
   * @param eventTime
   */
  isEventLate(event: ScheduledEventDto) {
    let response = false;

    if (
      event.date < this.currentDate ||
      (event.time && event.time < this.currentTime)
    ) {
      response = true;
    }
    return response;
  }

  editScheduledEvent() {
    this.feedActionsService.editScheduledEvent(this.event);
  }
}
