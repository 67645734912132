import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { FeedFacade } from '../+state/feed/feed.facade';
import { ChatFacade } from '../+state/chat.facade';
import { NoteFacade } from '../+state/note/note.facade';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  ActingUserDto,
  FeedItemDto,
  MessageTemplateEventDto,
  ScheduledEventActionEventArgsDto,
  ScheduledEventDto,
  UnreadFeedItemsDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { Platform } from '@act/core/platform';
import { getValueOfObservable } from '@act/common/utilities';
import { ScheduledEventFacade } from '../+state/scheduled-event/scheduled-event.facade';
import moment = require('moment');
import { ActivatedRoute } from '@angular/router';
import {
  getQueryValues,
  QueryValues
} from '../../../../apps/clients/care-portal/src/lib/router/router.component';
@Component({
  selector: 'feed-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @Input() user: ActingUserDto;
  @Input() users: UserDto[];
  @Input() userMap: Dictionary<UserDto>;
  @Input() member: UserDto;
  @ViewChild('scrollFeedItems', null) scrollFeedItems: ElementRef;
  @ViewChild('bottomFeed', null) bottomFeed: ElementRef;
  @ViewChildren('feedItemsRef') feedItemsRef: QueryList<ElementRef>;
  @Input() canToggleProfile: boolean;
  @Input() profileOpen: boolean;
  @Output() toggleProfile: EventEmitter<void> = new EventEmitter();
  @Input() canCloseFeed: boolean;
  @Output() closeFeed: EventEmitter<void> = new EventEmitter();
  foundElement: HTMLElement;

  feedItems: FeedItemDto[];
  distanceFromBottom = 0;
  newIndicatorLocation$ = new Subject<string>();
  initialUnreadInfo$: Observable<UnreadFeedItemsDto>;
  showNoItemText: boolean;
  upcomingEvents: ScheduledEventDto[] = [];
  pastEvents: ScheduledEventDto[] = [];
  currentDate = new Date();
  scheduledEventActionEvent: ScheduledEventActionEventArgsDto;
  selectTemplateEvent: MessageTemplateEventDto;
  scrollToBottomSubscription: Subscription;
  feedItemsSubscription: Subscription;
  feedItemsRefSubscription: Subscription;
  scheduledEventsSubscription: Subscription;
  queryValues: QueryValues;

  constructor(
    public chatFacade: ChatFacade,
    public feedFacade: FeedFacade,
    public noteFacade: NoteFacade,
    public platform: Platform,
    public scheduledEventFacade: ScheduledEventFacade,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  feedItems$ = this.chatFacade.feedItems$;
  lastEventItemId$ = this.chatFacade.lastEventItemId$;
  canLoadEarlier$ = this.chatFacade.canLoadEarlier$;
  canLoadLater$ = this.chatFacade.canLoadLater$;
  loadingLater$ = this.chatFacade.loadingLater$;
  loadingEarlier$ = this.chatFacade.loadingEarlier$;
  loadingFeedItems$ = this.chatFacade.loadingFeedItems$;
  itemsTypeFilters$ = this.chatFacade.itemsTypeFilters$;
  scheduledEvents$ = this.scheduledEventFacade.scheduledEvents$;
  lastFeedItemId = null;
  selectedFilters: string[] = [];
  ngOnInit() {
    this.queryValues = getQueryValues(this.route.snapshot.queryParamMap);
    this.scrollToBottomSubscription = this.chatFacade.scrollToBottom$.subscribe(
      this.scrollToBottom.bind(this)
    );
    this.feedItemsSubscription = this.feedItems$.subscribe(items => {
      const newLastFeedItemId = items.length
        ? items[items.length - 1].id
        : null;

      const feedItem = items.find(item => {
        return this.feedItemExists(item);
      });

      if (newLastFeedItemId !== this.lastFeedItemId) {
        // Scroll to bottom if the feed item doesn't exist
        if (!feedItem) {
          this.chatFacade.scrollToBottom();
        }
        this.lastFeedItemId = newLastFeedItemId;
      }
      this.showNoItemText = true;
      if (items.length) {
        this.showNoItemText = false;
      }
    });
    this.scheduledEventsSubscription = this.scheduledEvents$.subscribe(
      events => {
        // clear existing events
        this.upcomingEvents = [];
        this.pastEvents = [];
        if (!events || !events.length) {
          return;
        }
        const currentDate = new Date();
        const tomorrow = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1,
          0,
          0,
          0
        );
        this.upcomingEvents = events.filter(
          event =>
            moment(event.date + ' 00:00:00')
              .toDate()
              .getTime() >= tomorrow.getTime()
        );
        this.pastEvents = events.filter(
          event =>
            moment(event.date + ' 00:00:00')
              .toDate()
              .getTime() < tomorrow.getTime()
        );

        // Run change detection manually in child components
        this.changeDetectorRef.markForCheck();
      }
    );
  }
  ngAfterViewInit(): void {
    this.scrollFeedItems.nativeElement.addEventListener(
      'scroll',
      (e: Event) => {
        this.distanceFromBottom = this.getDistanceFromBottom();
        const posScrollTop = this.getScrollTopFromDistanceFromBottom(
          this.distanceFromBottom
        );
        if (posScrollTop === 0) {
          const canLoadEarlier = getValueOfObservable(this.canLoadEarlier$);
          if (canLoadEarlier) {
            this.loadEarlier();
            // HACK to scroll the list when new items load, otherwise the scroll sticks to the top
            setTimeout(() => {
              this.scrollFeedItems.nativeElement.scroll({
                top: 1
              });
            }, 300);
          }
        }
      }
    );

    // Scroll to feedItem if it exists
    this.feedItemsRefSubscription = this.feedItemsRef.changes.subscribe(
      (list: QueryList<ElementRef>) => {
        if (list.length && !this.foundElement) {
          const element = this.getFeedElement();
          if (element) {
            element.scrollIntoView();
            this.foundElement = element;
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.scrollToBottomSubscription.unsubscribe();
    this.feedItemsSubscription.unsubscribe();
    this.feedItemsRefSubscription.unsubscribe();
    this.scheduledEventsSubscription.unsubscribe();
  }
  getScrollTopFromDistanceFromBottom(distanceFromBottom: number) {
    return (
      this.scrollFeedItems.nativeElement.scrollHeight -
      distanceFromBottom -
      this.scrollFeedItems.nativeElement.offsetHeight
    );
  }
  getDistanceFromBottom() {
    return (
      this.scrollFeedItems.nativeElement.scrollHeight -
      this.scrollFeedItems.nativeElement.offsetHeight -
      this.scrollFeedItems.nativeElement.scrollTop
    );
  }
  loadEarlier() {
    this.chatFacade.loadNextFrame({ earlier: true });
  }
  /**
   * @description Update the feed item type filter
   * @param {Event} event
   */
  itemTypeFilterEventHandler($event: string[]) {
    this.selectedFilters = $event;
    this.chatFacade.updateFeedItemFilters($event);
  }

  scheduledEventActionEventHandler($event: ScheduledEventActionEventArgsDto) {
    this.scheduledEventActionEvent = $event;
  }
  trackItem(index: number, item: FeedItemDto): number {
    return item.id;
  }
  private scrollToBottom(): void {
    setTimeout(() => {
      this.scrollFeedItems.nativeElement.scroll({
        top: this.scrollFeedItems.nativeElement.scrollHeight // distanceFromBottom
      });
    });
  }

  // Get HTML Element from queryParams
  private getFeedElement() {
    let element;
    if (this.queryValues.feedItem) {
      element = document.getElementById(
        this.queryValues.feedItem + 'feedId'
      ) as HTMLElement;
    } else if (
      this.queryValues.feedItemExternalId &&
      this.queryValues.feedItemExternalType
    ) {
      const className = `${this.queryValues.feedItemExternalType}type-${this.queryValues.feedItemExternalId}id`;
      element = document.getElementsByClassName(className)[0] as HTMLElement;
    }

    return element;
  }

  // Check if feed item with queryParams exists for the member
  private feedItemExists({
    id,
    externalItemType,
    externalItemId
  }: FeedItemDto): boolean {
    if (this.queryValues) {
      return (
        Number(this.queryValues.feedItem) === id ||
        (Number(this.queryValues.feedItemExternalId) === externalItemId &&
          this.queryValues.feedItemExternalType === externalItemType)
      );
    }
    return false;
  }
}
