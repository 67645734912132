import { PhoneNumberDto, UserDto } from '@act/shared/data-transfer-objects';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';

@Component({
  selector: 'act-phone-numbers-display',
  templateUrl: './phone-numbers-display.component.html',
  styleUrls: ['./phone-numbers-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneNumbersDisplayComponent implements OnInit, OnChanges {
  @Input() phoneNumbers: PhoneNumberDto[];
  @Input() user: UserDto;

  showAll = false;

  constructor() {}

  ngOnInit() {
    this.phoneNumbers = this.phoneNumbers || [];
  }

  ngOnChanges() {
    this.phoneNumbers = this.phoneNumbers || [];
  }

  trackPhoneNumber(phoneNumber: PhoneNumberDto) {
    return phoneNumber.id;
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
  }
}
