import { Injectable } from '@angular/core';
import { SocketFacade } from '@act/common/sockets';
import {
  FeedItemUpdatedTypes,
  FeedLastSeenUpdatedTypes,
  FeedTypingListUpdatedTypes,
  JoinRoomEvent,
  NewFeedItemTypes,
  ScheduledEventEventTypes,
  IsTyping,
  IsDoneTyping
} from '@act/feed/events';
import { select, Store } from '@ngrx/store';
import { ClientBootstrap, MessageType, SOCKET_NAME } from '@act/shared/models';
import { Observable, Subject } from 'rxjs';
import { FeedItem } from '../models';
import { map } from 'rxjs/operators';
import { Platform } from '@act/core/platform';
import { getClientConfig } from '@act/shared/environment';
import { getValueOfObservable } from '@act/common/utilities';
import { HttpClient } from '@angular/common/http';
import {
  FeedItemDto,
  MessageDto,
  MessageWithFeedItemDto
} from '@act/shared/data-transfer-objects';
import {
  loadFeedItemFrame,
  setFeedItemFilters
} from './feed-item/feed-item.actions';
import {
  selectLoadingFeedItems,
  selectFeedItemFilters,
  selectSelectedFeedItems,
  selectCanLoadEarlier,
  selectCanLoadLater,
  selectLoadingEarlier,
  selectLoadingLater,
  selectLastEventItemId
} from './feed-item/feed-item.selectors';
import { createMessage, resendFailedMessage } from './message/message.actions';
import { FeedFacade } from './feed/feed.facade';
import { selectMessageLoading } from './message/message.selectors';

const url = () => {
  const config: ClientBootstrap = getClientConfig();
  return `${config.reema.serviceUris.websocketService}/chat`;
};

const socketConfig = () => ({
  url: url(),
  options: {
    path: '/socket.io',
    autoConnect: false,
    transports: ['websocket'],
    query: {
      token: Platform.getAuthToken(),
      deviceId: Platform.deviceId
    }
  }
});

@Injectable()
export class ChatFacade extends SocketFacade {
  private _scrollToBottom$: Subject<void> = new Subject<void>();
  scrollToBottom$: Observable<void> = this._scrollToBottom$.asObservable();

  private config: ClientBootstrap = getClientConfig();

  messageCreationIdTicker = 1;

  constructor(
    private feedFacade: FeedFacade,
    private platform: Platform,
    protected store: Store<any>,
    private http: HttpClient
  ) {
    super(socketConfig, SOCKET_NAME.CHAT, store);
  }

  joinRooms(): void {
    this.dispatcher.send(new JoinRoomEvent());
  }

  userIsTyping(feedId: number): void {
    this.dispatcher.send(new IsTyping({ feedId }));
  }

  userIsDoneTyping(feedId: number): void {
    this.dispatcher.send(new IsDoneTyping({ feedId }));
  }

  sendMessage(
    type: MessageType = MessageType.TEXT,
    memberId: string,
    text: string,
    delta: any,
    fileIds: string[],
    scheduledEventId: number = null
  ): void {
    const feedId = this.feedFacade.getFeedIdForMember(memberId);
    if (!feedId) {
      throw new Error('cannot send message to member. No feed found.');
    }

    const createdAt = new Date().getTime();

    const message: MessageDto = {
      id: null,
      type,
      text,
      incoming: false,
      feedId: feedId,
      userId: this.getUserId(),
      fileIds,
      createdAt,
      subjectId: null,
      noFollowUpNeeded: false,
      scheduledEventId: scheduledEventId
    };
    this.dispatcher.send(
      createMessage({ message, creationId: this.messageCreationIdTicker++ }),
      true
    );
    if (feedId) {
      this.feedFacade.updateLastSeen(feedId, message.createdAt);
    }
  }

  scrollToBottom(): void {
    this._scrollToBottom$.next();
  }

  protected registerEventListeners() {
    this.proxyWebsocketMessage(
      FeedTypingListUpdatedTypes.FEED_TYPING_LIST_UPDATED
    );

    this.proxyWebsocketMessage(NewFeedItemTypes.NEW_FEED_ITEM);
    this.proxyWebsocketMessage(FeedItemUpdatedTypes.FEED_ITEM_UPDATED);
    this.proxyWebsocketMessage(FeedItemUpdatedTypes.FEED_ITEM_DELETED);
    this.proxyWebsocketMessage(FeedLastSeenUpdatedTypes.FEED_LAST_SEEN_UPDATED);
    this.proxyWebsocketMessage(
      ScheduledEventEventTypes.SCHEDULED_EVENT_CREATED
    );
    this.proxyWebsocketMessage(
      ScheduledEventEventTypes.SCHEDULED_EVENT_DELETED
    );
    this.proxyWebsocketMessage(
      ScheduledEventEventTypes.SCHEDULED_EVENT_UPDATED
    );
  }

  getUserId(): string {
    return getValueOfObservable(this.platform.user$).id;
  }

  getFeedItems(
    feedId: number,
    limit: number,
    filters: { typeFilters: string[] },
    pivotalFeedItemId?: number,
    earlier?: boolean
  ): Observable<FeedItemDto[]> {
    let url = `${this.config.reema.serviceUris.websocketService}/feeds/${feedId}/items?page-size=${limit}`;

    if (pivotalFeedItemId) {
      const sortOrder = earlier ? 'DESC' : 'ASC';
      url += `&after-item-id=${pivotalFeedItemId}&sort-direction=${sortOrder}`;
    } else {
      url += `&sort-direction=DESC`;
    }

    if (filters && filters.typeFilters && filters.typeFilters.length) {
      url += `&type=${filters.typeFilters.join(',')}`;
    }
    return this.http
      .get<FeedItemDto[]>(url, { observe: 'response' })
      .pipe(map(response => response.body));
  }

  loadNextFrame(request: { earlier: boolean }) {
    this.store.dispatch(loadFeedItemFrame(request));
  }

  resendMessage(message: MessageDto): void {
    this.store.dispatch(
      resendFailedMessage({
        message,
        creationId: this.messageCreationIdTicker++
      })
    );
  }

  getMessages(messageIds: number[]): Observable<MessageDto[]> {
    const url = `${
      this.config.reema.serviceUris.websocketService
    }/chat/messages?ids=${messageIds.join(',')}`;
    return this.http
      .get<MessageDto[]>(url, { observe: 'response' })
      .pipe(map(response => response.body));
  }

  createMessage(message: MessageDto): Observable<MessageWithFeedItemDto> {
    const url = `${this.config.reema.serviceUris.websocketService}/chat/messages`;
    return this.http
      .post<MessageWithFeedItemDto>(url, message, { observe: 'response' })
      .pipe(map(response => response.body));
  }

  updateFeedItemFilters(itemTypeFilters: string[]) {
    this.store.dispatch(
      setFeedItemFilters({
        typeFilters: itemTypeFilters
      })
    );
  }

  updateMessageNoFollowUpNeeded(
    messageId: number,
    noFollowUpNeeded: boolean
  ): Observable<MessageDto> {
    const url = `${this.config.reema.serviceUris.websocketService}/chat/messages/${messageId}/no_follow_up_needed`;
    return this.http
      .post<MessageDto>(url, { noFollowUpNeeded }, { observe: 'response' })
      .pipe(map(response => response.body));
  }

  feedItems$: Observable<FeedItem[]> = this.store.pipe(
    select(selectSelectedFeedItems)
  );
  canLoadLater$: Observable<boolean> = this.store.pipe(
    select(selectCanLoadLater)
  );

  canLoadEarlier$: Observable<boolean> = this.store.pipe(
    select(selectCanLoadEarlier)
  );
  loadingLater$: Observable<boolean> = this.store.pipe(
    select(selectLoadingLater)
  );
  loadingEarlier$: Observable<boolean> = this.store.pipe(
    select(selectLoadingEarlier)
  );
  itemsTypeFilters$: Observable<{ typeFilters: string[] }> = this.store.pipe(
    select(selectFeedItemFilters)
  );
  loadingFeedItems$: Observable<boolean> = this.store.pipe(
    select(selectLoadingFeedItems)
  );

  lastEventItemId$ = this.store.select(selectLastEventItemId);

  messageLoading$: Observable<boolean> = this.store.select(
    selectMessageLoading
  );
}
