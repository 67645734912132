import { ChatFacade, NoteFacade } from '@act/feed';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CallFacade } from 'libs/feed/src/lib/+state/call/call.facade';
import { ScheduledEventFacade } from 'libs/feed/src/lib/+state/scheduled-event/scheduled-event.facade';
import { VisitFacade } from 'libs/feed/src/lib/+state/visit/visit.facade';
import { loadingItemTypes } from 'libs/shared/ui/spinner/src/lib/models/dot-spinner.model';
import { DotSpinnerService } from 'libs/shared/ui/spinner/src/lib/services/dot-spinner.service';
import { observable, Subscription } from 'rxjs';

@Component({
  selector: 'act-care-portal',
  templateUrl: './care-portal.component.html',
  styleUrls: ['./care-portal.component.scss']
})
export class CarePortalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
