import { AfterViewInit, Component } from '@angular/core';
import { AuthFacade } from '@act/shared/auth/clients';

@Component({
  selector: 'act-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
  constructor(private authFacade: AuthFacade) {}

  ngAfterViewInit(): void {
    (window as any).imgix && (window as any).imgix.init();
    if (!this.authFacade.isAuthenticated()) {
      this.authFacade.login();
    }
  }

  logIn() {
    this.authFacade.login();
  }
}
