import { UsersFacade } from '@act/features/users/data-access';
import {
  RaceEthnicityDto,
  UpdateRaceEthnicityRequestDto,
  UserDto,
  UserRaceEthnicityDto
} from '@act/shared/data-transfer-objects';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { combineLatest, from, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'act-member-info-edit',
  templateUrl: './member-info-edit.component.html',
  styleUrls: ['./member-info-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberInfoEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() user: UserDto;

  @Output() submitted: EventEmitter<void> = new EventEmitter();

  subscriptions: Subscription[] = [];

  languageOptions: { label: string; value: string }[];
  initialLanguage: string;
  updatedLanguage: string;
  languageChanged = false;

  selectedLanguageOption: string;
  otherLanguage: string;

  raceEthnicityOptions: RaceEthnicityOptions[] = [];
  raceEthnicityChanged = false;

  constructor(private usersFacade: UsersFacade) {
    const languages = [
      'English',
      'Spanish',
      'Hmong',
      'Arabic',
      'Chinese',
      'Russian',
      'Laos'
    ];
    this.languageOptions = languages.map(r => ({ label: r, value: r }));
    this.languageOptions.push({ label: 'Other', value: 'other' });
  }

  selectedLanguage: string;

  availableRaceEthnicities$: Observable<RaceEthnicityDto[]>;
  userRaceEthnicities$: Observable<UserRaceEthnicityDto[]>;

  languageUpdated() {
    this.updatedLanguage =
      this.selectedLanguageOption === 'other'
        ? this.otherLanguage
        : this.selectedLanguageOption;

    this.languageChanged = this.updatedLanguage !== this.initialLanguage;
  }

  async ngOnInit() {
    this.availableRaceEthnicities$ = from(
      this.usersFacade.loadAvailableRaceEthnicities()
    );
    this.userRaceEthnicities$ = from(
      this.usersFacade.loadUserRaceEthnicities(this.user.id)
    );
    const combinedObservable$ = combineLatest([
      this.availableRaceEthnicities$,
      this.userRaceEthnicities$
    ]);
    this.subscriptions.push(
      combinedObservable$.subscribe(([availableRaces, userRaces]) => {
        this.raceEthnicityOptions = this.getOptions(availableRaces, userRaces);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentLanguage =
      changes.user.currentValue && changes.user.currentValue.reportedLanguage;
    const previousLanguage =
      changes.user.previousValue && changes.user.previousValue.reportedLanguage;
    if (currentLanguage !== previousLanguage) {
      const selectedOption = this.languageOptions.find(
        o => o.value === this.user.reportedLanguage
      );
      const selectedLanguageOption = selectedOption
        ? selectedOption.value
        : null;
      const otherLanguage = selectedOption ? null : currentLanguage;

      this.initialLanguage = currentLanguage;
      this.selectedLanguageOption = selectedLanguageOption;
      this.otherLanguage = otherLanguage;
      this.languageUpdated();
    }
  }

  onSubmit() {
    if (this.languageChanged) {
      this.usersFacade.updateUser(this.user.id, {
        reportedLanguage: this.updatedLanguage
      });
      console.log('onSubmit: update language', this.updatedLanguage);
    } else {
      console.log('onSubmit: do not update language');
    }
    if (this.raceEthnicityChanged) {
      const updates: UpdateRaceEthnicityRequestDto[] = [];

      this.raceEthnicityOptions.forEach(o => {
        if (o.selected || o.details) {
          updates.push({
            raceEthnicityId: o.id,
            detail: o.details || null
          });
          if (o.children.length) {
            o.children.forEach(o => {
              if (o.selected || o.details) {
                updates.push({
                  raceEthnicityId: o.id,
                  detail: o.details || null
                });
              }
            });
          }
        }
      });
      console.log('onSubmit: update race', updates);

      this.usersFacade.updateUserRaceEthnicities(this.user.id, updates);
    } else {
      console.log('onSubmit: do not update race');
    }
    this.submitted.emit();
  }

  getOptions(
    availableRaceEthnicities: RaceEthnicityDto[],
    userRaceEthnicities: UserRaceEthnicityDto[]
  ): RaceEthnicityOptions[] {
    const userRaceMap = userRaceEthnicities.reduce(
      (map, userRace) => {
        map[userRace.raceEthnicityId] = userRace;
        return map;
      },
      <{ [key: string]: UserRaceEthnicityDto }>{}
    );

    const optionMap = availableRaceEthnicities.reduce(
      (map, race) => {
        map[race.id] = {
          id: race.id,
          selected: !!userRaceMap[race.id],
          name: race.name,
          details: userRaceMap[race.id] && userRaceMap[race.id].detail,
          requiresDetail: race.requiresDetail,
          children: []
        };
        return map;
      },
      <{ [key: string]: RaceEthnicityOptions }>{}
    );

    const optionArray: RaceEthnicityOptions[] = [];

    availableRaceEthnicities.forEach(race => {
      if (race.parentRaceEthnicityId) {
        // Non top-level options should be added to their parent's child array
        const parentOption = optionMap[race.parentRaceEthnicityId];
        if (parentOption) {
          parentOption.children.push(optionMap[race.id]);
        }
      } else {
        // Top-level options should be added to the options array
        optionArray.push(optionMap[race.id]);
      }
    });

    console.log('optionArray', optionArray);

    return optionArray;
  }

  optionUpdated(option: RaceEthnicityOptions, newValue: any) {
    if (option.requiresDetail) {
      option.selected = !!newValue;
    }
    if (option.children && !option.selected) {
      option.children.forEach(c => (c.selected = false));
    }
    this.raceEthnicityChanged = true;
  }

  trackById(index: number, object: { id: string | number }) {
    return object.id;
  }
}

interface RaceEthnicityOptions {
  id: number;
  selected: boolean;
  name: string;
  details: string;
  requiresDetail: boolean;
  children: RaceEthnicityOptions[];
}
