import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
  ElementRef,
  Renderer2
} from '@angular/core';

const HOVER_BACKGROUND = '#e7f6fc';
const DEFAULT_BACKGROUND = 'rgba(0, 0, 0, 0)';
const DEFAULT_BORDER = '2px dashed transparent';
const HOVER_BORDER = '2px dashed #21bce4';
const DEFAULT_PADDING = '10px 10px 3px 10px';
@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @Output() onFileDropped = new EventEmitter<any>();
  private showText = false;
  private textElement: HTMLElement;

  @HostBinding('style.background-color')
  private background = DEFAULT_BACKGROUND;
  @HostBinding('style.border')
  private border = DEFAULT_BORDER;
  @HostBinding('style.padding')
  private padding = DEFAULT_PADDING;
  @HostBinding('style.opacity') private opacity = '1';

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    this.background = HOVER_BACKGROUND;
    this.border = HOVER_BORDER;
    this.padding = DEFAULT_PADDING;
    this.opacity = '1';
    this.renderer.addClass(this.el.nativeElement, 'dragItem');
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    this.background = DEFAULT_BACKGROUND;
    this.border = DEFAULT_BORDER;
    this.padding = DEFAULT_PADDING;
    this.opacity = '1';
    this.renderer.removeClass(this.el.nativeElement, 'dragItem');
  }

  //Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    this.background = DEFAULT_BACKGROUND;
    this.border = DEFAULT_BORDER;
    this.padding = DEFAULT_PADDING;
    this.renderer.removeClass(this.el.nativeElement, 'dragItem');

    this.opacity = '1';
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }
}
