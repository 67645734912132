import { NoteDto, UserDto } from '@act/shared/data-transfer-objects';
import {
  Component,
  Input,
  OnChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NoteFacade } from '../+state/note/note.facade';
import { FeedActionsService } from '../feed-actions/feed-actions.service';
import { FeedCardAction, FeedCardData } from '@act/shared/ui/feed-card';
import { getCardDataFromNote } from './notes.card-helper';

@Component({
  selector: 'act-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnChanges {
  @Input() note: NoteDto;
  @Input() actingUser: UserDto;
  @Input() userMap: Dictionary<UserDto>;
  @ViewChild('notePreviewRef', { static: true }) notePreviewRef: TemplateRef<
    any
  >;
  cardData: FeedCardData;
  actions: FeedCardAction[];

  constructor(
    public noteFacade: NoteFacade,
    private feedActionsService: FeedActionsService
  ) {}

  editing$: Observable<boolean> = this.noteFacade.editedNoteId$.pipe(
    map(editedNoteId => editedNoteId === this.note.id)
  );

  ngOnChanges() {
    this.setCardData();
  }

  setCardData() {
    this.cardData = getCardDataFromNote(
      this.note,
      this.actingUser,
      this.userMap
    );

    this.actions = [
      {
        icon: 'edit',
        title: 'Edit',
        details: 'Edit Note',
        handler: () => this.editNote()
      }
    ];
  }

  /**
   * Open the edit note modal
   */
  editNote() {
    this.feedActionsService.editNote(this.note);
  }
}
