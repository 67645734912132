import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonComponentsFullscreenModule } from '@act/common/components/fullscreen';
import { SharedUiFormsModule } from '@act/shared/ui/forms';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,

    CommonComponentsFullscreenModule,
    SharedUiFormsModule
  ],
  declarations: [HomeComponent],
  exports: [HomeComponent]
})
export class CommonComponentsHomeModule {}
