import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  messageCreationError,
  createMessage,
  messageCreated,
  resendFailedMessage
} from './message.actions';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ChatFacade } from '../chat.facade';
import { of } from 'rxjs';

@Injectable()
export class MessageEffects {
  createMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMessage),
      mergeMap(action => {
        return this.chatFacade.createMessage(action.message).pipe(
          map(message =>
            messageCreated({ message, creationId: action.creationId })
          ),
          catchError(() =>
            of(messageCreationError({ creationId: action.creationId }))
          )
        );
      })
    )
  );

  resendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendFailedMessage),
      map(action => {
        return createMessage({
          message: action.message,
          creationId: action.creationId
        });
      })
    )
  );

  constructor(private actions$: Actions, private chatFacade: ChatFacade) {}
}
