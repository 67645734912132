import { createSelector } from '@ngrx/store';
import { cohortAdapter, cohortFeatureKey, State } from './cohort.reducer';
import { usersQueries } from 'libs/features/users/data-access/src/lib/+state/users/users.selectors';

const cohortSelectors = cohortAdapter.getSelectors();

const selectCohortState = (state: any): State => state[cohortFeatureKey];

export const selectCohortMap = createSelector(
  selectCohortState,
  cohortSelectors.selectEntities
);

export const selectCohort = createSelector(
  usersQueries.selectSelectedTeamMemberId,
  selectCohortMap,
  (idOfSelectedEntity, state) => {
    const entities = Object.values(state);
    return entities.filter(item => item.ownerUserId === idOfSelectedEntity);
  }
);

export const selectCohortsLoading = createSelector(
  selectCohortState,
  state => state.loadingCohorts
);
