import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { actions, ActionTypes } from './card.dto';

@Component({
  selector: 'act-card[heading][showAction]',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() heading: string;
  @Output() action = new EventEmitter<boolean>();
  @Input() showAction: boolean;
  @Input() icon: string;
  @Input() actionType: ActionTypes;
  @Input() addActionText: string;

  actions = actions;

  constructor() {}

  ngOnInit() {}
}
