import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { from, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JSFile } from '@act/server/models/files';
import { FileUploaderTriggerDirective } from '../file-uploader-trigger/file-uploader-trigger.directive';
import { FileThumbnailService } from '../../services/file-thumbnail.service';

const ONE_MEGA_BYTE = 1_000_000;

@Component({
  selector: 'act-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements AfterContentInit, OnChanges {
  constructor(
    private snackBar: MatSnackBar,
    private thumbnailService: FileThumbnailService
  ) {}

  @Input() maxCount: number = 10;
  @Input() maxFileSize: number = 5000000;
  @Input() allowedFileTypes: string[] = [];

  @Input() files: JSFile[];
  @Output() filesChange: EventEmitter<JSFile[]> = new EventEmitter();

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  @ContentChildren(FileUploaderTriggerDirective)
  fileUploaderTriggers: QueryList<FileUploaderTriggerDirective>;

  fileThumbnailMap: Map<JSFile, Observable<string | ArrayBuffer>> = new Map();

  // Listen to nested
  ngAfterContentInit() {
    this.fileUploaderTriggers.forEach(d => {
      d.onClick.subscribe(e => {
        this.fileInput.nativeElement.click();
      });
    });
  }

  ngOnChanges(change: SimpleChanges) {
    // If no files exist then reset the fileThumbnail map
    if (!this.files || !this.files.length) {
      this.fileThumbnailMap = new Map();
    }
  }

  addFile(fileList: FileList): Promise<void> {
    if (!fileList || !fileList.length) {
      return;
    }
    const file = fileList.item(0);

    if (this.files.length >= this.maxCount) {
      this.displayError(`You may only upload ${this.maxCount} files at once`);
      return;
    }
    if (file.size > this.maxFileSize) {
      this.displayError(
        `File must not be larger than ${this.maxFileSize / ONE_MEGA_BYTE} MB`
      );
      return;
    }
    if (
      this.allowedFileTypes.length &&
      !this.allowedFileTypes.includes(file.type)
    ) {
      this.displayError(`File type not supported`);
      return;
    }

    const fileExists = this.files.find((o: File) => {
      return (
        file.name === o.name &&
        file.lastModified === o.lastModified &&
        file.size === o.size &&
        file.type === o.type
      );
    });
    if (!fileExists) {
      this.updateFiles([...this.files, file]);
    }
  }

  removeFile(index: number) {
    if (index >= 0 && index < this.files.length) {
      this.fileThumbnailMap.delete(this.files[index]);

      const updatedFiles = [
        ...this.files.slice(0, index),
        ...this.files.slice(index + 1, this.files.length)
      ];
      this.updateFiles(updatedFiles);
    }
  }

  getFileThumbnail(file): Observable<string | ArrayBuffer> {
    if (!this.fileThumbnailMap.get(file)) {
      this.fileThumbnailMap.set(
        file,
        this.getThumbnailObservableFromFile(file)
      );
    }
    return this.fileThumbnailMap.get(file);
  }

  updateFiles(files: File[]) {
    this.files = files;
    this.filesChange.emit(this.files);
  }

  baseFileType(type: string): string {
    if (!type) {
      return null;
    }
    return type.split('/')[0];
  }

  displayError(errStr: string) {
    this.snackBar.open(errStr, 'close', { duration: 2000 });
  }

  getThumbnailObservableFromFile(
    file: JSFile
  ): Observable<string | ArrayBuffer> {
    if (!file) {
      return null;
    }

    return from(this.thumbnailService.getThumbnail(file));
  }
}
