import { UserRole } from '../users/user-role.enum';

export enum Application {
  CARE = 'CARE',
  MEMBER = 'MEMBER',
  CONSENT = 'CONSENT'
}

export const roleApplicationsMappings = {
  [UserRole.ADMIN]: [Application.CARE],
  [UserRole.PATIENT]: [Application.MEMBER],
  [UserRole.SOCIAL_WORKER]: [Application.CARE],
  [UserRole.COMMUNITY_HEALTH_WORKER]: [Application.CARE],
  [UserRole.GUIDE]: [Application.CARE],
  [UserRole.GUIDE_SUPERVISOR]: [Application.CARE],
  [UserRole.NURSE_PRACTICIONER]: [Application.CARE]
};
