import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromCallList from './call-list.reducer';
import {
  loadCallList,
  updateCallListFilters,
  updateCallSubject,
  callNumber,
  updateCallListPagination,
  initializeCallList,
  destroyCallList
} from './call-list.actions';
import { getValueOfObservable } from '@act/common/utilities';
import { CallListFilters } from './call-list.models';
import {
  selectCallList,
  selectCallListFilters,
  selectCallListLoading,
  selectCallListPagination,
  selectCallListUserLoading,
  selectCallListUserMap,
  selectCallListUsers
} from './call-list.selectors';
import { UserFacade } from '@act/features/user/data-access';

@Injectable()
export class CallListFacade {
  callCreationIdTicker = 1;

  constructor(
    private store: Store<fromCallList.State>,
    private userFacade: UserFacade
  ) {}

  callList$ = this.store.select(selectCallList);
  callListLoading$ = this.store.select(selectCallListLoading);
  callListFilters$ = this.store.select(selectCallListFilters);
  callListPagination$ = this.store.select(selectCallListPagination);

  userMap$ = this.store.select(selectCallListUserMap);
  users$ = this.store.select(selectCallListUsers);
  usersLoading$ = this.store.select(selectCallListUserLoading);

  setFilters(filters: CallListFilters) {
    this.store.dispatch(updateCallListFilters({ filters }));
  }

  setPagination(req: { pageSize: number; pageIndex: number }) {
    this.store.dispatch(updateCallListPagination(req));
  }

  initializeCallList() {
    this.store.dispatch(initializeCallList());
  }

  destroyCallList() {
    this.store.dispatch(destroyCallList());
  }

  loadList() {
    const { noSubjectId, ownCalls, incoming } = getValueOfObservable(
      this.callListFilters$
    );
    const pagination = getValueOfObservable(this.callListPagination$);

    this.store.dispatch(
      loadCallList({
        params: {
          noSubjectId: noSubjectId,
          participantIds: ownCalls
            ? [getValueOfObservable(this.userFacade.user$).id]
            : null,
          incoming: incoming === null || incoming === undefined
        },
        requestBody: null,
        pagination
      })
    );
  }

  updateSubjectForCall(callId: number, subjectId: string) {
    this.store.dispatch(
      updateCallSubject({ params: { callId }, requestBody: { subjectId } })
    );
  }

  callNumber(phoneNumber: string) {
    this.store.dispatch(
      callNumber({
        params: null,
        requestBody: { phoneNumber, targetUserId: null }
      })
    );
  }
}
