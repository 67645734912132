import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DotSpinnerComponent } from './dot-spinner/dot-spinner.component';
import { DotSpinnerService } from './services/dot-spinner.service';

@NgModule({
  imports: [CommonModule],
  declarations: [DotSpinnerComponent],
  exports: [DotSpinnerComponent],
  providers: [DotSpinnerService]
})
export class SharedUiSpinnerModule {}
