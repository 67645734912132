import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import {
  selectTeamMemberAction,
  usersQueries
} from '@act/features/users/data-access';
import { TodoRequests } from './todo.requests';
import { loadTodos, refreshTodos } from './todo.actions';
import { select, Store } from '@ngrx/store';

@Injectable()
export class TodoEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private todoRequests: TodoRequests
  ) {}

  loadTodos$ = this.todoRequests.getTodos.createEffect(this.actions$);

  initializeLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectTeamMemberAction),
      filter(action => !!action.teamMemberId),
      map(action => {
        return loadTodos({
          params: { guideId: action.teamMemberId },
          requestBody: null
        });
      })
    )
  );

  refreshTodos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshTodos),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedTeamMemberId))
      ),
      filter(([action, teamMemberId]) => !!teamMemberId),
      map(([action, teamMemberId]) => {
        return loadTodos({
          params: { guideId: teamMemberId },
          requestBody: null
        });
      })
    )
  );
}
