import { noteAdapter, noteFeatureKey, State } from './note.reducer';
import * as fromChat from '../chat.selectors';
import { createSelector } from '@ngrx/store';
import { NoteDto } from '@act/shared/data-transfer-objects';

const noteSelectors = noteAdapter.getSelectors();

const selectNoteState = createSelector(
  fromChat.selectChatState,
  (state): State => state[noteFeatureKey]
);

export const selectNoteEntities = createSelector(
  selectNoteState,
  noteSelectors.selectEntities
);

const selectAllNote = createSelector(
  selectNoteState,
  noteSelectors.selectAll
);

export const selectLoading = createSelector(
  selectNoteState,
  state => state.loading
);

export const selectEditedNoteId = createSelector(
  selectNoteState,
  state => state.editedNoteId
);

export const selectFeedItemIdNotesViewed = createSelector(
  selectNoteState,
  state => state.viewFeedItemNotes
);

export const selectNotesBeingCreated = createSelector(
  selectNoteState,
  (state: State): boolean => {
    return !!state.inflightCreationIds.length;
  }
);

export const selectNotesByFeedItemId = createSelector(
  selectAllNote,
  (notes: NoteDto[], feedItemId: number) => {
    return notes.reduce(
      (feedItemNotes, note) => {
        if (note.feedItemId === feedItemId) {
          feedItemNotes.push(note);
        }
        return feedItemNotes;
      },
      <NoteDto[]>[]
    );
  }
);

export const selectNotesForViewedFeedItemId = createSelector(
  state => state,
  selectFeedItemIdNotesViewed,
  (state, feedItemId: number) => {
    return selectNotesByFeedItemId(state, feedItemId);
  }
);
