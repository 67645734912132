import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LetDirective} from './directives/let/let.directive';

const DIRECTIVES = [LetDirective];

@NgModule({
  imports: [CommonModule],
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class CommonDirectivesModule {
}
