import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { SharedUiIconModule } from '@act/shared/ui/icon';
import { CommonPipesPhoneNumberModule } from '@act/common/pipes/phone-number';
import { FeaturesProfileUiModule } from '@act/features/profile/ui';

import { MomentModule } from 'ngx-moment';

import { MemberHeaderComponent } from './member-header/member-header.component';
import { MemberNavBarComponent } from './member-nav-bar/member-nav-bar.component';
import { CommonPipesHoursMinutesSecondsModule } from '@act/common/pipes/hours-minutes-seconds';

const COMPONENTS = [MemberHeaderComponent, MemberNavBarComponent];

@NgModule({
  imports: [
    CommonModule,

    // material modules
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTooltipModule,
    MatMenuModule,

    SharedUiIconModule,
    CommonPipesPhoneNumberModule,
    FeaturesProfileUiModule,
    CommonPipesHoursMinutesSecondsModule,

    MomentModule
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS]
})
export class FeaturesPatientsUiModule {}
