import { NgModule } from '@angular/core';
import { RouterModule, UrlSegment, Route } from '@angular/router';
import { CommonComponentsHomeModule } from '@act/common/components/home';
import { IsAuthenticatedService } from '@act/shared/auth/clients';
import { RouterComponent } from './router/router.component';

export function applicationMatcher(url: UrlSegment[], _, route: Route) {
  if (url[0].path === 'callbacks') {
    return null;
  }
  return {
    consumed: url,
    posParams: url.reduce((params, urlSegment, index) => {
      params['param' + (index + 1)] = new UrlSegment(urlSegment.path, {});
      return params;
    }, {})
  };
}

@NgModule({
  imports: [
    CommonComponentsHomeModule,
    RouterModule.forRoot(
      [
        {
          matcher: applicationMatcher,
          canActivate: [IsAuthenticatedService],
          component: RouterComponent
        }
      ],
      {
        enableTracing: false
      }
    )
  ]
})
export class CarePortalRoutingModule {
  constructor() {}
}
