import { Observable } from 'rxjs';

export type PlatformSpeedbump<T> = (
  data: T
) => Observable<boolean> | Promise<boolean> | boolean;

export class PlatformSpeedbumpRef<T> {
  constructor(
    public id: string,
    public fn: PlatformSpeedbump<T>,
    public remove: () => void
  ) {}
}
