import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent implements OnInit {
  @Input() text: string;
  @Input() content: TemplateRef<any>;
  constructor() {}

  ngOnInit() {}
}
