import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  clearFeed,
  deleteFeedItem,
  feedItemsFrameLoaded,
  feedItemsFrameLoadError,
  loadFeedItemFrame,
  newFeedItem,
  resetFeedItemFilters,
  setFeedItemFilters
} from './feed-item.actions';
import {
  map,
  mergeMap,
  switchMap,
  catchError,
  withLatestFrom
} from 'rxjs/operators';
import { ChatFacade } from '../chat.facade';
import {
  FeedItemUpdatedEventPayload,
  FeedItemUpdatedTypes,
  NewFeedItemEvent,
  NewFeedItemTypes
} from '@act/feed/events';
import { of, Subject } from 'rxjs';
import { select, Store, Action } from '@ngrx/store';
import {
  selectFeedItemWindow,
  selectFeedItemFilters
} from './feed-item.selectors';
import { feedQueries } from '../feed/feed.selectors';
import { selectFeed } from '../feed/feed.actions';

const FEED_ITEM_PAGE_SIZE = 100;

@Injectable()
export class FeedItemEffects {
  getFeedItemWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeedItemFrame),
      withLatestFrom(
        this.store.pipe(select(selectFeedItemWindow)),
        this.store.pipe(select(selectFeedItemFilters)),
        this.store.pipe(select(feedQueries.selectSelectedFeedId))
      ),
      mergeMap(([action, window, filters, feedId]) => {
        if (!window.earliest.id && !window.earliest.done) {
          // This is the first window loaded
          return this.chatFacade
            .getFeedItems(feedId, FEED_ITEM_PAGE_SIZE, filters)
            .pipe(
              map(feedItems =>
                feedItemsFrameLoaded({
                  items: feedItems,
                  earlier: action.earlier,
                  isMore: feedItems.length === FEED_ITEM_PAGE_SIZE
                })
              ),
              catchError(() =>
                of(feedItemsFrameLoadError({ earlier: action.earlier }))
              )
            );
        } else {
          const edge = action.earlier ? window.earliest : window.latest;

          // We've reached the end so don't load more
          if (edge.done) {
            const sub = new Subject<Action>();
            sub.next(
              feedItemsFrameLoaded({
                items: [],
                earlier: action.earlier,
                isMore: false
              })
            );
            return sub;
          }

          return this.chatFacade
            .getFeedItems(
              feedId,
              FEED_ITEM_PAGE_SIZE,
              filters,
              edge.id,
              action.earlier
            )
            .pipe(
              map(feedItems =>
                feedItemsFrameLoaded({
                  items: feedItems,
                  earlier: action.earlier,
                  isMore: feedItems.length === FEED_ITEM_PAGE_SIZE
                })
              ),
              catchError(() =>
                of(feedItemsFrameLoadError({ earlier: action.earlier }))
              )
            );
        }
      })
    )
  );

  // When feed is selected then clear feed items and load first window
  onFeedSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectFeed, setFeedItemFilters),
      withLatestFrom(this.store.pipe(select(feedQueries.selectSelectedFeedId))),
      switchMap(([action, feedId]) => {
        if (feedId && action) {
          const actions: Action[] = [clearFeed()];

          // Reset Feed Filters if selecting new feed
          if (action.type === selectFeed({ feedId: null }).type) {
            actions.push(resetFeedItemFilters());
          }
          actions.push(loadFeedItemFrame({ earlier: null }));

          return actions;
        } else {
          return [];
        }
      })
    )
  );

  newFeedItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewFeedItemTypes.NEW_FEED_ITEM),
      map((action: NewFeedItemEvent) =>
        newFeedItem({ item: action.payload.feedItem })
      )
    )
  );

  deleteItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedItemUpdatedTypes.FEED_ITEM_DELETED),
      map((action: { payload: FeedItemUpdatedEventPayload }) => {
        return deleteFeedItem({ feedItemId: action.payload.feedItem.id });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private chatFacade: ChatFacade,
    private store: Store<any>
  ) {}
}
