import { Action, createReducer, on } from '@ngrx/store';
import {
  loadUserConsent,
  userConsentLoaded,
  userConsentLoadError
} from './consent.actions';
import { TermGroupDto } from '@act/shared/data-transfer-objects';

export const consentFeatureKey = 'consents';

export interface State {
  userTermGroups: {
    [key: string]: TermGroupDto[];
  };
}

export const initialState: State = { userTermGroups: {} };

const consentReducer = createReducer(
  initialState,
  on(loadUserConsent, (state, action) => state),
  on(userConsentLoaded, (state, action) => ({
    userTermGroups: {
      ...state.userTermGroups,
      [action.params.userId]: action.responseBody
    }
  })),
  on(userConsentLoadError, (state, action) => state)
);

export function reducer(state: State | undefined, action: Action) {
  return consentReducer(state, action);
}
