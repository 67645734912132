import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  NeedCategoryDto,
  UserNeedDto
} from '@act/shared/data-transfer-objects';
import {
  loadUserNeeds,
  userNeedCreated,
  newUserNeed,
  userNeedDeleted,
  userNeedsLoaded,
  userNeedUpdated,
  userNeedOrderUpdated,
  updateUserNeedOrder,
  loadNeedCategories,
  needCategoriesLoaded
} from './need.actions';

export const userNeedFeatureKey = 'needs';

export interface State extends EntityState<UserNeedDto> {
  editedUserNeedId: number;
  loadingUserNeeds: boolean;
  inflightCreationIds: number[];
  needCategories: NeedCategoryDto[];
}

export const adapter: EntityAdapter<UserNeedDto> = createEntityAdapter<
  UserNeedDto
>();

export const initialState: State = adapter.getInitialState({
  editedUserNeedId: null,
  loadingUserNeeds: true,
  inflightCreationIds: [],
  needCategories: []
});

const userNeedReducer = createReducer(
  initialState,
  on(loadUserNeeds, (state, action) => {
    const newState = adapter.removeAll(state); // remove existing events
    let loadingUserNeeds = true;

    return {
      ...newState,
      loadingUserNeeds
    };
  }),
  on(userNeedsLoaded, userNeedOrderUpdated, (state, action) => {
    const newState = adapter.addMany(action.responseBody, state);

    let loadingUserNeeds = state.loadingUserNeeds;

    if (loadingUserNeeds) {
      loadingUserNeeds = false;
    }

    return {
      ...newState,
      loadingUserNeeds
    };
  }),
  on(loadNeedCategories, (state, action) => {
    return {
      ...state,
      loadingUserNeeds: true,
      needCategories: []
    };
  }),
  on(needCategoriesLoaded, (state, action) => {
    return {
      ...state,
      loadingUserNeeds: false,
      needCategories: action.responseBody
    };
  }),
  on(userNeedCreated, (state, action) => {
    return {
      ...adapter.upsertOne(action.responseBody, state),
      inflightCreationIds: state.inflightCreationIds.filter(
        id => id !== action.actionParams.creationId
      )
    };
  }),
  on(userNeedDeleted, (state, action) =>
    adapter.removeOne(action.params.userNeedId, state)
  ),
  on(newUserNeed, (state, action) => adapter.upsertOne(action.item, state)),
  on(userNeedUpdated, (state, action) => {
    return adapter.upsertOne(action.responseBody, state);
  })
);

export const userNeedAdapter = adapter;

export function reducer(state: State | undefined, action: Action) {
  return userNeedReducer(state, action);
}
