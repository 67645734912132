import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input/base-input.component';

export interface SelectDto {
  value: any;
  label: string;
}

@Component({
  selector: 'act-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent extends BaseInputComponent<string> {
  @Input() label: string;
  @Input() selectValue: any;
  @Input() placeHolderValue: string;
  @Input() panelClassName: string;
  @Input() floatLabelValue: string;
  @Input() options: SelectDto[];
  @Output() onSelectionChange = new EventEmitter<any>();
}
