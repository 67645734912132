import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector
} from '@angular/core';
import { MembersInfoWindowComponent } from '../members/members-info-window/members-info-window.component';
import { MarkerData } from './map.dto';

@Injectable()
export class MapEventsService {
  constructor(
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {}
  hoveredInfoWindow: google.maps.InfoWindow;
  clickedInfoWindow: google.maps.InfoWindow;
  hoveredCompRef: ComponentRef<MembersInfoWindowComponent>;
  clickedCompRef: ComponentRef<MembersInfoWindowComponent>;
  map: google.maps.Map;
  /**
   * Marker mouseout Handler
   */
  mouseOutHandler() {
    if (this.hoveredInfoWindow) {
      this.closeWindow(this.hoveredInfoWindow, this.hoveredCompRef);
    }
  }

  setMap(map: google.maps.Map) {
    this.map = map;
  }

  /**
   * Marker mouseover Handler
   */
  mouseOverHandler(
    data: MarkerData | MarkerData[],
    marker: google.maps.Marker
  ) {
    if (!this.hoveredInfoWindow) {
      this.hoveredInfoWindow = new google.maps.InfoWindow();
      this.hoveredInfoWindow.setOptions({ disableAutoPan: true });
    }
    const { nativeElement, compRef } = this.createInfoWindow(
      data,
      this.hoveredCompRef
    );
    this.hoveredCompRef = compRef;
    this.hoveredInfoWindow.setContent(nativeElement);
    this.hoveredInfoWindow.open({
      anchor: marker,
      map: this.map
    });
  }

  /**
   * Marker onclick Handler
   */
  markerClickHandler(
    data: MarkerData | MarkerData[],
    marker: google.maps.Marker
  ) {
    if (!this.clickedInfoWindow) {
      this.clickedInfoWindow = new google.maps.InfoWindow();
    }
    this.closeWindow(this.clickedInfoWindow, this.clickedCompRef);
    const { nativeElement, compRef } = this.createInfoWindow(
      data,
      this.clickedCompRef
    );
    this.clickedCompRef = compRef;
    this.clickedInfoWindow.setContent(nativeElement);
    this.clickedInfoWindow.open({
      anchor: marker,
      map: this.map
    });
  }

  /**
   * Map onclick Handler
   */
  mapClickHandler() {
    if (this.clickedInfoWindow) {
      this.closeWindow(this.clickedInfoWindow, this.clickedCompRef);
    }

    if (this.hoveredInfoWindow) {
      this.closeWindow(this.hoveredInfoWindow, this.hoveredCompRef);
    }
  }

  /**
   * Closes info window
   */
  private closeWindow(
    infoWindow: google.maps.InfoWindow,
    ref: ComponentRef<MembersInfoWindowComponent>
  ) {
    infoWindow.close();
    if (ref) {
      ref.destroy();
    }
  }

  /**
   * Creates new info window
   */
  private createInfoWindow(
    data: MarkerData | MarkerData[],
    compRef: ComponentRef<MembersInfoWindowComponent>
  ) {
    if (compRef) {
      compRef.destroy();
    }

    const compFactory = this.resolver.resolveComponentFactory(
      MembersInfoWindowComponent
    );
    compRef = compFactory.create(this.injector);

    if (Array.isArray(data)) {
      compRef.instance.data = data;
    } else {
      compRef.instance.data = [data];
    }

    const div = document.createElement('div');
    div.appendChild(compRef.location.nativeElement);

    this.appRef.attachView(compRef.hostView);
    compRef.onDestroy(() => {
      this.appRef.detachView(compRef.hostView);
    });

    return {
      nativeElement: div,
      compRef
    };
  }
}
