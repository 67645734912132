import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'act-html-content-editor',
  templateUrl: './html-content-editor.component.html',
  styleUrls: ['./html-content-editor.component.scss']
})
export class HtmlContentEditorComponent implements OnInit {
  @ViewChild(QuillEditorComponent, null) private editor: QuillEditorComponent;

  @Input() value: string;
  @Input() control: AbstractControl;

  get quillEditor() {
    return this.editor && this.editor.quillEditor;
  }

  constructor() {}

  quilModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }]
    ],
    clipboard: {
      matchVisual: false // hack to fix bug where a new line break would be added before a list item (I don't know why this works): https://github.com/quilljs/quill/issues/2905#issuecomment-683128521
    }
  };

  ngOnInit() {}
}
