export enum FabButtonType {
  SCHEDULED_EVENT = 'scheduled_event',
  NOTE = 'note',
  VISIT = 'visit',
  TEXT_MESSAGE = 'text_message',
  PHONE_CALL = 'phone_call'
}

export interface FabButtonDto {
  text: string;
  icon: string;
  type: FabButtonType;
}

export const fabButtons: FabButtonDto[] = [
  {
    text: 'Scheduled Event',
    icon: 'notifications',
    type: FabButtonType.SCHEDULED_EVENT
  },
  {
    text: 'Note',
    icon: 'assignment',
    type: FabButtonType.NOTE
  },
  {
    text: 'Visit',
    icon: 'domain',
    type: FabButtonType.VISIT
  },
  {
    text: 'Text Message',
    icon: 'textsms',
    type: FabButtonType.TEXT_MESSAGE
  },
  {
    text: 'Phone Call',
    icon: 'phone_forwarded',
    type: FabButtonType.PHONE_CALL
  }
];
