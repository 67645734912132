import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './member-stage.reducer';
import { Observable } from 'rxjs';
import { ClientBootstrap } from '@act/shared/models';
import {
  MemberStageDto,
  MemberStatusDto
} from '@act/shared/data-transfer-objects';
import { HttpClient } from '@angular/common/http';
import { getClientConfig } from '@act/shared/environment';
import { map } from 'rxjs/operators';
import {
  selectAllMemberStages,
  selectMemberStagesLoading,
  selectMemberStageMap,
  selectAllMemberStatuses,
  selectMemberStatusMap,
  selectMemberStatusesLoading
} from './member-stage.selectors';
import { Dictionary } from '@ngrx/entity';

@Injectable()
export class MemberStageFacade {
  constructor(private store: Store<State>, private http: HttpClient) {}

  private config: ClientBootstrap = getClientConfig();

  memberStages$: Observable<MemberStageDto[]> = this.store.pipe(
    select(selectAllMemberStages)
  );

  memberStageMap$: Observable<Dictionary<MemberStageDto>> = this.store.pipe(
    select(selectMemberStageMap)
  );

  memberStagesLoading$: Observable<boolean> = this.store.pipe(
    select(selectMemberStagesLoading)
  );

  memberStatuses$: Observable<MemberStatusDto[]> = this.store.pipe(
    select(selectAllMemberStatuses)
  );

  memberStatusMap$: Observable<Dictionary<MemberStatusDto>> = this.store.pipe(
    select(selectMemberStatusMap)
  );

  memberStatusesLoading$: Observable<boolean> = this.store.pipe(
    select(selectMemberStatusesLoading)
  );
}
