import { Injectable } from '@angular/core';
import { ConverterSet, ConverterGet } from '../models/converter.interface';
import { PersistenceProviders } from '../models/providers';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {
  private testKey = 'test';
  private localStorageAvailable = true;
  private backupStorage: {
    [key: string]: string;
  } = {};

  constructor() {
    try {
      window.localStorage.setItem(this.testKey, 'value');
      window.localStorage.removeItem(this.testKey);
    } catch(e) {
      this.localStorageAvailable = false;
    }
  }

  set<T>(key: string, value: string | T, provider = PersistenceProviders.LOCAL_STORAGE, converter?: ConverterSet<T>): void {
     if(converter) {
      value = converter(value as T);
     }

    if(PersistenceProviders.LOCAL_STORAGE) {
      this.localStorageAvailable ? window.localStorage.setItem(key, value as string) : this.backupStorage[key] = value as string;
    } else if(PersistenceProviders.COOKIES) {

    }
  }

  get<T>(key: string, provider = PersistenceProviders.LOCAL_STORAGE, converter?: ConverterGet<T>): T | string {
    let value: string;
    if(provider === PersistenceProviders.LOCAL_STORAGE) {
      value = this.localStorageAvailable ? window.localStorage.getItem(key) : this.backupStorage[key];
    } else if(PersistenceProviders.COOKIES) {

    }

    return converter ? converter(value as string) : value;
  }

  remove(key: string, provider = PersistenceProviders.LOCAL_STORAGE): void {
    if(provider === PersistenceProviders.LOCAL_STORAGE) {
      window.localStorage.removeItem(key);
    } else if(PersistenceProviders.COOKIES) {

    }
  }

  clear(provider = PersistenceProviders.LOCAL_STORAGE) {
    if(provider === PersistenceProviders.LOCAL_STORAGE) {
      window.localStorage.clear();
    } else if(PersistenceProviders.COOKIES) {

    }
  }
}
