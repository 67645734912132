import {
  NeedCategoryDto,
  UserNeedDto
} from '@act/shared/data-transfer-objects';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UserNeedFacade } from 'libs/feed/src/lib/+state/need/need.facade';

@Component({
  selector: 'act-need-display',
  templateUrl: './need-display.component.html',
  styleUrls: ['./need-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NeedDisplayComponent implements OnInit, OnChanges {
  @Input() userNeeds: UserNeedDto[];
  @Input() needCategories: NeedCategoryDto[];
  @Output() editNeed = new EventEmitter<UserNeedDto>();

  constructor(private userNeedFacade: UserNeedFacade) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.userNeeds) {
      this.userNeeds = changes.userNeeds.currentValue;
    }
  }

  drop(event) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.userNeeds, event.previousIndex, event.currentIndex);
      const userNeed = event.previousContainer.data[event.currentIndex];
      const userNeedAfter =
        event.currentIndex > 0
          ? event.previousContainer.data[event.currentIndex - 1]
          : null;
      this.userNeedFacade.updateUserNeedOrder({
        id: userNeed.id,
        op: 'move',
        after: userNeedAfter && userNeedAfter.id
      });
    }
  }

  getNeedCategoryName(id: number) {
    let name = 'Unknown';
    const category = (this.needCategories || []).find(i => i.id === id);

    if (category) {
      name = category.name;
    }
    return name;
  }

  getNeedStatusColor(status: string) {
    let color = '';
    switch (status.toLowerCase()) {
      case 'need identified':
        color = 'pink';
        break;
      case 'resource connected':
        color = 'blue';
        break;
      case 'need fulfilled':
        color = 'green';
        break;
    }
    return color;
  }
}
