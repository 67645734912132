import {
  PhoneNumberDto,
  CallCreationRequestDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import {
  Component,
  OnInit,
  Input,
  ComponentRef,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { formatPhoneNumber } from '@act/common/utilities';
import { CallFacade } from '../../+state/call/call.facade';
import { AnchoredDialogComponent } from 'libs/shared/ui/dialog/src/lib/components/anchored-dialog/anchored-dialog.component';
import { AnchoredDialogService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog.service';

@Component({
  selector: 'act-phone-dialog',
  templateUrl: './phone-dialog.component.html',
  styleUrls: ['./phone-dialog.component.scss']
})
export class PhoneDialogComponent implements OnInit {
  @Input() user: UserDto;
  @Input() scheduledEventId: number;

  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;
  anchoredDialogRef: ComponentRef<AnchoredDialogComponent>;

  constructor(
    public callFacade: CallFacade,
    private anchoredDialogService: AnchoredDialogService
  ) {}

  open(): ComponentRef<AnchoredDialogComponent> {
    if (!this.anchoredDialogRef) {
      const name = this.user
        ? this.user.firstName + ' ' + this.user.lastName
        : null;
      this.anchoredDialogRef = this.anchoredDialogService.create({
        icon: 'phone_forwarded',
        title: 'Create call' + (name ? ' for ' + name : ''),
        ref: this.dialogRef,
        actionType: 'create',
        eventType: 'call',
        eventId: this.user.id
      });
    }

    return this.anchoredDialogRef;
  }

  ngOnInit() {}

  phoneNumberInFormat(phone: string) {
    return formatPhoneNumber(phone);
  }

  startCall(request: CallCreationRequestDto): void {
    this.callFacade.startCall(request);
    this.anchoredDialogRef.instance.closeModal();
  }

  trackItem(index: number, number: PhoneNumberDto): number {
    return number.id;
  }
}
