import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of, Subject } from 'rxjs';
import {
  filter,
  groupBy,
  map,
  mergeMap,
  reduce,
  switchMap,
  toArray,
  withLatestFrom
} from 'rxjs/operators';
import {
  loadUserDetails,
  userDetailsLoaded,
  userPhoneNumberUpdated,
  userAddressUpdated,
  loadTeam,
  teamLoaded,
  loadMembers,
  selectTeamMember,
  loadTodayResults,
  todayResultsLoaded,
  userWasUpdated,
  selectMember,
  todayResultTypesLoaded,
  clearTodayItems,
  clearMembers,
  loadAllMembers,
  updateUserSuccess,
  allTodayResultsLoaded
} from './users.actions';
import { usersQueries } from './users.selectors';
import { userAuthenticated, userQueries } from '@act/features/user/data-access';
import { UsersRequests } from './users.requests';
import { TodayResultDto } from '@act/shared/data-transfer-objects';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private usersRequests: UsersRequests
  ) {}

  initializeLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthenticated),
      map(action => {
        return loadTeam({ params: null, requestBody: null });
      })
    )
  );

  triggerUserDetailsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectMember),
      filter(action => !!action.id),
      map(action => {
        return loadUserDetails({
          params: { userId: action.id },
          requestBody: null
        });
      })
    )
  );

  loadTeam$ = this.usersRequests.getTeam.createEffect(this.actions$);
  updateUser$ = this.usersRequests.updateUser.createEffect(this.actions$);
  updateUsers$ = this.usersRequests.updateUsers.createEffect(this.actions$);
  loadMembers$ = this.usersRequests.getMembersForGuide.createEffect(
    this.actions$
  );

  // If user selected doesn't match the guide selected then change the selected guide
  selectGuideIfNotSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userDetailsLoaded),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedMemberId)),
        this.store.pipe(select(usersQueries.selectSelectedTeamMemberId))
      ),
      filter(([action, memberId, guideId]) => {
        const guideMemberMismatch =
          memberId === action.responseBody.id &&
          action.responseBody.assignedGuideId !== guideId;
        return guideMemberMismatch && guideId !== 'all';
      }),
      map(([action, memberId, guideId]) => {
        return selectTeamMember({
          teamMemberId: action.responseBody.assignedGuideId
        });
      })
    )
  );

  reloadTodayViewWhenUserWasUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userWasUpdated),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedTeamMemberId))
      ),
      filter(
        ([action, selectedTeamMember]) =>
          action.user.assignedGuideId === selectedTeamMember
      ),
      map(([action, guideId]) => {
        return loadTodayResults({
          params: { guideId },
          actionParams: { silent: true },
          requestBody: null
        });
      })
    )
  );

  loadTodayResults$ = this.usersRequests.getTodayResultsForGuide.createEffect(
    this.actions$
  );

  loadAllTodayResults$ = this.usersRequests.getAllTodayResults.createEffect(
    this.actions$
  );

  loadTodayResultTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(todayResultsLoaded, allTodayResultsLoaded),
      mergeMap(({ responseBody }) => {
        return of(responseBody).pipe(
          mergeMap(today => today),
          groupBy(today => today.type),
          mergeMap(group$ =>
            group$.pipe(
              reduce(
                (acc, cur) => {
                  acc[group$.key].push(cur);
                  return acc;
                },
                { [group$.key]: [] }
              )
            )
          ),
          toArray(),
          map(today => {
            const object = Object.assign({}, ...today);
            for (const key in object) {
              if (Object.prototype.hasOwnProperty.call(object, key)) {
                const elements = object[key];
                elements.sort(
                  (a: TodayResultDto, b: TodayResultDto) =>
                    new Date(b.date).valueOf() - new Date(a.date).valueOf()
                );
                elements.sort((a: TodayResultDto, b: TodayResultDto) => {
                  if (a.time === null) {
                    return 1;
                  } else if (b.time === null) {
                    return -1;
                  } else {
                    if (a.time < b.time) return -1;
                    if (a.time > b.time) return 1;
                  }
                });
              }
            }
            return todayResultTypesLoaded({ today: object });
          })
        );
      })
    )
  );

  onTeamLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(teamLoaded, selectTeamMember),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedTeamMemberId)),
        this.store.pipe(select(usersQueries.selectAllTeam))
      ),
      filter(
        ([action, selectedTeamMemberId, teammates]) =>
          !!selectedTeamMemberId &&
          !!teammates.find(u => u.id === selectedTeamMemberId)
      ),
      map(([action, selectedTeamMemberId, user]) => {
        return loadMembers({
          params: { guideId: selectedTeamMemberId },
          requestBody: null
        });
      })
    )
  );

  clearTodayItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectTeamMember),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedTeamMemberId))
      ),
      filter(([action, selectedTeamMemberId]) => {
        return selectedTeamMemberId === null;
      }),
      switchMap(([action, selectedTeamMemberId]) =>
        of(clearTodayItems({}), clearMembers({}))
      )
    )
  );
  triggerLoadAllMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectTeamMember),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedTeamMemberId)),
        this.store.pipe(select(userQueries.selectUser))
      ),
      filter(
        ([action, selectedTeamMemberId, user]) =>
          selectedTeamMemberId === 'all' && user.featureFlags.VIEW_ALL_MEMBERS
      ),
      map(() => loadAllMembers({ requestBody: null, params: null }))
    )
  );

  loadAllMembers$ = this.usersRequests.getAllMembers.createEffect(
    this.actions$
  );

  // If no teammate or member is selected then select the current user when loaded
  selectTeammateIfNoneSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(teamLoaded),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedTeamMemberId)),
        this.store.pipe(select(usersQueries.selectSelectedMemberId)),
        this.store.pipe(select(userQueries.selectUser))
      ),
      filter(
        ([action, selectedTeamMemberId, selectedMemberId, user]) =>
          !selectedTeamMemberId &&
          !selectedMemberId &&
          !!action.responseBody.length
      ),
      map(([action, selectedTeamMemberId, selectedMemberId, user]) => {
        const userFound = !!action.responseBody.find(u => u.id === user.id);
        return selectTeamMember({
          teamMemberId: userFound ? user.id : action.responseBody[0].id
        });
      })
    )
  );

  getUserDetails$ = this.usersRequests.getUserDetails.createEffect(
    this.actions$
  );

  updateUserSummary$ = this.usersRequests.patchUserSummary.createEffect(
    this.actions$
  );

  /**
   * Phone numbers
   */
  userPhoneNumberUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userPhoneNumberUpdated),
      map(action => {
        return loadUserDetails({
          params: { userId: action.responseBody.userId },
          requestBody: null
        });
      })
    )
  );

  createUserPhoneNumber$ = this.usersRequests.postUserPhoneNumber.createEffect(
    this.actions$
  );

  updateUserPhoneNumber$ = this.usersRequests.patchUserPhoneNumber.createEffect(
    this.actions$
  );

  deleteUserPhoneNumber$ = this.usersRequests.deleteUserPhoneNumber.createEffect(
    this.actions$
  );

  /**
   * Address
   */
  userAddressUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAddressUpdated),
      map(action => {
        return loadUserDetails({
          params: { userId: action.responseBody.userId },
          requestBody: null
        });
      })
    )
  );

  createUserAddress$ = this.usersRequests.postUserAddress.createEffect(
    this.actions$
  );

  updateUserAddress$ = this.usersRequests.patchUserAddress.createEffect(
    this.actions$
  );

  deleteUserAddress$ = this.usersRequests.deleteUserAddress.createEffect(
    this.actions$
  );
}
