import { Action, ActionReducerMap, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  updateCallListFilters,
  loadCallList,
  callListLoaded,
  callListLoadError,
  updateCallListPagination,
  getCallUsersSuccess,
  destroyCallList,
  getCallUsers,
  getCallUsersError
} from './call-list.actions';
import { CallDto, UserDto } from '@act/shared/data-transfer-objects';
import { CallListFilters } from './call-list.models';
import { InjectionToken } from '@angular/core';

export const callListFeatureKey = 'call-list';
const DEFAULT_PAGE_LENGTH = 50;

export const CALL_LIST_FEATURE_REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<State>
>('CallList');

export interface State extends EntityState<CallDto> {
  loading: boolean;
  filters: CallListFilters;
  pagination: {
    pageSize: number;
    pageIndex: number;
  };
  users: UserSubState;
}

export interface UserSubState extends EntityState<UserDto> {
  loading: boolean;
}

export const adapter: EntityAdapter<CallDto> = createEntityAdapter<CallDto>({
  sortComparer: (a: CallDto, b: CallDto) =>
    a.timeStarted < b.timeStarted ? 1 : -1
});

export const usersSubAdapter: EntityAdapter<UserDto> = createEntityAdapter<
  UserDto
>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  filters: {
    noSubjectId: false,
    ownCalls: true,
    incoming: null
  },
  pagination: {
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_LENGTH
  },
  users: usersSubAdapter.getInitialState({
    loading: false
  })
});

const callListReducer = createReducer(
  initialState,
  on(loadCallList, (state, action) => ({
    ...adapter.removeAll(state),
    loading: true
  })),
  on(callListLoaded, (state, action) => ({
    ...adapter.upsertMany(action.responseBody, adapter.removeAll(state)),
    loading: false
  })),
  on(callListLoadError, (state, action) => ({
    ...state,
    loading: false
  })),
  on(updateCallListFilters, (state, action) => ({
    ...state,
    filters: action.filters,
    pagination: {
      pageIndex: 0,
      pageSize: state.pagination.pageSize
    }
  })),
  on(updateCallListPagination, (state, action) => ({
    ...state,
    pagination: {
      pageIndex: action.pageIndex,
      pageSize: action.pageSize
    }
  })),
  on(destroyCallList, (state, action) => ({
    ...adapter.removeAll(state),
    users: usersSubAdapter.removeAll(state.users)
  })),

  on(getCallUsers, (state, action) => ({
    ...state,
    users: {
      ...state.users,
      loading: true
    }
  })),
  on(getCallUsersSuccess, (state, action) => ({
    ...state,
    users: {
      ...usersSubAdapter.addAll(action.responseBody, state.users),
      loading: false
    }
  })),
  on(getCallUsersError, (state, action) => ({
    ...state,
    users: {
      ...state.users,
      loading: false
    }
  }))
);

export const callListAdapter = adapter;

export function reducer(state: State | undefined, action: Action) {
  return callListReducer(state, action);
}
