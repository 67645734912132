import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { DispatcherEffects } from './+state/dispatcher.effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule,
    EffectsModule.forFeature([DispatcherEffects])
  ],
  providers: []
})
export class CommonDispatcherModule {}
