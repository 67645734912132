import { ScheduledEventDto, UserDto } from '@act/shared/data-transfer-objects';
import { Dictionary } from '@ngrx/entity';
import { FeedCardData } from '@act/shared/ui/feed-card';
import {
  getCreatedByString,
  getTimeString
} from '../helpers/format-data.helper';

export const getCardDataFromScheduledEvent = (
  scheduledEvent: ScheduledEventDto,
  actingUser: UserDto,
  userMap: Dictionary<UserDto>
): FeedCardData => {
  const title = (() => {
    switch (scheduledEvent.type) {
      case 'call':
        return 'Scheduled Call';
      case 'message':
        return 'Scheduled Message';
      case 'visit':
        return 'Scheduled Visit';
      case 'tbd':
        return 'Scheduled Reminder';
      default:
        return 'Reminder';
    }
  })();

  return {
    icon: 'reminder',
    iconColor: 'reminder',

    titleParts: [
      {
        text: title,
        bold: true
      }
    ],
    metadataParts: scheduledEvent.time
      ? [{ text: getTimeString(scheduledEvent.time) }]
      : [],
    details: getCreatedByString(
      scheduledEvent.createdBy,
      actingUser.id,
      userMap
    ),
    audio: null,
    note: scheduledEvent.note,
    badges: []
  };
};
