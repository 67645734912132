import { Injectable } from '@angular/core';

// opentok
import * as openTok from '@opentok/client';
import Session = OT.Session;
import Stream = OT.Stream;
import WidgetProperties = OT.WidgetProperties;

@Injectable()
export class OpentokService {
  token: string;

  private defaultPublishOptions: WidgetProperties = {
    insertMode: 'append',
    height: '100%',
    width: '100%',
    fitMode: 'cover'
  };

  constructor() {}

  initSession(apiKey: string | number, sessionId: string): Session {
    return (window as any).OT.initSession(apiKey, sessionId);
  }

  publish(
    session: Session,
    id: string,
    options = this.defaultPublishOptions
  ): Promise<OT.Publisher> {
    return new Promise<OT.Publisher>((resolve, reject) => {
      const publisher = (window as any).OT.initPublisher(id, options);
      session.publish(publisher, err => {
        if (err) throw new Error('Could not start publishing');
        resolve(publisher);
      });
    });
  }

  subscribe(
    session: Session,
    stream: Stream,
    id: string,
    options = this.defaultPublishOptions
  ): OT.Subscriber {
    return session.subscribe(stream, id, options, err => {
      if (err) throw new Error('Could not resolve subscribers');
    });
  }
}
