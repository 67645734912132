import {
  Component,
  Input,
  Host,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { CheckboxGroupComponent } from './checkbox-group-component';

export interface ISetFilter {
  key: string;
  value: string | number;
  flag: string;
}

@Component({
  selector: 'checkbox',
  template: `
    <div class="outer-checkbox" (click)="toggleCheck()">
      <input type="checkbox" [checked]="filterChecked" />
      <div
        [ngClass]="filterChecked === true ? 'active-checkbox' : ''"
        style="display:flex; align-items: flex-start; align-content: flex-start;"
      >
        <ng-content></ng-content>
      </div>
      <div></div>
    </div>
  `,
  styles: [
    `
      .outer-checkbox {
        color: #0a2127;
        font-size: 12px;
        cursor: pointer;
        font-weight: 500;
        margin-bottom: 13px;
      }
      .outer-checkbox input[type='checkbox'] {
        display: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    `
  ]
})
export class CheckboxComponent {
  @Input() value: any;
  @Input() filterType: string;
  @Input() filterChecked: boolean;
  @Output() setFilter: EventEmitter<ISetFilter> = new EventEmitter();

  constructor(
    @Host() private checkboxGroup: CheckboxGroupComponent,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  toggleCheck() {
    const action = this.checkboxGroup.addOrRemove(this.value);
    this.setFilter.emit({
      key: this.filterType,
      value: this.value,
      flag: action
    });
  }
}
