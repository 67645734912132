import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  loadMemberStages,
  memberStagesLoaded,
  memberStageLoadError,
  loadMemberStatuses,
  memberStatusesLoaded,
  memberStatusLoadError
} from './member-stage.actions';
import { MemberStageFacade } from './member-stage.facade';
import { userAuthenticated } from '@act/features/user/data-access';
import { MemberStageRequests } from './member-stage.requests';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MemberStageEffects {
  constructor(
    private actions$: Actions,
    private memberStageFacade: MemberStageFacade,
    private memberStageRequests: MemberStageRequests
  ) {}

  initializeLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthenticated),
      switchMap(action => {
        return [
          loadMemberStages({ params: null, requestBody: null }),
          loadMemberStatuses({ params: null, requestBody: null })
        ];
      })
    )
  );

  loadMemberStages$ = this.memberStageRequests.getMemberStages.createEffect(
    this.actions$
  );

  loadMemberStatuses$ = this.memberStageRequests.getMemberStatuses.createEffect(
    this.actions$
  );
}
