import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromConsent from './+state/consent.reducers';
import { ConsentEffects } from './+state/consents/consent.effects';
import { ConsentFacade } from './+state/consents/consent.facade';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConsentRequests } from './+state/consents/consent.requests';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromConsent.consentFeatureKey,
      fromConsent.CONSENT_FEATURE_REDUCER_TOKEN
    ),
    EffectsModule.forFeature([ConsentEffects])
  ],
  providers: [
    ConsentFacade,
    ConsentRequests,
    {
      provide: fromConsent.CONSENT_FEATURE_REDUCER_TOKEN,
      useFactory: fromConsent.getReducers
    }
  ]
})
export class FeaturesConsentDataAccessModule {}
