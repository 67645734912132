import { Injectable } from '@angular/core';
import { Platform } from '@act/core/platform';
import { UsersFacade } from '@act/features/users/data-access';

@Injectable()
export class BootstrapService {
  constructor(private platform: Platform, private usersFacade: UsersFacade) {}

  bootstrap() {
    this.registerPlatformComponents();
    return true;
  }

  private registerPlatformComponents() {
    this.platform.usersMap$ = this.usersFacade.userMap$;
  }
}
