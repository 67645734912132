import {
  TermDto,
  TermGroupDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { ActDialog, ActDialogService } from '@act/shared/ui/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'act-consent-group',
  templateUrl: './consent-group.component.html',
  styleUrls: ['./consent-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsentGroupComponent implements OnInit {
  @Input() consentGroup: TermGroupDto;
  @Input() user: UserDto;

  @ViewChild('consentDialog', { static: true }) consentDialogRef: TemplateRef<
    any
  >;
  @ViewChild('consentLinkDialog', { static: true })
  consentLinkDialogRef: TemplateRef<any>;

  constructor(public dialogService: ActDialogService) {}

  ngOnInit() {}

  consentDialog: ActDialog;
  consentLinkDialog: ActDialog;

  createConsentLink() {
    this.consentLinkDialog = this.dialogService.createDialog({
      ref: this.consentLinkDialogRef,
      onOpen: () => {},
      onClose: () => {}
    });
    this.consentLinkDialog.open();
  }
  closeConsentLinkDialog() {
    this.consentLinkDialog.close();
  }

  selectedTerm: TermDto = null;
  viewTermDetails(term: TermDto) {
    this.selectedTerm = term;
    this.consentDialog = this.dialogService.createDialog({
      ref: this.consentDialogRef,
      onOpen: () => {},
      onClose: () => {}
    });
    this.consentDialog.open();
  }
  closeConsentDialog() {
    this.consentDialog.close();
  }
}
