import { Inject, Injectable, Optional } from '@angular/core';
import { InputDevice, InputDeviceType } from '@act/shared/models';
import { Subject } from 'rxjs';
import { createAudioMeter } from '../utilities/create-audio-meter.function';
import { volumeIsNonZero } from '../utilities/volume-is-non-zero.function';
import { SystemCheckServiceSubscriptions } from '../models/system-check-service-subscriptions.interface';
import { NonZeroVolumeTester } from '../models/volume-tester.model';

@Injectable({
    providedIn: 'root'
})
export class SystemCheckService {
    public selectedAudioDevice: InputDevice;
    public selectedVideoDevice: InputDevice;
    
    private devicePermissionsGranted: Subject<boolean> = new Subject<boolean>();
    
    private internetSpeedTestRunning: Subject<boolean> = new Subject<boolean>();
    private internetSpeedTestSucceeded: Subject<boolean> = new Subject<boolean>();
    
    private microphoneTestRunning: Subject<boolean> = new Subject<boolean>();
    private microphoneTestSucceeded: Subject<boolean> = new Subject<boolean>();
    
    private webcamTestRunning: Subject<boolean> = new Subject<boolean>();
    private webcamTestSucceeded: Subject<boolean> = new Subject<boolean>();
    
    // we make these assignments to make the class easier to test
    private audioMeter = createAudioMeter;
    private volumeIsNonZero = volumeIsNonZero;
    
    private complete: Subject<boolean> = new Subject<boolean>();
    
    /**
     * Anything listening to these subscriptions will get the updated state
     * of the data. Specifically, which tests are running and whether they
     * succeeded or not.
     */
    public subscriptions: SystemCheckServiceSubscriptions = {
        devicePermissionsGranted: this.devicePermissionsGranted.asObservable(),
        
        microphoneTestRunning: this.microphoneTestRunning.asObservable(),
        microphoneTestSucceeded: this.microphoneTestSucceeded.asObservable(),
        
        webcamTestRunning: this.webcamTestRunning.asObservable(),
        webcamTestSucceeded: this.webcamTestSucceeded.asObservable()
    };
    
    constructor() {
    }
    
    /**
     * Initiate the request to access a user's devices
     */
    public getPermissions(): void {
        const constraints: MediaStreamConstraints = {
            audio: true,
            video: true
        };

        navigator
            .mediaDevices
            .getUserMedia(constraints)
            .then((stream: MediaStream): void => {
                this.devicePermissionsGranted.next(true);
            })
            .catch((): void => {
                this.devicePermissionsGranted.next(false);
            });
    }
    
    /**
     * Get a list of all devices capable of audio
     */
    public async enumerateAudioDevices(): Promise<InputDevice[]> {
        return await this.getDevices('audioinput');
    }
    
    /**
     * Get a list of all devices capable of video
     */
    public async enumerateVideoDevices(): Promise<InputDevice[]> {
        return await this.getDevices('videoinput');
    }
    
    /**
     * Test to see if there is audio from a specific device
     */
    public testAudioInput(inputDevice: InputDevice): void {
        this.selectedAudioDevice = inputDevice;
        this.microphoneTestRunning.next(true);
        const constraints: MediaStreamConstraints = {
            audio: {
                deviceId: inputDevice.deviceId
            }
        };
        
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream: MediaStream) => {
                const audioContext = new AudioContext();
                const mediaStreamSource = audioContext.createMediaStreamSource(stream);
                const meter = this.audioMeter(audioContext);
                const func = this.checkIfVolumeIsNonZero(meter);
                meter.addListener(func.callback);
                mediaStreamSource.connect(meter);
                
                // listen for three seconds then evaluate
                // whether the check passed.
                setTimeout(() => {
                    const success: boolean = func.check();
                    this.microphoneTestSucceeded.next(success);
                    meter.disconnect();
                    this.microphoneTestRunning.next(false);
                }, 3000);
            })
            .catch((error: MediaStreamError) => {
                this.microphoneTestRunning.next(false);
                this.microphoneTestSucceeded.next(false);
            });
    }
    
    public retestAudioInput(): void {
        this.testAudioInput(this.selectedAudioDevice);
    }
    
    public testVideoInput(inputDevice: InputDevice): void {
        this.selectedVideoDevice = inputDevice;
        this.webcamTestRunning.next(true);
        const constraints: MediaStreamConstraints = {
            video: {
                deviceId: inputDevice.deviceId
            }
        };
        
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream: MediaStream) => {
                const frameRate: number = stream.getVideoTracks()[ 0 ].getSettings().frameRate;
                const height: number = stream.getVideoTracks()[ 0 ].getSettings().height;
                const width: number = stream.getVideoTracks()[ 0 ].getSettings().width;
                
                // the test to see if the webcam stream is acceptable
                const success: boolean = frameRate > 9
                    && height > 0
                    && width > 0;
                
                this.webcamTestSucceeded.next(success);
                this.webcamTestRunning.next(false);
            })
            .catch((error: MediaStreamError) => {
                this.webcamTestRunning.next(false);
                this.webcamTestSucceeded.next(false);
            });
    }
    
    public retestVideoInput(): void {
        this.testVideoInput(this.selectedVideoDevice);
    }
    
    /**
     * Expire any subscriptions or observables that may still
     * be listening. Write the final selected inputs to the store.
     */
    public testsComplete(): void {
        this.complete.next(true);
        this.complete.complete();
    }
    
    /**
     * Request access to the users input devices and if granted
     * return them in a list.
     */
    private async getDevices(filter: InputDeviceType = null): Promise<InputDevice[]> {
        const devices: InputDevice[] = await navigator
            .mediaDevices.enumerateDevices()
            .catch((err) => {
                throw new Error(`Could not list devices with filter ${filter || 'none'}: ${err}`);
            });
        
        return filter ? devices.filter((device: InputDevice) => device.kind === filter) : devices;
    }
    
    private checkIfVolumeIsNonZero(meter): NonZeroVolumeTester {
        let values: boolean[] = [];
        return {
            callback: (event) => {
                values.push(this.volumeIsNonZero(meter, event));
            },
            check: (): boolean => {
                const numberOfChecks: number = values.length;
                const numberOfSuccesses: number = [ ...values ].filter((val: boolean) => val === true).length;
                
                // test passes if at least 70% of the checks are non-zero volumes
                return numberOfSuccesses / numberOfChecks > .7;
            },
            getResults: (): boolean[] => values,
            mockResults: (mockResults: boolean[]): void => {
                values = mockResults;
            }
        };
    }
}
