import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadPatientsTypes,
  PatientsLoaded
} from '@act/features/patients/events';
import { map } from 'rxjs/operators';
import { upsertPatients } from './patients.actions';
import { UserDto } from '@act/shared/data-transfer-objects';

@Injectable()
export class PatientsEffects {
  patientsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadPatientsTypes.PATIENTS_LOADED),
      map((action: PatientsLoaded) => action.payload.patients),
      map((patients: UserDto[]) =>
        upsertPatients({
          patients: patients
        })
      )
    )
  );

  constructor(private actions$: Actions) {}
}
