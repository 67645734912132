import { FeedDto } from '@act/shared/data-transfer-objects';

export enum FeedLastSeenUpdatedTypes {
  FEED_LAST_SEEN_UPDATED = '[Feed] Feed Last Seen Updated'
}

export interface FeedLastSeenUpdatedEventPayload {
  feed: FeedDto;
}
export class FeedLastSeenUpdatedEvent {
  readonly type = FeedLastSeenUpdatedTypes.FEED_LAST_SEEN_UPDATED;

  constructor(public payload: FeedLastSeenUpdatedEventPayload) {}
}
