import { File } from '@act/server/models/files';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { loadavg } from 'os';
import {
  fileInfoLoadError,
  fileInfoReceived,
  filesUploaded,
  filesUploadError,
  getFileInfo,
  uploadFiles
} from './file.actions';

export const fileFeatureKey = 'files';

export interface FileDetail {
  id: string;
  loading: boolean;
  error: boolean;
  file: File;
}

export interface FileState extends EntityState<FileDetail> {
  loading: boolean;
}

export const initialState: FileState = {
  ids: [],
  entities: {},
  loading: false
};

export const fileAdapter: EntityAdapter<FileDetail> = createEntityAdapter<
  FileDetail
>({
  selectId: (f: FileDetail): string => f.id
});

const fileReducer = createReducer(
  initialState,
  on(fileInfoReceived, (state, action) =>
    fileAdapter.upsertOne(
      {
        loading: false,
        error: false,
        id: action.params.id,
        file: action.responseBody
      },
      state
    )
  ),
  on(getFileInfo, (state, action) =>
    fileAdapter.upsertOne(
      {
        loading: true,
        error: false,
        id: action.params.id,
        file: null
      },
      state
    )
  ),
  on(fileInfoLoadError, (state, action) =>
    fileAdapter.upsertOne(
      {
        loading: false,
        error: true,
        id: action.params.id,
        file: null
      },
      state
    )
  ),
  on(uploadFiles, (state, action) => ({ ...state, loading: true })),
  on(filesUploaded, (state, action) => {
    const files = action.responseBody.reduce(
      (acc: FileDetail[], file: File) => {
        return [{ loading: false, id: file.id, error: false, file }, ...acc];
      },
      [] as FileDetail[]
    );
    const newState = fileAdapter.upsertMany(files, state);
    return {
      ...newState,
      loading: false
    };
  }),
  on(filesUploadError, (state, action) => ({ ...state, loading: false }))
);

export function reducer(state: FileState | undefined, action: Action) {
  return fileReducer(state, action);
}
