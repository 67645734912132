import { TextTemplateRequests } from './text-template.requests';

const requests = new TextTemplateRequests(null, null);

export const {
  initiator: loadTextTemplates,
  success: textTemplatesLoaded,
  error: textTemplatesLoadError
} = requests.getTextTemplates.getRequestActions();

export const {
  initiator: loadTextTemplateVariables,
  success: textTemplateVariablesLoaded,
  error: textTemplateVariablesLoadError
} = requests.getTextTemplateVariables.getRequestActions();
