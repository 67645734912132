import { Socket } from 'ngx-socket-io';
import { ClientEvent } from '@act/shared/models';
import { Action, Store } from '@ngrx/store';

type Response = Action | Action[];

export class DispatcherService {
  constructor(private socket: Socket, private store: Store<any>) {}

  /**
   * Send a message over the socket with the option to send
   * the same message through the client event bus.
   */
  send<P = any, R = Action>(
    event: ClientEvent<P>,
    dispatchToClientStore = false
  ): any {
    if (dispatchToClientStore) this.store.dispatch(event);

    // we always need a payload because the backend appends to it
    // todo | allow the backend to create an object if it's needed
    const payload = event.payload || {};

    return this.socket.emit(
      event.type,
      payload,
      (res: Response) => res && this.handleResponse(res)
    );
  }

  /**
   * Dispatch any actions which come back from the service
   */
  private handleResponse(res: Response): void {
    if (!Array.isArray(res)) res = [res];

    res.forEach((action: any) => this.store.dispatch(action));
  }
}
