import { Action } from '@ngrx/store';

export enum IsTypingTypes {
  IS_TYPING = '[Chat] Is Typing',
  IS_DONE_TYPING = '[Chat] Is Done Typing'
}

export interface IsTypingPayload {
  feedId: number;
}

export class IsTyping implements Action {
  readonly type = IsTypingTypes.IS_TYPING;

  constructor(readonly payload: IsTypingPayload) {}
}

export type IsDoneTypingPayload = IsTypingPayload;

export class IsDoneTyping implements Action {
  readonly type = IsTypingTypes.IS_DONE_TYPING;

  constructor(readonly payload: IsDoneTypingPayload) {}
}
