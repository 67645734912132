import { PhoneNumberDto } from '@act/shared/data-transfer-objects';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'act-phone-number-display',
  templateUrl: './phone-number-display.component.html',
  styleUrls: ['./phone-number-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneNumberDisplayComponent implements OnInit {
  @Input() phoneNumber: PhoneNumberDto;

  constructor() {}

  ngOnInit() {}
}
