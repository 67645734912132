import { VisitDto } from '@act/shared/data-transfer-objects';
import { createAction, props } from '@ngrx/store';
import { VisitRequests } from './visit.requests';

const requests = new VisitRequests(null, null);

/**
 * Creating Visits
 */
export const {
  initiator: createVisit,
  success: visitCreated,
  error: visitCreationError
} = requests.postVisit.getRequestActions();

/**
 * Updating Visits
 */
export const {
  initiator: updateVisit,
  success: visitUpdated,
  error: visitUpdateError
} = requests.patchVisit.getRequestActions();

/**
 * Deleting Visits
 */
export const {
  initiator: deleteVisit,
  success: visitDeleted,
  error: visitDeleteError
} = requests.deleteVisit.getRequestActions();

/**
 *  Edit Visits
 */
export const editVisit = createAction(
  '[Visits] Edit Visit',
  props<{ visit: VisitDto }>()
);

export const stopEditingVisit = createAction(
  '[Visits] Stop Editing Visit',
  props<{ visitId: number }>()
);

export const startCreatingVisit = createAction(
  '[Visits] Start Creating Visit',
  props<{ subjectUserId: string }>()
);

export const triggerVisitOutcomeLoad = createAction(
  '[Visits] Trigger Visit Outcome Load'
);

export const {
  initiator: loadVisitOutcomes,
  success: visitOutcomesLoaded,
  error: visitOutcomesLoadError
} = requests.getVisitOutcomes.getRequestActions();

export const {
  initiator: loadVisits,
  success: visitsLoaded,
  error: visitLoadError
} = requests.getVisits.getRequestActions();

/**
 * Create Comment
 */
export const {
  initiator: createComment,
  success: commentCreated,
  error: commentCreationError
} = requests.createComment.getRequestActions();
