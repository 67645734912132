import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { FileRequests } from './file.requests';

@Injectable()
export class FileEffects {
  constructor(private actions$: Actions, private fileRequests: FileRequests) {}

  loadFile$ = this.fileRequests.getFileInfo.createEffect(this.actions$);

  uploadFiles$ = this.fileRequests.uploadFiles.createEffect(this.actions$);
}
