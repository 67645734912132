import {
  MessageTemplateCategoryDto,
  MessageTemplateVariablesDto
} from '@act/shared/data-transfer-objects';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import {
  loadTextTemplates,
  loadTextTemplateVariables,
  textTemplatesLoaded,
  textTemplatesLoadError,
  textTemplateVariablesLoaded
} from './text-template.actions';

export const textTemplateFeatureKey = 'text-template';

export interface State extends EntityState<MessageTemplateCategoryDto> {
  loadingTextTemplates: boolean;
  loadingTextTemplateVariables: boolean;
  textTemplateVariables: MessageTemplateVariablesDto;
}

export const adapter: EntityAdapter<
  MessageTemplateCategoryDto
> = createEntityAdapter<MessageTemplateCategoryDto>();

export const initialState: State = adapter.getInitialState({
  loadingTextTemplates: false,
  loadingTextTemplateVariables: false,
  textTemplateVariables: null
});

const textTemplateReducer = createReducer(
  initialState,
  on(loadTextTemplates, (state, action) => {
    const newState = adapter.removeAll(state);

    return {
      ...newState,
      loadingTextTemplates: true
    };
  }),
  on(textTemplatesLoadError, (state, action) => {
    return {
      ...state,
      loadingTextTemplates: false
    };
  }),
  on(textTemplatesLoaded, (state, action) => {
    const newState = adapter.addMany(action.responseBody, state);
    return {
      ...newState,
      loadingTextTemplates: false
    };
  }),
  on(loadTextTemplateVariables, (state, action) => {
    return {
      ...state,
      textTemplateVariables: null,
      loadingTextTemplateVariables: true
    };
  }),
  on(textTemplatesLoadError, (state, action) => {
    return {
      ...state,
      loadingTextTemplates: false
    };
  }),
  on(textTemplateVariablesLoaded, (state, action) => {
    return {
      ...state,
      textTemplateVariables: action.responseBody,
      loadingTextTemplateVariables: false
    };
  })
);

export const textTemplateAdapter = adapter;

export function reducer(state: State | undefined, action: Action) {
  return textTemplateReducer(state, action);
}
