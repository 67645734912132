import { Injectable, Injector } from '@angular/core';
import { AuthFacade, AuthService } from '@act/shared/auth/clients';
import { Router } from '@angular/router';
import { Platform } from '@act/core/platform';
import { UsersFacade } from '@act/features/users/data-access';
import { UserFacade } from '@act/features/user/data-access';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  private excludedUrlsFromInitialAuth = ['/callbacks/auth0'];
  private defaultPathWhenAuthenticated = '/members';

  constructor(
    private authService: AuthService,
    private injector: Injector,
    private platform: Platform,
    private usersFacade: UsersFacade,
    private userFacade: UserFacade
  ) {}

  async bootstrap() {
    // this should be the first thing in the method
    Platform.generateDeviceId();

    await this.registerGlobalAppProviders();

    const currentRouteShouldVerifyUser = !this.excludedUrlsFromInitialAuth.includes(
      window.location.pathname
    );

    // if the current URL is not in the excluded array, then
    // verify the user.
    if (currentRouteShouldVerifyUser) {
      try {
        await this.verifyUser();
      } catch (err) {
        const authFacade: AuthFacade = this.injector.get(AuthFacade);
        authFacade.login();
        return;
      }
      const next: string =
        window.location.pathname === '/'
          ? this.defaultPathWhenAuthenticated
          : window.location.pathname + window.location.search;
      this.postAuthBoostrap(next, true);
    }
  }

  /**
   * Post-authentication bootstrap steps
   */
  async postAuthBoostrap(
    defaultRedirectRoute: string,
    userDataHasBeenFetched = false
  ) {
    if (!userDataHasBeenFetched) {
      try {
        await this.verifyUser();
      } catch (err) {
        const authFacade: AuthFacade = this.injector.get(AuthFacade);
        authFacade.login();
        return;
      }
    }

    const router: Router = this.injector.get(Router);
    // this.usersFacade.loadProviders();
    router.navigateByUrl(defaultRedirectRoute);
  }

  private async verifyUser() {
    try {
      const user = await this.authService.verify();
      this.userFacade.userLoaded(user);
      this.userFacade.userAuthenticated();
    } catch (e) {
      this.userFacade.userNotAuthenticated();
      throw new Error('Unauthenticated: ' + e);
    }
  }

  private async registerGlobalAppProviders() {
    this.platform.userAuthenticated$ = this.userFacade.authenticated$;
    this.platform.user$ = this.userFacade.user$;

    // global app providers are registered, so the platform is
    // now ready
    Platform._ready$.next(true);

    return true;
  }
}
