import { AttributeGroupDto } from '@act/shared/data-transfer-objects';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'act-group-info-display[attributeGroup]',
  templateUrl: './group-info-display.component.html',
  styleUrls: ['./group-info-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupInfoDisplayComponent implements OnInit {
  @Input() attributeGroup: AttributeGroupDto;

  constructor() {}

  ngOnInit() {}
}
