import { AddressDto, UserDto } from '@act/shared/data-transfer-objects';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';

@Component({
  selector: 'act-addresses-display',
  templateUrl: './addresses-display.component.html',
  styleUrls: ['./addresses-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressesDisplayComponent implements OnInit, OnChanges {
  @Input() addresses: AddressDto[];
  @Input() user: UserDto[];

  showAll = false;

  constructor() {}

  ngOnInit() {
    this.addresses = this.addresses || [];
  }

  ngOnChanges() {
    this.addresses = this.addresses || [];
  }

  trackAddress(address: AddressDto) {
    return address.id;
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
  }
}
