import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { from, Observable } from 'rxjs';

const iconPromises: { [key: string]: Promise<SafeHtml> } = {};

@Component({
  selector: 'act-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  @Input() name: string;
  @ViewChild('svgContainer', null) svgContainer: ElementRef;
  svg$: Observable<SafeHtml>;

  ngOnInit() {
    this.svg$ = this.getSvg$(this.name);
  }

  getSvg$(name: string): Observable<SafeHtml> {
    const url = `/assets/svg-icons/${name}.svg`;

    if (!iconPromises[url]) {
      const headers = new HttpHeaders();
      headers.set('Accept', 'image/svg+xml');

      iconPromises[url] = this.http
        .get(url, {
          headers,
          responseType: 'text'
        })
        .pipe(
          map(svgString => this.sanitizer.bypassSecurityTrustHtml(svgString))
        )
        .toPromise();
    }

    return from(iconPromises[url]);
  }
}
