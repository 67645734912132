import { FeedItemDto } from '@act/shared/data-transfer-objects';

export enum NewFeedItemTypes {
  NEW_FEED_ITEM = '[Feed] New Feed Item'
}

export interface NewFeedItemEventPayload {
  feedItem: FeedItemDto;
}
export class NewFeedItemEvent {
  readonly type = NewFeedItemTypes.NEW_FEED_ITEM;

  constructor(public payload: NewFeedItemEventPayload) {}
}
