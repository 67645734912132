import {
  convertMsToHoursMinutesSeconds,
  formatPhoneNumber
} from '@act/common/utilities';
import { UserDto } from '@act/shared/data-transfer-objects';
import { Dictionary } from '@ngrx/entity';
import moment = require('moment');

export const getRoughDurationString = (timeMilliseconds: number): string => {
  const conversion = convertMsToHoursMinutesSeconds(timeMilliseconds);
  const allHours = conversion.day * 24 + conversion.hour;
  const includeSeconds = conversion.minute < 2;

  const hours = allHours ? `${allHours} hours ` : '';
  const minutes = conversion.minute ? `${conversion.minute} minutes` : '';
  const seconds = includeSeconds ? `${conversion.seconds} seconds` : '';

  return `${hours} ${minutes} ${seconds}`.trim();
};

export const getTimeString = (timeUnixMilliseconds: number): string => {
  if (!timeUnixMilliseconds) {
    return null;
  }
  return moment(timeUnixMilliseconds).format('h:mm a');
};

export const getShortName = (user: UserDto): string => {
  if (!user) {
    return null;
  }
  return (
    user.firstName + ' ' + (user.lastName ? ' ' + user.lastName[0] + '.' : '')
  );
};

export const getCreatedByString = (
  createdBy: string,
  actingUserId: string,
  userMap: Dictionary<UserDto>
) => {
  if (createdBy === null) return 'System created';
  if (createdBy === actingUserId) return 'Created by you';
  const creatorUser = userMap[createdBy];
  if (creatorUser) return 'Created by ' + getShortName(creatorUser);
  return 'Created by someone else';
};

export const getPhoneNumberInfo = (
  info: { userId: string; phoneNumber: string },
  userMap: Dictionary<UserDto>
) => {
  if (!info) {
    return null;
  }
  const user = userMap[info.userId];
  if (user) {
    if (user.isMember) {
      const phoneNumber = user.phoneNumbers.find(
        p => p.number === info.phoneNumber
      );
      if (phoneNumber) {
        // Member and we could find phone number
        return {
          title:
            user.firstName +
            ' ' +
            user.lastName +
            ': ' +
            formatPhoneNumber(info.phoneNumber) +
            (phoneNumber.primary ? ' (primary)' : ''),
          details: phoneNumber.description,
          phoneNumber
        };
      }
      // Member but could not find phone number
      return {
        title: user.firstName + ' ' + user.lastName,
        details: formatPhoneNumber(info.phoneNumber),
        phoneNumber: null
      };
    } else {
      // User is a guide
      return {
        title: user.firstName + ' ' + user.lastName,
        details: info.userId ? null : 'Unknown User',
        phoneNumber: null
      };
    }
  } else {
    // User not found
    return {
      title: formatPhoneNumber(info.phoneNumber),
      details: info.userId ? null : 'Unknown User'
    };
  }
};
