import { Injectable, Injector } from '@angular/core';
import { Platform, ProvidedUser } from '@act/core/platform';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthFacade } from './auth.facade';

@Injectable()
export class ProviderRegistrationService {
  user$: ProvidedUser = new Subject();
  userAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private platform: Platform, private injector: Injector) {}

  registerProviders() {
    const authFacade = this.injector.get<AuthFacade>(AuthFacade);
    Platform.getAuthToken = authFacade.getToken.bind(authFacade);
    // this.platform.userAuthenticated$ = authFacade.authenticated$;

    return true;
  }
}
