import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaveStatusComponent } from './save-status/save-status.component';
import { MatIconModule } from '@angular/material';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [SaveStatusComponent],
  exports: [SaveStatusComponent]
})
export class CommonUtilitiesAutosaveModule {}
