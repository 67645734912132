import { getValueOfObservable } from '@act/common/utilities';
import { UsersFacade } from '@act/features/users/data-access';
import {
  NeedCategoryDto,
  UserNeedDto
} from '@act/shared/data-transfer-objects';
import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  ApplicationRef
} from '@angular/core';
import { AnchoredDialogActionsService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog-actions.service';
import { AnchoredDialogService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog.service';
import { NeedDialogComponent } from '../needs/need-dialog/need-dialog.component';

@Injectable()
export class ProfileActionsService extends AnchoredDialogActionsService {
  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    appRef: ApplicationRef,
    injector: Injector,
    private usersFacade: UsersFacade,
    private anchoredDialogService: AnchoredDialogService
  ) {
    super(componentFactoryResolver, appRef, injector);
  }

  createNeed(
    memberId: string,
    needCategories: NeedCategoryDto[],
    onDone: () => any = null
  ) {
    const id = this.getId('create', 'need', memberId);
    if (!this.anchoredDialogService.isOpen(id)) {
      const userMap = getValueOfObservable(this.usersFacade.userMap$);
      this.open(
        NeedDialogComponent,
        {
          memberId,
          userMap,
          needCategories
        },
        onDone
      );
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }

  editNeed(
    memberId: string,
    needCategories: NeedCategoryDto[],
    need: UserNeedDto,
    onDone: () => any = null
  ) {
    const id = this.getId('edit', 'need', need.id);
    if (!this.anchoredDialogService.isOpen(id)) {
      const userMap = getValueOfObservable(this.usersFacade.userMap$);
      this.open(
        NeedDialogComponent,
        {
          memberId,
          userMap,
          needCategories,
          need
        },
        onDone
      );
    } else {
      this.anchoredDialogService.showDialog(id);
    }
  }
}
