import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'act-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true
    }
  ]
})
export class PhoneNumberInputComponent extends BaseInputComponent<string> {
  @Input() label: string;
  @Input() placeholderValue: string;

  transformToDisplay(value: string): string {
    return value.replace(/^\+(1){0,1}/, '');
  }

  transformFromDisplay(displayValue: string): string {
    return `+1${displayValue}`;
  }
}
