import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';
import { Platform } from '@act/core/platform';
import { Observable } from 'rxjs';
import { getValueOfObservable } from '@act/common/utilities';
import { PersistenceService } from '@act/shared/persistence';
import { REDIRECT_URI } from 'libs/shared/auth/models/src/lib/persistence-keys';
import { AuthFacade } from '../services/auth.facade';
import { map } from 'rxjs/operators';

@Injectable()
export class IsAuthenticatedService implements CanActivate {
  constructor(
    private platform: Platform,
    private persistenceService: PersistenceService,
    private authFacade: AuthFacade
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const userUnauthenticated$ = this.platform.userAuthenticated$.pipe(
      map(value => !value)
    );

    // Kind of a hack to log out a user if they have a token and but they are not authenticated
    //   If we don't do this the user is stuck because they can't log back in
    userUnauthenticated$.subscribe(unauthenticated => {
      const tokenExists = !!this.authFacade.getToken();
      if (unauthenticated && tokenExists) {
        this.authFacade.logout();
      }
    });

    if (getValueOfObservable(userUnauthenticated$)) {
      this.persistenceService.set(REDIRECT_URI, state.url);
      this.persistenceService.get(REDIRECT_URI);
    }
    return this.platform.userAuthenticated$;
  }
}
