import { Pipe, PipeTransform } from '@angular/core';
import { formatPhoneNumber } from '@act/common/utilities';

@Pipe({
  name: 'phoneNumberFormatter'
})
export class PhoneNumberPipe implements PipeTransform {
  phoneNumberRegex = /\+1\d{10}$/;
  transform(value: string, ...args: any[]): any {
    return formatPhoneNumber(value);
  }
}
