import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthActionTypes, AuthSuccessful} from '@act/shared/auth/clients';
import {BootstrapService} from '../services/bootstrap.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class AppEffects {
    @Effect({dispatch: false}) authSuccessful$ = this.actions$.pipe(
        ofType(AuthActionTypes.AUTH_SUCCESSFUL),
        tap((action: AuthSuccessful) => this.bootstrapService.postAuthBoostrap(action.payload.defaultRedirectRoute))
    );

    constructor(
        private actions$: Actions,
        private bootstrapService: BootstrapService
    ) {
    }
}
