import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedPersistenceModule } from '@act/shared/persistence';

import { Auth0CallbackComponent } from './components/auth0-callback/auth0-callback.component';
import { AuthFacade } from './services/auth.facade';
import { Auth0Service } from './services/auth0.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth-interceptor.service';
import { IsAuthenticatedService } from './guards/is-authenticated.service';
import { IsUnauthenticatedService } from './guards/is-unauthenticated.service';
import { AuthService } from './services/auth.service';
import { ProviderRegistrationService } from './services/provider-registration.service';
import { CommonComponentsFullscreenModule } from '@act/common/components/fullscreen';

export function registerProviderFactory(
  providerRegistrationService: ProviderRegistrationService
) {
  return () => providerRegistrationService.registerProviders();
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'callbacks/auth0',
        component: Auth0CallbackComponent
      }
    ]),
    SharedPersistenceModule,
    HttpClientModule,
    CommonComponentsFullscreenModule
  ],
  declarations: [Auth0CallbackComponent],
  providers: [
    AuthFacade,
    Auth0Service,
    AuthService,
    IsAuthenticatedService,
    IsUnauthenticatedService,
    ProviderRegistrationService,
    {
      provide: APP_INITIALIZER,
      useFactory: registerProviderFactory,
      deps: [ProviderRegistrationService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class SharedAuthClientsModule {}
