import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'act-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() src: string;
  @HostBinding('class.error') error: boolean = false;

  audio: HTMLAudioElement;

  playing$ = new Subject<boolean>();
  error$ = new Subject<string>();
  loading$ = new Subject<boolean>();

  playingBeforeScrub = false;

  @ViewChild('bar', null) bar: ElementRef;
  @ViewChild('slider', null) slider: ElementRef;
  @ViewChild('progressBar', null) progressBar: ElementRef;
  @ViewChild('bufferingBar', null) bufferingBar: ElementRef;
  @ViewChild('sizeLog', null) sizeLog: ElementRef;

  constructor(private renderer: Renderer2) {
    this.loading$.next(false);
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unInitializePlayer();
  }

  ngOnChanges() {
    this.unInitializePlayer();
  }

  playing: boolean = false;
  initialized: boolean = false;
  percent: number = 0;

  update(update: { playing?: boolean; percent?: number } = null) {
    if (!update) {
      update = {
        playing: this.playing,
        percent: this.percent
      };
    }

    this.playing = update.playing === undefined ? this.playing : update.playing;

    if (update.percent === undefined) {
      this.percent =
        this.initialized && this.audio
          ? this.audio.currentTime / this.audio.duration
          : this.percent;
    } else {
      this.percent = update.percent;
    }

    if (this.initialized) {
      this.audio.currentTime = this.audio.duration * this.percent;

      if (this.playing) {
        if (this.audio.paused) {
          this.audio.play();
        }
      } else {
        if (!this.audio.paused) {
          this.audio.pause();
        }
      }
      this.setCursor(this.percent);
      this.startCursor();
    } else {
      this.setCursor(this.percent);
    }
  }

  updateBufferingDisplay() {
    let percent = 0;
    if (this.audio && this.audio.buffered && this.audio.buffered.length) {
      var buffered = this.audio.buffered.end(0);
      var duration = this.audio.duration;
      percent = (buffered / duration) * 100;
      this.renderer.setStyle(
        this.bufferingBar.nativeElement,
        'width',
        `${percent}%`
      );
    }
  }

  private playerInitializationPromise: Promise<HTMLAudioElement>;
  private unInitializePlayer() {
    this.playerInitializationPromise = null;
    this.initialized = false;
    this.update({ playing: false, percent: 0 });
    this.audio = null;
  }

  private initializePlayer(): Promise<HTMLAudioElement> {
    if (!this.playerInitializationPromise) {
      this.playerInitializationPromise = new Promise<HTMLAudioElement>(
        (resolve, reject) => {
          let resolved = false;

          this.audio = new Audio(this.src);
          this.audio.play();
          this.audio.pause();

          this.updateBufferingDisplay();

          this.loading$.next(true);
          this.audio.oncanplay = event => {
            this.loading$.next(false);
            this.updateBufferingDisplay();
            if (!resolved) {
              resolve(this.audio);
              resolved = true;
              this.initialized = true;
              this.update();
            }
          };

          this.audio.onprogress = e => this.updateBufferingDisplay();

          this.audio.onplay = e => {
            this.playing$.next(true);
            this.startCursor();
          };
          this.audio.onpause = e => {
            this.playing$.next(false);
            this.startCursor();
          };

          this.audio.onerror = e => {
            this.error$.next('Error loading file');
            this.error = true;
            if (!resolved) {
              reject(e);
            }
          };
        }
      );
    }
    return this.playerInitializationPromise;
  }

  async play() {
    if (!this.initialized) {
      await this.initializePlayer();
    }
    this.update({ playing: true });
  }
  pause() {
    this.update({ playing: false });
  }

  async startCursor() {
    const percentage = this.audio.currentTime / this.audio.duration || 0;
    this.setCursor(percentage);

    const playing = !this.audio.paused;
    if (playing && this.initialized) {
      setTimeout(() => {
        const secondsLeft = this.audio.duration - this.audio.currentTime;
        this.renderer.setStyle(this.slider.nativeElement, 'left', `100%`);
        this.renderer.setStyle(
          this.slider.nativeElement,
          'transition',
          `left ${secondsLeft}s linear`
        );

        this.renderer.setStyle(this.progressBar.nativeElement, 'width', `100%`);
        this.renderer.setStyle(
          this.progressBar.nativeElement,
          'transition',
          `width ${secondsLeft}s linear`
        );
      }, 10);
    }
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }
    return value;
  }

  async scrubStart(event) {
    const playingBeforeScrub = this.playing;
    this.update({ playing: false, percent: this.getPercent(event.pageX) });

    const mouseMoveEvent = this.renderer.listen('document', 'mousemove', e => {
      this.update({ playing: false, percent: this.getPercent(e.pageX) });
    });

    const mouseUpEvent = this.renderer.listen('document', 'mouseup', e => {
      mouseMoveEvent();
      mouseUpEvent();
      this.update({
        playing: playingBeforeScrub,
        percent: this.getPercent(e.pageX)
      });
    });
  }

  setCursor(scrubPercent: number) {
    this.renderer.setStyle(
      this.slider.nativeElement,
      'left',
      `${scrubPercent * 100}%`
    );
    this.renderer.setStyle(
      this.slider.nativeElement,
      'transition',
      `left 0s linear`
    );

    this.renderer.setStyle(
      this.progressBar.nativeElement,
      'width',
      `${scrubPercent * 100}%`
    );
    this.renderer.setStyle(
      this.progressBar.nativeElement,
      'transition',
      `width 0s linear`
    );
  }

  getPercent(pageX: number): number {
    const rect = this.bar.nativeElement.getBoundingClientRect();
    const barLeft: number = rect.left;
    const barWidth: number = this.bar.nativeElement.offsetWidth;
    const barRight = barLeft + barWidth;

    const scrubLocation =
      Math.min(Math.max(pageX, barLeft), barRight) - barLeft;
    return scrubLocation / barWidth;
  }
}
