import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  selectFeed,
  updateFeeds,
  feedTypingListUpdated,
  feedLastSeenUpdated,
  feedLoaded
} from './feed.actions';
import { FeedTypingMap } from '../../models';
import { createReducer, on } from '@ngrx/store';
import { FeedDto } from '@act/shared/data-transfer-objects';

export const feedFeatureKey = 'feeds';

export interface State extends EntityState<FeedDto> {
  idOfSelectedFeed: number;
  typing: FeedTypingMap;
}

export const adapter: EntityAdapter<FeedDto> = createEntityAdapter<FeedDto>();

export const initialState: State = adapter.getInitialState({
  idOfSelectedFeed: null,
  typing: {}
});

export const reducer = createReducer(
  initialState,
  on(selectFeed, (state, action) => ({
    ...state,
    idOfSelectedFeed: action.feedId
  })),
  on(updateFeeds, (state, action) => adapter.upsertMany(action.feeds, state)),
  on(feedTypingListUpdated, (state, action) => {
    return {
      ...state,
      typing: {
        ...state.typing,
        [action.payload.feedId]: action.payload.typing || []
      }
    };
  }),
  on(feedLastSeenUpdated, (state, action) =>
    adapter.upsertOne(action.payload.feed, state)
  ),
  on(feedLoaded, (state, action) =>
    adapter.upsertOne(action.responseBody, state)
  )
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();
