import { ScheduledEventDto } from '@act/shared/data-transfer-objects';
import { createAction, props } from '@ngrx/store';
import { ScheduledEventRequests } from './scheduled-event.requests';

const requests = new ScheduledEventRequests(null, null);

export const {
  initiator: loadScheduledEvents,
  success: scheduledEventsLoaded,
  error: scheduledEventsLoadError
} = requests.getScheduledEvents.getRequestActions();

/**
 * Creating scheduled events
 */
export const {
  initiator: createScheduledEvent,
  success: scheduledEventCreated,
  error: scheduledEventCreationError
} = requests.postScheduledEvent.getRequestActions();

/**
 * Updating scheduled events
 */
export const {
  initiator: updateScheduledEvent,
  success: scheduledEventUpdated,
  error: scheduledEventUpdateError
} = requests.patchScheduledEvent.getRequestActions();

/**
 * Deleting scheduled events
 */
export const {
  initiator: deleteScheduledEvent,
  success: scheduledEventDeleted,
  error: scheduledEventDeleteError
} = requests.deleteScheduledEvent.getRequestActions();

/**
 * Snooze scheduled events
 */
export const {
  initiator: snoozeScheduledEvent,
  success: scheduledEventSnoozed,
  error: scheduledEventSnoozeError
} = requests.snoozeScheduledEvent.getRequestActions();

/**
 *  Edit scheduled events
 */
export const editScheduledEvent = createAction(
  '[Scheduled-Events] Edit Scheduled Event',
  props<{ scheduledEvent: ScheduledEventDto }>()
);

export const stopEditingScheduledEvent = createAction(
  '[Scheduled-Events] Stop Editing Scheduled Event',
  props<{ scheduledEventId: number }>()
);

export const startCreatingScheduledEvent = createAction(
  '[Scheduled-Events] Start Creating Scheduled Event',
  props<{ subjectUserId: string }>()
);

/**
 * Socket event action
 */
export const newScheduledEvent = createAction(
  '[Scheduled-Events] New Scheduled Event Added',
  props<{ item: ScheduledEventDto }>()
);
