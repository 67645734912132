import {
  Component,
  Input,
  Host} from '@angular/core';
import { CheckboxGroupComponent } from './checkbox-group-component';

@Component({
  selector: 'defaultCheckbox',
  template: `
    <div class="default-checkbox">
      <mat-checkbox (click)="toggleCheck()"><ng-content></ng-content></mat-checkbox>
      <div></div>
    </div>
  `,
  styles: [
    `
      .default-checkbox {
        color: #0a2127;
        font-size: 12px;
        cursor: pointer;
        font-weight: 500;
        margin-bottom: 5px;
      }
      ::ng-deep .default-checkbox .mat-checkbox .mat-checkbox-frame {
        border-color: #d6dddc;
        background: #fff;
      }
    `
  ]
})
export class DefaultCheckboxComponent {
  @Input() value: any;
  isCheckedVal = false;

  constructor(
    @Host() private checkboxGroup: CheckboxGroupComponent,
  ) {}

  toggleCheck() {
    const action = this.checkboxGroup.addOrRemove(this.value);
    //do action
  }

  isChecked() {
    this.isCheckedVal = this.checkboxGroup.contains(this.value);
    return this.checkboxGroup.contains(this.value);
  }
}
