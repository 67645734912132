import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActDialogService } from './services/act-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogBaseComponent } from './components/dialog-base/dialog-base.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SharedUiFormsModule } from '@act/shared/ui/forms';
import { AnchoredDialogComponent } from './components/anchored-dialog/anchored-dialog.component';
import { AnchoredDialogService } from './services/anchored-dialog.service';
import { AnchoredModalLayoutComponent } from './components/anchored-modal-layout/anchored-modal-layout.component';
import { CommonPipesHoursMinutesSecondsModule } from '@act/common/pipes/hours-minutes-seconds';
import { MomentModule } from 'ngx-moment';
import { SharedUiSpinnerModule } from '@act/shared/ui/spinner';
import { AnchoredDialogActionsService } from './services/anchored-dialog-actions.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    SharedUiFormsModule,
    CommonPipesHoursMinutesSecondsModule,
    MomentModule,
    SharedUiSpinnerModule
  ],
  exports: [AnchoredModalLayoutComponent],
  providers: [
    ActDialogService,
    AnchoredDialogService,
    AnchoredDialogActionsService
  ],
  declarations: [
    DialogBaseComponent,
    ConfirmDialogComponent,
    AnchoredDialogComponent,
    AnchoredModalLayoutComponent
  ],
  entryComponents: [
    DialogBaseComponent,
    ConfirmDialogComponent,
    AnchoredDialogComponent
  ]
})
export class SharedUiDialogModule {}
