import { Component, Input, OnInit } from '@angular/core';
import { FeedCardAction, FeedCardData } from '../..';
import { AdditionalFeedCardActions } from '../feed-card-content/feed-card-content.component';
@Component({
  selector: 'act-feed-card',
  templateUrl: './feed-card.component.html',
  styleUrls: ['./feed-card.component.scss']
})
export class FeedCardComponent implements OnInit {
  @Input() cardData: FeedCardData;
  @Input() actions: FeedCardAction[];
  @Input() additionalActions: AdditionalFeedCardActions[];
  @Input() hideIcon: boolean;

  constructor() {}

  ngOnInit() {}
}
