import {
  CohortDto,
  CreateCohortDto,
  UpdateCohortDto
} from '@act/shared/data-transfer-objects';
import { getClientConfig } from '@act/shared/environment';
import { ClientBootstrap } from '@act/shared/models';
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { createCohort, loadCohorts, updateCohort } from './cohort.actions';
import * as fromCohort from './cohort.reducer';
import {
  selectCohort,
  selectCohortMap,
  selectCohortsLoading
} from './cohort.selectors';

@Injectable()
export class CohortFacade {
  cohortCreationIdTicker = 1;
  constructor(private store: Store<fromCohort.State>) {}

  private config: ClientBootstrap = getClientConfig();

  cohorts$: Observable<CohortDto[]> = this.store.pipe(select(selectCohort));

  cohortsMap$: Observable<Dictionary<CohortDto>> = this.store.pipe(
    select(selectCohortMap)
  );

  cohortsLoading$: Observable<boolean> = this.store.pipe(
    select(selectCohortsLoading)
  );

  getCohortsOfMember(ownerId: string) {
    this.store.dispatch(
      loadCohorts({ params: { ownerUserId: ownerId }, requestBody: null })
    );
  }

  createCohort(request: CreateCohortDto) {
    this.store.dispatch(
      createCohort({
        params: null,
        requestBody: { ...request },
        actionParams: { creationId: this.cohortCreationIdTicker++ }
      })
    );
  }

  updateCohort(request: UpdateCohortDto, cohortId: number) {
    this.store.dispatch(
      updateCohort({ params: { cohortId }, requestBody: { ...request } })
    );
  }
}
