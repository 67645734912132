import { MemberFiltersDto } from '@act/shared/data-transfer-objects';
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'checkbox-group',
  template: `
    <ng-content></ng-content>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true
    }
  ]
})
export class CheckboxGroupComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  private _model: any;
  private onChange: (m: any) => void;
  private onTouched: (m: any) => void;
  @Input() filterType: string;
  @Input() memberFilters$: Observable<MemberFiltersDto>;
  filterSubscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.filterSubscription = this.memberFilters$.subscribe(
      (memberFilters: MemberFiltersDto) => {
        this._model = memberFilters ? memberFilters[this.filterType] : [];
      }
    );
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  get model() {
    return this._model;
  }

  set model(model: any) {
    this._model = model;
  }

  writeValue(value: any): void {
    this._model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set(value: any) {
    this._model = value;
    this.onChange(this._model);
  }

  addOrRemove(value: any) {
    let action = 'add';

    if (this.contains(value)) {
      this.remove(value);
      action = 'remove';
    } else {
      this.add(value);
    }
    return action;
  }

  contains(value: any): boolean {
    if (this._model instanceof Array) {
      return this._model.indexOf(value) > -1;
    } else if (!!this._model) {
      return this._model === value;
    }

    return false;
  }

  private add(value: any) {
    if (!this.contains(value)) {
      if (this._model instanceof Array) {
        this._model.push(value);
      } else {
        this._model = [value];
      }
      this.onChange(this._model);
    }
  }

  private remove(value: any) {
    const index = this._model.indexOf(value);
    if (!this._model || index < 0) {
      return;
    }

    this._model.splice(index, 1);
    this.onChange(this._model);
  }
}
