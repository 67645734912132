import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FadeExpanderComponent } from './fade-expander/fade-expander.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule],
  declarations: [FadeExpanderComponent],
  exports: [FadeExpanderComponent]
})
export class SharedUiFadeExpanderModule {}
