import {
  callAdapter,
  callFeatureKey,
  callOutcomeAdapter,
  State
} from './call.reducer';
import * as fromChat from '../chat.selectors';
import { createSelector } from '@ngrx/store';
import { InflightIds } from '@act/shared/api';

const callSelectors = callAdapter.getSelectors();
const callOutcomeSelectors = callOutcomeAdapter.getSelectors();

const selectCallState = createSelector(
  fromChat.selectChatState,
  (state): State => state[callFeatureKey]
);

export const selectCallEntities = createSelector(
  selectCallState,
  callSelectors.selectEntities
);

export const selectCallLoading = createSelector(
  selectCallState,
  state => state.loading
);

const selectCallOutcomeState = createSelector(
  selectCallState,
  state => state.outcomes
);

export const selectCallsBeingCreated = createSelector(
  selectCallState,
  (state: State): InflightIds => {
    return state.inflightCreationIds;
  }
);

export const selectInflightUpdateIds = createSelector(
  selectCallState,
  (state: State): InflightIds => {
    return state.inflightUpdateIds;
  }
);

export const selectCallOutcomesList = createSelector(
  selectCallOutcomeState,
  callOutcomeSelectors.selectAll
);

export const selectCallOutcomesMap = createSelector(
  selectCallOutcomeState,
  callOutcomeSelectors.selectEntities
);

export const selectCallOutcomesLoading = createSelector(
  selectCallOutcomeState,
  state => state.loading
);
