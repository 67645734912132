import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTextTemplate'
})
export class FormatTextTemplatePipe implements PipeTransform {
  transform(value: string, templateVariables: any, ...args: any[]): any {
    const tempVariables = Object.keys(templateVariables).reduce(
      (templateVar, key) => ({
        ...templateVar,
        [key]: '[' + key.replace(/([A-Z])/g, ' $1').toUpperCase() + ']'
      }),
      <{ [key: string]: string }>{}
    );
    const formattedTemplate = Object.entries(tempVariables).reduce(
      (acc, variable) => {
        const expression = `{{${variable[0]}}}`;
        const regex = new RegExp(expression, 'g');
        return acc.replace(regex, variable[1]);
      },
      value
    );
    return formattedTemplate;
  }
}
