import { EnhancedScriptProcessorNode } from '../models/volume-tester.model';

/**
 * https://github.com/cwilso/volume-meter/blob/master/volume-meter.js
 *
 * audioContext
 * -- the callback you want to fire when the audio level changes.
 * clipLevel
 * -- the level (0 to 1) that you would consider "clipping"
 * averaging
 * -- how "smoothed" you would like the meter to be over time. Should be between 0 and less than 1.
 * clipLag
 * -- how long you would like the "clipping" indicator to show after clipping has occured, in milliseconds
 */
export function createAudioMeter(
  audioContext: AudioContext,
  clipLevel: number = 0.98,
  averaging: number = 0.95,
  clipLag: number = 750
): EnhancedScriptProcessorNode {
  const processor: EnhancedScriptProcessorNode = audioContext.createScriptProcessor(512) as EnhancedScriptProcessorNode;

  processor.clipping = false;
  processor.lastClip = 0;
  processor.volume = 0;
  processor.clipLevel = clipLevel;
  processor.averaging = averaging;
  processor.clipLag = clipLag;

  // this will have no effect, since we don't copy the input to the output,
  // but works around a current Chrome bug.
  processor.connect(audioContext.destination);

  processor.addListener = (listener: EventListenerOrEventListenerObject): void => {
    processor.addEventListener('audioprocess', listener);
  };

  processor.removeListener = (listener: EventListenerOrEventListenerObject): void => {
    processor.disconnect();
    processor.removeEventListener('audioprocess', listener);
  };

  return processor;
}
