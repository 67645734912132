import * as fromUsers from './users.reducer';
import { createSelector } from '@ngrx/store';

const selectUsersState = (state: any): fromUsers.State =>
  state[fromUsers.usersFeatureKey];

const selectAllUsers = createSelector(
  selectUsersState,
  fromUsers.selectAll
);

const selectUserEntities = createSelector(
  selectUsersState,
  state => state && state.entities
);

const selectTeamIds = createSelector(
  selectUsersState,
  state => state.teamIds
);

const selectAllTeam = createSelector(
  selectTeamIds,
  selectUserEntities,
  (teamIds, userMap) => teamIds.map(id => userMap[id]).filter(u => u)
);

const selectSelectedTeamMemberId = createSelector(
  selectUsersState,
  state => state.selectedTeamMemberId
);

const selectSelectedTeamMember = createSelector(
  selectSelectedTeamMemberId,
  selectUserEntities,
  (selectedTeamMemberId, entities) =>
    (entities || {})[selectedTeamMemberId] || null
);

const selectMemberIds = createSelector(
  selectUsersState,
  state => state.memberIds
);

const selectAllMembers = createSelector(
  selectMemberIds,
  selectUserEntities,
  (memberIds, userMap) => memberIds.map(id => userMap[id]).filter(u => u)
);

const selectSelectedMemberId = createSelector(
  selectUsersState,
  state => state && state.selectedMemberId
);

const selectMembersLoading = createSelector(
  selectUsersState,
  state => state.membersLoading
);

const selectUserDetailsLoading = createSelector(
  selectUsersState,
  (state: fromUsers.State, userId: string) => {
    return (
      state.userDetailsLoaded[userId] && state.userDetailsLoaded[userId].loading
    );
  }
);

const selectUserDetailsError = createSelector(
  selectUsersState,
  (state: fromUsers.State, userId: string) => {
    return (
      state.userDetailsLoaded[userId] && state.userDetailsLoaded[userId].error
    );
  }
);

const selectMemberFilters = createSelector(
  selectUsersState,
  state => state.selectedMemberFilters
);

const selectAllFilteredMembers = createSelector(
  selectAllMembers,
  selectMemberFilters,
  (members, filters) => {
    if (!filters) {
      return members;
    }
    const { statuses, events, cohorts } = filters;
    let guideMembers = [...members];
    if (events && events.length) {
      guideMembers = members.filter(member =>
        events.includes(member.nextEventType)
      );
    }

    if (statuses && statuses.length) {
      guideMembers = guideMembers.filter(member =>
        statuses.includes(member.memberStatus && member.memberStatus.id)
      );
    }

    if (cohorts && cohorts.length) {
      guideMembers = guideMembers.filter(member =>
        cohorts.includes(member.cohort === null ? null : member.cohort.id)
      );
    }

    return guideMembers;
  }
);

const todayResults = createSelector(
  selectUsersState,
  state => state.today
);

const loadingToday = createSelector(
  selectUsersState,
  state => state.loadingToday
);

export const usersQueries = {
  selectAllUsers,
  selectUserEntities,
  selectAllTeam,
  selectSelectedTeamMemberId,
  selectAllMembers,
  selectMembersLoading,
  selectSelectedMemberId,
  selectUserDetailsLoading,
  selectUserDetailsError,
  selectMemberFilters,
  selectAllFilteredMembers,
  todayResults,
  loadingToday
};
