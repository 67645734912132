import { Action } from '@ngrx/store';

export enum FeedTypingListUpdatedTypes {
  FEED_TYPING_LIST_UPDATED = '[Chat] Feed Typing List Updated'
}

export interface FeedTypingListUpdatedPayload {
  feedId: number;
  typing: string[];
}

export class FeedTypingListUpdated implements Action {
  readonly type = FeedTypingListUpdatedTypes.FEED_TYPING_LIST_UPDATED;

  constructor(readonly payload: FeedTypingListUpdatedPayload) {}
}
