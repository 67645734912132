import * as fromChat from '../chat.selectors';
import * as fromFeed from './feed.reducer';
import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { FeedDto } from '@act/shared/data-transfer-objects';

const selectFeedState = createSelector(
  fromChat.selectChatState,
  (state: any): fromFeed.State => state[fromFeed.feedFeatureKey]
);

const selectAllFeeds = createSelector(
  selectFeedState,
  fromFeed.selectAll
);

const selectSelectedFeedId = createSelector(
  selectFeedState,
  (state: fromFeed.State) => state.idOfSelectedFeed
);

const selectSubjectFeedMap = createSelector(
  selectAllFeeds,
  feeds =>
    feeds.reduce(
      (map, feed) => {
        map[feed.subjectId] = feed;
        return map;
      },
      <{ [key: string]: FeedDto }>{}
    )
);

const selectSelectedFeed = createSelector(
  selectFeedState,
  (state: fromFeed.State) => state.entities[state.idOfSelectedFeed]
);

const selectFeedTypingMap = createSelector(
  selectFeedState,
  (state: fromFeed.State) => state.typing
);

export const feedQueries = {
  selectAllFeeds,
  selectSelectedFeedId,
  selectSelectedFeed,
  selectSubjectFeedMap,
  selectFeedTypingMap
};
