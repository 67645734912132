import { getValueOfObservable } from '@act/common/utilities';
import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  Type,
  ComponentRef
} from '@angular/core';
import { AnchoredDialogComponent } from 'libs/shared/ui/dialog/src/lib/components/anchored-dialog/anchored-dialog.component';
import {
  ActionType,
  EventType,
  shouldOpenDialogObservable$
} from 'libs/shared/ui/dialog/src/lib/models/dialog-options.model';

interface OpenAndDestroy {
  open: () => ComponentRef<AnchoredDialogComponent>;
  ngOnChanges?: () => any;
  ngOnInit?: () => any;
}

@Injectable()
export class AnchoredDialogActionsService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  protected getId(
    actionType: ActionType,
    eventType: EventType,
    eventId: number | string
  ): string {
    return `${actionType}_${eventType}_${eventId}`;
  }

  protected open<T extends OpenAndDestroy>(
    componentType: Type<T>,
    inputs: Partial<T> = {},
    onDone: () => any = null
  ) {
    const shouldOpenDialog = getValueOfObservable(shouldOpenDialogObservable$);
    if (shouldOpenDialog) {
      const componentRef = this.appendComponentToBody(componentType, inputs);
      const component = this.getComponent(componentRef);
      if (component.ngOnChanges) {
        component.ngOnChanges();
      }

      if (component.ngOnInit) {
        component.ngOnInit();
      }
      const ref = component.open();
      ref.onDestroy(() => {
        componentRef.destroy();
        if (onDone) {
          onDone();
        }
      });
    }
  }

  protected getComponent<T>(componentRef: ComponentRef<T>): T {
    return componentRef.instance;
  }

  protected appendComponentToBody<T>(
    component: Type<T>,
    inputs: Partial<T> = {}
  ): ComponentRef<T> {
    // 1. Create a component reference from the component
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    // Set inputs
    Object.entries(inputs).forEach(([key, value]) => {
      componentRef.instance[key] = value;
    });

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // 4. Append DOM element to the body
    document.body.appendChild(domElem);

    // HACK to get this - I ran out of time to figure out how to do this correctly
    return componentRef;
  }
}
