import {
  AddressCreationRequestDto,
  AddressDto,
  PhoneNumberCreationRequestDto,
  PhoneNumberDto,
  RaceEthnicityDto,
  TodayResultDto,
  UpdateAddressRequestDto,
  UpdatePhoneNumberRequestDto,
  UpdateRaceEthnicityRequestDto,
  UpdateUserRequestDto,
  UpdateUserSummaryRequestDto,
  UserDto,
  UserRaceEthnicityDto,
  UserSummaryDto
} from '@act/shared/data-transfer-objects';

import { ApiRequestDefinition } from '@act/shared/api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class UsersRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  updateUser = ApiRequestDefinition.createRequestDefinition<
    UserDto,
    UpdateUserRequestDto,
    { userId: string },
    null
  >('/users/:userId', 'PATCH', '[Users] Update User', this.store, this.http);

  updateUsers = ApiRequestDefinition.createRequestDefinition<
    UserDto[],
    UpdateUserRequestDto,
    { id: string[] },
    null
  >('/users', 'PATCH', '[Users] Update Users', this.store, this.http);

  getMembersForGuide = ApiRequestDefinition.createRequestDefinition<
    UserDto[],
    null,
    { guideId: string },
    null
  >('/users', 'GET', '[Users] Get Members', this.store, this.http);

  getTeam = ApiRequestDefinition.createRequestDefinition<
    UserDto[],
    null,
    null,
    null
  >('/users/team', 'GET', '[Users] Get Team', this.store, this.http);

  postUserPhoneNumber = ApiRequestDefinition.createRequestDefinition<
    PhoneNumberDto,
    PhoneNumberCreationRequestDto,
    { userId: string },
    null
  >(
    '/users/:userId/phone-numbers',
    'POST',
    '[Users] Create User Phone Number',
    this.store,
    this.http
  );

  patchUserSummary = ApiRequestDefinition.createRequestDefinition<
    UserSummaryDto,
    UpdateUserSummaryRequestDto,
    { userId: string },
    null
  >(
    '/users/:userId/summary',
    'PATCH',
    '[Users] Update User Summary',
    this.store,
    this.http
  );

  getUserDetails = ApiRequestDefinition.createRequestDefinition<
    UserDto,
    null,
    { userId: string },
    null
  >(
    '/users/:userId?include-details=true',
    'GET',
    '[Users] Get User Details',
    this.store,
    this.http
  );

  patchUserPhoneNumber = ApiRequestDefinition.createRequestDefinition<
    PhoneNumberDto,
    UpdatePhoneNumberRequestDto,
    { userId: string; phoneNumberId: number },
    null
  >(
    '/users/:userId/phone-numbers/:phoneNumberId',
    'PATCH',
    '[Users] Update User Phone Number',
    this.store,
    this.http
  );

  deleteUserPhoneNumber = ApiRequestDefinition.createRequestDefinition<
    PhoneNumberDto,
    null,
    { userId: string; phoneNumberId: number },
    null
  >(
    '/users/:userId/phone-numbers/:phoneNumberId',
    'DELETE',
    '[Users] Delete User Phone Number',
    this.store,
    this.http
  );

  postUserAddress = ApiRequestDefinition.createRequestDefinition<
    AddressDto,
    AddressCreationRequestDto,
    { userId: string },
    null
  >(
    '/users/:userId/addresses',
    'POST',
    '[Users] Create User Address',
    this.store,
    this.http
  );

  patchUserAddress = ApiRequestDefinition.createRequestDefinition<
    AddressDto,
    UpdateAddressRequestDto,
    { userId: string; addressId: number },
    null
  >(
    '/users/:userId/addresses/:addressId',
    'PATCH',
    '[Users] Update User Address',
    this.store,
    this.http
  );

  deleteUserAddress = ApiRequestDefinition.createRequestDefinition<
    AddressDto,
    null,
    { userId: string; addressId: number },
    null
  >(
    '/users/:userId/addresses/:addressId',
    'DELETE',
    '[Users] Delete User Address',
    this.store,
    this.http
  );

  getTodayResultsForGuide = ApiRequestDefinition.createRequestDefinition<
    TodayResultDto[],
    null,
    { guideId: string },
    { silent: boolean }
  >('/users/today', 'GET', '[Users] Get Today Results', this.store, this.http);

  getAllMembers = ApiRequestDefinition.createRequestDefinition<
    UserDto[],
    null,
    null
  >('/users', 'GET', '[Users] Get All Members', this.store, this.http);

  getAllTodayResults = ApiRequestDefinition.createRequestDefinition<
    TodayResultDto[],
    null,
    null,
    { silent: boolean }
  >(
    '/users/today',
    'GET',
    '[Users] Get All Today Results',
    this.store,
    this.http
  );

  getAvailableRaceEthnicities = ApiRequestDefinition.createRequestDefinition<
    RaceEthnicityDto[],
    null,
    null
  >(
    '/users/race-ethnicities',
    'GET',
    '[Users] Get Available Race/Ethnicities',
    this.store,
    this.http
  );

  getUserRaceEthnicities = ApiRequestDefinition.createRequestDefinition<
    UserRaceEthnicityDto[],
    null,
    { userId: string }
  >(
    '/users/:userId/race-ethnicities',
    'GET',
    '[Users] Get User Race/Ethnicities',
    this.store,
    this.http
  );

  updateUserRaceEthnicities = ApiRequestDefinition.createRequestDefinition<
    UserRaceEthnicityDto[],
    UpdateRaceEthnicityRequestDto[],
    { userId: string }
  >(
    '/users/:userId/race-ethnicities',
    'PUT',
    '[Users] Get User Race/Ethnicities',
    this.store,
    this.http
  );
}

export const requests = {};
