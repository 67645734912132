import { FeedItemDto } from '@act/shared/data-transfer-objects';

export enum FeedItemUpdatedTypes {
  FEED_ITEM_UPDATED = '[Feed] Feed Item Updated',
  FEED_ITEM_DELETED = '[Feed] Feed Item Deleted'
}

export interface FeedItemUpdatedEventPayload {
  feedItem: FeedItemDto;
}
export class FeedItemUpdatedEvent {
  readonly type = FeedItemUpdatedTypes.FEED_ITEM_UPDATED;

  constructor(public payload: FeedItemUpdatedEventPayload) {}
}

export class FeedItemDeletedEvent {
  readonly type = FeedItemUpdatedTypes.FEED_ITEM_DELETED;

  constructor(public payload: FeedItemUpdatedEventPayload) {}
}
