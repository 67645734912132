import { SOCKET_NAME } from '@act/shared/models';

export enum HealthCheckTypes {
  HEALTH_CHECK = '[Socket] Health Check'
}

export class HealthCheck {
  readonly type = HealthCheckTypes.HEALTH_CHECK;

  constructor(public payload: HealthCheckPayload) {}
}

export interface HealthCheckPayload {
  deviceId: string;
  socketName: SOCKET_NAME;
}
