import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'act-member-nav-bar',
  templateUrl: './member-nav-bar.component.html',
  styleUrls: ['./member-nav-bar.component.scss']
})
export class MemberNavBarComponent {
  @Input() feedFilter: string;
  @Output() setFeedFilter: EventEmitter<string> = new EventEmitter();
}
