import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromNote from './note.reducer';
import { Observable } from 'rxjs';
import {
  CommentDto,
  NoteDto,
  UpdateNoteRequestDto
} from '@act/shared/data-transfer-objects';
import { ClientBootstrap } from '@act/shared/models';
import { getClientConfig } from '@act/shared/environment';
import {
  deleteNote,
  editNote,
  stopEditingNote,
  stopViewingFeedItemNotes,
  toggleNoteEditing,
  viewFeedItemNotes
} from './note.actions';
import {
  selectEditedNoteId,
  selectNotesByFeedItemId,
  selectFeedItemIdNotesViewed,
  selectNotesForViewedFeedItemId,
  selectLoading
} from './note.selectors';
import { FeedItem } from '../../models';
import { selectFeedItemForNotesBeingViewed } from '../feed-item/feed-item.selectors';
import { NoteRequests } from './note.requests';

@Injectable()
export class NoteFacade {
  noteCreationIdTicker = 1;

  constructor(
    private store: Store<fromNote.State>,
    private noteRequests: NoteRequests
  ) {}

  updateNote(noteId: number, request: UpdateNoteRequestDto): Promise<NoteDto> {
    return this.noteRequests.patchNote.makeRequest({
      params: { noteId },
      body: { ...request }
    });
  }

  autoSaveNote(noteId: number, text: string): Promise<NoteDto> {
    return this.noteRequests.patchNote.makeRequest({
      params: { noteId },
      body: { text, noteId, delta: null, isAutoUpdate: false }
    });
  }

  deleteNote(noteId: number) {
    this.store.dispatch(deleteNote({ params: { noteId }, requestBody: null }));
  }

  createNoteForUser(subjectId: string, text: string): Promise<NoteDto> {
    return this.noteRequests.postNote.makeRequest({
      params: null,
      body: { userId: subjectId, text },
      actionParams: {
        creationId: this.noteCreationIdTicker++
      }
    });
  }

  createComment(
    noteId: number,
    text: string,
    feedItemId: number
  ): Promise<CommentDto> {
    return this.noteRequests.createComment.makeRequest({
      params: { noteId },
      body: { text },
      actionParams: { feedItemId }
    });
  }

  editNote(note: NoteDto) {
    this.store.dispatch(editNote({ note }));
  }

  stopEditingNote(noteId: number) {
    this.store.dispatch(stopEditingNote({ noteId }));
  }

  toggleNoteEditing(note: NoteDto) {
    this.store.dispatch(toggleNoteEditing({ note }));
  }

  viewFeedItemNotes(feedItemId: number) {
    this.store.dispatch(viewFeedItemNotes({ feedItemId }));
  }

  stopViewingFeedItemNotes(feedItemId: number) {
    this.store.dispatch(stopViewingFeedItemNotes({ feedItemId }));
  }

  getNotesForFeedItem$(feedItemId: number) {
    return this.store.select(selectNotesByFeedItemId, feedItemId);
  }

  editedNoteId$: Observable<number> = this.store.select(selectEditedNoteId);
  feedItemIdNotesViewed$: Observable<number> = this.store.select(
    selectFeedItemIdNotesViewed
  );
  notesForViewedFeedItem$: Observable<NoteDto[]> = this.store.select(
    selectNotesForViewedFeedItemId
  );
  itemWhosNotesWereViewing$: Observable<FeedItem> = this.store.select(
    selectFeedItemForNotesBeingViewed
  );

  noteLoading$: Observable<boolean> = this.store.select(selectLoading);

  doesNoteHaveUnsavedChanges(note: NoteDto): boolean {
    return note.text === note.draftText;
  }

  private async getAutoSave(subjectId: string, type: string): Promise<string> {
    const response = await this.noteRequests.getAutoSaveNote.makeSimpleRequest({
      params: { subjectId, type }
    });
    return response.text;
  }
  private async clearAutoSave(subjectId: string, type: string): Promise<void> {
    await this.noteRequests.clearAutoSaveNote.makeSimpleRequest({
      params: { subjectId, type }
    });
  }
  private async updateAutoSave(
    subjectId: string,
    type: string,
    text: string
  ): Promise<void> {
    await this.noteRequests.updateAutoSaveNote.makeSimpleRequest({
      body: { text },
      params: { subjectId, type }
    });
  }

  private crateAutoSaveFunctions(type: string) {
    return {
      get: (subjectId: string) => this.getAutoSave(subjectId, type),
      update: (subjectId: string, text: string) =>
        this.updateAutoSave(subjectId, type, text),
      clear: (subjectId: string) => this.clearAutoSave(subjectId, type)
    };
  }

  autoSave = {
    visit: this.crateAutoSaveFunctions('visit'),
    call: this.crateAutoSaveFunctions('call'),
    note: this.crateAutoSaveFunctions('note'),
    need: this.crateAutoSaveFunctions('need')
  };
}
