import { IsNotEmpty, Matches } from 'class-validator';

export interface CohortDto {
  id: number;
  name: string;
  colorHex: string;
  ownerUserId: string;
}

export class UpdateCohortDto {
  @IsNotEmpty({ message: 'name is required' })
  name: string;
  @IsNotEmpty({ message: 'colorHex is required' })
  @Matches(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/, {
    message:
      'cohortHex is not a valid color hex code. Should be of the form #789ABC'
  })
  colorHex: string;
}
export type CreateCohortDto = UpdateCohortDto;
