export interface LoadingItems {
  visit: boolean;
  reminder: boolean;
  call: boolean;
  note: boolean;
  message: boolean;
}

export const initialLoadingState: LoadingItems = {
  visit: false,
  reminder: false,
  call: false,
  note: false,
  message: false
};

enum LoadingItemTypes {
  VISIT = 'visit',
  REMINDER = 'reminder',
  CALL = 'call',
  NOTE = 'note',
  MESSAGE = 'message'
}

export const loadingItemTypes = {
  visit: LoadingItemTypes.VISIT,
  reminder: LoadingItemTypes.REMINDER,
  call: LoadingItemTypes.CALL,
  note: LoadingItemTypes.NOTE,
  message: LoadingItemTypes.MESSAGE
};
