export const PREFIXES = {
  CALL_SERVICE: '/calls',
  CHAT_SERVICE: '/chat',
  CLIENT_SERVICE: '/clients',
  CONFIG_SERVICE: '/config',
  ENROLLMENT_SERVICE: '/enrollments',
  GLOBAL_PREFIX: '/api',
  NOTES_SERVICE: '/notes',
  SURVEY_SERVICE: '/survey',
  USER_SERVICE: '/users',
  WEBSOCKET_SERVICE: '/websocket',
  SMS_SERVICE: '/sms',
  FILE_SERVICE: '/files',
  CONSENT_SERVICE: '/consent',
  FEED_SERVICE: '/feeds',
  VISIT_SERVICE: '/visits',
  CARE_SERVICE: '/care',
  NOTIFICATION_SERVICE: '/notifications'
};

export const PATH_PREFIX_PROVIDER = 'PATH_PREFIX_PROVIDER';
