import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'act-fade-expander',
  templateUrl: './fade-expander.component.html',
  styleUrls: ['./fade-expander.component.scss']
})
export class FadeExpanderComponent implements OnInit, OnChanges {
  @ViewChild('container', null) container: ElementRef;
  @ViewChild('content', null) content: ElementRef;

  @Input() height: number = 150;
  @Input() showMoreText: string;
  @Input() forcedOpen: boolean;
  @Input() updateTriggers: any[];

  canToggle = false;
  opened = false;
  closedHeight: number;
  openedHeight: number;
  private extraOpenHeight = 35;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  private setHeights() {
    this.canToggle = this.content.nativeElement.offsetHeight > this.height;
    if (this.canToggle) {
      this.closedHeight = this.height;
      this.openedHeight = null;
    } else {
      this.closedHeight = null;
      this.openedHeight = null;
    }
  }

  toggle() {
    this.opened = !this.opened;
  }

  public ngAfterViewInit(): void {
    this.setHeights();
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.opened = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    // If an item in the updateTriggers array has changed then trigger an update
    let shouldTriggerUpdate = false;

    if (!this.opened) {
      if (changes.updateTriggers) {
        const currentValue = changes.updateTriggers.currentValue || [];
        const previousValue = changes.updateTriggers.previousValue || [];
        shouldTriggerUpdate = currentValue.reduce(
          (shouldTriggerUpdate, curValue, index: number) => {
            if (shouldTriggerUpdate) {
              return true;
            }
            return previousValue[index] !== curValue;
          },
          false
        );
      }
      if (shouldTriggerUpdate) {
        setTimeout(() => {
          this.setHeights();
          this.changeDetectorRef.detectChanges();
        }, 1);
      }
    }
  }
}
