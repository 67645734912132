import { createReducer, on, Action } from '@ngrx/store';
import * as UserActions from './user.actions';
import { ActingUserDto, UserDto } from '@act/shared/data-transfer-objects';

export const userFeatureKey = 'user';

export interface State {
  user: ActingUserDto;
  authenticated: boolean;
}

export const initialState: State = {
  user: null,
  authenticated: false
};

const userReducer = createReducer(
  initialState,
  on(UserActions.userLoaded, (state, action) => ({
    ...state,
    user: action.user
  })),
  on(UserActions.userAuthenticated, (state, action) => ({
    ...state,
    authenticated: true
  })),
  on(UserActions.userNotAuthenticated, (state, action) => ({
    ...state,
    authenticated: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}
