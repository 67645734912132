import {
  CallDto,
  NoteDto,
  CallOutcomeDto,
  UserDto,
  CallUser
} from '@act/shared/data-transfer-objects';
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  ComponentRef,
  OnChanges,
  TemplateRef
} from '@angular/core';
import { CallFacade } from '../../+state/call/call.facade';
import { FormControl, Validators } from '@angular/forms';
import { formatPhoneNumber, getValueOfObservable } from '@act/common/utilities';
import { DebounceAutoSaver } from '@act/common/utilities/autosave';
import { ActDialogService } from '@act/shared/ui/dialog';
import { SelectDto } from 'libs/shared/ui/forms/src/lib/inputs/select/select.component';
import { AnchoredDialogComponent } from 'libs/shared/ui/dialog/src/lib/components/anchored-dialog/anchored-dialog.component';
import { RichTextInputComponent } from 'libs/shared/ui/forms/src/lib/inputs/rich-text-input/rich-text-input.component';
import { CallStatus } from '@act/shared/models';
import { Dictionary } from '@ngrx/entity';
import { AnchoredDialogService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog.service';
import { map } from 'rxjs/operators';
import { Platform } from '@act/core/platform';

const ICON_MISSED = 'Phone_fail_black.svg';
const ICON_INCOMING = 'Phone_black.svg';
const ICON_OUTGOING = 'Phone_black.svg';
const ICON_ACTIVE = 'Phone_black.svg';

@Component({
  selector: 'act-edit-call-notes-dialog',
  templateUrl: './edit-call-notes-dialog.component.html',
  styleUrls: ['./edit-call-notes-dialog.component.scss']
})
export class EditCallNotesDialogComponent
  implements OnInit, OnDestroy, OnChanges {
  @ViewChild(RichTextInputComponent, null)
  private noteInput: RichTextInputComponent;
  @Input() call: CallDto;
  @Input() userMap: Dictionary<UserDto>;

  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;

  user: UserDto;

  originatorName: string;
  receiverName: string;
  phoneNumber: string;
  primaryPhoneText: string;

  anchoredDialogRef: ComponentRef<AnchoredDialogComponent>;

  icon: string;
  title: string;
  callOriginator: UserDto;

  callNote = new FormControl();
  callOutcome = new FormControl('');
  commentControl: FormControl = new FormControl('', Validators.required);
  unsavedChanges = false;
  noteId: number;
  note: NoteDto;
  userId: string;
  saving: boolean;
  showComments: boolean = false;

  constructor(
    public callFacade: CallFacade,
    private anchoredDialogService: AnchoredDialogService,
    private dialogService: ActDialogService,
    private platform: Platform
  ) {}

  outcomes$ = this.callFacade.callOutcomes$;
  outcomeOptions$ = this.outcomes$.pipe(
    map(outcomes => outcomes.map(o => ({ value: o.id, label: o.text })))
  );

  open(): ComponentRef<AnchoredDialogComponent> {
    if (!(getValueOfObservable(this.outcomes$) || []).length) {
      this.callFacade.loadCallOutcomes();
    }

    if (this.call.note) {
      setTimeout(() => this.callNote.setValue(this.call.note));

      setTimeout(() => {
        // Put cursor to end
        const selection = this.noteInput.editor.quillEditor.getSelection();
        if (selection) {
          const text = this.call.note;
          this.noteInput.editor.quillEditor.setSelection(
            selection.index + text.length,
            0
          );
        }
      }, 300);
    }

    if (this.call.outcomeId) {
      this.callOutcome.setValue(this.call.outcomeId);
    }

    this.focusQuillEditor();

    const formattedPhoneNumber = this.phoneNumber
      ? formatPhoneNumber(this.phoneNumber)
      : '';
    const callSubtitle = this.call.incoming
      ? `${this.originatorName} ${formattedPhoneNumber}`
      : `${this.originatorName} to ${this.receiverName}`;

    const user = this.userMap ? this.userMap[this.call.subjectId || ''] : null;
    const name = user ? user.firstName + ' ' + user.lastName : null;

    if (!this.anchoredDialogRef) {
      this.anchoredDialogRef = this.anchoredDialogService.create({
        icon: this.call.incoming ? 'phone_callback' : 'phone_forwarded',
        title:
          'Edit ' +
          (this.call.incoming ? 'Inbound Call' : 'Outbound Call') +
          (name ? ' for ' + name : ''),
        ref: this.dialogRef,
        subTitle: this.originatorName ? callSubtitle : null,
        date: this.call.timeStarted,
        duration: this.call.timeEnded - this.call.timeConnected,
        actionType: 'edit',
        eventType: 'call',
        eventId: this.call.id,
        isLoading$: this.callFacade.callLoading$
      });
    }

    return this.anchoredDialogRef;
  }

  ngOnInit() {
    this.showComments = !!getValueOfObservable(this.platform.user$).featureFlags
      .INTERACTION_COMMENT;
  }

  ngOnChanges() {
    this.user = this.userMap[this.call.subjectId];
    this.title = this.getTitle(this.call);
    this.icon = this.getIcon(this.call);

    if (this.call.originator) {
      const callUserName = this.setCallDetails(
        this.call.originator,
        () => this.call.incoming
      );
      this.originatorName = callUserName;
      this.callOriginator = this.userMap[this.call.originator.userId];
    }

    if (this.call.receiver) {
      const callUserName = this.setCallDetails(
        this.call.receiver,
        () => !this.call.incoming
      );
      this.receiverName = callUserName;
    }
  }

  setCallDetails(callUser: CallUser, predicate: () => boolean): string {
    let callUserName;

    if (callUser.userId) {
      const user = this.userMap[callUser.userId];
      callUserName =
        user && user.firstName
          ? `${user.firstName} ${user.lastName ? user.lastName : ''}`
          : 'Unknown User';

      if (user && user.phoneNumbers.length) {
        const userPhoneDetails = user.phoneNumbers.find(
          i => i.number === callUser.phoneNumber
        );

        if (userPhoneDetails && predicate()) {
          this.phoneNumber = userPhoneDetails.number;
          this.primaryPhoneText = userPhoneDetails.description
            ? `, ${userPhoneDetails.description}`
            : null;
          if (userPhoneDetails.primary) {
            this.primaryPhoneText += ` (Primary)`;
          }
        } else if (!userPhoneDetails) {
          this.phoneNumber = callUser.phoneNumber;
          this.primaryPhoneText = null;
        }
      }
    }

    return callUserName;
  }

  getIcon(call: CallDto): string {
    if (!call) {
      return null;
    }

    let icon: string;
    if (this.call) {
      icon = this.call.incoming ? ICON_INCOMING : ICON_OUTGOING;

      if (this.call.status == CallStatus.ACTIVE) {
        icon = ICON_ACTIVE;
      }
      if (
        [CallStatus.MISSED, CallStatus.FAILED, CallStatus.DECLINED].includes(
          this.call.status
        )
      ) {
        icon = ICON_MISSED;
      }
    }

    return icon ? `/assets/icons/${icon}` : null;
  }

  getTitle(call: CallDto): string {
    if (!call) {
      return 'Call';
    }

    let callBase = call.incoming ? 'Inbound Call' : 'Outbound Call';

    const showStatus = ['busy', 'no-answer', 'failed', 'canceled'].includes(
      this.call.systemStatus
    );
    const status = showStatus ? `(${this.call.systemStatus})` : '';

    switch (call.status) {
      case CallStatus.CONNECTING:
        return `${callBase} Connecting...`;
      case CallStatus.DECLINED:
        return `${callBase} Declined ${status}`;
      case CallStatus.FAILED:
        return `${callBase} Failed ${status}`;
      case CallStatus.MISSED:
        return `${callBase} Missed ${status}`;
      case CallStatus.ACTIVE:
        return `${callBase} Active ${status}`;
      case CallStatus.ENDED:
        return `${callBase} Completed ${status}`;
    }
  }

  autoSaver = new DebounceAutoSaver(
    this.callNote.valueChanges,
    v => this.autoSaveCallNote(v),
    v => (this.call ? this.call.note !== v : false)
  );

  ngOnDestroy() {
    this.autoSaver.destroy();
  }

  private focusQuillEditor() {
    setTimeout(
      () => this.noteInput.editor && this.noteInput.editor.quillEditor.focus(),
      100
    );
  }

  trackItem(index: number, item: CallOutcomeDto): number {
    return item.id;
  }

  handleCommentChange(commentControl: FormControl) {
    this.commentControl = commentControl;
  }

  async saveCall() {
    if (this.call.id) {
      let outcomeId = null;
      let outcomeDetails = null;
      let selectedOutcome: CallOutcomeDto = null;

      if (this.callOutcome.value) {
        outcomeId = this.callOutcome.value;
        selectedOutcome = (getValueOfObservable(this.outcomes$) || []).find(
          i => i.id === outcomeId
        );
        outcomeDetails = selectedOutcome.text;
      }

      // Should we save the call?
      let save = true;
      if (
        this.user.memberStage.key === 'outreach' &&
        selectedOutcome &&
        selectedOutcome.key === 'engaged'
      ) {
        const confirmed = await this.dialogService.confirm(
          `<p>Saving call with outcome "${selectedOutcome.text}" will move ${this.user.name} to be In Program.</p>
          <p>Are you sure ${this.user.name} meets all of our requirements for them to be considered "Engaged"?</p>
          <ul>
            <li>Member has a positive disposition about Reema’s services</li>
            <li>Member does not express hesitancy around legitimacy after being introduced to Guide, given business card, and being directed to the website</li>
            <li>Guide has an established next step with member</li>
            <li>Member understands expectation around at least monthly contact</li>
          </ul>`,
          {
            yes: 'Yes, save and mark as engaged',
            no: 'No',
            messageIsHtml: true
          }
        );
        save = confirmed;
      }

      if (save) {
        this.saving = true;
        await this.callFacade.updateCall(this.call.id, {
          note: this.callNote.value,
          outcomeId,
          outcomeDetails
        });
        this.anchoredDialogRef.instance.closeModal();
        this.saving = false;
      }
    }
  }

  async addComment() {
    if (this.call.id && this.commentControl.value) {
      await this.callFacade.createComment(
        this.call.id,
        this.commentControl.value
      );
      this.close();
    }
  }

  close() {
    this.anchoredDialogRef.instance.closeModal();
  }

  async autoSaveCallNote(callNote: string): Promise<void> {
    if (this.call.id) {
      await this.callFacade.autoSaveCallNote(this.call.id, callNote);
    }
  }
}
