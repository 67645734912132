import {
  MessageTemplateCategoryDto,
  MessageTemplateVariablesDto
} from '@act/shared/data-transfer-objects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromTextTemplate from './text-template.reducer';
import { TextTemplateRequests } from './text-template.requests';
import {
  selectLoadingTextTemplates,
  selectLoadingTextTemplateVariables,
  selectTextTemplates,
  selectTextTemplateVariables
} from './text-template.selectors';

@Injectable()
export class TextTemplateFacade {
  constructor(
    private store: Store<fromTextTemplate.State>,
    private http: HttpClient
  ) {}

  textTemplates$: Observable<MessageTemplateCategoryDto[]> = this.store.pipe(
    select(selectTextTemplates)
  );

  textTemplateVariables$: Observable<
    MessageTemplateVariablesDto
  > = this.store.pipe(select(selectTextTemplateVariables));

  loadingTextTemplates$: Observable<boolean> = this.store.pipe(
    select(selectLoadingTextTemplates)
  );

  loadingTextTemplateVariables$: Observable<boolean> = this.store.pipe(
    select(selectLoadingTextTemplateVariables)
  );

  async getTextTemplatesForUser(userId: string) {
    const requests = new TextTemplateRequests(null, this.http);
    const [templates, variables] = await Promise.all([
      requests.getTextTemplates.makeSimpleRequest({
        params: { userId }
      }),
      requests.getTextTemplateVariables.makeSimpleRequest({
        params: { userId }
      })
    ]);
    return { templates, variables };
  }
}
