import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonSnackbarModule } from '@act/common/snackbar';
import { CommonDialogsConfirmationModule } from '@act/common/dialogs/confirmation';
import { reducer } from './+state/file/file.reducer';
import { FileEffects } from './+state/file/file.effects';
import { FileFacade } from './+state/file/file.facade';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'libs/shared/auth/clients/src/lib/interceptors/auth-interceptor.service';
import { FileRequests } from './+state/file/file.requests';

@NgModule({
  imports: [
    CommonModule,
    CommonSnackbarModule,
    CommonDialogsConfirmationModule,
    StoreModule.forFeature('files', reducer),
    EffectsModule.forFeature([FileEffects])
  ],
  providers: [
    FileFacade,
    FileRequests,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class FeaturesFilesDataAccessModule {}
