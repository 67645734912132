import { consentFeatureKey, State } from './consent.reducer';
import * as fromParent from '../consent.selectors';
import { createSelector } from '@ngrx/store';

const selectConsentState = createSelector(
  fromParent.selectChatState,
  (state): State => state[consentFeatureKey]
);

export const selectUserTermGroups = createSelector(
  selectConsentState,
  (state: State, userId: string) => state.userTermGroups[userId]
);
