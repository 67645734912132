import { Inject, Injectable } from '@angular/core';
import { CLIENT_CONFIG } from '@act/shared/environment';
import { ClientBootstrap } from '@act/shared/models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserDto } from '@act/shared/data-transfer-objects';
import { map } from 'rxjs/operators';

@Injectable()
export class UsersService {
  constructor(
    @Inject(CLIENT_CONFIG) private config: ClientBootstrap,
    private http: HttpClient
  ) {}

  loadProviders(): Observable<{ data: UserDto[] }> {
    const endpoint = `${this.config.reema.serviceUris.userService}/providers`;
    return this.http.get<{ data: UserDto[] }>(endpoint).pipe(
      map((res: { data: UserDto[] }) => ({
        data: res.data
      }))
    );
  }

  loadUsersByIds(ids: string[]): Observable<{ data: UserDto[] }> {
    const endpoint = `${this.config.reema.serviceUris.userService}`;
    return this.http.post<{ data: UserDto[] }>(endpoint, { ids });
  }
}
