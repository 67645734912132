import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import {
  PatientsFeatureState,
  patientsKey
} from '@act/features/patients/models';

import * as fromPatients from './patients/patients.reducer';

export const PATIENT_FEATURE_REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<PatientsFeatureState>
>('Patients');

export function getReducers(): ActionReducerMap<PatientsFeatureState> {
  return {
    [patientsKey]: fromPatients.reducer
  };
}
