import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouterFacade } from './services/router.facade';

export function initRouter(routerFacade: RouterFacade) {
  return () => routerFacade.bootstrap();
}

@NgModule({
  imports: [RouterModule],
  providers: [
    RouterFacade,
    { provide: APP_INITIALIZER, useFactory: initRouter, deps: [RouterFacade], multi: true }

  ]
})
export class CommonRouterModule {}
