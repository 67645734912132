import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'act-note-preview',
  templateUrl: './note-preview.component.html',
  styleUrls: ['./note-preview.component.scss']
})
export class NotePreviewComponent implements OnInit {
  @Input() item: any;
  @Input() type: string;
  @Input() fullNote: string;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.getFullNote();
  }

  getFullNote() {
    this.fullNote = this.item.note || '';

    if (this.type === 'note' && this.item.text) {
      this.fullNote = this.item.text;
    }
  }
}
