import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  callCreated,
  callCreationError,
  createCall,
  loadCallOutcomes,
  callOutcomesLoaded,
  callOutcomesLoadError,
  callUpdated,
  callUpdateError,
  noFollowUpUpdated,
  updateCall,
  createComment,
  commentCreated,
  commentCreationError
} from './call.actions';
import { CallDto, CallOutcomeDto } from '@act/shared/data-transfer-objects';
import {
  addToInflightList,
  InflightIds,
  removeFromInflightList
} from '@act/shared/api';

export const callFeatureKey = 'calls';

export interface State extends EntityState<CallDto> {
  outcomes: OutcomeState;
  inflightCreationIds: InflightIds;
  inflightUpdateIds: InflightIds;
  loading: boolean;
}

export const adapter: EntityAdapter<CallDto> = createEntityAdapter<CallDto>({
  sortComparer: (a: CallDto, b: CallDto) =>
    a.timeStarted > b.timeStarted ? 1 : -1
});

interface OutcomeState extends EntityState<CallOutcomeDto> {
  loading: boolean;
}

export const callOutcomeAdapter: EntityAdapter<
  CallOutcomeDto
> = createEntityAdapter<CallOutcomeDto>();

export const initialState: State = adapter.getInitialState({
  outcomes: callOutcomeAdapter.getInitialState({ loading: false }),
  inflightCreationIds: {},
  inflightUpdateIds: {},
  loading: false
});

const callReducer = createReducer(
  initialState,
  on(createCall, (state, action) => ({
    ...state,
    inflightCreationIds: addToInflightList(
      action.actionParams.creationId,
      state.inflightCreationIds
    ),
    loading: true
  })),
  on(callCreated, (state, action) => ({
    ...adapter.upsertOne(action.responseBody, state),
    inflightCreationIds: removeFromInflightList(
      action.actionParams.creationId,
      state.inflightCreationIds
    ),
    loading: false
  })),
  on(callCreationError, (state, action) => ({
    ...state,
    inflightCreationIds: removeFromInflightList(
      action.actionParams.creationId,
      state.inflightCreationIds
    ),
    loading: false
  })),
  on(updateCall, (state, action) => ({
    ...state,
    inflightUpdateIds: addToInflightList(
      action.params.callId,
      state.inflightUpdateIds
    ),
    loading: !!action.requestBody.outcomeId
  })),
  on(createComment, (state, action) => ({
    ...state,
    inflightUpdateIds: addToInflightList(
      action.params.callId,
      state.inflightUpdateIds
    ),
    loading: true
  })),
  on(callUpdated, (state, action) => ({
    ...adapter.upsertOne(action.responseBody, state),
    inflightUpdateIds: removeFromInflightList(
      action.params.callId,
      state.inflightUpdateIds
    ),
    loading: false
  })),
  on(commentCreated, (state, action) => ({
    ...state,
    inflightUpdateIds: removeFromInflightList(
      action.params.callId,
      state.inflightUpdateIds
    ),
    loading: false
  })),
  on(callUpdateError, commentCreationError, (state, action) => ({
    ...state,
    inflightUpdateIds: removeFromInflightList(
      action.params.callId,
      state.inflightUpdateIds
    ),
    loading: false
  })),
  on(loadCallOutcomes, (state, action) => ({
    ...state,
    outcomes: { ...state.outcomes, loading: true }
  })),
  on(callOutcomesLoaded, (state, action) => ({
    ...state,
    outcomes: {
      ...callOutcomeAdapter.upsertMany(action.responseBody, state.outcomes),
      loading: false
    }
  })),
  on(callOutcomesLoadError, (state, action) => ({
    ...state,
    outcomes: {
      ...callOutcomeAdapter.removeAll(state.outcomes),
      loading: true
    }
  })),
  on(noFollowUpUpdated, (state, action) =>
    adapter.upsertOne(action.responseBody, state)
  )
);

export const callAdapter = adapter;

export function reducer(state: State | undefined, action: Action) {
  return callReducer(state, action);
}
