import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as UserActions from './users.actions';
import {
  MemberFiltersDto,
  TodayResultDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { userLoaded } from '@act/features/user/data-access';

export const usersFeatureKey = 'users';

export interface TodayTypes {
  call: TodayResultDto[];
  visit: TodayResultDto[];
  message: TodayResultDto[];
  reminder: TodayResultDto[];
  recent: TodayResultDto[];
}

export interface State extends EntityState<UserDto> {
  // additional entities state properties
  userDetailsLoaded: {
    [key: string]: { loading: boolean; loaded: boolean; error?: boolean };
  };

  teamIds: string[];
  teamLoading: boolean;
  selectedTeamMemberId: string;

  memberIds: string[];
  membersLoading: boolean;
  selectedMemberId: string;
  selectedMemberFilters: MemberFiltersDto;

  today: TodayTypes;
  loadingToday: boolean;
  loggedInUserId: string;
}

export const adapter: EntityAdapter<UserDto> = createEntityAdapter<UserDto>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  userDetailsLoaded: {},
  teamIds: [],
  teamLoading: false,
  memberIds: [],
  membersLoading: false,
  selectedTeamMemberId: null,
  selectedMemberFilters: null,
  selectedMemberId: null,
  today: {
    call: [],
    visit: [],
    message: [],
    reminder: [],
    recent: []
  },
  loadingToday: false,
  loggedInUserId: null
});

export const userReducer = createReducer(
  initialState,

  /**
   * Load Team
   */
  on(UserActions.loadTeam, (state, action) => ({
    ...adapter.removeMany(state.teamIds, { ...state }),
    teamIds: [],
    teamLoading: true
  })),
  on(UserActions.teamLoaded, (state, action) => {
    const newUsers = [...action.responseBody];
    newUsers.sort((a, b) => a.name.localeCompare(b.name));

    return {
      ...adapter.addMany(newUsers, { ...state }),
      teamIds: putUserInFront(newUsers, state.loggedInUserId).map(u => u.id),
      teamLoading: false
    };
  }),
  on(UserActions.teamLoadError, (state, action) => ({
    ...state,
    teamLoading: false
  })),
  on(UserActions.selectTeamMember, (state, action) => {
    // If we select a team member that doesn't match the current selected member then clear the selected member
    const selectedMember = state.entities[state.selectedMemberId];
    const shouldResetSelectedMember =
      selectedMember && selectedMember.assignedGuideId !== action.teamMemberId;
    return {
      ...state,
      selectedTeamMemberId: action.teamMemberId,
      selectedMemberId: shouldResetSelectedMember
        ? null
        : state.selectedMemberId
    };
  }),
  on(userLoaded, (state, action) => ({
    ...state,
    teamIds: putUserInFront(
      state.teamIds.map(id => state.entities[id]),
      action.user.id
    ).map(u => u.id),
    loggedInUserId: action.user.id
  })),

  /**
   * Load Members
   */
  on(UserActions.loadMembers, UserActions.loadAllMembers, (state, action) => ({
    ...adapter.removeMany(state.memberIds, { ...state }),
    memberIds: [],
    membersLoading: true
  })),
  on(
    UserActions.membersLoaded,
    UserActions.allMembersLoaded,
    (state, action) => {
      const selectedMemberFilters = JSON.parse(
        localStorage.getItem(state.selectedTeamMemberId)
      );
      const newUsers = [...action.responseBody];

      newUsers.sort((a, b) => {
        const compare = compareNullAtEnd(a.nextEventDate, b.nextEventDate);
        return compare === 0
          ? compareNullAtEnd(a.nextEventTime, b.nextEventTime)
          : compare;
      });

      return {
        ...adapter.addMany(newUsers, { ...state }),
        memberIds: newUsers.map(u => u.id),
        membersLoading: false,
        selectedMemberFilters
      };
    }
  ),
  on(
    UserActions.membersLoadError,
    UserActions.allMembersLoadError,
    (state, action) => ({
      ...state,
      membersLoading: false
    })
  ),
  on(UserActions.selectMember, (state, action) => ({
    ...state,
    selectedMemberId: action.id
  })),

  on(UserActions.userWasUpdated, (state, action) => {
    if (state.entities[action.user.id]) {
      return adapter.upsertOne(action.user, { ...state });
    } else {
      return { ...state };
    }
  }),

  on(UserActions.updateUserSuccess, (state, action) => {
    if (state.entities[action.responseBody.id]) {
      return adapter.upsertOne(action.responseBody, { ...state });
    } else {
      return { ...state };
    }
  }),

  on(UserActions.updateUsersSuccess, (state, action) => {
    const usersInState = action.responseBody.filter(u => state.entities[u.id]);
    if (usersInState.length) {
      const updatedUsers = adapter.upsertMany(usersInState, { ...state });

      // Update memberIds when user(s) is/are assigned to a guide
      const memberIds = state.memberIds.filter(id => {
        const foundUser = usersInState.find(u => u.id === id);
        if (foundUser) {
          return foundUser.assignedGuideId === state.selectedTeamMemberId;
        }
        return true;
      });

      return { ...updatedUsers, memberIds };
    } else {
      return { ...state };
    }
  }),

  // Loading "detailed" user object
  on(UserActions.loadUserDetails, (state, action) => ({
    ...state,
    userDetailsLoaded: {
      ...state.userDetailsLoaded,
      [action.params.userId]: { loading: true, loaded: false }
    }
  })),
  on(UserActions.userDetailsLoaded, (state, action) => ({
    ...adapter.upsertOne(action.responseBody, { ...state }),
    userDetailsLoaded: {
      ...state.userDetailsLoaded,
      [action.responseBody.id]: { loading: false, loaded: true }
    }
  })),
  on(UserActions.userDetailsLoadError, (state, action) => ({
    ...state,
    userDetailsLoaded: {
      ...state.userDetailsLoaded,
      [action.params.userId]: { loading: false, loaded: false, error: true }
    }
  })),

  // Update Summary
  on(UserActions.updateUserSummary, (state, action) => {
    return { ...state };
  }),
  on(UserActions.userSummaryUpdated, (state, action) =>
    adapter.updateOne(
      {
        id: action.responseBody.userId,
        changes: { summary: action.responseBody }
      },
      { ...state }
    )
  ),
  on(UserActions.userSummaryUpdateError, (state, action) => {
    return { ...state };
  }),

  // Create/update/delete Create Phone Number
  on(UserActions.userPhoneNumberCreated, (state, action) => {
    const user = state.entities[action.responseBody.userId];
    const existingItems = user ? user.phoneNumbers || [] : [];
    return adapter.updateOne(
      {
        id: action.responseBody.userId,
        changes: { phoneNumbers: [...existingItems, action.responseBody] }
      },
      { ...state }
    );
  }),
  on(UserActions.userPhoneNumberUpdated, (state, action) => {
    const user = state.entities[action.responseBody.userId];
    const existingItems = user ? user.phoneNumbers || [] : [];
    return adapter.updateOne(
      {
        id: action.responseBody.userId,
        changes: {
          phoneNumbers: existingItems.map(pn =>
            pn.id === action.responseBody.id ? action.responseBody : pn
          )
        }
      },
      { ...state }
    );
  }),
  on(UserActions.userPhoneNumberDeleted, (state, action) => {
    const user = state.entities[action.params.userId];
    const existingItems = user ? user.phoneNumbers || [] : [];
    return adapter.updateOne(
      {
        id: action.params.userId,
        changes: {
          phoneNumbers: existingItems.filter(
            pn => pn.id !== action.params.phoneNumberId
          )
        }
      },
      { ...state }
    );
  }),

  // Create/update/delete Create Address
  on(UserActions.userAddressCreated, (state, action) => {
    const user = state.entities[action.responseBody.userId];
    const existingItems = user ? user.addresses || [] : [];
    return adapter.updateOne(
      {
        id: action.responseBody.userId,
        changes: { addresses: [...existingItems, action.responseBody] }
      },
      { ...state }
    );
  }),
  on(UserActions.userAddressUpdated, (state, action) => {
    const user = state.entities[action.responseBody.userId];
    const existingItems = user ? user.addresses || [] : [];
    return adapter.updateOne(
      {
        id: action.responseBody.userId,
        changes: {
          addresses: existingItems.map(pn =>
            pn.id === action.responseBody.id ? action.responseBody : pn
          )
        }
      },
      { ...state }
    );
  }),
  on(UserActions.userAddressDeleted, (state, action) => {
    const user = state.entities[action.params.userId];
    const existingItems = user ? user.addresses || [] : [];
    return adapter.updateOne(
      {
        id: action.params.userId,
        changes: {
          addresses: existingItems.filter(
            pn => pn.id !== action.params.addressId
          )
        }
      },
      { ...state }
    );
  }),
  on(UserActions.addRemoveFilter, (state, action) => {
    let preStateMemberFilters = { ...state.selectedMemberFilters };

    if (!preStateMemberFilters) {
      preStateMemberFilters = {};
    }

    if (action.flag === 'add') {
      if (preStateMemberFilters[action.key] !== undefined) {
        const index = preStateMemberFilters[action.key].indexOf(action.value);

        if (index < 0) {
          preStateMemberFilters[action.key].push(action.value);
        }
      } else {
        preStateMemberFilters = {
          ...preStateMemberFilters,
          [action.key]: [action.value]
        };
      }
    } else if (action.flag === 'remove') {
      if (preStateMemberFilters[action.key] !== undefined) {
        const index = preStateMemberFilters[action.key].indexOf(action.value);

        if (index > -1) {
          preStateMemberFilters[action.key].splice(index, 1);
        }
      } else {
        delete preStateMemberFilters[action.key];
      }
    }

    if (state.selectedTeamMemberId && preStateMemberFilters) {
      localStorage.setItem(
        state.selectedTeamMemberId,
        JSON.stringify(preStateMemberFilters)
      );
    }
    state.selectedMemberFilters = preStateMemberFilters;
    return { ...state };
  }),
  on(UserActions.clearFilter, (state, action) => {
    state.selectedMemberFilters = null;
    localStorage.removeItem(state.selectedTeamMemberId);
    return { ...state };
  }),
  on(
    UserActions.loadTodayResults,
    UserActions.loadAllTodayResults,
    (state, action) => {
      if (action.actionParams.silent) {
        return { ...state };
      }
      let newToday = state.today;
      newToday = {
        call: [],
        visit: [],
        message: [],
        reminder: [],
        recent: []
      };
      return {
        ...state,
        today: newToday,
        loadingToday: true
      };
    }
  ),
  on(UserActions.todayResultTypesLoaded, (state, action) => {
    let newToday = state.today;
    newToday = action.today;

    return { ...state, today: action.today, loadingToday: false };
  }),
  on(
    UserActions.todayResultsLoadError,
    UserActions.allTodayResultsLoadError,
    (state, action) => {
      return { ...state, loadingToday: false };
    }
  ),
  on(UserActions.clearTodayItems, (state, action) => {
    return { ...state, today: { ...initialState.today } };
  }),
  on(UserActions.clearMembers, (state, action) => {
    return {
      ...adapter.removeMany(state.memberIds, { ...state }),
      memberIds: []
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

const putUserInFront = (users: UserDto[], userId: string): UserDto[] => {
  const index = users.findIndex(u => u.id === userId);
  if (index < 0) {
    return users;
  }
  return [
    users[index],
    ...users.slice(0, index),
    ...users.slice(index + 1, users.length)
  ];
};

const compareNullAtEnd = <T extends number | string>(
  a: T,
  b: T,
  ascending = true
) => {
  if (a === b) return 0;
  // nulls sort after anything else
  else if (a === null) return 1;
  else if (b === null) return -1;
  // otherwise, if we're ascending, lowest sorts first
  else if (ascending) return a < b ? -1 : 1;
  // if descending, highest sorts first
  else return a < b ? 1 : -1;
};
