import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DotSpinnerService } from '../services/dot-spinner.service';

@Component({
  selector: 'act-dot-spinner',
  templateUrl: './dot-spinner.component.html',
  styleUrls: ['./dot-spinner.component.scss']
})
export class DotSpinnerComponent implements OnInit, OnChanges {
  constructor(public dotSpinnerService: DotSpinnerService) {}

  @Input() size: 'x-small' | 'small' | 'medium' | 'large' = 'medium';

  @HostBinding('class.x-small') xSmallClass = false;
  @HostBinding('class.small') smallClass = false;
  @HostBinding('class.medium') mediumClass = false;
  @HostBinding('class.large') largeClass = false;

  spinnerSubscription: Subscription;

  private setClass() {
    this.xSmallClass = this.size === 'x-small';
    this.smallClass = this.size === 'small';
    this.mediumClass = this.size === 'medium';
    this.largeClass = this.size === 'large';
  }
  ngOnInit() {
    this.setClass();
  }

  ngOnChanges() {
    this.setClass();
  }
}
