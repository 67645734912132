import { createAction, props } from '@ngrx/store';

import { UserDto } from '@act/shared/data-transfer-objects';
import { UsersRequests } from './users.requests';
import { TodayTypes } from './users.reducer';

const requests = new UsersRequests(null, null);

export const {
  initiator: loadTeam,
  success: teamLoaded,
  error: teamLoadError
} = requests.getTeam.getRequestActions();

export const selectTeamMember = createAction(
  '[Users] Select Team Member',
  props<{ teamMemberId: string }>()
);

export const {
  initiator: loadMembers,
  success: membersLoaded,
  error: membersLoadError
} = requests.getMembersForGuide.getRequestActions();

export const {
  initiator: loadTodayResults,
  success: todayResultsLoaded,
  error: todayResultsLoadError
} = requests.getTodayResultsForGuide.getRequestActions();

export const todayResultTypesLoaded = createAction(
  '[Users] Today Result Types Loaded',
  props<{ today: TodayTypes }>()
);

export const {
  initiator: loadAllMembers,
  success: allMembersLoaded,
  error: allMembersLoadError
} = requests.getAllMembers.getRequestActions();

export const {
  initiator: loadAllTodayResults,
  success: allTodayResultsLoaded,
  error: allTodayResultsLoadError
} = requests.getAllTodayResults.getRequestActions();

export const selectMember = createAction(
  '[Users] Select Member',
  props<{ id: string }>()
);

export const userWasUpdated = createAction(
  '[Users] User Was Updated',
  props<{ user: UserDto }>()
);

export const {
  initiator: updateUser,
  success: updateUserSuccess,
  error: updateUserError
} = requests.updateUser.getRequestActions();

export const {
  initiator: updateUsers,
  success: updateUsersSuccess,
  error: updateUsersError
} = requests.updateUsers.getRequestActions();

// Load User Details
export const {
  initiator: loadUserDetails,
  success: userDetailsLoaded,
  error: userDetailsLoadError
} = requests.getUserDetails.getRequestActions();

// Update Summary
export const {
  initiator: updateUserSummary,
  success: userSummaryUpdated,
  error: userSummaryUpdateError
} = requests.patchUserSummary.getRequestActions();

// Create Phone Number
export const {
  initiator: createUserPhoneNumber,
  success: userPhoneNumberCreated,
  error: userPhoneNumberCreateError
} = requests.postUserPhoneNumber.getRequestActions();

// Update Phone Number
export const {
  initiator: updateUserPhoneNumber,
  success: userPhoneNumberUpdated,
  error: userPhoneNumberUpdateError
} = requests.patchUserPhoneNumber.getRequestActions();

// Delete Phone Number
export const {
  initiator: deleteUserPhoneNumber,
  success: userPhoneNumberDeleted,
  error: userPhoneNumberDeleteError
} = requests.deleteUserPhoneNumber.getRequestActions();

// Create Address
export const {
  initiator: createUserAddress,
  success: userAddressCreated,
  error: userAddressCreateError
} = requests.postUserAddress.getRequestActions();

// Update Address
export const {
  initiator: updateUserAddress,
  success: userAddressUpdated,
  error: userAddressUpdateError
} = requests.patchUserAddress.getRequestActions();

// Delete Address
export const {
  initiator: deleteUserAddress,
  success: userAddressDeleted,
  error: userAddressDeleteError
} = requests.deleteUserAddress.getRequestActions();

export const addRemoveFilter = createAction(
  '[Users] Add/Remove Filter',
  props<{ key: string; value: string | number; flag: string }>()
);

export const clearFilter = createAction('[Users] Clear Filter');

export const clearTodayItems = createAction(
  '[User] Clear Today Items',
  props<{}>()
);

export const clearMembers = createAction('[User] Clear Members', props<{}>());
