import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromVisit from './visit.reducer';
import { Observable } from 'rxjs';
import {
  VisitDto,
  VisitCreationRequestDto,
  VisitUpdateRequestDto,
  CommentDto
} from '@act/shared/data-transfer-objects';
import { HttpClient } from '@angular/common/http';
import { ClientBootstrap } from '@act/shared/models';
import { getClientConfig } from '@act/shared/environment';
import {
  deleteVisit,
  editVisit,
  loadVisitOutcomes,
  loadVisits,
  startCreatingVisit,
  stopEditingVisit,
  triggerVisitOutcomeLoad
} from './visit.actions';
import {
  selectEditedVisitId,
  selectEditedVisit,
  selectVisitOutcomesList,
  selectVisitOutcomesMap,
  selectVisitLoading
} from './visit.selectors';
import { VisitRequests } from './visit.requests';
import { NoteFacade } from '../note/note.facade';
import {
  selectCallsBeingCreated,
  selectInflightUpdateIds
} from './visit.selectors';

@Injectable()
export class VisitFacade {
  visitCreationIdTicker = 1;

  constructor(
    private store: Store<fromVisit.State>,
    private http: HttpClient,
    private noteFacade: NoteFacade,
    private visitRequests: VisitRequests
  ) {}

  private config: ClientBootstrap = getClientConfig();

  inflightUpdateIds$ = this.store.select(selectInflightUpdateIds);
  inflightCreationIds$ = this.store.select(selectCallsBeingCreated);
  visitLoading$ = this.store.select(selectVisitLoading);

  updateVisit(
    visitId: number,
    request: VisitUpdateRequestDto
  ): Promise<VisitDto> {
    return this.visitRequests.patchVisit.makeRequest({
      params: { visitId },
      body: { ...request }
    });
  }

  deleteVisit(visitId: number) {
    this.store.dispatch(
      deleteVisit({
        params: { visitId },
        requestBody: null
      })
    );
  }

  startVisitCreation(userId: string) {
    this.store.dispatch(startCreatingVisit({ subjectUserId: userId }));
  }

  createVisitForUser(request: VisitCreationRequestDto): Promise<VisitDto> {
    const creationId = this.visitCreationIdTicker++;
    return this.visitRequests.postVisit.makeRequest({
      body: { ...request },
      actionParams: { creationId }
    });
  }

  async autoSaveVisitNote(visitId: number, note: string): Promise<void> {
    await this.visitRequests.patchVisit.makeRequest({
      params: { visitId },
      body: { note }
    });
  }

  editVisit(visit: VisitDto) {
    this.store.dispatch(editVisit({ visit }));
  }

  stopEditingVisit(visitId: number) {
    this.store.dispatch(stopEditingVisit({ visitId }));
  }

  isUnsavedVisit(visit: VisitDto): boolean {
    return visit && visit.id === fromVisit.UNSAVED_VISIT_ID;
  }

  editedVisitId$: Observable<number> = this.store.select(selectEditedVisitId);
  editedVisit$: Observable<VisitDto> = this.store.select(selectEditedVisit);

  visitOutcomes$ = this.store.select(selectVisitOutcomesList);
  visitOutcomesMap$ = this.store.select(selectVisitOutcomesMap);

  getVisits(visitIds: number[]): void {
    this.store.dispatch(
      loadVisits({ params: { ids: visitIds }, requestBody: null })
    );
  }

  getVisitOutcomes() {
    this.store.dispatch(loadVisitOutcomes({ params: null, requestBody: null }));
  }

  createComment(visitId: number, text: string): Promise<CommentDto> {
    return this.visitRequests.createComment.makeRequest({
      params: { visitId },
      body: { text }
    });
  }

  loadVisitOutcomes() {
    this.store.dispatch(triggerVisitOutcomeLoad());
  }
}
