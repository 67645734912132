import { CallDto, UserDto } from '@act/shared/data-transfer-objects';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatDialog,
  MatDialogRef,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Dictionary } from '@ngrx/entity';
import { FileFacade } from 'libs/features/files/data-access/src/lib/+state/file/file.facade';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CallListFacade } from '../+state/call-list.facade';
import { PageEvent } from '@angular/material/paginator';
import { ActDialog, ActDialogService } from '@act/shared/ui/dialog';
import { FeedActionsService } from '@act/feed';

interface CallDataSource extends CallDto {
  length: number;
  from: string;
  to: string;
}

@Component({
  selector: 'act-call-list',
  templateUrl: './call-list.component.html',
  styleUrls: ['./call-list.component.scss']
})
export class CallListComponent implements OnChanges {
  constructor(
    public readonly fileFacade: FileFacade,
    private readonly callListFacade: CallListFacade,
    private readonly dialogService: ActDialogService,
    public feedActionService: FeedActionsService
  ) {}

  @Input() calls: CallDto[];
  @Input() userMap: Dictionary<UserDto>;
  @Input() teamMap: Dictionary<UserDto>;
  @Input() currentUser: UserDto;

  @ViewChild(MatSort, null) sort: MatSort;

  dataSource: MatTableDataSource<CallDataSource> = null;
  members$ = this.callListFacade.users$.pipe(
    map(users => users.filter(u => u.role === 'PATIENT'))
  );

  infinity = Number.POSITIVE_INFINITY;

  callListPagination$ = this.callListFacade.callListPagination$;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.calls) {
      this.dataSource = new MatTableDataSource(
        this.calls.map(call => ({
          ...call,
          length: call.timeEnded
            ? call.timeEnded - (call.timeConnected, call.timeStarted)
            : 0,
          from: call.originator ? call.originator.phoneNumber : null,
          to: call.receiver ? call.receiver.phoneNumber : null
        }))
      );
      this.dataSource.sort = this.sort;
    }
  }

  updatePagination(event: PageEvent) {
    this.callListFacade.setPagination({
      pageSize: event.pageSize,
      pageIndex: event.pageIndex
    });
  }

  /***
   *
   * Reassignment stuff
   *
   */
  callToReassign: CallDto = null;
  dialogRef: ActDialog;
  @ViewChild('dialogRef', { static: true }) dialogTemplateRef: TemplateRef<any>;
  reassignControl = new FormControl();
  selectedReassignUser: UserDto = null;

  filteredMembers$ = combineLatest([
    this.members$,
    this.reassignControl.valueChanges
  ]).pipe(
    map(([members, filter]) => {
      // If user changes the text and it doesn't match the selected user's name then remove selection
      if (
        this.selectedReassignUser &&
        this.selectedReassignUser.name !== filter
      ) {
        this.selectedReassignUser = null;
      }

      filter = typeof filter === 'string' ? filter : null;
      const parts =
        filter &&
        filter
          .toLowerCase()
          .split(' ')
          .filter(p => p);
      if (!parts || !parts.length) {
        return members;
      }

      return members.filter(u =>
        parts.reduce(
          (match, part) => match && u.name.toLowerCase().includes(part),
          true
        )
      );
    })
  );

  openReassign(call: CallDto) {
    this.callToReassign = call;
    this.reassignControl.setValue('');

    this.dialogRef = this.dialogService.createDialog(
      {
        ref: this.dialogTemplateRef,
        onClose: () => {
          this.callToReassign = null;
        },
        onOpen: () => {}
      },
      'reassign-dialog'
    );
    this.dialogRef.open();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  reassignmentUserSelected(event: MatAutocompleteSelectedEvent) {
    const user: UserDto = event.option.value;
    this.reassignControl.setValue(user.name);
    this.selectedReassignUser = user;
  }

  reassignCall() {
    this.callListFacade.updateSubjectForCall(
      this.callToReassign.id,
      this.selectedReassignUser.id
    );
    this.closeDialog();
  }

  editCall(call: CallDto) {
    this.feedActionService.editCall(call);
  }
}
