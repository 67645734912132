import { FileState, fileAdapter, FileDetail } from './file.reducer';
import { File } from '@act/server/models/files';
import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

const baseSelector = (state: { files: FileState }) => state.files;

const {
  selectEntities: selectFileEntities,
  selectIds: selectFileIds,
  selectAll: selectAllFile,
  selectTotal: selectFileTotal
} = fileAdapter.getSelectors(baseSelector);

const selectFile = createSelector(
  selectFileEntities,
  (dic: Dictionary<FileDetail>, props: { id: string }): File =>
    dic[props.id] ? dic[props.id].file : null
);

const selectFileLoading = createSelector(
  selectFileEntities,
  (dic: Dictionary<FileDetail> = {}, props: { id: string }): boolean =>
    dic[props.id] ? dic[props.id].loading : false
);

const filesLoading = createSelector(
  baseSelector,
  state => state.loading
);

export const fileQueries = {
  selectFile,
  selectFileLoading,
  filesLoading
};
