import {
  Component,
  Input,
  Host,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { CheckboxGroupComponent } from './checkbox-group-component';

export interface ISetFilter {
  key: string;
  value: string | number;
  flag: string;
}

@Component({
  selector: 'defaultCheckbox',
  template: `
    <div class="default-checkbox">
      <mat-checkbox (change)="toggleCheck()" [checked]="filterChecked"
        ><ng-content></ng-content
      ></mat-checkbox>
      <div></div>
    </div>
  `,
  styles: [
    `
      .default-checkbox {
        color: #0a2127;
        font-size: 12px;
        cursor: pointer;
        font-weight: 500;
        margin-bottom: 5px;
      }
      ::ng-deep .default-checkbox .mat-checkbox .mat-checkbox-frame {
        border-color: #d6dddc;
        background: #fff;
      }
    `
  ]
})
export class DefaultCheckboxComponent {
  @Input() value: any;
  @Output() setFilter = new EventEmitter<ISetFilter>();
  @Input() filterType: string;
  @Input() filterChecked: boolean;

  constructor(@Host() private checkboxGroup: CheckboxGroupComponent) {}

  toggleCheck() {
    const action = this.checkboxGroup.addOrRemove(this.value);
    this.setFilter.emit({
      key: this.filterType,
      value: this.value,
      flag: action
    });
  }
}
