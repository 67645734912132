import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedCardComponent } from './feed-card/feed-card.component';
import {
  MatCardModule,
  MatIconModule,
  MatButtonModule,
  MatTooltipModule,
  MatIconRegistry
} from '@angular/material';
import { MomentModule } from 'ngx-moment';
import { CommonPipesHoursMinutesSecondsModule } from '@act/common/pipes/hours-minutes-seconds';
import { CommonPipesPhoneNumberModule } from '@act/common/pipes/phone-number';
import { FeedCardContentComponent } from './feed-card-content/feed-card-content.component';
import { SharedUiAudioPlayerModule } from '@act/shared/ui/audio-player';
import { FeedCardIconsComponent } from './feed-card-icons/feed-card-icons.component';
import { DomSanitizer } from '@angular/platform-browser';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatChipsModule,
    MomentModule,
    MatTooltipModule,
    CommonPipesHoursMinutesSecondsModule,
    CommonPipesPhoneNumberModule,
    SharedUiAudioPlayerModule
  ],
  declarations: [
    FeedCardComponent,
    FeedCardContentComponent,
    FeedCardIconsComponent
  ],
  exports: [FeedCardComponent, FeedCardContentComponent, FeedCardIconsComponent]
})
export class SharedUiFeedCardModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    [
      { name: 'phone_inbound', svg: 'phone_inbound.svg' },
      { name: 'phone_outbound', svg: 'phone_outbound.svg' },
      { name: 'text_inbound', svg: 'text_inbound.svg' },
      { name: 'text_outbound', svg: 'text_outbound.svg' },
      { name: 'phone_missed', svg: 'phone_missed.svg' },
      { name: 'phone_failed', svg: 'phone_failed.svg' }
    ].forEach(({ name, svg }) => {
      iconRegistry.addSvgIcon(
        name,
        sanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${svg}`)
      );
    });
  }
}
