import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  updateCallListFilters,
  loadCallList,
  callSubjectUpdated,
  updateCallListPagination,
  initializeCallList,
  getCallUsers
} from './call-list.actions';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
  selectCallListFilters,
  selectCallListPagination
} from './call-list.selectors';
import { userQueries } from 'libs/features/user/data-access/src/lib/+state/user.selectors';
import { CallListRequests } from './call-list.requests';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CallListEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private http: HttpClient,
    private callListRequests: CallListRequests
  ) {}

  triggerLoadCalls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        initializeCallList,
        updateCallListFilters,
        callSubjectUpdated,
        updateCallListPagination
      ),
      withLatestFrom(
        this.store.pipe(select(selectCallListFilters)),
        this.store.pipe(select(selectCallListPagination)),
        this.store.pipe(select(userQueries.selectUser))
      ),
      map(([action, filters, pagination, loggedInUser]) => {
        const { noSubjectId, ownCalls, incoming } = filters;
        return loadCallList({
          params: {
            noSubjectId: noSubjectId,
            participantIds: ownCalls ? [loggedInUser.id] : null,
            incoming:
              incoming === null || incoming === undefined ? null : incoming
          },
          requestBody: null,
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize
          }
        });
      })
    )
  );

  initiateUserDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeCallList),
      map(action => {
        return getCallUsers({ params: null, requestBody: null });
      })
    )
  );

  loadCalls$ = this.callListRequests.getCalls.createEffect(this.actions$);

  updateCallSubject$ = this.callListRequests.updateSubjectForCall.createEffect(
    this.actions$
  );

  callNumber$ = this.callListRequests.callNumber.createEffect(this.actions$);

  loadCallUsers$ = this.callListRequests.getUsersForCallList.createEffect(
    this.actions$
  );
}
