import { ConsentRequests } from './consent.requests';

const requests = new ConsentRequests(null, null);

export const {
  initiator: loadUserConsent,
  success: userConsentLoaded,
  error: userConsentLoadError
} = requests.getConsentsForUser.getRequestActions();

export const {
  initiator: submitConsentResponse,
  success: submitConsentResponseSuccess,
  error: submitConsentResponseError
} = requests.postConsentResponse.getRequestActions();
