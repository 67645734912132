import { ApiRequestDefinition } from '@act/shared/api';
import { CallDto, UserDto } from '@act/shared/data-transfer-objects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class CallListRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getCalls = ApiRequestDefinition.createRequestDefinitionWithPagination<
    CallDto[],
    null,
    { noSubjectId: boolean; incoming: boolean; participantIds: string[] }
  >('/calls', 'GET', '[Call-List] Get Call List', this.store, this.http);

  updateSubjectForCall = ApiRequestDefinition.createRequestDefinition<
    CallDto[],
    { subjectId: string },
    { callId: number }
  >(
    '/calls/:callId/subjectId',
    'PATCH',
    '[Call-List] Update Call Subject',
    this.store,
    this.http
  );

  callNumber = ApiRequestDefinition.createRequestDefinition<
    CallDto,
    { phoneNumber: string; targetUserId: null },
    null,
    null
  >('/calls', 'POST', '[Call-List] Call Number', this.store, this.http);

  getUsersForCallList = ApiRequestDefinition.createRequestDefinition<
    UserDto[],
    null,
    null
  >('/users', 'GET', '[Call-List] Get Users', this.store, this.http);
}
