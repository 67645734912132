import { createSelector } from '@ngrx/store';
import { adapter, todoFeatureKey, TodoState } from './todo.reducer';

export const stateSelectors = adapter.getSelectors();

const selectTodoState = (state: any): TodoState => state[todoFeatureKey];

const selectAllTodos = createSelector(
  selectTodoState,
  stateSelectors.selectAll
);

const selectTodosMap = createSelector(
  selectTodoState,
  stateSelectors.selectEntities
);

const selectTodosLoading = createSelector(
  selectTodoState,
  state => state.loading
);

export const todoQueries = {
  selectAllTodos,
  selectTodosMap,
  selectTodosLoading
};
