import { AbstractControl } from '@angular/forms';
import { Subject, asyncScheduler, Observable } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

export class ThrottledChangeWatcher<T> {
  get changes$(): Observable<T> {
    return this._changes$;
  }

  get throttledChanges$(): Observable<T> {
    return this._throttledChanges$;
  }

  private _changes$: Subject<T>;
  private _throttledChanges$: Subject<T>;

  listening = true;

  constructor(
    converter: (strValue: string) => T,
    private control: AbstractControl
  ) {
    this._throttledChanges$ = new Subject();
    this._changes$ = new Subject();

    this.control.valueChanges.subscribe((value: string) => {
      if (this.listening) {
        this._changes$.next(converter(value));
      }
    });

    this._changes$
      .pipe(throttleTime(2000, asyncScheduler, { trailing: true }))
      .subscribe(value => {
        if (this.listening) {
          this._throttledChanges$.next(value);
        }
      });
  }

  startWatching() {
    this.listening = true;
  }

  stopWatching() {
    this.listening = false;
  }
}
