import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonDirectivesModule } from '@act/common/directives';
import { FeaturesPatientsUiModule } from '@act/features/patients/ui';
import { FeaturesProfileUiModule } from '@act/features/profile/ui';
import { SharedTeammateModule } from '@act/shared/teammate';
import { FeaturesPatientsDataAccessModule } from '@act/features/patients/data-access';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MembersComponent } from './members/members.component';

import { FeedModule } from '@act/feed';
import { SharedUiFormsModule } from '@act/shared/ui/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUiDialogModule } from '@act/shared/ui/dialog';
import { BreakpointService } from './members/breakpoint.service';
const FEATURE_COMPONENTS = [MembersComponent];

@NgModule({
  imports: [
    CommonModule,
    CommonDirectivesModule,
    ReactiveFormsModule,

    // feature sub-modules
    FeaturesPatientsDataAccessModule,
    FeaturesPatientsUiModule,
    FeaturesProfileUiModule,
    FeedModule,

    // deps
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    // shared module
    SharedUiFormsModule,
    SharedTeammateModule,
    FeedModule,
    SharedUiDialogModule
  ],
  declarations: [FEATURE_COMPONENTS],
  exports: FEATURE_COMPONENTS,
  providers: [BreakpointService]
})
export class FeaturesPatientsFeatureV1Module {}
