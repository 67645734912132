import { getValueOfObservable } from '@act/common/utilities';
import { NoteDto, UserDto } from '@act/shared/data-transfer-objects';
import { Platform } from '@act/core/platform';
import { NoteFacade } from '../../+state/note/note.facade';
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  ComponentRef,
  TemplateRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';

import { Subject } from 'rxjs';
import { DebounceAutoSaver } from '@act/common/utilities/autosave';
import { AnchoredDialogComponent } from 'libs/shared/ui/dialog/src/lib/components/anchored-dialog/anchored-dialog.component';
import { RichTextInputComponent } from 'libs/shared/ui/forms/src/lib/inputs/rich-text-input/rich-text-input.component';
import { AnchoredDialogService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog.service';
import { Dictionary } from '@ngrx/entity';

@Component({
  selector: 'act-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss']
})
export class NoteDialogComponent implements OnInit, OnDestroy {
  @Input() note: NoteDto;
  @Input() memberId: string;
  @Input() userMap: Dictionary<UserDto>;

  @ViewChild(RichTextInputComponent, null)
  private noteInput: RichTextInputComponent;
  user: UserDto;

  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;
  anchoredDialogRef: ComponentRef<AnchoredDialogComponent>;

  noteId: number;
  currentTime: number = Date.now();
  unsavedChanges = false;
  editMode = false;
  textNote = new FormControl();
  saving = false;
  commentControl: FormControl = new FormControl('', Validators.required);
  showComments: boolean = false;
  public proposedChanges: Subject<NoteDto> = new Subject();

  quilModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }]
    ],
    clipboard: {
      matchVisual: false
    }
  };

  constructor(
    private platform: Platform,
    public noteFacade: NoteFacade,
    private anchoredDialogService: AnchoredDialogService,
    public dialog: MatDialog
  ) {}

  open(): ComponentRef<AnchoredDialogComponent> {
    this.editMode = !!this.note;
    const user = this.userMap ? this.userMap[this.memberId || ''] : null;
    const name = user ? user.firstName + ' ' + user.lastName : null;
    this.anchoredDialogRef = this.anchoredDialogService.create({
      icon: 'assignment',
      title:
        (this.editMode ? 'Edit Note' : 'Create Note') +
        (name ? ' for ' + name : ''),
      ref: this.dialogRef,
      actionType: this.editMode ? 'edit' : 'create',
      eventType: 'note',
      eventId: this.editMode ? this.note.id : this.memberId,
      isLoading$: this.noteFacade.noteLoading$
    });

    return this.anchoredDialogRef;
  }

  async ngOnInit() {
    this.user = getValueOfObservable(this.platform.user$);
    this.showComments = !!getValueOfObservable(this.platform.user$).featureFlags
      .INTERACTION_COMMENT;
    this.memberId = this.memberId;

    if (this.note) {
      this.noteId = this.note.id;
      this.editMode = true;
      let noteText = '';

      if (this.note.text) {
        noteText = this.note.text;
      }

      if (!this.note.savedOnce && this.note.draftText) {
        noteText = this.note.draftText;
      }

      setTimeout(() => this.textNote.setValue(noteText));
      setTimeout(() => {
        // Put cursor to end
        const selection = this.noteInput.editor.quillEditor.getSelection();
        if (selection) {
          this.noteInput.editor.quillEditor.setSelection(
            selection.index + noteText.length,
            0
          );
        }
      }, 300);
    } else {
      const note = await this.noteFacade.autoSave.note.get(this.memberId);
      setTimeout(() => this.textNote.setValue(note));
    }

    if (this.noteId) this.focusQuillEditor();
  }

  private focusQuillEditor() {
    setTimeout(
      () => this.noteInput.editor && this.noteInput.editor.quillEditor.focus(),
      100
    );
  }

  private buildChangedNote(): NoteDto {
    const text: string = this.textNote.value;
    const delta = this.noteInput.editor.quillEditor.editor.delta;
    const proposed: NoteDto = { ...this.note, text, delta } as NoteDto;

    return proposed;
  }

  autoSaver = new DebounceAutoSaver(this.textNote.valueChanges, n =>
    this.autoSaveNote(n)
  );

  ngOnDestroy() {
    this.autoSaver.destroy();
  }

  deleteNote() {
    if (this.noteId) {
      this.noteFacade.deleteNote(this.noteId);
    }
    this.anchoredDialogRef.instance.closeModal();
  }

  cancel() {
    this.anchoredDialogRef.instance.closeModal();
  }

  async saveNote() {
    const noteData = this.buildChangedNote();
    this.saving = true;

    if (this.noteId) {
      await this.noteFacade.updateNote(this.noteId, {
        noteId: this.noteId,
        text: noteData.text,
        delta: noteData.delta,
        isAutoUpdate: false
      });
      this.noteFacade.stopEditingNote(this.noteId);
    } else {
      await this.noteFacade.createNoteForUser(this.memberId, noteData.text);
      await this.noteFacade.autoSave.note.clear(this.memberId);
    }
    this.saving = false;
    this.anchoredDialogRef.instance.closeModal();
  }

  async autoSaveNote(text: string) {
    if (this.noteId) {
      await this.noteFacade.autoSaveNote(this.noteId, text);
    } else {
      await this.noteFacade.autoSave.note.update(this.memberId, text);
    }
  }

  close() {
    if (!this.editMode && !this.textNote.value) {
      this.deleteNote();
    } else {
      this.cancel();
    }
  }

  async addComment() {
    if (this.note.id && this.commentControl.value) {
      await this.noteFacade.createComment(
        this.note.id,
        this.commentControl.value,
        this.note.feedItemId
      );
      this.anchoredDialogRef.instance.closeModal();
    }
  }

  handleCommentChange(commentControl: FormControl) {
    this.commentControl = commentControl;
  }
}
