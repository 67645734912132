import {
  UserNeedDto,
  NeedCategoryDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ComponentRef,
  TemplateRef
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Platform } from '@act/core/platform';
import { UserNeedFacade } from 'libs/feed/src/lib/+state/need/need.facade';
import { ActDialogService } from '@act/shared/ui/dialog';
import { NoteFacade } from '@act/feed';
import { DebounceAutoSaver } from '@act/common/utilities/autosave';
import { RichTextInputComponent } from 'libs/shared/ui/forms/src/lib/inputs/rich-text-input/rich-text-input.component';
import { SelectDto } from 'libs/shared/ui/forms/src/lib/inputs/select/select.component';
import { AnchoredDialogComponent } from 'libs/shared/ui/dialog/src/lib/components/anchored-dialog/anchored-dialog.component';
import { Dictionary } from '@ngrx/entity';
import { AnchoredDialogService } from 'libs/shared/ui/dialog/src/lib/services/anchored-dialog.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'act-need-dialog',
  templateUrl: './need-dialog.component.html',
  styleUrls: ['./need-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NeedDialogComponent implements OnInit {
  @ViewChild(RichTextInputComponent, null)
  private noteInput: RichTextInputComponent;
  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;
  statuses = ['Need Identified', 'Resource Connected', 'Need Fulfilled'];
  statusOptions: SelectDto[] = this.statuses.map(option => {
    return {
      value: option,
      label: option
    };
  });
  needCategoryOptions: SelectDto[] = [];
  anchoredDialogRef: ComponentRef<AnchoredDialogComponent>;
  pipe = new DatePipe('en-US');
  @Input() memberId: string;
  @Input() userMap: Dictionary<UserDto>;
  @Input() need: UserNeedDto;
  @Input() needCategories: NeedCategoryDto[];

  editNeed = false;
  needForm: FormGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    status: new FormControl('', Validators.required)
  });
  needNote = new FormControl();
  saving = false;

  quilModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }]
    ],
    clipboard: {
      matchVisual: false
    }
  };

  constructor(
    public userNeedFacade: UserNeedFacade,
    private platform: Platform,
    private dialogService: ActDialogService,
    private noteFacade: NoteFacade,
    private anchoredDialogService: AnchoredDialogService
  ) {}

  needId: number;

  async ngOnInit() {
    this.memberId = this.memberId;

    if (this.need) {
      this.needId = this.need.id;
      this.editNeed = true;
      this.needForm.controls['title'].setValue(this.need.title);
      this.needForm.controls['status'].setValue(this.need.status);
      this.needForm.controls['category'].setValue(this.need.categoryId);
      this.needNote.setValue(this.need.note);
    } else {
      const note = await this.noteFacade.autoSave.need.get(this.memberId);
      this.needNote.setValue(note);
    }

    if (this.needCategories) {
      this.getSelectOptions();
    }
  }

  open(): ComponentRef<AnchoredDialogComponent> {
    if (!this.anchoredDialogRef) {
      const user = this.userMap[this.memberId];
      const name = user ? user.firstName + ' ' + user.lastName : null;
      this.anchoredDialogRef = this.anchoredDialogService.create({
        icon: null,
        title:
          (this.need ? 'Edit' : 'Create') +
          ' Need' +
          (name ? ' for ' + name : ''),
        ref: this.dialogRef,
        actionType: this.need ? 'edit' : 'create',
        eventType: 'need',
        eventId: this.need ? this.need.id : this.memberId,
        isLoading$: this.userNeedFacade.loadingUserNeeds$,
        subTitle: this.need
          ? `Created ${this.pipe.transform(this.need.createdAt, 'longDate')}`
          : ''
      });
    }
    return this.anchoredDialogRef;
  }

  ngAfterViewInit() {
    if (this.need) {
      setTimeout(() => {
        // Put cursor to end
        const selection = this.noteInput.editor.quillEditor.getSelection();
        if (selection) {
          const text = this.need.note;
          this.noteInput.editor.quillEditor.setSelection(
            selection.index + text.length,
            0
          );
        }
      }, 300);
    }
  }

  async onSubmit() {
    this.saving = true;
    if (!this.editNeed) {
      await this.userNeedFacade.createUserNeed({
        userId: this.memberId,
        title: this.needForm.value.title,
        categoryId: this.needForm.value.category,
        status: this.needForm.value.status,
        note: this.needNote.value
      });
      this.noteFacade.autoSave.need.clear(this.memberId);
    } else {
      await this.userNeedFacade.updateUserNeed(
        {
          title: this.needForm.value.title,
          categoryId: this.needForm.value.category,
          status: this.needForm.value.status,
          note: this.needNote.value
        },
        this.needId
      );
    }
    this.saving = false;
    this.close();
  }

  autoSaver = new DebounceAutoSaver(this.needNote.valueChanges, n =>
    this.autoSaveNeedNote(n)
  );

  async autoSaveNeedNote(note: string) {
    if (this.editNeed) {
      await this.userNeedFacade.autoSaveNeedNote(this.needId, note);
    } else {
      await this.noteFacade.autoSave.need.update(this.memberId, note);
    }
  }

  close() {
    this.anchoredDialogRef.instance.closeModal();
  }

  async deleteUserNeed() {
    const confirmation = await this.dialogService.confirm(
      'Are you sure you want to delete this need?'
    );
    if (confirmation) {
      this.userNeedFacade.clearUserNeed(this.needId);
      this.close();
    }
  }

  getSelectOptions() {
    this.needCategoryOptions = this.needCategories.map(need => {
      return { label: need.name, value: need.id };
    });
  }
}
