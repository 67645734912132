import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadCallOutcomes, triggerCallOutcomeLoad } from './call.actions';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectFeed } from '../feed/feed.actions';
import {
  selectCallOutcomesList,
  selectCallOutcomesLoading
} from './call.selectors';
import { CallRequests } from './call.requests';

@Injectable()
export class CallEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private callRequests: CallRequests
  ) {}
  createCall$ = this.callRequests.createCall.createEffect(this.actions$);

  updateCall$ = this.callRequests.patchCall.createEffect(this.actions$);

  getCallOutcomes$ = this.callRequests.getCallOutcomes.createEffect(
    this.actions$
  );

  noFollowUp$ = this.callRequests.updateCallNoFollowUpNeeded.createEffect(
    this.actions$
  );

  createComment$ = this.callRequests.createComment.createEffect(this.actions$);

  triggerCallOutcomesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectFeed, triggerCallOutcomeLoad),
      withLatestFrom(
        this.store.pipe(select(selectCallOutcomesList)),
        this.store.pipe(select(selectCallOutcomesLoading))
      ),
      mergeMap(([action, callOutcomes, callOutcomesLoading]) => {
        if ((!callOutcomes || !callOutcomes.length) && !callOutcomesLoading) {
          return of(loadCallOutcomes({ params: null, requestBody: null }));
        } else {
          return EMPTY; // HACK to return nothing
        }
      })
    )
  );
}
