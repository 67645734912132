import { Component, Input, OnChanges, OnInit } from '@angular/core';

const SHOW_USER_CONTEXT_TIME_GAP = 1000 * 60 * 5; // 5 minutes
const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;
const MILLISECONDS_TIMEZONE_OFFSET = new Date().getTimezoneOffset() * 60 * 1000;

@Component({
  selector: 'act-day-breaker',
  templateUrl: './day-breaker.component.html',
  styleUrls: ['./day-breaker.component.scss']
})
export class DayBreakerComponent implements OnInit, OnChanges {
  constructor() {}

  @Input() time: number;
  @Input() previousTime: number;

  isSameDay = true;

  ngOnInit() {
    this.isSameDay = this.getIsSameDay(this.time, this.previousTime);
  }

  ngOnChanges() {
    this.isSameDay = this.getIsSameDay(this.time, this.previousTime);
  }

  private getIsSameDay(date1: number, date2: number): boolean {
    if (!date1 || !date2) {
      return false;
    }
    const [day1Day, day2Day] = [date1, date2].map(date =>
      Math.floor((date - MILLISECONDS_TIMEZONE_OFFSET) / MILLISECONDS_IN_DAY)
    );
    return day1Day === day2Day;
  }
}
