export const markerIconTemplate = [
  '<?xml version="1.0"?>',
  '<svg width="26px" height="26px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">',
  '<circle stroke="#222" fill="{{ color }}" cx="50" cy="50" r="35"/>',
  '</svg>'
].join('\n');

export const circleTemplate = `<circle cx="150" cy="150" r="{{ radius }}" stroke="{{ color }}" stroke-width="150"
  stroke-dasharray= "{{ remainingDash }} {{ totalDash }}" fill="none"
/>`;

export const baseUrl = 'data:image/svg+xml;charset=UTF-8;base64,';

export const DEFAULT_COHORT_COLOR = '#fff';

export interface MarkerData {
  cohortColor: string;
  memberName: string;
  memberStatus: string;
  memberId: string;
}

export interface MarkerIconTemplateVariablesDto {
  color: string;
}

export interface CircleTemplateVariablesDto
  extends MarkerIconTemplateVariablesDto {
  remainingDash: number;
  totalDash: number;
  radius: number;
}
