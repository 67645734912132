import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'act-form-view-only',
  templateUrl: './form-view-only.component.html',
  styleUrls: ['./form-view-only.component.scss']
})
export class FormViewOnlyComponent implements OnInit {
  @HostBinding('class.act-form-item') someField: boolean = true;
  @Input() value: string;
  @Input() label: string;

  constructor() {}

  ngOnInit() {}
}
