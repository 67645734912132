import { createAction, props, Store } from '@ngrx/store';
import { stringify as httpStringify } from 'query-string';
import { ClientBootstrap } from '@act/shared/models';
import { getClientConfig } from '@act/shared/environment';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

const httpEscape = (str: string) =>
  httpStringify({ test: str }).replace('test=', '');

interface Params {
  [key: string]: string | boolean | number | string[] | number[];
}

type Method = 'POST' | 'PATCH' | 'PUT' | 'GET' | 'DELETE';

const apiErrorSubject$ = new Subject<{
  params: any;
  requestBody: any;
  actionParams: any;
  responseBody: HttpErrorResponse;
}>();

export const apiErrorObservable$ = apiErrorSubject$.asObservable();

interface Pagination {
  pageSize: number;
  pageIndex: number;
}

export class ApiRequestDefinition<
  RESPONSE extends object,
  BODY extends object,
  PARAMS extends Params,
  ACTION_PARAMS extends Params = undefined,
  PAGINATION extends Pagination = undefined
> {
  // private - use static creation functions
  private constructor(
    private url: string,
    private method: Method,
    private actionPrefix: string,
    private store: Store<any>,
    private http: HttpClient,
    private paginate: boolean = false
  ) {}

  /**
   * Actions
   */
  private initiatorAction = createAction(
    `${this.actionPrefix} Request`,
    props<{
      params: PARAMS;
      requestBody: BODY;
      actionParams?: ACTION_PARAMS;
      pagination?: PAGINATION;
    }>()
  );

  private successAction = createAction(
    `${this.actionPrefix} Request Success`,
    props<{
      params: PARAMS;
      requestBody: BODY;
      responseBody: RESPONSE;
      actionParams?: ACTION_PARAMS;
      pagination?: PAGINATION;
    }>()
  );

  private errorAction = createAction(
    `${this.actionPrefix} Request Error`,
    props<{
      params: PARAMS;
      requestBody: BODY;
      actionParams?: ACTION_PARAMS;
      pagination?: PAGINATION;
    }>()
  );

  private config: ClientBootstrap;

  getRequestActions() {
    return {
      initiator: this.initiatorAction,
      success: this.successAction,
      error: this.errorAction
    };
  }

  // Make Request and state will be updated
  async makeRequest(request?: {
    params?: PARAMS;
    body?: BODY;
    actionParams?: ACTION_PARAMS;
    pagination?: PAGINATION;
  }): Promise<RESPONSE> {
    try {
      if (this.store) {
        // dispatch initiator action even though we are calling the request directly. In this case we should
        const initiatorActionPayload = {
          params: request.params,
          requestBody: request.body,
          actionParams: request.actionParams,
          pagination: request.pagination,
          _isDirect: true
        };
        this.store.dispatch(this.initiatorAction(initiatorActionPayload));
      }

      const result = await this.makeRequestInternal(request).toPromise();

      if (this.store) {
        this.store.dispatch(
          this.successAction({
            params: request.params,
            requestBody: request.body,
            responseBody: result,
            actionParams: request.actionParams,
            pagination: request.pagination
          })
        );
      }
      return result;
    } catch (e) {
      if (this.store) {
        // Centralized error handler, to show error snackbar
        apiErrorSubject$.next({
          params: request.params,
          requestBody: request.body,
          actionParams: request.actionParams,
          responseBody: e
        });

        this.store.dispatch(
          this.errorAction({
            params: request.params,
            requestBody: request.body,
            actionParams: request.actionParams,
            pagination: request.pagination
          })
        );
      }
      throw e;
    }
  }

  async makeSimpleRequest(request?: {
    params?: PARAMS;
    body?: BODY;
    actionParams?: ACTION_PARAMS;
    pagination?: PAGINATION;
  }): Promise<RESPONSE> {
    return this.makeRequestInternal(request).toPromise();
  }

  private makeRequestInternal(request?: {
    params?: PARAMS;
    body?: BODY;
    pagination?: PAGINATION;
  }): Observable<RESPONSE> {
    if (!request) {
      request = {
        params: null,
        body: null,
        pagination: null
      };
    }
    if (!this.config) {
      this.config = getClientConfig();
    }
    const url = `${
      this.config.reema.serviceUris.websocketService
    }${this.buildUrl(this.url, request.params, request.pagination)}`;
    return this.http
      .request<RESPONSE>(this.method, url, {
        body: request.body,
        observe: 'response'
      })
      .pipe(map(response => response.body));
  }

  private buildUrl(url: string, params: any, pagination: Pagination): string {
    let queryParamKeys: string[] = [];
    let routeParamKeys: string[] = [];

    if (params) {
      const routeParamMatch = url.match(/\/:(\w|\d|-)+/g);
      if (routeParamMatch) {
        routeParamKeys = routeParamMatch
          .map(m => m.replace('/:', ''))
          .filter(key => params[key] !== undefined);
      }
      queryParamKeys = Object.keys(params).filter(
        key => !routeParamKeys.find(k => k === key)
      );
    }

    if (routeParamKeys) {
      url = routeParamKeys.reduce((url: string, key): string => {
        const value = params[key];
        if (value != null) {
          return url.replace(`:${key}`, httpEscape(value.toString()));
        }
      }, url);
    }

    if (!pagination && this.paginate) {
      pagination = {
        pageIndex: 0,
        pageSize: 5
      };
    }

    if (queryParamKeys || pagination) {
      const query: Params = queryParamKeys.reduce((queryParams, key) => {
        queryParams[key] = params[key];
        return queryParams;
      }, {});

      if (pagination) {
        query['page-start'] = pagination.pageIndex * pagination.pageSize;
        query['page-size'] = pagination.pageSize;
      }

      // Array to comma delimited string
      Object.entries(query).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          query[key] = value.join(',');
        }
      });
      const qs = httpStringify(query);
      if (qs && qs != '') {
        url = `${url}?${qs}`;
      }
    }

    return url;
  }

  createEffect(actions$: Actions) {
    return createEffect(() =>
      actions$.pipe(
        ofType(this.initiatorAction),
        filter(action => !(<any>action)._isDirect), // Do not initiate actions that are direct
        mergeMap(action => {
          return this.makeRequestInternal({
            body: action.requestBody,
            params: action.params,
            pagination: action.pagination
          }).pipe(
            map(response =>
              this.successAction({
                params: action.params,
                requestBody: action.requestBody,
                responseBody: response,
                actionParams: action.actionParams
              })
            ),
            catchError((err: HttpErrorResponse) => {
              apiErrorSubject$.next({
                params: action.params,
                requestBody: action.requestBody,
                actionParams: action.actionParams,
                responseBody: err
              });
              return of(
                this.errorAction({
                  params: action.params,
                  requestBody: action.requestBody,
                  actionParams: action.actionParams
                })
              );
            })
          );
        })
      )
    );
  }

  static createRequestDefinition<
    RESPONSE extends object,
    BODY extends object,
    PARAMS extends Params,
    ACTION_PARAMS extends Params = undefined
  >(
    route: string,
    method: Method,
    actionPrefix: string,
    store: Store<any>,
    http: HttpClient
  ) {
    return new ApiRequestDefinition<RESPONSE, BODY, PARAMS, ACTION_PARAMS>(
      route,
      method,
      actionPrefix,
      store,
      http
    );
  }

  static createRequestDefinitionWithPagination<
    RESPONSE extends object,
    BODY extends object,
    PARAMS extends Params,
    ACTION_PARAMS extends Params = undefined
  >(
    route: string,
    method: Method,
    actionPrefix: string,
    store: Store<any>,
    http: HttpClient
  ) {
    return new ApiRequestDefinition<
      RESPONSE,
      BODY,
      PARAMS,
      ACTION_PARAMS,
      Pagination
    >(route, method, actionPrefix, store, http, true);
  }
}
