import { Injectable, Injector } from '@angular/core';
import {Router, RouterEvent, NavigationEnd, ActivatedRouteSnapshot} from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class RouterFacade {
  private static _activeUrl$: BehaviorSubject<string> = new BehaviorSubject(null);

  private router: Router;
  activeUrl$: Observable<string> = RouterFacade._activeUrl$.asObservable();

  private bootstrapped = false;

  constructor(private injector: Injector) {}

  bootstrap(): boolean {
    if (this.bootstrapped) return true;

    this.router = this.injector.get(Router);
    RouterFacade._activeUrl$.next(this.router.url);
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => RouterFacade._activeUrl$.next(event.url));

    this.bootstrapped = true;

    return true;
  }

  navigate(...args) {
    this.router.navigate.apply(this.router, args);
  }
}
