import { TodoDto } from '@act/shared/data-transfer-objects';

export enum TodoEventTypes {
  TODO_CREATED = '[Todos] Todo Created',
  TODO_UPDATED = '[Todos] Todo Updated',
  TODO_DELETED = '[Todos] Todo Deleted'
}

export interface TodoEventPayload {
  todo: TodoDto;
}

export class TodoCreatedEvent {
  readonly type = TodoEventTypes.TODO_CREATED;

  constructor(public payload: TodoEventPayload) {}
}

export class TodoUpdatedEvent {
  readonly type = TodoEventTypes.TODO_UPDATED;

  constructor(public payload: TodoEventPayload) {}
}

export interface TodoDeletedEventPayload {
  todoId: number;
  userId: string;
}

export class TodoDeletedEvent {
  readonly type = TodoEventTypes.TODO_DELETED;

  constructor(public payload: TodoDeletedEventPayload) {}
}
