import {
  Directive,
  Output,
  Input,
  EventEmitter,
  HostBinding,
  HostListener,
  ElementRef
} from '@angular/core';

@Directive({
  selector: '[fileUploaderTrigger]'
})
export class FileUploaderTriggerDirective {
  constructor() {
    console.log('FileUploaderTriggerDirective');
  }
  @Output() onClick = new EventEmitter<any>();

  @HostListener('click') elementClicked(event) {
    console.log('FileUploaderTriggerDirective clicked');
    this.onClick.emit(event);
  }
}
