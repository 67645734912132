import { UserDto } from '@act/shared/data-transfer-objects';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'act-member-info-display',
  templateUrl: './member-info-display.component.html',
  styleUrls: ['./member-info-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberInfoDisplayComponent implements OnInit {
  @Input() user: UserDto;
  constructor() {}

  ngOnInit() {}
}
