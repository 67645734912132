import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromUserNeed from './need.reducer';
import { Observable } from 'rxjs';
import {
  UserNeedDto,
  CreateUserNeedRequestDto,
  UpdateUserNeedRequestDto,
  NeedCategoryDto,
  UpdateUserNeedSortOrderDto
} from '@act/shared/data-transfer-objects';
import {
  selectNeedCategories,
  selectLoadingUserNeeds,
  selectUserNeed
} from './need.selectors';
import {
  deleteUserNeed,
  loadUserNeeds,
  updateUserNeedOrder
} from './need.actions';
import { NeedRequests } from './need.requests';

@Injectable()
export class UserNeedFacade {
  userNeedCreationIdTicker = 1;

  constructor(
    private store: Store<fromUserNeed.State>,
    private needRequests: NeedRequests
  ) {}

  needCategories$: Observable<NeedCategoryDto[]> = this.store.select(
    selectNeedCategories
  );

  userNeeds$: Observable<UserNeedDto[]> = this.store.pipe(
    select(selectUserNeed)
  );

  loadingUserNeeds$: Observable<boolean> = this.store.pipe(
    select(selectLoadingUserNeeds)
  );
  async autoSaveNeedNote(
    userNeedId: number,
    note: string
  ): Promise<UserNeedDto> {
    const need = await this.needRequests.patchUserNeed.makeRequest({
      params: { userNeedId },
      body: { note }
    });
    return need;
  }

  createUserNeed(request: CreateUserNeedRequestDto): Promise<UserNeedDto> {
    return this.needRequests.postUserNeed.makeRequest({
      params: null,
      body: request,
      actionParams: { creationId: this.userNeedCreationIdTicker++ }
    });
  }

  updateUserNeed(
    request: UpdateUserNeedRequestDto,
    userNeedId: number
  ): Promise<UserNeedDto> {
    return this.needRequests.patchUserNeed.makeRequest({
      body: { ...request },
      params: { userNeedId }
    });
  }

  updateUserNeedOrder(request: UpdateUserNeedSortOrderDto) {
    this.store.dispatch(
      updateUserNeedOrder({ params: null, requestBody: { ...request } })
    );
  }

  getUserNeedOfMember(memberId: string) {
    this.store.dispatch(
      loadUserNeeds({
        params: { userId: memberId },
        requestBody: null
      })
    );
  }

  clearUserNeed(userNeedId: number) {
    this.store.dispatch(
      deleteUserNeed({
        params: { userNeedId },
        requestBody: null
      })
    );
  }
}
