import {
  ScheduledEventActionEventArgsDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import {
  Component,
  ComponentRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { AnchoredDialogComponent } from 'libs/shared/ui/dialog/src/lib/components/anchored-dialog/anchored-dialog.component';
import { FeedActionsService } from '../feed-actions/feed-actions.service';
import { FabButtonDto, fabButtons, FabButtonType } from './action-panel.data';
import { speedDialFabAnimations } from './speed-dial-fab.animations';

@Component({
  selector: 'act-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.scss'],
  animations: speedDialFabAnimations
})
export class ActionPanelComponent implements OnInit, OnChanges {
  fabButtons = fabButtons;
  buttons = [];
  fabTogglerState = 'inactive';

  constructor(private feedActionsService: FeedActionsService) {}

  @Input() member: UserDto;
  @Input() userMap: Dictionary<UserDto>;
  @Input() scheduledEventActionEvent: ScheduledEventActionEventArgsDto;

  scheduledEventId: number;
  anchoredDialogRef: ComponentRef<AnchoredDialogComponent>;
  actionType: string;

  @HostListener('window:keyup.esc') onKeyUp() {
    if (this.buttons.length) {
      this.hideItems();
    }
  }

  ngOnInit() {
    this.actionType = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { scheduledEventActionEvent } = changes;
    if (scheduledEventActionEvent && scheduledEventActionEvent.currentValue) {
      this.scheduledEventId =
        scheduledEventActionEvent.currentValue.scheduledEventId;
      this.showPanel(scheduledEventActionEvent.currentValue.actionType, true);
    }
  }

  showPanel(action: string, isScheduledEventAction = false, showChat = false) {
    this.actionType = action;

    if (!isScheduledEventAction) {
      this.scheduledEventId = null;
    }

    if (action === 'message') {
      this.createMessage();
    } else if (action === 'call') {
      this.viewPhoneNumbers();
    } else if (action === 'visit') {
      this.createVisit();
    } else if (action === 'event') {
      this.createScheduledEvent();
    }
  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  handleClick(button: FabButtonDto) {
    switch (button.type) {
      case FabButtonType.VISIT:
        this.createVisit();
        break;
      case FabButtonType.PHONE_CALL:
        this.viewPhoneNumbers();
        break;
      case FabButtonType.NOTE:
        this.createNote();
        break;
      case FabButtonType.SCHEDULED_EVENT:
        this.createScheduledEvent();
        break;
      case FabButtonType.TEXT_MESSAGE:
        this.createMessage();
        break;
    }
    this.onToggleFab();
  }

  createVisit() {
    this.feedActionsService.createVisit(
      this.member.id,
      this.scheduledEventId,
      () => {
        this.actionType = '';
        this.scheduledEventId = null;
      }
    );
  }

  createNote() {
    this.feedActionsService.createNote(this.member.id, () => {
      this.actionType = '';
      this.scheduledEventId = null;
    });
  }

  createScheduledEvent() {
    this.feedActionsService.createScheduledEvent(this.member.id, () => {
      this.actionType = '';
      this.scheduledEventId = null;
    });
  }

  createMessage() {
    this.feedActionsService.createMessage(
      this.member.id,
      this.scheduledEventId,
      () => {
        this.actionType = '';
        this.scheduledEventId = null;
      }
    );
  }

  viewPhoneNumbers() {
    this.feedActionsService.createCall(
      this.member.id,
      this.scheduledEventId,
      () => {
        this.actionType = '';
        this.scheduledEventId = null;
      }
    );
  }
}
