import { CohortDto } from '@act/shared/data-transfer-objects';
import {
  Component,
  HostListener,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'act-edit-cohort-dialog',
  templateUrl: './edit-cohort-dialog.component.html',
  styleUrls: ['./edit-cohort-dialog.component.scss']
})
export class EditCohortDialogComponent implements OnInit, OnDestroy {
  @Output() updateCohort: EventEmitter<CohortDto> = new EventEmitter();
  @Output() addCohort: EventEmitter<{
    name: string;
    colorHex: string;
  }> = new EventEmitter();
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();

  @Input() title: string;
  cohorts: CohortDto[];
  selectedCohort: CohortDto;
  @Input() cohorts$: Observable<CohortDto[]>;
  @Input() cohortsLoading$: Observable<boolean>;
  cohortsSubscription: Subscription;
  cohortName: string = '';
  showAddCohort: boolean = false;
  colors = [
    '#60bd4e',
    '#f1d600',
    '#ff9e19',
    '#ea5b46',
    // '#ea5b46', Duplicate value
    '#0479bf',
    '#cadb57',
    '#fcc15e',
    // '#f75f5e', Similar to above red
    '#7dbcfe',
    '#d198e3'
  ];

  constructor() {}

  ngOnInit() {
    this.cohortsSubscription = this.cohorts$.subscribe(cohorts => {
      this.cohorts = cohorts;
    });
  }

  ngOnDestroy() {
    this.cohortsSubscription.unsubscribe();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.closeDialog.emit();
  }

  selectCohort(cohort: CohortDto) {
    this.selectedCohort = { ...cohort };
    this.showAddCohort = false;
  }

  onSave(cohort: CohortDto) {
    this.updateCohort.emit(cohort);
    this.selectedCohort = null;
  }

  onAdd() {
    this.addCohort.emit({
      name: this.cohortName,
      colorHex: this.getNextColor()
    });
    this.showAddCohort = false;
    this.cohortName = null;
  }

  onAddCohort() {
    this.showAddCohort = true;
    this.selectedCohort = null;
  }

  handleChange(name, event: 'new' | 'edit') {
    if (event === 'new') {
      this.cohortName = name.target.value.replace(/^\s+/g, '');
    } else {
      this.selectedCohort.name = name.target.value.replace(/^\s+/g, '');
    }
  }

  getNextColor = (): string => {
    const lastCohort = this.cohorts.length
      ? this.cohorts[this.cohorts.length - 1]
      : null;
    const lastColorIndex = lastCohort
      ? this.colors.findIndex(c => c === lastCohort.colorHex)
      : -1;
    const useFirstColor =
      lastColorIndex < 0 || lastColorIndex === this.colors.length - 1;
    return useFirstColor ? this.colors[0] : this.colors[lastColorIndex + 1];
  };
}
