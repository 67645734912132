import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'act-html-content-viewer',
  templateUrl: './html-content-viewer.component.html',
  styleUrls: ['./html-content-viewer.component.scss']
})
export class HtmlContentViewerComponent implements OnInit {
  @Input() value: string;

  constructor() {}

  ngOnInit() {}
}
