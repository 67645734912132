import { Component, Input, OnChanges, OnInit } from '@angular/core';

export type FeedCardAction = {
  icon: string;
  title: string;
  details?: string;
  handler: () => void;
};

export type AdditionalFeedCardActions = Pick<
  FeedCardAction,
  'title' | 'details' | 'handler'
>;

export type FeedCardData = {
  icon: string;
  iconColor: string;
  titleParts: {
    text: string;
    bold?: boolean;
    details?: string;
  }[];
  metadataParts: {
    text: string;
    details?: string;
    bold?: boolean;
  }[];
  details: string;
  audio: string;
  note: string;
  badges: {
    text: string;
    details: string;
    color: 'red';
  }[];
};

@Component({
  selector: 'act-feed-card-content',
  templateUrl: './feed-card-content.component.html',
  styleUrls: ['./feed-card-content.component.scss']
})
export class FeedCardContentComponent implements OnInit, OnChanges {
  constructor() {}

  @Input() cardData: FeedCardData;
  @Input() actions: FeedCardAction[];
  @Input() additionalActions: AdditionalFeedCardActions[];

  data: FeedCardData;

  ngOnInit() {}

  ngOnChanges() {
    this.data = this.cardData;
  }
}
