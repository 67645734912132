import { Injectable } from '@angular/core';
import { JSFile } from '@act/server/models/files';

@Injectable()
export class FileThumbnailService {
  constructor() {}

  getThumbnailFromImageFile(file: JSFile): Promise<string | ArrayBuffer> {
    if (!file) {
      return null;
    }

    return new Promise((resolve, reject) => {
      try {
        var reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
      } catch (e) {
        reject(e);
      }
    });
  }

  // FROM https://codepen.io/aertmann/pen/mAVaPx
  getThumbnailFromVideoFile(file: JSFile): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      try {
        var fileReader = new FileReader();
        fileReader.onload = function() {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = document.createElement('video');

          var snapImage = () => {
            var canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext('2d')
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            var image = canvas.toDataURL();
            resolve(image);

            var success = image.length > 100000;
            return success;
          };

          var timeupdate = () => {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
              video.pause();
            }
          };

          video.addEventListener('loadeddata', () => {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
            }
          });

          video.addEventListener('timeupdate', timeupdate);
          video.preload = 'metadata';
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          // video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);
      } catch (e) {
        reject(e);
      }
    });
  }

  getThumbnail(file: JSFile): Promise<string | ArrayBuffer> {
    if (file.type.match('image')) {
      return this.getThumbnailFromImageFile(file);
    } else if (file.type.match('video')) {
      return this.getThumbnailFromVideoFile(file);
    } else {
      return Promise.resolve(null);
    }
  }
}
