import { createAction, props } from '@ngrx/store';
import { CallListFilters } from './call-list.models';
import { CallListRequests } from './call-list.requests';

const requests = new CallListRequests(null, null);

export const {
  initiator: loadCallList,
  success: callListLoaded,
  error: callListLoadError
} = requests.getCalls.getRequestActions();

export const {
  initiator: updateCallSubject,
  success: callSubjectUpdated,
  error: callSubjectUpdateError
} = requests.updateSubjectForCall.getRequestActions();

export const {
  initiator: callNumber,
  success: callNumberSuccess,
  error: callNumberError
} = requests.callNumber.getRequestActions();

export const {
  initiator: getCallUsers,
  success: getCallUsersSuccess,
  error: getCallUsersError
} = requests.getUsersForCallList.getRequestActions();

export const initializeCallList = createAction('[Call List] Init Call List');
export const destroyCallList = createAction('[Call List] Destroy Call List');

export const updateCallListFilters = createAction(
  '[Call List] Update Call Filters',
  props<{ filters: CallListFilters }>()
);

export const updateCallListPagination = createAction(
  '[Call List] Update Call Pagination',
  props<{ pageSize: number; pageIndex: number }>()
);
