import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FileFacade } from 'libs/features/files/data-access/src/lib/+state/file/file.facade';

@Component({
  selector: 'act-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent {
  constructor(private dialog: MatDialog) {}

  @Input() fileId: string;
  @Output() load: EventEmitter<void> = new EventEmitter();
  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;

  onClick() {
    this.dialog.open(this.dialogRef);
  }

  onLoad() {
    this.load.next();
  }
}
