import { SelectDto } from 'libs/shared/ui/forms/src/lib/inputs/select/select.component';

export const quickDates: SelectDto[] = [
  { label: 'Tomorrow', value: '1D' },
  { label: 'In 2 Days', value: '2D' },
  { label: 'In 1 week', value: '1W' },
  { label: 'In 2 Weeks', value: '2W' },
  { label: 'In 1 Month', value: '1M' },
  { label: 'Custom Date/Time', value: 'custom' }
];
