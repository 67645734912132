import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  BreakpointService,
  ScreenSize
} from 'libs/features/patients/feature-v1/src/lib/members/breakpoint.service';
import { maximumVisibleDialogSubject$ } from 'libs/shared/ui/dialog/src/lib/models/dialog-options.model';

@Component({
  selector: 'act-calls-page',
  templateUrl: './calls-page.component.html',
  styleUrls: ['./calls-page.component.scss']
})
export class CallsPageComponent implements OnDestroy {
  constructor(private breakpointService: BreakpointService) {
    this.breakpointService.getBreakPoints().forEach(breakpoint => {
      const breakpointSubscription = breakpoint.subscribe(({ state, size }) => {
        if (state.matches) {
          // this.setSize(size);
          // this.setOpenValues();
          this.screenSize = size;
          maximumVisibleDialogSubject$.next(
            this.getMaximumVisibleDialogCount()
          );
        }
      });
      this.breakpointSubscriptions.push(breakpointSubscription);
    });
  }

  screenSize: ScreenSize;
  breakpointSubscriptions: Subscription[] = [];

  ngOnDestroy() {
    if (this.breakpointSubscriptions && this.breakpointSubscriptions.length) {
      this.breakpointSubscriptions.forEach(e => e.unsubscribe());
    }
  }

  private getMaximumVisibleDialogCount() {
    if (this.screenSize === ScreenSize.ExtraLarge) {
      return 3;
    } else if (this.screenSize === ScreenSize.Large) {
      return 2;
    }
    return 1;
  }
}
