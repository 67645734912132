import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SubscriptionContext } from 'twilio/lib/rest/events/v1/subscription';
import { CallListFacade } from '../+state/call-list.facade';
import { CallListFilters } from '../+state/call-list.models';

@Component({
  selector: 'act-call-filters',
  templateUrl: './call-filters.component.html',
  styleUrls: ['./call-filters.component.scss']
})
export class CallFiltersComponent implements OnInit, OnDestroy {
  constructor(private readonly callListFacade: CallListFacade) {
    this.setForm({ noSubjectId: false, ownCalls: true, incoming: null });
  }

  filters$ = this.callListFacade.callListFilters$;

  formGroup: FormGroup;

  filtersSubscription: Subscription;

  formGroupSubscription: Subscription;

  ngOnInit() {
    this.filtersSubscription = this.filters$.subscribe(f => {
      this.setForm(f);
    });
  }

  ngOnDestroy(): void {
    this.filtersSubscription.unsubscribe();
    if (this.formGroupSubscription) {
      this.formGroupSubscription.unsubscribe();
    }
  }

  setForm(f: CallListFilters) {
    const fb = new FormBuilder();
    this.formGroup = fb.group({
      noSubjectId: new FormControl(f.noSubjectId),
      ownCalls: new FormControl(f.ownCalls),
      incoming: new FormControl(f.incoming)
    });

    // When form changes update the filters
    this.formGroupSubscription = this.formGroup.valueChanges.subscribe(
      values => {
        this.callListFacade.setFilters({
          noSubjectId: values.noSubjectId,
          ownCalls: values.ownCalls,
          incoming: values.incoming
        });
      }
    );
  }

  onChange(thing) {}
}
