import { ErrorPayload } from '@act/shared/models';
import { Action } from '@ngrx/store';
import { FeedDto, UserDto } from '@act/shared/data-transfer-objects';

export enum JoinRoomTypes {
  JOIN_ROOM = '[Chat] Join Room',
  ROOM_JOINED = '[Chat] Room Joined',
  ROOM_JOIN_FAILED = '[Chat] Room Join Failed'
}

export class JoinRoomEvent implements Action {
  readonly type = JoinRoomTypes.JOIN_ROOM;
}

export interface RoomJoinedPayload {
  feeds: FeedDto[];
  users: UserDto[];
}
export class RoomJoined implements Action {
  readonly type = JoinRoomTypes.ROOM_JOINED;

  constructor(readonly payload: RoomJoinedPayload) {}
}

export class RoomJoinFailed implements Action {
  readonly type = JoinRoomTypes.ROOM_JOIN_FAILED;

  constructor(public payload: ErrorPayload) {}
}
