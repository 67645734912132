import { UsersFacade } from '@act/features/users/data-access';
import { Component, Input, OnInit } from '@angular/core';
import { MarkerData } from '../../services/map.dto';

@Component({
  selector: 'act-members-info-window',
  templateUrl: './members-info-window.component.html',
  styleUrls: ['./members-info-window.component.scss']
})
export class MembersInfoWindowComponent implements OnInit {
  @Input() data: MarkerData[];

  constructor(private usersFacade: UsersFacade) {}

  ngOnInit() {}

  memberView(memberId: string) {
    this.usersFacade.selectMember(memberId);
  }
}
