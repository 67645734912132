import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AnchoredDialogData } from '../../models/dialog-options.model';

@Component({
  selector: 'act-anchored-dialog',
  templateUrl: './anchored-dialog.component.html',
  styleUrls: ['./anchored-dialog.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnchoredDialogComponent implements OnInit {
  constructor() {}
  @Input() dialog: AnchoredDialogData;
  @Output() closeEvent = new EventEmitter<string>();
  @Input() leftValue: number;
  @Input() showMinimize: boolean;
  minimized: boolean = false;
  modalClose: boolean = false;
  ngOnInit() {
    if (this.dialog && this.dialog.options.onOpen) {
      this.dialog.options.onOpen();
    }
  }

  expandModal() {
    this.minimized = !this.minimized;
  }
  closeModal() {
    this.modalClose = true;
    this.closeEvent.emit(this.dialog.id);
    if (this.dialog && this.dialog.options.onClose) {
      this.dialog.options.onClose();
    }
  }

  getStyles() {
    return { right: `${this.leftValue}px` };
  }
}
