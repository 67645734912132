import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ScheduledEventDto } from '@act/shared/data-transfer-objects';
import {
  createScheduledEvent,
  scheduledEventCreated,
  deleteScheduledEvent,
  loadScheduledEvents,
  newScheduledEvent,
  scheduledEventCreationError,
  scheduledEventDeleted,
  scheduledEventDeleteError,
  scheduledEventsLoaded,
  scheduledEventSnoozed,
  scheduledEventSnoozeError,
  scheduledEventUpdated,
  snoozeScheduledEvent,
  updateScheduledEvent
} from './scheduled-event.actions';

export const scheduledEventFeatureKey = 'scheduled-events';

export interface State extends EntityState<ScheduledEventDto> {
  editedScheduledEventId: number;
  loadingScheduledEvents: boolean;
}

export const adapter: EntityAdapter<ScheduledEventDto> = createEntityAdapter<
  ScheduledEventDto
>();

export const initialState: State = adapter.getInitialState({
  editedScheduledEventId: null,
  loadingScheduledEvents: false
});

const scheduledEventReducer = createReducer(
  initialState,
  on(loadScheduledEvents, (state, action) => {
    const newState = adapter.removeAll(state); // remove existing events
    let loadingScheduledEvents = state.loadingScheduledEvents;

    return {
      ...newState,
      loadingScheduledEvents
    };
  }),
  on(scheduledEventsLoaded, (state, action) => {
    const newState = adapter.addMany(action.responseBody, state);

    let loadingScheduledEvents = state.loadingScheduledEvents;

    if (loadingScheduledEvents) {
      loadingScheduledEvents = false;
    }

    return {
      ...newState,
      loadingScheduledEvents
    };
  }),
  on(deleteScheduledEvent, (state, action) => ({
    ...state,
    loadingScheduledEvents: true
  })),
  on(scheduledEventDeleted, (state, action) => {
    const event = state.entities[action.params.scheduledEventId];
    const shouldRemoveEvent = event && event.userId === action.params.userId;
    return shouldRemoveEvent
      ? {
          ...adapter.removeOne(action.params.scheduledEventId, state),
          loadingScheduledEvents: false
        }
      : { ...state, loadingScheduledEvents: false };
  }),
  on(scheduledEventDeleteError, (state, action) => ({
    ...state,
    loadScheduledEvents: false
  })),
  on(snoozeScheduledEvent, (state, action) => ({
    ...state,
    loadingScheduledEvents: true
  })),
  on(scheduledEventSnoozed, (state, action) => ({
    ...adapter.upsertOne(action.responseBody, state),
    loadingScheduledEvents: false
  })),
  on(scheduledEventSnoozeError, (state, action) => ({
    ...state,
    loadingScheduledEvents: false
  })),
  on(createScheduledEvent, (state, action) => ({
    ...state,
    loadingScheduledEvents: true
  })),
  on(scheduledEventCreated, (state, action) => ({
    ...adapter.upsertOne(action.responseBody, state),
    loadingScheduledEvents: false
  })),
  on(scheduledEventCreationError, (state, action) => ({
    ...state,
    loadingScheduledEvents: false
  })),
  on(newScheduledEvent, (state, action) => ({
    ...adapter.upsertOne(action.item, state),
    loadingScheduledEvents: false
  })),
  on(scheduledEventCreationError, (state, action) => ({
    ...state,
    loadingScheduledEvents: false
  })),
  on(updateScheduledEvent, (state, action) => ({
    ...state,
    loadingScheduledEvents: true
  })),
  on(scheduledEventUpdated, (state, action) => {
    const event = state.entities[action.responseBody.id];
    const shouldUpdateEvent =
      event && event.userId === action.responseBody.userId;
    if (!shouldUpdateEvent) {
      return { ...state, loadingScheduledEvents: false };
    }

    if (action.responseBody.resolved) {
      // If Item is resolved then remove it
      return {
        ...adapter.removeOne(action.responseBody.id, state),
        loadingScheduledEvents: false
      };
    }
    return {
      ...adapter.upsertOne(action.responseBody, state),
      loadingScheduledEvents: false
    };
  })
);

export const scheduledEventAdapter = adapter;

export function reducer(state: State | undefined, action: Action) {
  return scheduledEventReducer(state, action);
}
