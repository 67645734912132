import {Action} from '@ngrx/store';
import {SOCKET_NAME} from '@act/shared/models';

export enum SocketActionTypes {
    CLIENT_SOCKET_CONNECTED = '[Socket] Socket Connected',
    CLIENT_SOCKET_DISCONNECTED = '[Socket] Socket Disconnected',
    CLIENT_SOCKET_RECONNECTED = '[Socket] Socket Reconnected',
    CLIENT_SOCKET_INITIALIZED = '[Socket] Socket Reconnected',
}

export class ClientSocketConnected implements Action {
    readonly type = SocketActionTypes.CLIENT_SOCKET_CONNECTED;
    
    constructor(public payload: { socketName: SOCKET_NAME; }) {
    }
}

export class ClientSocketDisconnected implements Action {
    readonly type = SocketActionTypes.CLIENT_SOCKET_DISCONNECTED;
    
    constructor(public payload: { socketName: SOCKET_NAME; }) {
    }
}

export class ClientSocketReconnected implements Action {
    readonly type = SocketActionTypes.CLIENT_SOCKET_RECONNECTED;
    
    constructor(public payload: { socketName: SOCKET_NAME; }) {
    }
}

export class ClientSocketInitialized implements Action {
    readonly type = SocketActionTypes.CLIENT_SOCKET_INITIALIZED;
    
    constructor(public payload: { socketName: SOCKET_NAME; }) {
    }
}