import { Pipe, PipeTransform } from '@angular/core';
import { convertMsToHoursMinutesSeconds } from '@act/common/utilities';

// Converts date string to a date 'YYYY-MM-DD' -> new Date(...)
@Pipe({
  name: 'dateStringToDate'
})
export class DateStringToDatePipe implements PipeTransform {
  transform(
    value: number,
    utcTime: boolean,
    time: number,
    ...args: any[]
  ): any {
    const utcDate = time ? new Date(time) : new Date(value);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000
    );
    return utcTime ? utcDate : localDate;
  }
}
