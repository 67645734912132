import { ApiRequestDefinition } from '@act/shared/api';
import {
  AutoSaveNoteDto,
  CommentDto,
  CreateNoteRequestDto,
  NoteDto,
  NoteWithFeedItemDto,
  UpdateAutoSaveNoteDto,
  UpdateNoteRequestDto
} from '@act/shared/data-transfer-objects';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class NoteRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  patchNote = ApiRequestDefinition.createRequestDefinition<
    NoteDto,
    UpdateNoteRequestDto,
    { noteId: number },
    null
  >('/notes/:noteId', 'PATCH', '[Note] Update Note', this.store, this.http);

  deleteNote = ApiRequestDefinition.createRequestDefinition<
    null,
    null,
    { noteId: number },
    null
  >('/notes/:noteId', 'DELETE', '[Note] Delete Note', this.store, this.http);

  postNote = ApiRequestDefinition.createRequestDefinition<
    NoteWithFeedItemDto,
    CreateNoteRequestDto,
    null,
    { creationId: number }
  >('/notes', 'POST', '[Note] Create Note', this.store, this.http);

  getAutoSaveNote = ApiRequestDefinition.createRequestDefinition<
    AutoSaveNoteDto,
    null,
    { subjectId: string; type: string }
  >(
    '/notes/auto-save',
    'GET',
    '[Note] Get Autosave Note',
    this.store,
    this.http
  );

  updateAutoSaveNote = ApiRequestDefinition.createRequestDefinition<
    AutoSaveNoteDto,
    UpdateAutoSaveNoteDto,
    { subjectId: string; type: string }
  >(
    '/notes/auto-save',
    'POST',
    '[Note] Update Autosave Note',
    this.store,
    this.http
  );

  clearAutoSaveNote = ApiRequestDefinition.createRequestDefinition<
    AutoSaveNoteDto,
    null,
    { subjectId: string; type: string }
  >(
    '/notes/auto-save',
    'DELETE',
    '[Note] Clear Autosave Note',
    this.store,
    this.http
  );

  createComment = ApiRequestDefinition.createRequestDefinition<
    CommentDto,
    { text: string },
    { noteId: number },
    { feedItemId: number }
  >(
    '/notes/:noteId/comments',
    'POST',
    '[Note] Create Comment',
    this.store,
    this.http
  );
}
