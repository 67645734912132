import { Platform } from '@act/core/platform';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CallListFacade } from '../+state/call-list.facade';
import { UsersFacade } from '@act/features/users/data-access';

@Component({
  selector: 'act-calls-dashboard',
  templateUrl: './calls-dashboard.component.html',
  styleUrls: ['./calls-dashboard.component.scss']
})
export class CallsDashboardComponent implements OnInit {
  constructor(
    private readonly callListFacade: CallListFacade,
    private readonly usersFacade: UsersFacade,
    private platform: Platform
  ) {}

  userMap$ = this.callListFacade.userMap$;
  teamMap$ = this.usersFacade.userMap$;
  calls$ = this.callListFacade.callList$;
  currentUser$ = this.platform.user$;
  loading$ = combineLatest([
    this.callListFacade.callListLoading$,
    this.callListFacade.usersLoading$
  ]).pipe(map(([callsLoading, usersLoading]) => callsLoading || usersLoading));

  ngOnInit() {
    this.callListFacade.initializeCallList();
  }

  ngOnDestroy() {
    this.callListFacade.destroyCallList();
  }

  @ViewChild(MatSort, null) sort: MatSort;

  dataSource$ = this.calls$.pipe(
    map(calls => {
      const newDataSource = new MatTableDataSource(
        calls.map(call => ({
          ...call,
          length: call.timeEnded
            ? call.timeEnded - (call.timeConnected, call.timeStarted)
            : 0,
          from: call.originator ? call.originator.phoneNumber : null,
          to: call.receiver ? call.receiver.phoneNumber : null
        }))
      );
      newDataSource.sort = this.sort;
      return newDataSource;
    })
  );
}
