import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromTextTemplate from './text-template/+state/text-template.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TextTemplateEffects } from './text-template/+state/text-template.effects';
import { TextTemplateFacade } from './text-template/+state/text-template.facade';
import { TextTemplateComponent } from './text-template/ui/text-template.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonPipesTextFormatModule } from '@act/common/pipes/text-format';
import { TextTemplateRequests } from './text-template/+state/text-template.requests';
import { SharedUiFormsModule } from '@act/shared/ui/forms';
@NgModule({
  declarations: [TextTemplateComponent],
  imports: [
    CommonModule,
    SharedUiFormsModule,
    StoreModule.forFeature(
      fromTextTemplate.textTemplateFeatureKey,
      fromTextTemplate.reducer
    ),

    EffectsModule.forFeature([TextTemplateEffects]),
    MatTabsModule,
    CommonPipesTextFormatModule
  ],
  providers: [TextTemplateFacade, TextTemplateRequests],
  exports: [TextTemplateComponent]
})
export class SharedTextTemplateModule {}
