import { EnhancedScriptProcessorNode } from '../models/volume-tester.model';

export function volumeIsNonZero(meter: EnhancedScriptProcessorNode, event): boolean {
  const buf = event.inputBuffer.getChannelData(0);
  const bufLength = buf.length;
  let sum = 0;
  let x;

  // Do a root-mean-square on the samples: sum up the squares...
  for (let i = 0; i < bufLength; i++) {
    x = buf[ i ];
    if (Math.abs(x) >= meter.clipLevel) {
      meter.clipping = true;
    }
    sum += x * x;
  }

  // ... then take the square root of the sum.
  const rms = Math.sqrt(sum / bufLength);

  // Now smooth this out with the averaging factor applied
  // to the previous sample - take the max here because we
  // want "fast attack, slow release."
  const volume = Math.max(rms, meter.volume * meter.averaging);

  return volume > .00009;
}
