import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'libs/feed/src/lib/dialog/confirm-dialog/confirm-dialog.component';
import { UsersFacade } from '@act/features/users/data-access';
import {
  MemberFiltersDto,
  CohortDto,
  MemberStageDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { UserFacade } from '@act/features/user/data-access';
import { CohortFacade } from 'libs/features/users/data-access/src/lib/+state/cohort/cohort.facade';
import { MemberStageFacade } from '@act/features/member-stage/data-access';
import { Subscription } from 'rxjs';
import { ActDialogService } from '@act/shared/ui/dialog';

@Component({
  selector: 'act-members-list',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit, OnDestroy {
  showFilters = false;
  memberFilters: MemberFiltersDto;
  selectedUserIds: string[];
  dialogRef: MatDialogRef<any>;
  selectedMemberId: string;
  filterSubscription: Subscription;
  teamMemberSubscription: Subscription;

  constructor(
    public usersFacade: UsersFacade,
    private dialog: MatDialog,
    public userFacade: UserFacade,
    public cohortFacade: CohortFacade,
    public memberStageFacade: MemberStageFacade,
    private dialogService: ActDialogService
  ) {}

  ngOnInit() {
    this.filterSubscription = this.usersFacade.selectedMemberFilters$.subscribe(
      filters => {
        this.memberFilters = filters;
      }
    );
    this.teamMemberSubscription = this.usersFacade.selectedTeamMemberId$.subscribe(
      id => {
        this.selectedMemberId = id;
      }
    );
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
    this.teamMemberSubscription.unsubscribe();
  }

  onUpdateFilter(filter) {
    this.usersFacade.updateMemberFilter(filter.key, filter.value, filter.flag);
  }

  displayFilters() {
    this.showFilters = !this.showFilters;
  }

  setSelectedUserIds(userIds: string[]) {
    this.selectedUserIds = userIds;
  }

  async assignGuide(selectedTeamMate: UserDto) {
    if (this.selectedUserIds) {
      const numSelected = this.selectedUserIds.length;
      const confirm = await this.dialogService.confirm(
        `Are you sure you wish to assign ${numSelected} members to  ${selectedTeamMate.name}?`
      );
      if (confirm) {
        this.usersFacade.updateUsers(this.selectedUserIds, {
          assignedGuideId: selectedTeamMate.id
        });
      }
    }
  }

  async assignCohort(selectedCohort: CohortDto) {
    if (this.selectedUserIds) {
      const numSelected = this.selectedUserIds.length;

      let confirmationText = `Are you sure you wish to unassign ${numSelected} member${
        numSelected === 1 ? '' : 's'
      } from their current cohort${numSelected === 1 ? '' : 's'}?`;
      let cohortId = null;
      if (selectedCohort) {
        confirmationText = `Are you sure you wish to assign ${numSelected} member${
          numSelected === 1 ? '' : 's'
        } to the cohort ${selectedCohort.name}?`;
        cohortId = selectedCohort.id;
      }

      const confirm = await this.dialogService.confirm(confirmationText);
      if (confirm) {
        this.usersFacade.updateUsers(this.selectedUserIds, {
          cohortId
        });
      }
    }
  }

  async assignMemberStage(selectedStage?: MemberStageDto) {
    if (this.selectedUserIds) {
      const numSelected = this.selectedUserIds.length;
      const confirm = await this.dialogService.confirm(
        `Are you sure you wish to assign ${numSelected} members to the stage ${selectedStage.name}?`
      );
      if (confirm) {
        this.usersFacade.updateUsers(this.selectedUserIds, {
          memberStageId: selectedStage.id
        });
      }
    }
  }
}
