const phoneNumberRegex = /\+1\d{10}$/;

export const formatPhoneNumber = (value: string): string => {
  if (!phoneNumberRegex.test(value)) {
    console.log(`phoneNumber not well formatted: ${value}`);
    return value;
  }

  const areaCode = value.substring(2, 5);
  const centerThree = value.substring(5, 8);
  const lastFour = value.substring(8, 12);

  return `(${areaCode}) ${centerThree}-${lastFour}`;
};
