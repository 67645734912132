import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import * as fromConsent from './consents/consent.reducer';

export const consentFeatureKey = 'consent';

export const CONSENT_FEATURE_REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<ConsentFeatureState>
>('Consent');

export interface ConsentFeatureState {
  [fromConsent.consentFeatureKey]: fromConsent.State;
}

export function getReducers(): ActionReducerMap<ConsentFeatureState> {
  return {
    [fromConsent.consentFeatureKey]: fromConsent.reducer
  };
}
