import { File } from '@act/server/models/files';
import { ApiRequestDefinition } from '@act/shared/api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class FileRequests {
  constructor(private store: Store<any>, private http: HttpClient) {}

  getFileInfo = ApiRequestDefinition.createRequestDefinition<
    File,
    null,
    { id: string },
    null
  >('/files/:id', 'GET', '[File] Get File Info', this.store, this.http);

  uploadFiles = ApiRequestDefinition.createRequestDefinition<
    File[],
    FormData,
    null
  >('/files/upload', 'POST', '[File] Upload Files', this.store, this.http);
}
export const requests = {};
