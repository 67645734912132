import { CommentDto, UserDto } from '@act/shared/data-transfer-objects';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Dictionary } from '@ngrx/entity';

@Component({
  selector: 'act-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  constructor() {}
  @Input() comments: CommentDto[];
  @Input() userMap: Dictionary<UserDto>;

  @Output() commentChange: EventEmitter<FormControl> = new EventEmitter();

  commentControl: FormControl = new FormControl('', Validators.required);

  onChange() {
    this.commentChange.emit(this.commentControl);
  }

  ngOnInit() {}
}
