import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'yearsFromDate'
})
export class YearsFromDatePipe implements PipeTransform {
  transform(value: Date, ...args: any[]): any {
    return moment().diff(value, 'years');
  }
}
