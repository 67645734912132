import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  createMessage,
  messageCreated,
  messageCreationError
} from './message.actions';
import { MessageDto } from '@act/shared/data-transfer-objects';

export const messageFeatureKey = 'messages';

export interface State extends EntityState<MessageDto> {
  inflightCreationIds: number[];
  loading: boolean;
}

export const adapter: EntityAdapter<MessageDto> = createEntityAdapter<
  MessageDto
>({
  sortComparer: (a: MessageDto, b: MessageDto) =>
    a.createdAt > b.createdAt ? 1 : -1
});

export const initialState: State = adapter.getInitialState({
  inflightCreationIds: [],
  loading: false
});

const messageReducer = createReducer(
  initialState,
  on(createMessage, (state, action) => {
    return {
      ...state,
      inflightCreationIds: [...state.inflightCreationIds, action.creationId],
      loading: true
    };
  }),
  on(messageCreated, (state, action) => {
    return {
      ...adapter.upsertOne(action.message, state),
      inflightCreationIds: state.inflightCreationIds.filter(
        id => id !== action.creationId
      ),
      loading: false
    };
  }),
  on(messageCreationError, (state, action) => {
    return {
      ...state,
      inflightCreationIds: state.inflightCreationIds.filter(
        id => id !== action.creationId
      ),
      loading: false
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return messageReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const messageAdapter = adapter;
