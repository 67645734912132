export * from './lib/rxjs/get-value-of-observable';
export * from './lib/rxjs/type';

export * from './lib/math/convert-ms-to-hours-minutes-seconds';
export * from './lib/strings/uuidv4.function';
export * from './lib/strings/unique';
export * from './lib/strings/pick-first';
export * from './lib/strings/pick-first-char-with-dot';
export * from './lib/strings/remove-html-tags';

export * from './lib/xlsx/parse-header-column-names.function';

export * from './lib/platform/platform-filter';
export * from './lib/phone/phone-number-formatter';

export * from './lib/throttled-input/throttled-change-watcher';

export * from './lib/transform/map-objects';
