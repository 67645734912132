import { createAction, props, Action } from '@ngrx/store';
import { type } from '@act/common/utilities';
import { ActingUserDto } from '@act/shared/data-transfer-objects';

export const UserActionTypes = {
  USER_LOADED: type('[User] user loaded'),
  USER_AUTHENTICATED: type('[User] user authenticated'),
  USER_NOT_AUTHENTICATED: type('[User] user not authenticated')
};

export class UserLoaded implements Action {
  readonly type = UserActionTypes.USER_LOADED;

  constructor(public payload: { user: ActingUserDto }) {}
}

export const userLoaded = createAction(
  UserActionTypes.USER_LOADED,
  props<{ user: ActingUserDto }>()
);

export const userAuthenticated = createAction(
  UserActionTypes.USER_AUTHENTICATED
);

export const userNotAuthenticated = createAction(
  UserActionTypes.USER_NOT_AUTHENTICATED
);
