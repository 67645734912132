import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesUserDataAccessModule } from '@act/features/user/data-access';

@NgModule({
  imports: [
    CommonModule,

    // feature sub-modules
    FeaturesUserDataAccessModule
  ]
})
export class FeaturesUserFeatureV1Module {}
