import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { feedsUpdated, selectFeed } from '@act/feed';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { feedQueries } from 'libs/feed/src/lib/+state/feed/feed.selectors';
import {
  selectMemberAction,
  usersQueries
} from '@act/features/users/data-access';

@Injectable()
export class MemberEffects {
  memberSelectedFeedSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectMemberAction, feedsUpdated),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedMemberId)),
        this.store.pipe(select(feedQueries.selectAllFeeds)),
        this.store.pipe(select(feedQueries.selectSelectedFeedId))
      ),
      filter(([action, selectedMemberId, feeds, selectedFeedId]) => {
        if (!selectedMemberId) return false;
        const feed = (feeds || []).find(f => f.subjectId === selectedMemberId);
        if (!feed) return false;
        if (selectedFeedId === feed.id) return false;
        return true;
      }),
      map(([action, selectedMemberId, feeds, selectedFeedId]) => {
        const feed = (feeds || []).find(f => f.subjectId === selectedMemberId);
        return selectFeed({ feedId: feed.id });
      })
    )
  );

  constructor(private actions$: Actions, private store: Store<any>) {}
}
