import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CallCreationRequestDto,
  UserDto
} from '@act/shared/data-transfer-objects';

@Component({
  selector: 'act-member-header',
  templateUrl: './member-header.component.html',
  styleUrls: ['./member-header.component.scss']
})
export class MemberHeaderComponent {
  @Input() member: UserDto;
  @Input() hideActions: boolean;
  @Input() canToggleProfile: boolean;
  @Input() profileOpen: boolean;

  @Output() newNote: EventEmitter<UserDto> = new EventEmitter();
  @Output() newVisit: EventEmitter<UserDto> = new EventEmitter();
  @Output() startCall: EventEmitter<
    CallCreationRequestDto
  > = new EventEmitter();
  @Output() toggleProfile: EventEmitter<void> = new EventEmitter();
}
