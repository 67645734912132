import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'act-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent implements OnInit {
  @Input() fullCenter: boolean;
  
  constructor() {
  }
  
  ngOnInit() {
  }
  
}
