import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ClientBootstrap } from '@act/shared/models';
import {
  CLIENT_CONFIG,
  CLIENT_CONFIG_KEY,
  CLIENT_ENVIRONMENT,
  ENVS,
  PORTS
} from '@act/shared/environment';

if (environment.production) {
  enableProdMode();
}

/**
 * Fetch our client config and set a static provider
 */
(async () => {
  const configServiceUri = 'https://api.reemahealth.com/config';
  const forceEnvLocally = null; // ENVS.DEVELOPMENT;
  // const configServiceUri = `http://localhost:${PORTS.CONFIG_SERVICE}${forceEnvLocally ? '?forceEnv' + forceEnvLocally : ''}`;
  const res = (await fetch(configServiceUri, {
    method: 'GET',
    cache: 'no-cache'
  }).catch(err => {
    console.error(err);
    return;
  })) as any;

  const json: {
    config: ClientBootstrap;
    environment: string;
  } = await res.json();

  // set our config to the window property
  window[CLIENT_CONFIG_KEY] = json;

  console.log(window[CLIENT_CONFIG_KEY]);

  platformBrowserDynamic([
    { provide: CLIENT_CONFIG, useValue: json.config },
    { provide: CLIENT_ENVIRONMENT, useValue: json.environment }
  ])
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
})();
