import {Dispatcher} from '@act/common/dispatcher';
import {BehaviorSubject, Observable, Subject, timer, interval} from 'rxjs';
import {Store} from '@ngrx/store';
import {Socket, SocketIoConfig} from 'ngx-socket-io';
import {SOCKET_NAME} from '@act/shared/models';
import {takeUntil} from 'rxjs/operators';
import {ClientSocketConnected, ClientSocketDisconnected, ClientSocketInitialized, ClientSocketReconnected} from './+state/socket.actions';
import {SocketInitializedFailedPayload, SocketInitializedPayload, SocketInitializedTypes} from './events/socket-initialized.event';
import {getValueOfObservable} from '@act/common/utilities';
import { HealthCheck } from './events/health-check.event';
import {Platform} from '@act/core/platform';
import {WebsocketAuthorizationErrorTypes, WebsocketAuthorizationErrorPayload} from '@act/common/events';

const defaultReconnectionInterval = 3000;

export abstract class SocketFacade {
  protected socket: Socket;
  protected dispatcher: Dispatcher;

  private connected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private reconnectionTimer: Observable<number>;

  private healthCheckInterval = 20000;
  private healthCheck$ = interval(this.healthCheckInterval);

  /**
   * Fires when the socket is connected. Be careful with this because this hook
   * happens at the same time that we're writing the client auth data into the
   * websocket connection DB. If your message needs the userId, use the
   * onInitialized$ hook instead.
   */
  protected _onConnect$: Subject<Dispatcher> = new Subject<Dispatcher>();
  onConnect$: Observable<Dispatcher> = this._onConnect$.asObservable();

  /**
   * Fires when a client's socket ID and user ID have been successfully written
   * to the database. This is the hook that most people will use to key off of
   * when sending an initial message.
   */
  protected _onInitialized$: Subject<Dispatcher> = new Subject<Dispatcher>();
  onInitialized$: Observable<Dispatcher> = this._onInitialized$.asObservable();

  /**
   * This is safe to use whenever because obviously the socket is done.
   */
  protected _onDisconnect$: Subject<Dispatcher> = new Subject<Dispatcher>();
  onDisconnect$: Observable<Dispatcher> = this._onDisconnect$.asObservable();

  protected constructor(
      private socketConfigFactory: () => SocketIoConfig,
      private socketName: SOCKET_NAME,
      protected store: Store<any>,
      private autoReconnect: number | false = defaultReconnectionInterval
  ) {
    if (autoReconnect) this.setupAutoReconnect(autoReconnect);
    this.enableHealthCheck();
  }

  connect() {
    // we only want to create the socket once, so check if our dispatcher
    // has already been instantiated and, if so, skip this.
    if (!this.dispatcher) this.initializeSocket();
    if (this.connected$.getValue()) return;

    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  on(event: string, callback: () => void): void {
    this.socket.on(event, callback);
  }

  fromEvent<R = any>(event: string): Observable<R> {
    return this.socket.fromEvent(event);
  }

  getSocket(): Socket {
    return this.socket;
  }

  proxyWebsocketMessage<P = any>(type: string, payloadMapperFunction?: (payload: P) => any): void {
    this.socket
        .fromEvent(type)
        .subscribe((payload?: any) => {
          // construct a new action from the type only
          const action: {type: string; payload?: P} = { type };
          // if a non-null payload was sent with the websocket message, then
          // either use the formatter or send as-is
          if(payload) {
            action.payload = payloadMapperFunction ? payloadMapperFunction(payload) : payload;
          }
          this.store.dispatch(action)
        });
  }

  protected connected(): boolean {
    return getValueOfObservable(this.connected$);
  }

  /**
   * Overridden by the child class.
   */
  protected registerEventListeners() {
    console.info(`No event listeners registered for ${this.socketName} socket facade`);
  }

  private initializeSocket() {
    this.socket = new Socket(this.socketConfigFactory());
    this.dispatcher = new Dispatcher(this.socket, this.store);

    // connect listener
    this.socket.on('connect', () => {
      this.connected$.next(true);
      this._onConnect$.next(this.dispatcher);
      this.store.dispatch(new ClientSocketConnected({socketName: this.socketName}));
      console.log(`${this.socketName} socket connected`);
    });

    // disconnect listener
    this.socket.on('disconnect', () => {
      this.connected$.next(false);
      this._onDisconnect$.next(this.dispatcher);
      this.store.dispatch(new ClientSocketDisconnected({socketName: this.socketName}));
      console.log(`${this.socketName} socket disconnected`);
    });

    this.socket.on('reconnect', () => {
      this.store.dispatch(new ClientSocketReconnected({socketName: this.socketName}));
      console.log(`${this.socketName} socket reconnected`);
    });

    // initialized listener: at this step we have auth and should be ready to go
    this.socket.fromEvent(SocketInitializedTypes.SOCKET_INITIALIZED).subscribe((payload: SocketInitializedPayload) => {
      if (payload.name === this.socketName) {
        this._onInitialized$.next(this.dispatcher);
        this.store.dispatch(new ClientSocketInitialized({socketName: this.socketName}));
        console.log(`${this.socketName} socket initialized`);
      }
    });
    this.socket.fromEvent(SocketInitializedTypes.SOCKET_INITIALIZATION_FAILED).subscribe((payload: SocketInitializedFailedPayload) => {
      if (payload.name === this.socketName) console.error(payload.error);
    });

    // authorization error handler
    this.socket.fromEvent(WebsocketAuthorizationErrorTypes.WEBSOCKET_AUTHORIZATION_ERROR).subscribe((payload: WebsocketAuthorizationErrorPayload) => {
      console.log(`authorization error`, payload);
      this.disconnect();
      this.connect();
    });

    this.registerEventListeners();
  }

  private setupAutoReconnect(interval: number) {
    this.reconnectionTimer = timer(interval);
    this.onDisconnect$.subscribe((dispatcher: Dispatcher) => this.reconnect(dispatcher));
  }

  private reconnect(disptcher: Dispatcher) {
    this.reconnectionTimer.pipe(takeUntil(this.connected$)).subscribe(() => {
      console.log(`${this.socketName} socket reconnecting...`);
      this.registerEventListeners();
      this.connect();
    });
  }

  private enableHealthCheck() {
    this.healthCheck$.subscribe(() => {
      if(this.connected()) {
        this.dispatcher.send(new HealthCheck({
          deviceId: Platform.deviceId,
          socketName: this.socketName
        }))
      }
    });
  }
}
