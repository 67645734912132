export enum UserRole {
  ADMIN = 'ADMIN',
  PATIENT = 'PATIENT',
  SOCIAL_WORKER = 'SOCIAL_WORKER',
  COMMUNITY_HEALTH_WORKER = 'COMMUNITY_HEALTH_WORKER',
  NURSE_PRACTICIONER = 'NURSE_PRACTICIONER',
  GUIDE = 'GUIDE',
  GUIDE_SUPERVISOR = 'GUIDE_SUPERVISOR',
  OTHER = 'OTHER'
}

export const patientRoles = [UserRole.PATIENT];
export const careProviderRoles = [
  UserRole.ADMIN,
  UserRole.SOCIAL_WORKER,
  UserRole.COMMUNITY_HEALTH_WORKER,
  UserRole.NURSE_PRACTICIONER,
  UserRole.GUIDE,
  UserRole.GUIDE_SUPERVISOR
];
export const adminRoles = [UserRole.ADMIN];
export const adminOrCareProvider = [...careProviderRoles].concat(adminRoles);
export const isCareProvider = (role: UserRole) =>
  careProviderRoles.includes(role);

export const UserRoleMappings = {
  [UserRole.ADMIN]: 'Admin',
  [UserRole.PATIENT]: 'Patient',
  [UserRole.SOCIAL_WORKER]: 'Social Worker',
  [UserRole.COMMUNITY_HEALTH_WORKER]: 'Community Health Worker',
  [UserRole.GUIDE]: 'Community Guide',
  [UserRole.GUIDE_SUPERVISOR]: 'Community Guide',
  [UserRole.NURSE_PRACTICIONER]: 'Nurse Practitioner'
};
