import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom
} from 'rxjs/operators';
import { selectTeamMember } from '../users/users.actions';
import { usersQueries } from '../users/users.selectors';
import { loadCohorts } from './cohort.actions';
import { CohortRequests } from './cohort.requests';

@Injectable()
export class CohortEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private cohortRequests: CohortRequests
  ) {}

  triggerScheduledEventsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectTeamMember),
      withLatestFrom(
        this.store.pipe(select(usersQueries.selectSelectedTeamMemberId))
      ),
      filter(
        ([action, selectedTeamMemberId]) =>
          selectedTeamMemberId && selectedTeamMemberId !== 'all'
      ),
      map(([action, memberId]) => {
        return loadCohorts({
          params: { ownerUserId: memberId },
          requestBody: null
        });
      })
    )
  );

  getCohorts$ = this.cohortRequests.getCohorts.createEffect(this.actions$);

  createCohort$ = this.cohortRequests.createCohort.createEffect(this.actions$);

  updateCohort$ = this.cohortRequests.patchCohort.createEffect(this.actions$);
}
