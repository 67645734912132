import { Platform } from '@act/core/platform';
import { SocketFacade } from '@act/common/sockets';
import { TodoDto } from '@act/shared/data-transfer-objects';
import { TodoEventTypes } from '@act/features/todos/events';
import { ClientBootstrap, SOCKET_NAME } from '@act/shared/models';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TodoState } from './todo.reducer';
import { todoQueries } from './todo.selectors';
import { getClientConfig } from '@act/shared/environment';
import { refreshTodos, snoozeTodo } from './todo.actions';

const url = () => {
  const config: ClientBootstrap = getClientConfig();
  return `${config.reema.serviceUris.websocketService}/todo`;
};

const socketConfig = () => ({
  url: url(),
  options: {
    path: '/socket.io',
    autoConnect: false,
    transports: ['websocket'],
    query: {
      token: Platform.getAuthToken(),
      deviceId: Platform.deviceId
    }
  }
});

@Injectable()
export class TodoFacade extends SocketFacade {
  private interval: NodeJS.Timeout;
  constructor(store: Store<TodoState>) {
    super(socketConfig, SOCKET_NAME.TODO, store);

    // every hour we should check to see if the todos have changed
    const hourInMilliseconds = 60 * 60 * 1000;
    this.interval = setInterval(() => this.refreshTodo(), hourInMilliseconds);
  }

  destroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private refreshTodo() {
    this.store.dispatch(refreshTodos());
  }

  todos$: Observable<TodoDto[]> = this.store.pipe(
    select(todoQueries.selectAllTodos)
  );

  todosLoading$: Observable<boolean> = this.store.pipe(
    select(todoQueries.selectTodosLoading)
  );

  snoozeTodo(todo: TodoDto) {
    this.store.dispatch(
      snoozeTodo({
        params: { guideId: todo.userId, todoId: todo.id },
        requestBody: null
      })
    );
  }

  protected registerEventListeners() {
    this.proxyWebsocketMessage(TodoEventTypes.TODO_CREATED);
    this.proxyWebsocketMessage(TodoEventTypes.TODO_DELETED);
    this.proxyWebsocketMessage(TodoEventTypes.TODO_UPDATED);
  }
}
