import {Directive, HostListener, Input} from '@angular/core';
import {Breakpoints} from '../models/breakpoints.interface';
import {BehaviorSubject, Subject} from 'rxjs';
import {DisplayEnvironment} from '@act/shared/models';
import {Platform} from '@act/core/platform';
import {debounceTime} from 'rxjs/operators';
import {getValueOfObservable} from '@act/common/utilities';

@Directive({
  selector: '[updateDisplayEnvironment]'
})
export class UpdateDisplayEnvironmentDirective {
  private defaultBreakpointOrder = ['small', 'medium', 'large', 'xl'];
  private defaultBreakpoints: Breakpoints = {
    small: 400,
    medium: 768,
    large: 1024,
    xl: 1468
  };
  private displayEnvironment: BehaviorSubject<DisplayEnvironment> = new BehaviorSubject(null);
  private doChanges: Subject<void> = new Subject();

  @Input() updateDisplayEnvironment;
  @Input() breakpoints: Breakpoints = this.defaultBreakpoints;
  @Input() breakpointOrder: string[] = this.defaultBreakpointOrder;

  @HostListener('window:resize', ['$event'])
  onResize($event: Event) {
    this.doChanges.next();
  }

  constructor() {
    Platform.displayEnvironment = this.displayEnvironment.asObservable();
    this.doUpdate();
    this.doChanges.pipe(debounceTime(200)).subscribe(() => this.doUpdate());
  }

  private doUpdate() {
    const breakpoint = this.calculateBreakpoint(window.innerWidth);
    const computed = {
      window: {
        height: window.innerHeight,
        width: window.innerWidth
      },
      media: {
        breakpoint
      }
    };
    this.displayEnvironment.next(computed);
  }

  private calculateBreakpoint(width: number) {
    let i = 0;
    for (let bp of this.breakpointOrder) {
      const val = {
        size: this.breakpoints[bp],
        name: bp
      };
      // we're smaller than the smallest defined BP, return that one.
      if (width < this.breakpoints[bp] && i === 0) return val;

      // we're at the last one so if we haven't returned, return now
      if (!this.defaultBreakpointOrder[i + 1]) return val;

      if (width > this.breakpoints[bp] && width < this.breakpoints[i + 1]) return val;
    }
  }
}
