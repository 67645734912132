import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { FileState } from './file.reducer';
import { fileQueries } from './file.selectors';

import { File, JSFile } from '@act/server/models/files';
import { getValueOfObservable } from '@act/common/utilities';
import { HttpClient } from '@angular/common/http';
import { ClientBootstrap } from '@act/shared/models';
import { CLIENT_CONFIG } from '@act/shared/environment';
import { AuthFacade } from 'libs/shared/auth/clients/src/lib/services/auth.facade';
import { getFileInfo } from './file.actions';
import { FileRequests } from './file.requests';

@Injectable()
export class FileFacade {
  constructor(
    @Inject(CLIENT_CONFIG) private config: ClientBootstrap,
    private store: Store<{ files: FileState }>,
    private http: HttpClient,
    private authFacade: AuthFacade,
    private fileRequests: FileRequests
  ) {}

  loadFileInfo(id: string): void {
    if (
      !getValueOfObservable(this.file$(id)) &&
      !getValueOfObservable(this.isFileLoading$(id))
    ) {
      this.store.dispatch(getFileInfo({ params: { id }, requestBody: null }));
    }
  }

  isFileLoading$(id: string): Observable<boolean> {
    return this.store.pipe(select(fileQueries.selectFileLoading, { id: id }));
  }

  filesLoading$: Observable<boolean> = this.store.select(
    fileQueries.filesLoading
  );

  file$(id: string): Observable<File> {
    return this.store.pipe(select(fileQueries.selectFile, { id: id }));
  }

  getContentUrl(fileId: string): string {
    return `${
      this.config.reema.serviceUris.websocketService
    }/files/${fileId}/content?token=${this.authFacade.getToken()}`;
  }

  uploadFiles(files: JSFile[]): Promise<File[]> {
    const formData = files.reduce((fd, f) => {
      fd.append('file', f);
      return fd;
    }, new FormData());

    return this.fileRequests.uploadFiles.makeRequest({
      body: formData
    });

    // const url = `${this.config.reema.serviceUris.websocketService}/files/upload`;
    // return this.http
    //   .post<File[]>(url, formData, {
    //     observe: 'response'
    //   })
    //   .pipe(map(response => response.body))
    //   .toPromise();
  }
}
