import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  noteCreated,
  editNote,
  toggleNoteEditing,
  stopEditingNote
} from './note.actions';
import { map, withLatestFrom } from 'rxjs/operators';
import { selectEditedNoteId } from './note.selectors';
import { select, Store } from '@ngrx/store';
import { NoteRequests } from './note.requests';

@Injectable()
export class NoteEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private noteRequests: NoteRequests
  ) {}
  createNote$ = this.noteRequests.postNote.createEffect(this.actions$);

  updateNote$ = this.noteRequests.patchNote.createEffect(this.actions$);

  deleteNote$ = this.noteRequests.deleteNote.createEffect(this.actions$);

  createComment$ = this.noteRequests.createComment.createEffect(this.actions$);

  editCreatedNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteCreated),
      map(action => editNote({ note: action.responseBody }))
    )
  );

  toggleNoteEditing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleNoteEditing),
      withLatestFrom(this.store.pipe(select(selectEditedNoteId))),
      map(([action, currentNoteEddited]) => {
        if (action.note.id === currentNoteEddited) {
          return stopEditingNote({ noteId: action.note.id });
        }
        return editNote({ note: action.note });
      })
    )
  );
}
