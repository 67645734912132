import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, concatMap } from 'rxjs/operators';
import {Action} from '@ngrx/store';

import { DispatcherActionTypes, Dispatch } from './dispatcher.actions';
import { DispatchEventPayload } from '../models/dispatch-event-payload.interface';
import { Socket } from 'ngx-socket-io';
import { ClientEvent } from '@act/shared/models';
import { Subject, concat } from 'rxjs';

@Injectable()
export class DispatcherEffects {
  dispatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DispatcherActionTypes.DISPATCH),
      map((action: Dispatch) => action.payload),
      concatMap((payload: DispatchEventPayload) => this.dispatchEvent(payload.socket, payload.event))
    )
  );

  constructor(private actions$: Actions) {}

  private dispatchEvent(socket: Socket, event: ClientEvent<any>) {
    const obs = new Subject<Action>();
    socket.emit(event.type, event.payload, (res: any) => {
      if (res) {
        obs.next(<any>res);
      } else {
        obs.next(<any>{ type: 'Dispatcher Error', payload: { error: res } });
      }

      obs.complete();
    });

    return obs.asObservable();
  }
}
