import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

interface LetContext<T> {
  $implicit: T;
  let: T;
}

@Directive({
  selector: '[let]'
})
export class LetDirective<T> {
  private _context: LetContext<T> = {$implicit: null, let: null};
  
  constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<T>>) {
    _viewContainer.createEmbeddedView(_templateRef, this._context);
  }
  
  @Input()
  set let(value: T) {
    this._context.$implicit = this._context.let = value;
  }
}
