import {
  CallDto,
  CallOutcomeDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { CallStatus } from '@act/shared/models';
import { Dictionary } from '@ngrx/entity';
import { FeedCardData } from '@act/shared/ui/feed-card';
import {
  getPhoneNumberInfo,
  getRoughDurationString,
  getTimeString
} from '../helpers/format-data.helper';

export const outcomeRequirementDateCutoff = new Date(2021, 9, 16).getTime();

export const getCardDataFromCall = (
  call: CallDto,
  userMap: Dictionary<UserDto>,
  callOutcomesMap: Dictionary<CallOutcomeDto>,
  getContentUrl: (fileId: string) => string
): FeedCardData => {
  const originator = getPhoneNumberInfo(call.originator, userMap);
  const receiver = getPhoneNumberInfo(call.receiver, userMap);

  const outcome = callOutcomesMap[call.outcomeId];
  let outcomeString = 'Unknown';
  if (outcome) {
    outcomeString =
      outcome.key === 'other'
        ? call.outcomeDetails || outcome.text
        : outcome.text;
  }

  const getDescription = (callStatus: CallStatus, outcomeString: string) => {
    switch (callStatus) {
      case CallStatus.CONNECTING:
        return `Connecting...`;
      case CallStatus.DECLINED:
        return `Declined, ${outcomeString}`;
      case CallStatus.FAILED:
        return `Failed, ${outcomeString}`;
      case CallStatus.MISSED:
        return `Missed, ${outcomeString}`;
      case CallStatus.ACTIVE:
        return `Active, ${outcomeString}`;
      case CallStatus.ENDED:
        return `Completed, ${outcomeString}`;
    }
  };

  let icon = '';
  let iconColor = '';
  if (call.incoming) {
    icon = 'call_incoming';
    iconColor = 'incoming';
    if (call.status === CallStatus.MISSED) {
      icon = 'call_incoming_missed';
      iconColor = 'incoming-missed';
    }
  } else {
    icon = 'call_outgoing';
    iconColor = 'outgoing';
    if (call.status === CallStatus.MISSED) {
      icon = 'call_outgoing_missed';
      iconColor = 'outgoing-missed';
    }
  }
  if (call.status === CallStatus.FAILED) {
    icon = 'call_failed';
    iconColor = 'failure';
  }

  return {
    icon,
    iconColor,

    titleParts: [
      {
        text: call.incoming ? 'Incoming Call' : 'Outbound Call',
        bold: true
      },
      ...(originator
        ? [
            {
              text: 'from'
            },
            {
              text: originator.title,
              bold: true,
              details: originator.details
            }
          ]
        : []),

      ...(receiver
        ? [
            {
              text: 'to'
            },
            {
              text: receiver.title,
              bold: true,
              details: receiver.details
            }
          ]
        : [])
    ],
    metadataParts: [
      getTimeString(call.timeStarted),
      call.timeEnded && call.timeConnected
        ? getRoughDurationString(call.timeEnded - call.timeConnected)
        : null
    ]
      .filter(a => a)
      .map(s => ({ text: s })),
    details: getDescription(call.status, outcomeString),
    audio: call.voicemailFileId ? getContentUrl(call.voicemailFileId) : null,
    note: call.note,
    badges:
      !outcome && call.timeStarted > outcomeRequirementDateCutoff
        ? [
            {
              text: 'Incomplete',
              color: 'red',
              details: 'No outcome for call'
            }
          ]
        : []
  };
};
