import { CohortDto } from '@act/shared/data-transfer-objects';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import {
  cohortCreated,
  cohortCreationError,
  cohortsLoaded,
  cohortsLoadError,
  cohortUpdated,
  cohortUpdateError,
  createCohort,
  loadCohorts,
  updateCohort
} from './cohort.actions';

export const cohortFeatureKey = 'cohorts';

export interface State extends EntityState<CohortDto> {
  loadingCohorts: boolean;
}

export const adapter: EntityAdapter<CohortDto> = createEntityAdapter<
  CohortDto
>();

export const initialState: State = adapter.getInitialState({
  loadingCohorts: false
});

const cohortReducer = createReducer(
  initialState,
  on(loadCohorts, (state, action) => {
    const newState = adapter.removeAll(state);

    return {
      ...newState,
      loadingCohorts: true
    };
  }),
  on(cohortsLoaded, (state, action) => {
    const newState = adapter.addMany(action.responseBody, state);

    return {
      ...newState,
      loadingCohorts: false
    };
  }),
  on(cohortsLoadError, state => {
    return { ...state, loadingCohorts: false };
  }),
  on(createCohort, state => {
    return { ...state, loadingCohorts: true };
  }),
  on(cohortCreated, (state, action) => {
    const newState = adapter.addOne(action.responseBody, state);
    return {
      ...newState,
      loadingCohorts: false
    };
  }),
  on(cohortCreationError, state => {
    return { ...state, loadingCohorts: false };
  }),
  on(updateCohort, state => {
    return { ...state, loadingCohorts: true };
  }),
  on(cohortUpdated, (state, action) => {
    const newState = adapter.upsertOne(action.responseBody, state);
    return {
      ...newState,
      loadingCohorts: false
    };
  }),
  on(cohortUpdateError, state => {
    return { ...state, loadingCohorts: false };
  })
);

export const cohortAdapter = adapter;

export function reducer(state: State | undefined, action: Action) {
  return cohortReducer(state, action);
}
