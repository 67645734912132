import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UsersFacade } from '@act/features/users/data-access';
import * as moment from 'moment';
import {
  AddressDto,
  MemberFiltersDto,
  UserDto
} from '@act/shared/data-transfer-objects';
import { Platform } from '@act/core/platform';
import { MemberStageFacade } from '@act/features/member-stage/data-access';
import { UserNeedFacade } from 'libs/feed/src/lib/+state/need/need.facade';
import { PatientsFacade } from '@act/features/patients/data-access';
import { ChatFacade } from '@act/feed';
import { CohortFacade } from 'libs/features/users/data-access/src/lib/+state/cohort/cohort.facade';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { MapService } from '../../services/map.service';
import { getValueOfObservable } from '@act/common/utilities';

@Component({
  selector: 'act-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = [
    'select',
    'cohort',
    'name',
    'nextEventDate',
    'nextEventType',
    'memberStatusId',
    'lastEventAt',
    'firstName'
  ];
  eventTypes: { [key: string]: string } = {
    call: 'Phone Call',
    message: 'Text',
    reminder: 'Reminder',
    tbd: 'Auto-Reminder',
    visit: 'Visit'
  };
  public membersData: MatTableDataSource<UserDto>;
  selection = new SelectionModel<UserDto>(true, []);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() membersLoading: boolean;
  @Input() showFiltersBtn: boolean;
  @Input() idOfSelectedGuide: string;
  @Input() canResetFilters: boolean;
  @Output() showFilters: EventEmitter<void> = new EventEmitter();
  guideMembers: UserDto[];
  listFilters = false;
  @Input() memberFilters: MemberFiltersDto;
  currentDate = moment().format('YYYY-MM-DD');
  dialogRef: MatDialogRef<any>;
  feedFilter = '';
  viewingProfile = false;
  canToggleProfile = false;
  membersListIsOpen = true;
  mapView: boolean;
  membersSubscription: Subscription;
  mapViewSubscription: Subscription;
  @Output() selectedUserIdsEvent: EventEmitter<string[]> = new EventEmitter();
  constructor(
    public usersFacade: UsersFacade,
    private dialog: MatDialog,
    public platform: Platform,
    public userNeedFacade: UserNeedFacade,
    public memberStageFacade: MemberStageFacade,
    private chatFacade: ChatFacade,
    private patientsFacade: PatientsFacade,
    public cohortFacade: CohortFacade,
    public mapService: MapService
  ) {}

  ngOnInit() {
    this.mapView = getValueOfObservable(this.mapService.isMapView$);
    this.membersData = new MatTableDataSource();
    this.membersSubscription = this.usersFacade.members$.subscribe(members => {
      this.guideMembers = members;
      this.getMembersList();
    });
    this.mapViewSubscription = this.mapService.isMapView$.subscribe(value => {
      this.mapView = value;
    });
  }

  ngOnDestroy() {
    this.membersSubscription.unsubscribe();
    this.mapViewSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.membersData.sort = this.sort;
  }

  getMembersList() {
    this.selection.clear();
    this.selectedUserIdsEvent.emit(null);
    this.membersData = new MatTableDataSource(this.guideMembers);
    this.membersData.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'cohort':
          return item.cohort && item.cohort.name;
        case 'memberStatusId':
          return item.memberStatus && item.memberStatus.name;
        default:
          return item[property];
      }
    };
    this.membersData.sort = this.sort;
  }

  getProperty(item, property) {
    switch (property) {
      case 'cohort':
        return item[property].name;
      case 'memberStatusId':
        return item.memberStatus.name;
      default:
        return item[property];
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.membersData.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.membersData.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: UserDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${
      this.selection.isSelected(row) ? 'deselect' : 'select'
    } row ${row.name + 1}`;
  }

  removeSingleFilter(filterValue: string | number, filterType: string) {
    this.usersFacade.updateMemberFilter(filterType, filterValue, 'remove');
  }

  clearAll() {
    this.usersFacade.clearMemberFilter();
  }

  /** Open the dialog for member list view */
  onMemberView(row: UserDto) {
    this.usersFacade.selectMember(row.id);
  }

  /** Get primary address of member */
  getPrimaryAddress(addresses: AddressDto[]) {
    let addressString = '';

    if (addresses.length) {
      const address = addresses.find(i => i.primary);

      if (address) {
        addressString = `${address.line1}${
          address.line2 ? ' ' + address.line2 : ''
        }, ${address.city} ${address.state} ${address.zip}`;
      }
    }
    return addressString;
  }

  selectedMemberIds() {
    const selectedUserIds = this.selection.selected.map(({ id }) => id);
    this.selectedUserIdsEvent.emit(selectedUserIds);
  }
}
