import { type } from '@act/common/utilities';
import { Action } from '@ngrx/store';

import {
  CreateEnrollmentActionPayload,
  EnrollmentCreatedActionPayload,
  EnrollmentsLoadedActionPayload,
  LoadEnrollmentsActionPayload
} from '@act/features/enrollments/models';

export const WebsocketEnrollmentsActionTypes = {
  // TODO need this
  LOAD_ENROLLMENTS: type('[enrollments] load enrollments'),
  ENROLLMENTS_LOADED: type('[enrollments] enrollments loaded'),
  LOAD_ENROLLMENTS_FAILED: type('[enrollments] load enrollments failed'),

  // TODO need this
  CREATE_ENROLLMENT: type('[enrollments] create enrollment'),

  // TODO need this
  ENROLLMENT_CREATED: type('[enrollments] enrollment created'),
  CREATE_ENROLLMENT_FAILED: type('[enrollments] create enrollment failed'),

  SELECT_ENROLLMENT: type('[enrollments] select enrollment'),
  ENROLLMENT_SELECTED: type('[enrollments] enrollment selected'),

  // TODO need this
  ENROLLMENT_UPDATED: type('[enrollments] enrollment updated')
};

// TODO need this
export class WebsocketEnrollmentCreatedAction implements Action {
  public type = WebsocketEnrollmentsActionTypes.ENROLLMENT_CREATED;

  constructor(public payload: EnrollmentCreatedActionPayload) {}
}

// TODO need this
export class WebsocketEnrollmentsLoadedAction implements Action {
  public type = WebsocketEnrollmentsActionTypes.ENROLLMENTS_LOADED;

  constructor(public payload: EnrollmentsLoadedActionPayload) {}
}
